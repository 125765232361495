import { useEffect, useRef, memo } from "react";
import { AnimatedSprite } from "@pixi/react";
import { useVFX } from "@/hooks/useVFX";

export const VFXComponent = memo(
  ({
    type,
    x,
    y,
    scale = 0.5,
    speed = 0.5,
    isActive,
    loop = false,
    onComplete,
  }) => {
    const { loadedTextures } = useVFX();
    const spriteRef = useRef(null);

    useEffect(() => {
      if (spriteRef.current) {
        if (isActive && loadedTextures[type]) {
          spriteRef.current.textures = loadedTextures[type];
          spriteRef.current.play();
        } else if (!isActive) {
          spriteRef.current.stop();
        }
      }
    }, [isActive, type, loadedTextures]);

    if (!isActive || !loadedTextures[type]) return null;

    return (
      <AnimatedSprite
        ref={spriteRef}
        textures={loadedTextures[type]}
        isPlaying={true}
        x={x}
        y={y}
        anchor={0.5}
        animationSpeed={speed}
        scale={scale}
        zIndex={0}
        loop={loop}
        onComplete={() => onComplete(type)}
      />
    );
  }
);

VFXComponent.displayName = "VFXComponent";
