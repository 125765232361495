import { LinearProgress, Modal, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import { backgroundColorHex } from "@/theme/colors";
import { H6 } from "./Typography";
import { useImageLoadStore } from "@/hooks/useImageLoadStore";
import { SmartImage } from "@/components/SmartImage";

const MIN_TIME_ELAPSED = 2000;

export function RootOverlay() {
  const initialOpen = import.meta.env.PROD;
  const [hasMinTimeElapsed, setHasMinTimeElapsed] = useState(false);
  const [open, setOpen] = useState(initialOpen);
  const { isAllLoaded, getProgress, reset } = useImageLoadStore();
  const loaded = isAllLoaded();

  useEffect(() => {
    if (import.meta.env.PROD) {
      // Set minimum display time of 2 seconds in production
      const timer = setTimeout(() => {
        setHasMinTimeElapsed(true);
      }, MIN_TIME_ELAPSED);
      return () => clearTimeout(timer);
    } else {
      // In development, consider minimum time as already elapsed
      setHasMinTimeElapsed(true);
    }
  }, []);

  useEffect(() => {
    if (open) {
      // Reset the image load tracking when the overlay opens
      reset();
    }
  }, [open, reset]);

  useEffect(() => {
    if (import.meta.env.DEV) {
      setOpen(false);
    }
    if (loaded && hasMinTimeElapsed) {
      // Close the overlay only when both images are loaded AND minimum time has elapsed
      setOpen(false);
    }
  }, [loaded, hasMinTimeElapsed]);

  const progress = getProgress();

  return (
    <Modal
      open={open}
      sx={{
        height: "100vh",
        overflow: "hidden",
        backgroundColor: backgroundColorHex,
        padding: "40px 40px",
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "transparent",
          },
        },
      }}
    >
      <Stack
        spacing={2}
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "flex-end",
          ":focus-visible": {
            outline: "none",
          },
        }}
      >
        <SmartImage
          src="/images/backgrounds/splash2.webp"
          alt="Splash"
          priority
          width={700}
          height={300}
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "auto",
            zIndex: -1,
          }}
        />

        <LinearProgress
          variant="determinate"
          value={progress}
          color="primary"
          sx={{ width: "100%", height: 14 }}
        />
        <H6>LOADING… {`${Math.round(progress)}%`}</H6>
      </Stack>
    </Modal>
  );
}
