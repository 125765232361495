import { ability_V1, ability_V3, ability_V4, ability_V5 } from "./sounds";

export const ABILITY_SOUNDS = {
  ability_V1: ability_V1,
  ability_V3: ability_V3,
  ability_V4: ability_V4,
  ability_V5: ability_V5,
};
export const VFX_SPRITES = {
  lightning_1: { path: "/vfx/lightning_1/tile", frames: 9 },
  lightning_2: { path: "/vfx/lightning_2/tile", frames: 9 },
  smoke: { path: "/vfx/smoke_1/tile", frames: 25 },
  smoke_3: { path: "/vfx/smoke_3/tile", frames: 16 },
  hit_1: { path: "/vfx/hit_11/tile", frames: 16 },
  aura_3: { path: "/vfx/aura_3/tile", frames: 10, loop: true },
  aura_13: {
    path: "/vfx/aura_13/tile",
    frames: 10,
    format: "webp",
    loop: true,
  },
  aura_53c: {
    path: "/vfx/aura_53c/tile",
    frames: 19,
    format: "webp",
    loop: true,
    duration: 2300, // 4 times
    scale: 0.5,
  },
  ability_V1: {
    path: "/vfx/ability_V1/tile",
    frames: 8,
    format: "webp",
    speed: 0.4,
  },
  ability_V3: {
    path: "/vfx/ability_V3/tile",
    frames: 5,
    format: "webp",
    scale: 0.6,
    speed: 0.3,
  },
  ability_V5: {
    path: "/vfx/ability_V5/tile",
    frames: 4,
    format: "webp",
    scale: 1.4,
    speed: 0.35,
  },
  ability_V4: {
    path: "/vfx/ability_V4/tile",
    frames: 20,
    format: "webp",
    scale: 0.7,
  },
};
