import { create } from "zustand";
import { gameStatsActions } from "../actions/gameStats";
import { combatActions } from "../actions/combatActions";

const ENEMY_ATTACK_RATE = 2000;

const initialState = {
  enemy: null,
  character: null,
  enemyAttackIntervalId: null,
};

export const useEnemyStore = create((set) => ({
  ...initialState,

  setEnemy: (enemy) => set({ enemy }),

  setCharacter: (character) => set({ character }),

  setFirstEnemy: (dungeon, worldId) => {
    const firstEnemy = gameStatsActions.calculateEnemyStats(
      dungeon.checkpoints[0],
      worldId
    );
    set({ enemy: firstEnemy });
  },

  setNextEnemy: (dungeon, worldId, nextCheckpointIndex) => {
    const nextEnemy = gameStatsActions.calculateEnemyStats(
      dungeon.checkpoints[nextCheckpointIndex],
      worldId
    );
    set({ enemy: nextEnemy });
  },

  startEnemyAttackInterval: () => {
    const enemyAttackIntervalId = setInterval(
      () => combatActions.enemyAttack(),
      ENEMY_ATTACK_RATE
    );
    set({ enemyAttackIntervalId });
  },

  clearEnemyAttackInterval: () => {
    set((state) => {
      if (state.enemyAttackIntervalId) {
        clearInterval(state.enemyAttackIntervalId);
      }
      return { enemyAttackIntervalId: null };
    });
  },

  reset: () => {
    set((state) => {
      if (state.enemyAttackIntervalId) {
        clearInterval(state.enemyAttackIntervalId);
      }
      return initialState;
    });
  },
}));
