export function createClaimedOffersMap(limitedTimeOffers) {
  const map = new Map();
  limitedTimeOffers?.forEach((offer) => {
    if (offer.claimed) {
      map.set(offer.offerId, true);
    }
  });
  return map;
}

export function isItemAvailable(item, claimedOffersMap, userPasses) {
  // VIP pass check
  if (item.id === "vip-pass") {
    return !userPasses?.vip;
  }

  // Limited time offer check
  if (claimedOffersMap.get(item.id)) {
    return false;
  }

  return true;
}

export function filterCategoryItems(categories, claimedOffersMap, userPasses) {
  if (!categories) return [];

  return categories.map((category) => ({
    ...category,
    items: category.items.filter((item) =>
      isItemAvailable(item, claimedOffersMap, userPasses)
    ),
  }));
}

export function getValidOffer(offers = [], index = 0, shopItems = []) {
  if (!offers.length) return null;
  const offer = offers[index];
  if (!offer || offer.claimed) return null;

  // Find the package details from shopItems
  const offerPackage = shopItems
    ?.find((category) => category.name === "Packs")
    ?.items?.find((item) => item.id === offer.offerId);

  return offerPackage ? { ...offer, ...offerPackage } : null;
}

export function getVIPOffer(shopItems = []) {
  return shopItems
    ?.find((i) => i.name === "Packs")
    ?.items?.find((p) => p.id === "vip-pass");
}
