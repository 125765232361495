import { useState } from "react";
import { Paragraph, Small } from "@/components/Typography";
import { backgroundColorHex } from "@/theme/colors";
import { Modal, Stack } from "@mui/material";
import { useRefreshUser } from "@/hooks/useRefreshUser";
import { SimpleButton } from "@/components/SimpleButton";
import { SimpleModal } from "@/components/SimpleModal";
import { useUserStore } from "../logic/useUserStore";
import { useAppStore } from "@/hooks/useAppStore";

export function DebugModal({ open, onClose }) {
  const { version } = useAppStore();
  const { resetUser } = useUserStore();
  const refreshUser = useRefreshUser();
  const [resetModalOpen, setResetModalOpen] = useState(false);

  const handleResetUser = async () => {
    const resetResult = await resetUser();
    if (resetResult.success) {
      console.log("User reset confirmed");
      setResetModalOpen(false);
      await refreshUser();
      onClose();
    } else {
      console.error("Failed to reset user:", resetResult.error);
    }
  };

  return (
    <>
      <SimpleModal open={open} onClose={onClose} title="Debug">
        <Stack spacing={2} px={3}>
          <Stack alignItems="center" spacing={2} justifyContent="space-between">
            <SimpleButton
              color="red"
              size="S"
              onClick={() => setResetModalOpen(true)}
            >
              Reset User
            </SimpleButton>
            {/* <SimpleButton color="green" size="S" onClick={handleGetGems}>
              Get 1000 Gems
            </SimpleButton>
            <SimpleButton
              color="green"
              size="S"
              onClick={() => navigate("/preview/vfx")}
            >
              VFX Tester
            </SimpleButton>
            <SimpleButton
              color="green"
              size="S"
              onClick={() => navigate("/preview/pixi")}
            >
              Pixi Tester
            </SimpleButton> */}
          </Stack>
          <Stack textAlign="center">
            <Small>{version}</Small>
          </Stack>
        </Stack>
      </SimpleModal>

      <Modal
        open={resetModalOpen}
        sx={{
          backgroundColor: backgroundColorHex,
          userSelect: "none",
        }}
      >
        <Stack
          spacing={2}
          px={6}
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            ":focus-visible": {
              outline: "none",
            },
          }}
        >
          <Paragraph>
            Are you sure you want to reset all your user data? This action
            cannot be undone.
          </Paragraph>
          <Stack direction="row" spacing={2}>
            <SimpleButton size="S" onClick={() => setResetModalOpen(false)}>
              Cancel
            </SimpleButton>
            <SimpleButton color="red" size="S" onClick={handleResetUser}>
              Confirm
            </SimpleButton>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
}
