import { SimpleModal } from "../../components/SimpleModal";
import { Box, Stack } from "@mui/material";
import { H5, Paragraph } from "../../components/Typography";
import { SimpleButton } from "../../components/SimpleButton";
import { SmartImage } from "@/components/SmartImage";
import { SlowStarEffect } from "@/components/effects/SlowStarEffect";
import { useTranslation } from "react-i18next";
import { CountdownTimer } from "@/components/CountdownTimer";
import { PurchaseSuccessModal } from "./PurchaseSuccessModal";
import { useEffect, useState } from "react";
import { generateInvoice, getInvoice, getPendingChests } from "@/lib/api";
import { openInvoice } from "@/utils/telegram";
import { useRefreshUser } from "@/hooks/useRefreshUser";
import { useAlert } from "@/hooks/useAlert";
import { useUserStore } from "../logic/useUserStore";
import { useChestStore } from "../logic/useChestStore";

export function LTOModal({ open, onClose, onPurchased, item }) {
  const { t } = useTranslation();
  const { setPendingChests } = useChestStore();
  const { user } = useUserStore();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const refreshUser = useRefreshUser();
  const { setError } = useAlert();

  const handlePremiumPurchase = async () => {
    try {
      const { data } = await generateInvoice({
        productId: item.id,
        jwt: user.jwt,
      });
      console.log("👉  invoice link:", data.invoiceLink);
      setInvoiceId(data?.invoice?.id);
      await openInvoice(data?.invoiceLink);

      // Check for new chests after a delay
      setTimeout(async () => {
        try {
          const response = await getPendingChests({
            userId: user.id,
            jwt: user.jwt,
          });
          setPendingChests(response.data.chests);
        } catch (error) {
          console.error("Error fetching pending chests:", error);
        }
      }, 5000); // 5 second delay
    } catch (error) {
      setError(error.response?.data?.error || error.message);
    }
  };

  const handleClose = () => {
    onClose();
    setInvoiceId(null);
    onPurchased(item);
  };

  useEffect(() => {
    let intervalId;
    if (invoiceId) {
      intervalId = setInterval(async () => {
        try {
          const { data } = await getInvoice({ invoiceId, jwt: user.jwt });
          if (data.status === "PAID") {
            clearInterval(intervalId);
            setShowSuccessModal(true);
            refreshUser();
          }
        } catch (error) {
          console.error("Error checking invoice status:", error);
        }
      }, 2000); // Check every 2 seconds
    }
    return () => clearInterval(intervalId);
  }, [invoiceId]);

  return (
    <>
      <SimpleModal
        open={open}
        onClose={onClose}
        modalSx={{ background: item.background }}
        titleSx={{ background: item.background }}
      >
        {item.expiresAt && (
          <Box sx={{ textAlign: "center" }}>
            <CountdownTimer end={item.expiresAt} size="L" />
          </Box>
        )}
        <Stack
          spacing={0}
          textAlign="center"
          alignItems="center"
          position="relative"
        >
          <Box sx={{ position: "absolute", height: 120, width: 120 }}>
            <SmartImage
              scope="modal"
              src="/images/effects/burst_1.webp"
              alt="Background"
              height={100}
              width={100}
              style={{
                position: "absolute",
                top: 0,
                left: "-20%",
                transform: "translateY(-50%)",
                width: "140%",
                height: "auto",
                animation: "rotateImage 20s linear infinite",
                zIndex: -1,
              }}
            />
          </Box>
          <SmartImage
            src={item.image}
            alt={item.name}
            width={200}
            height={200}
            style={{ margin: "0 auto", width: 160, height: 160 }}
          />
          <SlowStarEffect
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: "0 auto",
              width: 160,
              height: 160,
            }}
          />

          {["gems", "runes", "pack"].includes(item?.type) && (
            <>
              <Stack mb={2} spacing={1}>
                <Paragraph>{t("store.buy", { title: item.title })}</Paragraph>
                <H5>= ${item.price}</H5>
              </Stack>
              <SimpleButton
                color="green"
                onClick={handlePremiumPurchase}
                image="/images/icons/tg_star.webp"
              >
                {item.stars}
              </SimpleButton>
            </>
          )}

          {["chest", "hero", "gold"].includes(item.type) && (
            <>
              <Stack mb={2} spacing={1}>
                <Paragraph>
                  {t("store.buy", { title: item.title || item.name })}
                </Paragraph>
              </Stack>
              <SimpleButton
                color="green"
                onClick={handlePremiumPurchase}
                image={item.price > 0 && "/images/icons/gem.webp"}
              >
                {item.price === 0 ? "FREE" : item.price}
              </SimpleButton>
            </>
          )}
        </Stack>
      </SimpleModal>

      <PurchaseSuccessModal
        open={showSuccessModal}
        onClose={handleClose}
        item={item}
      />
    </>
  );
}
