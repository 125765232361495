import { SimpleModal } from "../../components/SimpleModal";
import { Box, Stack } from "@mui/material";
import { SimpleButton } from "../../components/SimpleButton";
import { SmartImage } from "@/components/SmartImage";
import { SlowStarEffect } from "@/components/effects/SlowStarEffect";
import { useTranslation } from "react-i18next";
import { Paragraph } from "@/components/Typography";
import ConfettiExplosion from "react-confetti-explosion";
import { confettiProps } from "@/constants";

export function PurchaseSuccessModal({ open, onClose, item }) {
  const { t } = useTranslation();
  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={t("store.success")}
      modalSx={{ background: item.background }}
      titleSx={{ background: item.background }}
    >
      <Stack
        spacing={0}
        textAlign="center"
        alignItems="center"
        position="relative"
      >
        <Box sx={{ position: "absolute", height: 120, width: 120 }}>
          <SmartImage
            scope="modal"
            src="/images/effects/burst_1.webp"
            alt="Background"
            height={100}
            width={100}
            style={{
              position: "absolute",
              top: 0,
              left: "-20%",
              transform: "translateY(-50%)",
              width: "140%",
              height: "auto",
              animation: "rotateImage 20s linear infinite",
              zIndex: -1,
            }}
          />
        </Box>

        <ConfettiExplosion
          {...confettiProps}
          zIndex={100000}
          style={{
            position: "absolute",
          }}
        />

        <SmartImage
          src={item.image}
          alt={item.name}
          width={160}
          height={160}
          style={{ margin: "0 auto" }}
        />

        <SlowStarEffect
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "0 auto",
            width: 160,
            height: 160,
          }}
        />

        <Stack mb={2} spacing={1}>
          <Paragraph>
            {t("store.successMessage", {
              amount: item.amount,
              type: item.type,
            })}
          </Paragraph>
        </Stack>

        <SimpleButton color="green" onClick={onClose}>
          Ok
        </SimpleButton>
      </Stack>
    </SimpleModal>
  );
}
