import { useState, createContext, useCallback, useMemo } from "react";
import * as PIXI from "pixi.js";
import { VFX_SPRITES } from "@/utils/vfxSprites";
import { CDN_URL } from "@/constants";

export const VFXContext = createContext();

export function VFXManager({ children }) {
  const [activeEffects, setActiveEffects] = useState({});
  const [loadedTextures, setLoadedTextures] = useState({});

  const triggerVFX = useCallback((type, x, y, scale = 0.5) => {
    let effectType = type;
    if (type === "lightning") {
      effectType = Math.random() < 0.5 ? "lightning_1" : "lightning_2";
    }

    const {
      loop = false,
      speed = 0.5,
      scale: configScale,
    } = VFX_SPRITES[effectType];

    setActiveEffects((prev) => ({
      ...prev,
      [effectType]: {
        x,
        y,
        scale: configScale || scale,
        speed,
        isActive: true,
        loop,
      },
    }));
  }, []);

  const onEffectComplete = useCallback((type) => {
    setActiveEffects((prev) => ({
      ...prev,
      [type]: { ...prev[type], isActive: false },
    }));
  }, []);

  const preloadAllVFX = useCallback(async () => {
    try {
      const textures = {};
      await Promise.all(
        Object.entries(VFX_SPRITES).map(async ([type, config]) => {
          const { path, frames, format = "png" } = config;
          textures[type] = await Promise.all(
            Array.from({ length: frames }, (_, i) =>
              PIXI.Assets.load(
                `${CDN_URL}${path}${i.toString().padStart(3, "0")}.${format}`
              )
            )
          );
        })
      );
      setLoadedTextures(textures);
      console.log("VFX assets preloaded successfully");
    } catch (error) {
      console.error("Error preloading VFX assets:", error);
    }
  }, []);

  const preloadVFX = useCallback(async (type) => {
    try {
      if (!VFX_SPRITES[type]) {
        throw new Error(`VFX type '${type}' not found`);
      }

      const { path, frames, format = "png" } = VFX_SPRITES[type];
      const textures = await Promise.all(
        Array.from({ length: frames }, (_, i) =>
          PIXI.Assets.load(
            `${CDN_URL}${path}${i.toString().padStart(3, "0")}.${format}`
          )
        )
      );

      setLoadedTextures((prev) => ({
        ...prev,
        [type]: textures,
      }));

      console.log(`VFX asset '${type}' preloaded successfully`);
    } catch (error) {
      console.error(`Error preloading VFX asset '${type}':`, error);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      triggerVFX,
      onEffectComplete,
      preloadAllVFX,
      preloadVFX,
      activeEffects,
      loadedTextures,
    }),
    [
      triggerVFX,
      onEffectComplete,
      preloadAllVFX,
      preloadVFX,
      activeEffects,
      loadedTextures,
    ]
  );

  return (
    <VFXContext.Provider value={contextValue}>{children}</VFXContext.Provider>
  );
}
