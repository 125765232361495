import {
  backgroundColorSecondary,
  borderRadiusNew,
  getTileStyles,
} from "@/theme/colors";
import { Box, darken, Stack } from "@mui/material";
import { H6 } from "@/components/Typography";
import { SmartImage } from "@/components/SmartImage";
import { useClickSound } from "@/hooks/useClickSound";
import { RarityChip } from "./RarityChip";

export function HeroItem({
  hero,
  onSelect,
  isSelected,
  isOwned,
  isActiveHero,
}) {
  const handleSelect = useClickSound(() => onSelect(hero));

  if (!hero) return null;

  const background = isSelected ? { backgroundColor: hero.color } : {};

  const text =
    (isActiveHero && "Selected") ||
    (isOwned && "OWNED") ||
    (hero.price === 0 ? "FREE" : hero.price);

  return (
    <Stack
      sx={{
        ...getTileStyles(140),
        position: "relative",
        height: "140px",
        justifyContent: "center",
        alignItems: "center",
        touchAction: "pan-y", // Allow vertical scrolling
        opacity: hero.locked ? 0.6 : 1,
        ...background,
      }}
      onClick={handleSelect}
    >
      <RarityChip rarity={hero?.rarity} />
      <SmartImage
        src={`/heroes/${hero.id}.webp`}
        alt={hero.name}
        width={110}
        height={110}
        style={{
          position: "absolute",
          top: "4%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      />

      <Box
        sx={{
          position: "absolute",
          right: "2%",
          top: "-4%",
          width: "30px",
          height: "30px",
        }}
      >
        <SmartImage
          src={`/images/stones/${hero.element}.webp`}
          alt={hero.element}
          width={50}
          height={50}
          style={{
            width: "30px",
            height: "30px",
            zIndex: 1,
          }}
        />
      </Box>

      {hero.locked && (
        <SmartImage
          src="/images/icons/Icon_ItemIcon_Lock.png"
          alt="lock"
          width={30}
          height={30}
          style={{
            position: "absolute",
            bottom: "22%",
            right: 0,
            // transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        />
      )}

      <Stack
        direction="row"
        sx={{
          backgroundColor: darken(backgroundColorSecondary, 0.2),
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          boxShadow: `
              inset 0px -4px 1px 0px rgba(0, 0, 0, 0.4), 
              inset 0px -16px 0px 2px rgba(0, 0, 0, 0.05)
          `,
          borderBottomLeftRadius: borderRadiusNew,
          borderBottomRightRadius: borderRadiusNew,
          height: "34px",
        }}
      >
        {!isOwned && hero.currency === "gems" && (
          <SmartImage
            src="/images/icons/gem.webp"
            alt="gem"
            width={30}
            height={30}
            style={{
              marginLeft: "-5px",
              marginRight: "5px",
            }}
          />
        )}
        <H6 py={1} color="#fff">
          {text}
        </H6>
      </Stack>
    </Stack>
  );
}
