import { Alert, Snackbar } from "@mui/material";
import { createContext, useState } from "react";
import { createErrorMessage } from "../utils/misc";

export const AlertContext = createContext();

export function AlertProvider({ children }) {
  const [status, setStatus] = useState();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setStatus(undefined);
  };

  function setSuccess(message = "Success") {
    setStatus({
      type: "success",
      message,
    });
  }

  function setError(error) {
    if (typeof error === "string") {
      const duration = error === "Out of energy" ? 1000 : 5000;
      setStatus(createErrorMessage(error, duration));
    } else {
      console.error(error.message);
      setStatus(createErrorMessage(error));
    }
  }

  return (
    <AlertContext.Provider value={{ status, setSuccess, setError }}>
      {children}
      <Snackbar
        open={!!status}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={status?.hideDuration}
        onClose={handleClose}
        sx={{ marginTop: "80px", maxWidth: "100%" }}
        disableWindowBlurListener
      >
        {status && (
          <Alert sx={{ maxWidth: "100%" }} severity={status?.type}>
            {status?.message}
          </Alert>
        )}
      </Snackbar>
    </AlertContext.Provider>
  );
}
