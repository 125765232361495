import { SmartImage } from "../SmartImage";

export function BurstImage({ sx, size = 50 }) {
  return (
    <SmartImage
      src="/images/effects/burst_1.webp"
      alt="Background"
      height={size}
      width={size}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        transform: "translateY(-50%)",
        width: "100%",
        height: "auto",
        animation: "rotateImage 10s linear infinite",
        zIndex: 0,
        ...sx,
      }}
    />
  );
}
