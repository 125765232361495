import { keyframes } from "@emotion/react";

export const glowAnimation = (color) => keyframes`
  0% {
    box-shadow: 
      0 0 2px ${color},
      inset 2px 2px 1px 0px rgba(255,255,255,.3), 
      7px 7px 20px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.5),
      inset 0px -7px 0px 0px rgba(0, 0, 0, 0.1),
      7px 7px 10px 0px rgba(0, 0, 0, 0.1),
      0px 4px 2px 0px rgba(0, 0, 0, 0.5)
  }
  50% {
    box-shadow: 
      0 0 10px ${color}, 
      inset 2px 2px 1px 0px rgba(255,255,255,.3), 
      7px 7px 20px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.5),
      inset 0px -7px 0px 0px rgba(0, 0, 0, 0.1),
      7px 7px 10px 0px rgba(0, 0, 0, 0.1),
      0px 4px 2px 0px rgba(0, 0, 0, 0.5)
  }
  100% {
    box-shadow: 
      0 0 2px ${color}, 
      inset 2px 2px 1px 0px rgba(255,255,255,.3), 
      7px 7px 20px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.5),
      inset 0px -7px 0px 0px rgba(0, 0, 0, 0.1),
      7px 7px 10px 0px rgba(0, 0, 0, 0.1),
      0px 4px 2px 0px rgba(0, 0, 0, 0.5)
  }
`;
