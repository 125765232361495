import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import components from "./components";
import { darkPalette } from "./colors";

const baseOptions = {
  direction: "ltr",
  typography: {
    fontFamily: "'Squirk', sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: darkPalette,
};

function createCustomTheme() {
  let theme = createTheme(baseOptions);

  theme.components = components(theme);

  return theme;
}

export const CustomTheme = ({ children }) => {
  const theme = createCustomTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
