import { ScreenModal } from "@/components/ScreenModal";
import { useEffect, useState } from "react";
import { useUserStore } from "../logic/useUserStore";
import { useAlert } from "@/hooks/useAlert";
import { Stack, Avatar, Box } from "@mui/material";
import { primary, scrollbarStyles } from "@/theme/colors";
import { SmartImage } from "@/components/SmartImage";
import { H4, H5, Small } from "@/components/Typography";
import { useSounds } from "@/hooks/useSound";
import { clickSound } from "@/utils/sounds";
import { useHapticFeedback } from "@telegram-apps/sdk-react";
import { useTranslation } from "react-i18next";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useLeaderboardStore } from "../logic/useLeaderboardStore";

export function Rank({ open, onClose }) {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState();
  const { fetchLeaderboards, leaderboards } = useLeaderboardStore();
  const { user } = useUserStore();
  const { setError } = useAlert();
  const { playSound } = useSounds();
  const hapticFeedback = useHapticFeedback(true);

  const tabs = leaderboards ? Object.keys(leaderboards) : [];

  const handleTabChange = (tab) => {
    playSound(clickSound);
    hapticFeedback?.impactOccurred("light");
    setCurrentTab(tab);
  };

  useEffect(() => {
    async function init() {
      if (!leaderboards) {
        const response = await fetchLeaderboards();
        if (!response.success) {
          setError(response.error);
        }
      }
    }
    init();
  }, []);

  useEffect(() => {
    if (tabs.length > 0 && !currentTab) {
      setCurrentTab(tabs[0]);
    }
  }, [tabs, currentTab]);

  const ITEM_HEIGHT = 90; // Adjust based on your item height

  const renderLeaderboardItem = ({ index, style }) => {
    const item = leaderboards?.[currentTab]?.users?.[index];
    if (!item) return null;

    const isUser = user && item.id === user.id;
    const medal = index < 3 ? `/images/icons/medal_${index + 1}.webp` : null;
    const name = item.username || item.firstName;
    const vipStyle = item.vip && {
      border: `2px solid ${primary.main}`,
      boxShadow: `0px 0px 20px 2px ${primary.main}`,
    };
    const cycle = item.cycle;

    return (
      <div style={style}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            padding: "10px",
            margin: "5px 0px",
            background: isUser
              ? primary.main
              : "linear-gradient(to bottom, #3B60DA, #3C61DB)",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Stack alignItems="center" flex={1}>
            {medal ? (
              <SmartImage
                src={medal}
                alt={`Medal ${index + 1}`}
                width={80}
                height={80}
                style={{
                  width: "40px",
                  height: "40px",
                  transform: "scale(1.3)",
                }}
              />
            ) : (
              <H4 sx={{ width: "30px", textAlign: "center" }}>{index + 1}</H4>
            )}
          </Stack>
          <Stack alignItems="center" flex={1}>
            {item.imageURL ? (
              <Avatar
                src={item.imageURL}
                alt={name}
                sx={{ width: 32, height: 32, ...vipStyle }}
              />
            ) : (
              <Avatar sx={{ width: 32, height: 32, ...vipStyle }}>
                {name?.[0]}
              </Avatar>
            )}
          </Stack>
          <Stack flex={4} sx={{ overflow: "hidden" }}>
            <H5 ml={1}>{name}</H5>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={0.5}
            >
              {cycle && (
                <SmartImage
                  src={`/images/badges/${cycle}.webp?v=2`}
                  height={40}
                  width={40}
                  alt={`${cycle} badge`}
                  style={{
                    width: `${40}px`,
                    height: "auto",
                    zIndex: 1,
                  }}
                />
              )}
              <Avatar
                src={item?.activeHero?.image}
                sx={{ width: 32, height: 32 }}
              />
              <Stack direction="row">
                {item?.activeItems?.map((item) => (
                  <SmartImage
                    key={item.id}
                    src={item.image}
                    height={20}
                    width={20}
                    alt={item.id}
                    loading="lazy"
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
          <H4>{item.xp.toLocaleString()}</H4>
        </Stack>
      </div>
    );
  };

  const getUserRank = () => {
    if (!user || !leaderboards || !leaderboards[currentTab]) return null;
    return leaderboards[currentTab].users.findIndex(
      (item) => item.id === user.id
    );
  };

  const userRank = getUserRank();

  return (
    <ScreenModal
      open={open}
      onClose={onClose}
      titleBgColor="#2C74F2"
      title="Ranking"
      titleImage="/images/icons/medal_gold.webp"
      contentSx={{
        padding: "0px",
        background: "linear-gradient(to bottom, #3D97E8, #6D60FF)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      closeIconSx={{
        top: "10px",
      }}
    >
      <Stack direction="row">
        {tabs.map((tab) => (
          <Stack
            key={tab}
            sx={{
              height: "40px",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 0,
              ...(tab === currentTab && {
                backgroundColor: primary.main,
              }),
              boxShadow: `
                  inset 0px 1px 0px 0px rgba(255,255,255,.5),
                  inset 0px -3px 0px 0px rgba(0, 0, 0, 0.4),
                  inset 0px -20px 0px 2px rgba(0, 0, 0, 0.1)
              `,
              "&:active": {
                transform: "translateY(2px)",
              },
            }}
            onClick={() => handleTabChange(tab)}
          >
            {tab}
          </Stack>
        ))}
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt="5px"
        >
          <Small>
            {t("rank.lastUpdate")}{" "}
            {new Date(leaderboards?.[currentTab]?.updatedAt).toLocaleString()}
          </Small>
        </Stack>

        {userRank !== -1 && userRank >= 7 && (
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "transparent",
              backdropFilter: "blur(5px)",
            }}
          >
            {renderLeaderboardItem({
              index: userRank,
              style: {},
            })}
          </Box>
        )}

        <Box
          sx={{
            flex: 1,
            ...scrollbarStyles,
          }}
        >
          {leaderboards?.[currentTab]?.users && (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  width={width}
                  itemCount={leaderboards[currentTab].users.length}
                  itemSize={ITEM_HEIGHT}
                >
                  {renderLeaderboardItem}
                </List>
              )}
            </AutoSizer>
          )}
        </Box>
      </Box>
    </ScreenModal>
  );
}
