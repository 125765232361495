export const hasActiveChallenge = (challenge, challenges) => {
  if (!challenge || !challenges) return false;

  const currentChallenge = challenges.find(
    (c) => c.id === challenge.challengeId
  );

  if (!currentChallenge) return false;

  const now = new Date().getTime();
  const endDate = new Date(challenge.endDate).getTime();

  return now < endDate;
};

export const getCurrentChallenge = (challenge, challenges) => {
  if (!hasActiveChallenge(challenge, challenges)) return null;

  const currentChallenge = challenges.find(
    (c) => c.id === challenge.challengeId
  );

  return {
    ...challenge,
    ...currentChallenge,
    missions: challenge?.missions,
    missionsTemplate: currentChallenge?.missions,
  };
};
