import { getGradient, getTileStyles, primary } from "@/theme/colors";
import { Stack } from "@mui/material";
import { H3 } from "@/components/Typography";
import { IconNumber } from "@/components/IconNumber";
import { SmartImage } from "@/components/SmartImage";
import { calculateHeroStat, calculateHeroEnergy } from "@/utils/stats";
import { HeroLevel } from "./HeroLevel";
import { RarityChip } from "./RarityChip";

export function SelectedHero({ hero, onClick }) {
  const gradient = getGradient(hero?.color);

  if (!hero) return null;

  const rarityIndex = hero.rarityIndex;
  const attackValue = calculateHeroStat(hero, "attack", rarityIndex);
  const defenseValue = calculateHeroStat(hero, "defense", rarityIndex);
  const energyValue = calculateHeroEnergy(hero, rarityIndex);

  return (
    <Stack
      sx={{
        ...getTileStyles(110),
        background: gradient,
        position: "relative",
        height: "120px",
        justifyContent: "space-around",
        flexDirection: "row",
      }}
      mb={4}
      onClick={onClick}
    >
      <Stack flex={2} justifyContent="center" alignItems="center">
        {/* Placeholder for the hero image */}
      </Stack>

      <Stack flex={3} alignItems="center" spacing={2} py={2}>
        <H3 color={primary.white}>{hero?.name}</H3>

        <RarityChip rarity={hero?.rarity} />

        <HeroLevel
          level={hero.level}
          maxxedOut={hero.maxxedOut}
          size="M"
          sx={{
            position: "absolute",
            top: "-24px",
            right: 0,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />

        <SmartImage
          src={`/images/stones/${hero.element}.webp`}
          alt={hero.element}
          width={100}
          height={100}
          style={{
            position: "absolute",
            top: "14px",
            right: "10px",
            margin: "auto",
            width: "38px",
            height: "38px",
          }}
        />

        <Stack direction="row" spacing={1}>
          <IconNumber
            imgSrc="/images/items/Sword_B.webp"
            value={attackValue}
            size="S"
            sx={{ background: "rgba(0,0,0,0.2)", border: "none" }}
          />
          <IconNumber
            imgSrc="/images/icons/heart.webp"
            value={defenseValue}
            size="S"
            sx={{ background: "rgba(0,0,0,0.2)", border: "none" }}
          />
          <IconNumber
            imgSrc="/images/icons/energy.webp"
            value={energyValue}
            size="S"
            sx={{ background: "rgba(0,0,0,0.2)", border: "none" }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
