import { Stack, Box } from "@mui/material";
import {
  primary,
  secondary,
  backgroundColorHex,
  backgroundColorSecondary,
} from "@/theme/colors";

const activeDotStyle = {
  content: '""',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 6,
  height: 6,
  borderRadius: "50%",
  backgroundColor: "white",
};

export const WorldProgressBar = ({ currentLevel, maxLevel, sx, ...other }) => {
  const totalDots = maxLevel;
  const activeDots = Math.ceil((currentLevel / maxLevel) * totalDots);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      margin="auto"
      sx={{ position: "relative", ...sx }}
      {...other}
    >
      {/* Connecting line */}
      <Box
        sx={{
          position: "absolute",
          top: "44%",
          left: 12,
          right: 12,
          height: 2,
          backgroundColor: secondary.main,
          opacity: 0.6,
          zIndex: 0,
        }}
      />

      {[...Array(totalDots)].map((_, index) => (
        <Box
          key={index}
          sx={{
            width: 12,
            height: 12,
            borderRadius: "50%",
            border: `1px solid ${secondary.main}`,
            background:
              index < activeDots
                ? `linear-gradient(135deg, ${primary.main}, ${primary.light})`
                : `linear-gradient(135deg, ${backgroundColorHex}, ${backgroundColorSecondary})`,
            boxShadow:
              index < activeDots ? `0 0 8px 2px ${primary.main}` : "none",
            transition: "all 0.3s ease-in-out",
            zIndex: 1,
            position: "relative",
            "&::after": index < activeDots ? activeDotStyle : {},
          }}
        />
      ))}
    </Stack>
  );
};
