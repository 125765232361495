import { Box, Grid, Stack } from "@mui/material";
import { Paragraph } from "../../components/Typography";
import { SlowStarEffect } from "../../components/effects/SlowStarEffect";
import { SmartImage } from "@/components/SmartImage";
import { WhiteRadial } from "@/components/effects/WhiteRadial";
import { BurstImage } from "@/components/effects/BurstImage";

export function StoreItemPack({ item }) {
  return (
    <Box
      sx={{
        padding: "6px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          zIndex: item.id === "vip-pass" ? 1 : 2,
          backgroundColor: "rgba(0,0,0,0.1)",
          borderRadius: "4px",
          position: "relative",
        }}
      >
        <Paragraph>{item.name}</Paragraph>
      </Box>

      <Grid container mt={1} justifyContent="center" alignItems="center">
        <Grid item xs={7}>
          <Box
            sx={{
              position: "relative",
              height: item.height - item.height * 0.4,
            }}
          >
            <WhiteRadial />

            <SlowStarEffect
              count={20}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                marginLeft: "20px",
                marginTop: "-20px",
                width: 120,
                height: 120,
                zIndex: 3,
              }}
            />

            <BurstImage
              size={300}
              sx={{
                top: "-10%",
              }}
            />

            <SmartImage
              src={item.image}
              alt={item.name || "Item"}
              width={300}
              height={300}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: item.size === 12 ? 180 : 140,
                height: item.size === 12 ? 180 : 140,
                zIndex: 1,
              }}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={5}
          zIndex={0}
          sx={{
            lineHeight: 1,
          }}
        >
          <Stack
            sx={{
              backgroundColor: "rgba(0,0,0,0.2)",
              borderRadius: "4px",
              padding: "4px 6px",
              // mt: 1,
            }}
          >
            {item.content?.map((content, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                textAlign="left"
                spacing={0.5}
              >
                <SmartImage
                  src={content.image}
                  alt={content.description}
                  width={24}
                  height={24}
                />
                <Paragraph
                  sx={{ fontSize: item.height > 200 ? "12px" : "14px" }}
                >
                  {content.description}
                </Paragraph>
              </Stack>
            ))}

            {!item.content && item.description && (
              <Paragraph sx={{ fontSize: "18px" }}>
                {item.description}
              </Paragraph>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
