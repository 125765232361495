import { Stack, Box } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import {
  backgroundColorHex,
  backgroundColorSecondary,
  primary,
  secondary,
} from "@/theme/colors";
import { memo } from "react";

const ChestIcon = memo(() => {
  return (
    <Box
      sx={{
        position: "relative",
        width: 24,
        height: 24,
        "&::before": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            "radial-gradient(circle, rgba(255,214,65,0.6) 0%, rgba(255,214,65,0) 70%)",
          borderRadius: "50%",
          zIndex: 0,
          animation: "pulse-level-progress 2s infinite",
        },
      }}
    >
      <SmartImage
        src="/images/icons/Icon_ItemIcon_Treasure.webp"
        width={24}
        height={24}
        alt="chest"
        style={{ position: "relative", zIndex: 1 }}
      />
    </Box>
  );
});
ChestIcon.displayName = "ChestIcon";

const FinalEnemyIcon = memo(({ type }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: type === "elite" ? 24 : 35,
        height: type === "elite" ? 24 : 35,
        "&::before": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            "radial-gradient(circle, rgba(255,0,0,0.6) 0%, rgba(255,0,0,0) 70%)",
          borderRadius: "50%",
          zIndex: 0,
        },
      }}
    >
      <SmartImage
        src={`/images/icons/${type}.webp`}
        height={type === "elite" ? 24 : 35}
        width={type === "elite" ? 24 : 35}
        alt={type}
      />
    </Box>
  );
});
FinalEnemyIcon.displayName = "FinalEnemyIcon";

export const LevelProgressBar = memo(({ currentCheckpoint, checkpoints }) => {
  // Add a chest at the end of the checkpoints array
  const updatedCheckpoints = [...checkpoints, { type: "chest" }];

  return (
    <Stack direction="row" alignItems="center" spacing={1} margin="auto" mt={2}>
      <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
        {updatedCheckpoints?.map((checkpoint, index) =>
          index === updatedCheckpoints.length - 2 ? (
            <FinalEnemyIcon key={index} type={checkpoint.type} />
          ) : checkpoint.type === "chest" ? (
            <ChestIcon key={index} />
          ) : (
            <Box
              key={index}
              sx={{
                width: 10,
                height: 10,
                // borderRadius: "50%",
                mx: 1,
                transform: "rotate(45deg)",
                border: `1px solid ${secondary.main}`,
                background:
                  index < currentCheckpoint
                    ? `linear-gradient(to top, ${primary.main}, ${primary.light})`
                    : `linear-gradient(to top, ${backgroundColorHex}, ${backgroundColorSecondary})`,
                boxShadow:
                  index < currentCheckpoint
                    ? `0 0 6px 3px ${primary.main}, inset 0 0 2px 1px ${primary.light}`
                    : "0 0 6px 3px rgba(0,0,0,0.2)",
                transition: "all 0.3s ease-in-out",
              }}
            />
          )
        )}
      </Box>
    </Stack>
  );
});
LevelProgressBar.displayName = "LevelProgressBar";
