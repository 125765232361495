import { useState, useEffect, memo } from "react";
import { Small } from "./Typography";

export const CountdownTimer = memo(({ end, size = "S" }) => {
  const [timeLeft, setTimeLeft] = useState("");

  const sizeConfig = {
    S: { fontSize: "10px", width: "120%" },
    M: { fontSize: "15px", width: "120%" },
    L: { fontSize: "20px", width: "120%" },
    XL: { fontSize: "26px", width: "120%" },
  };

  const sizeStyle = sizeConfig[size];

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const endTime = new Date(end).getTime();
      const difference = endTime - now;

      if (difference <= 0) {
        return "Ended";
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      if (days > 0) {
        return `${days}d ${hours}h`;
      } else {
        const paddedHours = hours.toString().padStart(2, "0");
        const paddedMinutes = minutes.toString().padStart(2, "0");
        const paddedSeconds = seconds.toString().padStart(2, "0");
        return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
      }
    };

    // Initial calculation
    setTimeLeft(calculateTimeLeft());

    // Update every second instead of every minute when showing HH:MM:SS
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [end]);

  return <Small sx={sizeStyle}>{timeLeft}</Small>;
});

CountdownTimer.displayName = "CountdownTimer";
