import { Display } from "./Display";
import { SmartImage } from "./SmartImage";
import { CoinCounter } from "./CoinCounter";

export function ResourceDisplay({
  icon,
  iconComponent,
  count,
  onClick,
  showCondition = true,
  visibilityCondition = true,
  altText,
  ml = 2,
  displaySx,
}) {
  if (!showCondition) return null;

  return (
    <Display
      onClick={onClick}
      sx={{
        flex: 1,
        justifyContent: "flex-end",
        visibility: visibilityCondition ? "visible" : "hidden",
        ...displaySx,
      }}
    >
      {iconComponent}
      {icon && (
        <SmartImage
          src={icon}
          height={50}
          width={50}
          alt={altText || "Resource Icon"}
          style={{ position: "absolute", top: "-14px", left: "-16px" }}
        />
      )}
      <CoinCounter
        iconPosition="left"
        count={count}
        size="S"
        withIcon={false}
        ml={ml}
      />
    </Display>
  );
}
