import { CountdownTimer } from "@/components/CountdownTimer";
import { PriorityBadge } from "@/components/PriorityBadge";
import { SmartImage } from "@/components/SmartImage";
import { Small } from "@/components/Typography";
import { useClickSound } from "@/hooks/useClickSound";
import { Stack } from "@mui/material";

export const MainMenuItem = ({
  icon,
  title,
  onClick,
  hasBadge,
  size = "M",
  countdownEnd,
  sx,
}) => {
  const handleClick = useClickSound(onClick);
  const sizeConfig = {
    L: {
      fontSize: 12,
      iconSize: 50,
    },
    M: {
      fontSize: 10,
      iconSize: 44,
    },
    S: {
      fontSize: 8,
      iconSize: 30,
    },
  };
  return (
    <Stack
      onClick={handleClick}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        ...sx,  
      }}
    >
      {hasBadge && <PriorityBadge />}
      <SmartImage
        src={icon}
        width={sizeConfig[size].iconSize}
        height={sizeConfig[size].iconSize}
        alt={title}
      />
      <Small
        sx={{
          fontSize: sizeConfig[size].fontSize,
          marginTop: "-8px",
        }}
      >
        {title}
      </Small>
      {countdownEnd && <CountdownTimer end={countdownEnd} />}
    </Stack>
  );
};
