import { create } from "zustand";
import { resetUser, updateUserSettings } from "@/lib/api";

export const useUserStore = create((set, get) => ({
  user: null,

  setUser: (update) => set((state) => ({ user: { ...state.user, ...update } })),

  setUserSafe: (update) =>
    set((state) => ({
      user: {
        ...state.user,
        ...update,
        inventory: state.user.inventory,
        heroes: state.user.heroes,
        selectedHero: state.user.selectedHero,
        equippedItems: state.user.equippedItems,
      },
    })),

  updateUserPotions: (potions) =>
    set((state) => ({
      user: {
        ...state.user,
        resources: { ...state.user.resources, potions },
      },
    })),

  updateSettings: async (newSettings) => {
    const { user } = get();
    try {
      const response = await updateUserSettings({
        userId: user.id,
        ...newSettings,
        jwt: user.jwt,
      });
      set((state) => ({
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            ...response.data.settings,
          },
        },
      }));
    } catch (error) {
      console.error("Failed to update user settings:", error);
    }
  },

  // Helper methods for settings
  setPlaySounds: (playSounds) => get().updateSettings({ playSounds }),
  setPlayMusic: (playMusic) => get().updateSettings({ playMusic }),

  resetUser: async () => {
    const user = useUserStore.getState().user;
    try {
      await resetUser({ userId: user.id, jwt: user.jwt });
      // TODO: reset achievements
      // set({ playerAchievements: {} });
      return { success: true };
    } catch (error) {
      console.error("Failed to reset user:", error);
      return { success: false, error: error.message };
    }
  },
}));
