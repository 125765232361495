import { create } from "zustand";
import { purchaseChest as purchaseChestApi } from "@/lib/api";
import { useUserStore } from "./useUserStore";

const initialState = {
  pendingChests: [],
  chestHash: null,
};

export const useChestStore = create((set) => ({
  ...initialState,

  setPendingChests: (chests) => set({ pendingChests: chests }),

  setChestHash: (hash) => set({ chestHash: hash }),

  purchaseChest: async (chestLevel) => {
    const user = useUserStore.getState().user;
    try {
      const { data } = await purchaseChestApi({
        userId: user.id,
        chestLevel,
        jwt: user.jwt,
      });

      // Update user data
      useUserStore.getState().setUser({
        ...user,
        resources: data.user.resources,
        inventory: [...user.inventory, data.item],
      });

      // Update chest state
      set(() => ({
        chestHash: data.hash,
      }));

      return { success: true };
    } catch (error) {
      console.error("Failed to purchase chest:", error);
      return {
        success: false,
        error: error.response?.data?.error || error.message,
      };
    }
  },
}));
