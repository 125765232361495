import { useState, useEffect, useCallback } from "react";
import { Sprite, useTick } from "@pixi/react";
import { Assets } from "pixi.js";

export const CoinDrop = ({ startX, startY, endX, endY, onComplete }) => {
  const [position, setPosition] = useState({ x: startX, y: startY });
  const [rotation, setRotation] = useState(0);
  const [alpha, setAlpha] = useState(1);
  const [texture, setTexture] = useState(null);
  const [state, setState] = useState("flying");

  const COLLECT_SPEED = 30; // Adjust this value to change the collection speed
  const COLLECTION_POINT = {
    x: window.innerWidth / 2.4,
    y: window.innerHeight * 0.24,
  }; // Adjust this to match the position in the image

  const [controlPoint] = useState(() => ({
    x: startX + (Math.random() - 0.5) * 200,
    y: startY + (Math.random() - 0.5) * 100,
  }));

  const [time, setTime] = useState(0);
  const [bounceVelocity, setBounceVelocity] = useState(0);
  const [bounceStartTime, setBounceStartTime] = useState(null);
  const [collectStartPosition, setCollectStartPosition] = useState(null);

  useEffect(() => {
    const loadTexture = async () => {
      const coinTexture = await Assets.load("/images/icons/coin.webp");
      setTexture(coinTexture);
    };
    loadTexture();
  }, []);

  const bezierCurve = useCallback((t, p0, p1, p2) => {
    const x = (1 - t) * (1 - t) * p0.x + 2 * (1 - t) * t * p1.x + t * t * p2.x;
    const y = (1 - t) * (1 - t) * p0.y + 2 * (1 - t) * t * p1.y + t * t * p2.y;
    return { x, y };
  }, []);

  useTick((delta) => {
    switch (state) {
      case "flying":
        handleFlying(delta);
        break;
      case "dropping":
        handleDropping(delta);
        break;
      case "resting":
        handleResting(delta);
        break;
      case "collecting":
        handleCollecting(delta);
        break;
    }
  });

  const handleFlying = (delta) => {
    setTime((prev) => {
      const newTime = prev + delta * 0.02;
      if (newTime >= 1) {
        setState("dropping");
        setBounceStartTime(Date.now());
        return 1;
      }
      return newTime;
    });

    const newPos = bezierCurve(time, { x: startX, y: startY }, controlPoint, {
      x: endX,
      y: endY,
    });

    setPosition(newPos);
    setRotation((prev) => prev + 0.1 * delta);
  };

  const handleDropping = (delta) => {
    const currentTime = Date.now();
    if (currentTime - bounceStartTime > 100) {
      setState("resting");
      return;
    }

    setBounceVelocity((prev) => prev + 1 * delta);
    setPosition((prev) => ({
      x: prev.x,
      y: Math.min(prev.y + bounceVelocity, endY),
    }));

    if (position.y >= endY && bounceVelocity > 0) {
      setBounceVelocity((prev) => -prev * 0.6);
    }
  };

  const handleResting = () => {
    setPosition((prev) => ({ ...prev, y: endY }));
    setTimeout(() => {
      setState("collecting");
      setCollectStartPosition({ ...position });
    }, 1000); // Wait for 1 second before starting collection
  };

  const handleCollecting = (delta) => {
    const dx = COLLECTION_POINT.x - position.x;
    const dy = COLLECTION_POINT.y - position.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    if (distance < 1) {
      onComplete();
      return;
    }

    const speed = COLLECT_SPEED * delta;
    const moveFactor = Math.min(speed / distance, 1);

    setPosition((prev) => ({
      x: prev.x + dx * moveFactor,
      y: prev.y + dy * moveFactor,
    }));

    setRotation((prev) => prev + 0.2 * delta);
    setAlpha((prev) => Math.max(0, prev - 0.01 * delta));
  };

  if (!texture || alpha <= 0) return null;

  return (
    <Sprite
      texture={texture}
      x={position.x}
      y={position.y}
      rotation={rotation}
      anchor={0.5}
      scale={0.18}
      alpha={alpha}
    />
  );
};
