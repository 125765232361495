import { Box } from "@mui/material";
import { useEffect, useState } from "react";

export const InvisibleClickBox = ({ onClick, delay = 500 }) => {
  const [pointerEvents, setPointerEvents] = useState("none");

  useEffect(() => {
    const timer = setTimeout(() => {
      setPointerEvents("auto");
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      onClick={onClick}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "transparent",
        zIndex: 100,
        pointerEvents: pointerEvents,
      }}
    />
  );
};
