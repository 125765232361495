import { useState } from "react";
import {
  getAssets,
  authTelegramUser,
  authUser,
  hasJWT,
  getStoredJWT,
  setStoredJWT,
} from "@/lib/api";
import { useAsyncEffect } from "@/hooks/useAsyncEffect";
import { retrieveLaunchParams } from "@telegram-apps/sdk-react";
import { useAlert } from "@/hooks/useAlert";
import { useGameState } from "../game/logic/useGameState";
import { useIntroState } from "../game/logic/useIntroState";
import { RootOverlay } from "./RootOverlay";
import { TelegramError } from "./TelegramError";
import { NetworkError } from "./NetworkError";

export function Root({ children }) {
  const { setError } = useAlert();
  const { initializeGame } = useGameState();
  const { initializeTutorial } = useIntroState();
  const [isLoading, setIsLoading] = useState(true);
  const [telegramError, setTelegramError] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  useAsyncEffect(async () => {
    try {
      const { data: assets } = await getAssets();

      const getTrackingParams = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return {
          source: urlParams.get("source"),
          campaign: urlParams.get("campaign"),
          startParam: urlParams.get("tgWebAppStartParam"),
        };
      };

      const authenticateUser = async () => {
        const trackingParams = getTrackingParams();

        if (hasJWT()) {
          try {
            const { data } = await authUser(getStoredJWT(), trackingParams);
            return data;
          } catch (error) {
            console.warn("JWT auth failed, falling back to Telegram auth");
          }
        }
        const { initDataRaw } = retrieveLaunchParams();
        const { data } = await authTelegramUser(initDataRaw, trackingParams);
        return data;
      };

      const userData = await authenticateUser();
      setStoredJWT(userData.jwt);
      console.log("👉  userData:", userData);

      initializeGame(userData, assets);
      initializeTutorial(userData?.tutorial?.step);

      setIsLoading(false);
    } catch (error) {
      console.error("Error initializing game:", error, error.message);
      if (error.message.includes("Network Error")) {
        setNetworkError(true);
      } else if (
        error.message.includes("Unable to retrieve launch parameters")
      ) {
        setTelegramError(true);
      } else {
        setError("Failed to initialize game. Please try again later.");
      }
    }
  });

  if (networkError) {
    return <NetworkError />;
  }

  if (telegramError) {
    return <TelegramError />;
  }

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <RootOverlay />
      {children}
    </>
  );
}
