import { create } from "zustand";

const initialState = {
  battleSession: false,
  battleStarted: false,
  battleEnded: false,
  battleLock: false,
  dungeonCompleted: false,
  playerLost: false,
};

export const useBattleStore = create((set) => ({
  ...initialState,

  setBattleLock: (locked) => set({ battleLock: locked }),

  startBattleSession: () => set({ battleSession: true }),

  startBattle: () =>
    set({
      battleStarted: true,
      battleEnded: false,
      dungeonCompleted: false,
      playerLost: false,
    }),

  endBattle: (options = {}) =>
    set({
      battleStarted: false,
      battleEnded: true,
      battleLock: false,
      dungeonCompleted: options.completed || false,
      playerLost: options.playerLost || false,
    }),

  reset: () => set(initialState),
}));
