import {
  backgroundColorHex,
  backgroundColorSecondary,
  primary,
  secondary,
} from "@/theme/colors";
import { Box, Stack } from "@mui/material";
import { H3, Paragraph } from "../../components/Typography";
import { useClickSound } from "@/hooks/useClickSound";
import { useTheme, useMediaQuery } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import { BurstImage } from "@/components/effects/BurstImage";
import { SlowStarEffect } from "@/components/effects/SlowStarEffect";
import { LEVEL_CAPS } from "@/constants";
import { RarityChip } from "../hero/RarityChip";

const sizeConfig = {
  small: {
    XS: 50,
    S: 60,
    M: 75,
    L: 100,
  },
  medium: {
    XS: 60,
    S: 70,
    M: 90,
    L: 110,
  },
  large: {
    XS: 70,
    S: 100,
    M: 120,
    L: 140,
  },
};

export function Item({
  scope,
  item,
  isSelected,
  onClick,
  tileIndex,
  xs = 3,
  size = "S", // size prop
  showLevel = false,
  count,
  label,
  alternativeIcon,
  imageSx,
  showBurst = false,
  showRarity = false,
  sx,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const itemLevel = item?.level;
  const maxLevel = LEVEL_CAPS[item?.rarity];
  const isMaxLevel = itemLevel >= maxLevel;

  const getSize = () => {
    if (isSmallScreen) return sizeConfig.small[size] || 80;
    if (isMediumScreen) return sizeConfig.medium[size] || 80;
    if (isLargeScreen) return sizeConfig.large[size] || 80;
    return 80; // Default size
  };

  const iconSize = getSize();

  const iconImage = item?.icon || alternativeIcon;

  const handleClick = useClickSound(onClick);

  return (
    <Stack
      key={item?.id || tileIndex}
      position="relative"
      xs={xs}
      onClick={handleClick}
      sx={{
        display: "flex",
        zIndex: 3,
        justifyContent: "center",
        alignItems: "center",
        pointerEvents: onClick ? "auto" : "none",
        ...sx,
      }}
    >
      <SmartImage
        scope={scope}
        src={
          item?.rarity
            ? `/images/items/tile_${item.rarity}.webp`
            : "/images/items/tile_COMMON.webp"
        }
        height={iconSize}
        width={iconSize}
        style={{
          width: `${iconSize}px`,
          height: "auto",
          border: isSelected
            ? `2px solid ${secondary.main}`
            : "2px solid transparent", // Highlight color for selection
          cursor: "pointer",
        }}
        alt="Tile"
      />

      {label && (
        <Box
          sx={{
            position: "absolute",
            top: "46%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <H3>{label}</H3>
        </Box>
      )}

      {showRarity && (
        <RarityChip
          rarity={item?.rarity}
          sx={{ top: "-10px", left: "-6px", height: "16px", fontSize: "12px" }}
        />
      )}

      {showLevel && item?.level > 0 && (
        <Box
          sx={{
            background: isMaxLevel
              ? `linear-gradient(to top, ${secondary.main}, ${primary.main})`
              : `linear-gradient(to top, ${backgroundColorHex}, ${backgroundColorSecondary})`,
            border: isMaxLevel
              ? "none"
              : `1px solid ${backgroundColorSecondary}`,
            position: "absolute",
            bottom: "14%",
            left: "10%",
            zIndex: 3,
            height: `${Math.round(iconSize * 0.2)}px`,
            width: `${Math.round(iconSize * 0.2)}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "3px",
          }}
        >
          <Paragraph
            color="white"
            fontSize={`${Math.round(iconSize * 0.14)}px`}
            lineHeight={1.2}
          >
            {item.level}
          </Paragraph>
        </Box>
      )}

      {count > 0 && (
        <Box
          sx={{
            // backgroundColor: primary.main,
            color: "#000000",
            position: "absolute",
            bottom: "14%",
            right: "14%",
            zIndex: 3,
            height: `${Math.round(iconSize * 0.2)}px`,
            width: `${Math.round(iconSize * 0.2)}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "3px",
          }}
        >
          <Paragraph
            color="white"
            fontSize={`${Math.round(iconSize * 0.18)}px`}
            lineHeight={1.2}
            sx={{
              textShadow: "0px 2px 2px #000",
            }}
          >
            <span style={{ fontSize: "8px" }}>x</span>
            {count}
          </Paragraph>
        </Box>
      )}

      {iconImage && (
        <SmartImage
          scope={scope}
          src={iconImage}
          height={Math.round(iconSize * 0.8)}
          width={Math.round(iconSize * 0.8)}
          style={{
            width: `${Math.round(iconSize * 0.8)}px`,
            height: "auto",
            position: "absolute",
            top: "48%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
            ...imageSx,
          }}
          alt="Item"
        />
      )}
      {showBurst && <BurstImage size={iconSize} />}
      {showBurst && <SlowStarEffect />}
    </Stack>
  );
}
