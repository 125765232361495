export function getImageForRewardType(rewardType) {
  const imageMap = {
    gems: "/images/icons/gem.webp",
    gold: "/images/icons/coin.webp",
    runes: "/images/icons/rune.webp",
    stones: "/images/stones/air.webp",
    scrolls: "/images/items/scroll_attack.webp",
  };
  return imageMap[rewardType] || "/images/icons/xp.webp";
}

export function mergeAchievements(achievements, playerAchievements) {
  if (!achievements || !playerAchievements) return [];
  return Object.entries(achievements)
    .filter(([metricType]) => metricType !== "gear" && metricType !== "enemies")
    .map(([metricType, metricData]) => {
      const playerAchievement = playerAchievements[metricType] || {};

      if (metricType === "equippedItems") {
        return mergeEquippedItemsAchievements(metricData, playerAchievement);
      } else {
        return mergeRegularAchievements(
          metricData,
          playerAchievement,
          metricType
        );
      }
    });
}

export function mergeEquippedItemsAchievements(metricData, playerAchievement) {
  const subAchievements = Object.entries(metricData)
    .filter(([key]) => key !== "name")
    .map(([rarity, data]) => ({
      name: `${rarity} Items`,
      threshold: data.threshold,
      reward: `${data.reward} ${data.rewardType}`,
      rewardType: data.rewardType,
      rewardCount: data.reward,
      ...(playerAchievement[rarity] || {
        achieved: false,
        claimed: false,
        currentCount: 0,
      }),
    }));

  const lastAchievement = subAchievements[subAchievements.length - 1];

  const achievedCount = subAchievements.filter((sa) => sa.achieved).length;

  return {
    name: metricData.name,
    description: metricData.description,
    type: "equippedItems",
    nextAchievement: subAchievements.find((sa) => !sa.claimed),
    lastAchievement: lastAchievement.claimed ? lastAchievement : null,
    totalSubAchievements: subAchievements.length,
    achievedSubAchievements: achievedCount,
  };
}

export function mergeRegularAchievements(
  metricData,
  playerAchievement,
  metricType
) {
  const subAchievements = metricData.thresholds.map((threshold, index) => ({
    threshold,
    level: index,
    reward: `${metricData.rewards[index]} ${metricData.rewardType}`,
    rewardType: metricData.rewardType,
    rewardCount: metricData.rewards[index],
    ...(playerAchievement[index] || {
      achieved: false,
      claimed: false,
      currentCount: 0,
    }),
  }));

  const lastAchievement = subAchievements[subAchievements.length - 1];

  const achievedCount = subAchievements.filter((sa) => sa.achieved).length;

  return {
    name: metricData.name,
    description: metricData.description,
    type: metricType,
    nextAchievement: subAchievements.find((sa) => !sa.claimed),
    lastAchievement: lastAchievement.claimed ? lastAchievement : null,
    totalSubAchievements: subAchievements.length,
    achievedSubAchievements: achievedCount,
  };
}

export function hasClaimableAchievements(mergedAchievements) {
  return mergedAchievements.some(
    (achievement) =>
      achievement.nextAchievement &&
      achievement.nextAchievement.achieved &&
      !achievement.nextAchievement.claimed
  );
}
