import { Box } from "@mui/material";
import { useState, useEffect, useRef, useCallback, memo } from "react";
import { H2, H4 } from "../../components/Typography";
import { SmartImage } from "@/components/SmartImage";
import gsap from "gsap";
import { useBattleStore } from "@/game/logic/useBattleStore";
import { getAbility } from "@/utils/merge";
import { useUserStore } from "@/game/logic/useUserStore";
import { useAssetStore } from "@/game/logic/useAssetStore";
import { combatActions } from "@/game/actions/combatActions";

export const Ability = memo(() => {
  const { battleStarted, battleLock } = useBattleStore();
  const { ABILITIES } = useAssetStore();
  const { user } = useUserStore();
  const boxRef = useRef(null);
  const progressRef = useRef(null);
  const tl = useRef(null);
  const pulseAnimation = useRef(null);
  const ability = getAbility(user.selectedHero, ABILITIES);
  const cooldownDuration = Math.ceil(ability?.cooldown / 1000); // Convert to seconds
  const [cooldownRemaining, setCooldownRemaining] = useState(cooldownDuration);

  const fullyCharged = cooldownRemaining === 0;

  const updateCooldown = useCallback(() => {
    if (!battleStarted) return;

    if (tl.current) tl.current.kill();

    tl.current = gsap.timeline();
    tl.current.to(progressRef.current, {
      height: "100%",
      duration: cooldownDuration,
      ease: "linear",
      onUpdate: () => {
        const progress = tl.current.progress();
        const remaining = Math.ceil((1 - progress) * cooldownDuration);
        if (remaining !== cooldownRemaining) {
          setCooldownRemaining(remaining);
        }
      },
      onComplete: () => setCooldownRemaining(0),
    });
  }, [cooldownDuration, battleStarted]);

  const handleAbilityClick = useCallback(() => {
    if (cooldownRemaining === 0 && !battleLock) {
      combatActions.performAttack(true);
      setCooldownRemaining(cooldownDuration);
      gsap.to(boxRef.current, { scale: 1, duration: 0.3 });
      gsap.set(progressRef.current, { height: "0%" });
      updateCooldown();
    }
  }, [cooldownRemaining, cooldownDuration, updateCooldown, battleLock]);

  useEffect(() => {
    if (battleStarted) {
      updateCooldown();
    } else {
      setCooldownRemaining(cooldownDuration);
    }
    return () => {
      if (tl.current) tl.current.kill();
    };
  }, [updateCooldown, battleStarted, cooldownDuration]);

  useEffect(() => {
    if (fullyCharged) {
      if (pulseAnimation.current) pulseAnimation.current.kill();
      pulseAnimation.current = gsap.to(boxRef.current, {
        scale: 1.05,
        yoyo: true,
        repeat: -1,
        duration: 1,
        ease: "power1.inOut",
      });
    } else {
      if (pulseAnimation.current) pulseAnimation.current.kill();
      gsap.to(boxRef.current, { scale: 1, duration: 0.3 });
    }

    return () => {
      if (pulseAnimation.current) pulseAnimation.current.kill();
    };
  }, [fullyCharged]);

  return (
    <Box
      ref={boxRef}
      onClick={handleAbilityClick}
      sx={{
        width: "90px",
        height: "90px",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        position: "absolute",
        top: "-110px",
        right: "20px",
        border: "2px solid white",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        userSelect: "none",
        boxShadow: fullyCharged
          ? `
            inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4), 
            inset 0px 10px 0px 2px rgba(0, 0, 0, 0.1), 
            7px 7px 10px 0px rgba(0,0,0,.1), 
            0px 0px 10px 2px white,
            0px 0px 30px 0px gold
          `
          : `
            inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4), 
            inset 0px 10px 0px 2px rgba(0, 0, 0, 0.1), 
            7px 7px 10px 0px rgba(0,0,0,.1)
          `,
      }}
    >
      <Box
        ref={progressRef}
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "0%",
          backgroundColor: "rgba(255, 255, 255, 0.4)",
        }}
      />
      <SmartImage
        src={ability.icon}
        width={90}
        height={90}
        alt="Ability"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          opacity: cooldownRemaining > 0 ? 0.4 : 1,
        }}
      />
      {cooldownRemaining > 0 ? (
        <H2 color="white" sx={{ zIndex: 1 }}>
          {cooldownRemaining}
        </H2>
      ) : (
        fullyCharged && (
          <H4 color="white" sx={{ zIndex: 1 }}>
            READY
          </H4>
        )
      )}
    </Box>
  );
});

Ability.displayName = "Ability";
