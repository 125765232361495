import { SimpleModal } from "@/components/SimpleModal";
import { Grid, Stack } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import { H6, Paragraph, Small } from "@/components/Typography";
import { useTranslation } from "react-i18next";
import { useUserStore } from "../logic/useUserStore";
import { useAssetStore } from "../logic/useAssetStore";

export function PotionsModal({ open, onClose }) {
  const { t } = useTranslation();
  const { user } = useUserStore();
  const potions = user?.resources?.potions;
  const { POTIONS } = useAssetStore();

  return (
    <SimpleModal open={open} onClose={onClose} title={t("potions.title")}>
      {potions ? (
        <Grid container spacing={3}>
          {Object.entries(POTIONS).map(([, potion]) => {
            const amount = potions[potion.type] || 0;
            return (
              <Grid item xs={4} key={potion.type}>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Paragraph variant="small" align="center">
                    {potion.type}
                  </Paragraph>
                  <SmartImage
                    src={potion.icon}
                    width={80}
                    height={80}
                    alt={potion.name}
                    style={{ width: "60px", height: "60px" }}
                  />
                  <H6>{amount}</H6>
                  <Small align="center" color="white">
                    {potion.description}
                  </Small>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Paragraph align="center">{t("gear.noPotions")}</Paragraph>
      )}
    </SimpleModal>
  );
}
