import { create } from "zustand";
import { calculateHeroEnergy } from "@/utils/stats";

const initialState = {
  energy: 0,
  maxEnergy: 0,
  energyRegenIntervalId: null,
};

export const useEnergyStore = create((set, get) => ({
  ...initialState,

  initializeEnergy: (assets) => {
    set({
      energy: assets.STAT_CONFIG.energy.base,
      maxEnergy: assets.STAT_CONFIG.energy.maxEnergy,
    });
  },

  setEnergy: (energy) => set({ energy }),

  startEnergyRegen: (player) => {
    // Clear existing interval if any
    get().clearEnergyRegen();

    const energyRegenIntervalId = setInterval(() => {
      const { energy, maxEnergy } = get();
      if (energy < maxEnergy) {
        set({ energy: Math.min(energy + 1, maxEnergy) });
      }
    }, player.energyRegenRate);

    set({ energyRegenIntervalId });
  },

  clearEnergyRegen: () => {
    const { energyRegenIntervalId } = get();
    if (energyRegenIntervalId) {
      clearInterval(energyRegenIntervalId);
    }
    set({ energyRegenIntervalId: null });
  },

  calculateHeroEnergy: (hero) => {
    const energy = calculateHeroEnergy(hero, hero.rarityIndex);
    set({ energy });
    return energy;
  },

  useEnergy: (amount = 1) => {
    const { energy } = get();
    const newEnergy = Math.max(energy - amount, 0);
    set({ energy: newEnergy });
    return newEnergy;
  },

  hasEnoughEnergy: (amount = 1) => {
    return get().energy >= amount;
  },

  reset: () => {
    get().clearEnergyRegen();
    set(initialState);
  },

  addEnergy: (amount) => {
    const { energy, maxEnergy } = get();
    const newEnergy = Math.min(energy + amount, maxEnergy);
    set({ energy: newEnergy });
  },
}));
