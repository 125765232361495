import { useState, useEffect, useCallback, useRef } from "react";
import { initDevtools } from "@pixi/devtools";
import { Stage } from "@pixi/react";
import { useAppStore } from "@/hooks/useAppStore";
import { PixiContent, ScreenType } from "./components/PixiContent";
import { useBattleStore } from "./logic/useBattleStore";

export function PixiWorld() {
  const [stageSize, setStageSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const { battleSession } = useBattleStore();
  const stageRef = useRef(null);
  const { tab } = useAppStore();

  const handleResize = useCallback(() => {
    setStageSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (
      import.meta.env.DEV &&
      typeof window !== "undefined" &&
      stageRef.current
    ) {
      const app = stageRef.current.app;
      initDevtools({ stage: app.stage, renderer: app.renderer });
    }
  }, []);

  const getCurrentScreen = () => {
    if (battleSession) return ScreenType.BATTLE;
    if (tab === "/") return ScreenType.HOME;
    if (tab === "/Gear") return ScreenType.GEAR;
    if (tab === "/Hero") return ScreenType.HERO;
    return null; // Default to HOME if no match
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        // removing pointer events to allow UI layer to handle clicks
        pointerEvents: battleSession ? "auto" : "none",
      }}
    >
      <Stage
        options={{
          backgroundAlpha: 0,
          resolution: window.devicePixelRatio || 1,
          autoDensity: true,
        }}
        width={stageSize.width}
        height={stageSize.height}
        ref={stageRef}
      >
        <PixiContent currentScreen={getCurrentScreen()} stageSize={stageSize} />
      </Stage>
    </div>
  );
}
