import { useState, useEffect } from "react";
import { Container } from "@pixi/react";
import { VFXManager } from "./VFXManager";
import { GameplayScene } from "../battle/GameplayScene";
import { VFXLayer } from "./VFXLayer";
import { PlayerAnimated } from "./PlayerAnimated";
import { PlayerAvatar } from "./PlayerAvatar";
import { SelectedHeroSpine } from "./SelectedHeroSpine";

// Enum-like object for screen types
export const ScreenType = {
  BATTLE: "BATTLE",
  HOME: "HOME",
  GEAR: "GEAR",
  HERO: "HERO",
};

// Component mapping
const screenComponents = {
  [ScreenType.BATTLE]: () => (
    <VFXManager>
      <GameplayScene />
      <VFXLayer />
    </VFXManager>
  ),
  [ScreenType.HOME]: ({ stageSize }) => (
    <PlayerAnimated
      size={130}
      x={stageSize.width / 2}
      y={stageSize.height / 1.7}
      stageWidth={stageSize.width}
    />
  ),
  [ScreenType.GEAR]: ({ stageSize }) => (
    <PlayerAvatar
      size={160}
      skin="Front"
      animate
      shadow
      spotlight
      isPixiContext
      x={stageSize.width / 2}
      y={stageSize.width / 2}
    />
  ),
  [ScreenType.HERO]: ({ stageSize }) => (
    <SelectedHeroSpine size={120} x={stageSize.width / 10} y={50} />
  ),
};

export function PixiContent({
  currentScreen,
  stageSize,
  appearanceDelay = 1400,
}) {
  const [opacity, setOpacity] = useState(1);
  const [displayedScreen, setDisplayedScreen] = useState(currentScreen);

  useEffect(() => {
    if (currentScreen !== displayedScreen) {
      setOpacity(0);

      const isHomeBattleTransition =
        (currentScreen === ScreenType.HOME &&
          displayedScreen === ScreenType.BATTLE) ||
        (currentScreen === ScreenType.BATTLE &&
          displayedScreen === ScreenType.HOME);

      const transitionDelay = isHomeBattleTransition ? appearanceDelay : 0;

      const timer = setTimeout(() => {
        setDisplayedScreen(currentScreen);
        setOpacity(1);
      }, transitionDelay);

      return () => clearTimeout(timer);
    }
  }, [currentScreen, displayedScreen, appearanceDelay]);

  const ScreenComponent = screenComponents[displayedScreen] || (() => null);

  return (
    <Container alpha={opacity}>
      <ScreenComponent stageSize={stageSize} />
    </Container>
  );
}
