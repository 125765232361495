import { METRICS_UPDATE_DELAY } from "@/constants";
import { updatePlayerMetrics, sendMetricsBeacon } from "@/lib/api";

class MetricsBatcher {
  constructor(batchSize = 10, flushInterval = METRICS_UPDATE_DELAY) {
    this.batchSize = batchSize;
    this.flushInterval = flushInterval;
    this.metrics = {};
    this.timer = null;
  }

  add(metric, value) {
    if (metric === "enemies") {
      if (!this.metrics[metric]) {
        this.metrics[metric] = {};
      }
      this.metrics[metric][value.enemyId] = value.difficulty;
    } else {
      if (!this.metrics[metric]) {
        this.metrics[metric] = 0;
      }
      this.metrics[metric] += value;
    }

    if (Object.keys(this.metrics).length >= this.batchSize) {
      this.flush();
    } else if (!this.timer) {
      this.timer = setTimeout(() => this.flush(), this.flushInterval);
    }
  }

  async flush() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (Object.keys(this.metrics).length === 0) return;

    const metricsToSend = { ...this.metrics };
    this.metrics = {};

    const jwt = localStorage.getItem("userJWT");
    if (!jwt) {
      console.error("No JWT found, cannot update metrics");
      return;
    }

    try {
      await updatePlayerMetrics({
        updates: metricsToSend,
        jwt,
      });
    } catch (error) {
      console.error("Failed to update metrics:", error);
      // Add the failed metrics back to the batch
      Object.entries(metricsToSend).forEach(([key, value]) =>
        this.add(key, value)
      );
    }
  }

  async flushNow() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    await this.flush();
  }

  flushWithBeacon() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (!this.metrics.sessionDuration) return;

    const sessionLength = this.metrics.sessionDuration;
    this.metrics = {};

    const jwt = localStorage.getItem("userJWT");
    if (!jwt) {
      console.error("No JWT found, cannot update metrics");
      return;
    }

    return sendMetricsBeacon(sessionLength, jwt);
  }
}

export const metrics = new MetricsBatcher();
