import { Small } from "@/components/Typography";
import { borderRadiusNew, primary } from "@/theme/colors";
import { lighten, LinearProgress, Stack } from "@mui/material";
import { useTranslation } from "@/hooks/useTranslation";
import { SmartImage } from "@/components/SmartImage";
import { SimpleButton } from "@/components/SimpleButton";
import { useAlert } from "@/hooks/useAlert";
import { useSounds } from "@/hooks/useSound";
import { won2xSound } from "@/utils/sounds";
import { useAssetStore } from "@/game/logic/useAssetStore";
import { useChallengeStore } from "@/game/logic/useChallengeStore";

export function QuestMission({ mission, day, missionIndex }) {
  const { t } = useTranslation();
  const { claimChallengeRewards } = useChallengeStore();
  const { setError } = useAlert();
  const { playSound } = useSounds();

  const currentCount = mission.currentCount;
  const threshold = mission.threshold;
  const isCompleted = currentCount >= threshold;
  const isClaimed = mission.claimed;
  const disabled = isClaimed || !isCompleted;
  const rewardCount = mission.gems || 3; // Assuming the reward is stored in the mission object
  const { RARITY } = useAssetStore();
  const rarityStage = Object.keys(RARITY)?.[mission.level];

  const handleClaimReward = async () => {
    const result = await claimChallengeRewards({
      type: "mission",
      day,
      missionIndex,
    });
    if (result.success) {
      playSound(won2xSound);
    } else {
      setError(result.error);
      console.error("Failed to claim reward:", result.error);
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        padding: "6px 6px",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        margin: "4px 6px",
        borderRadius: borderRadiusNew,
        justifyContent: "space-between",
      }}
    >
      <Stack flex={3}>
        <Small>
          {t(`missions.${mission.type}`)} {mission.level && `- ${rarityStage}`}
        </Small>

        <Stack position="relative" direction="column">
          <LinearProgress
            variant="determinate"
            value={
              isCompleted
                ? 100
                : Math.min(((currentCount || 0) / threshold) * 100, 100)
            }
            style={{ flexGrow: 1 }}
            sx={{
              height: "14px",
              width: "100%",
              borderRadius: "2px",
              "& .MuiLinearProgress-bar": {
                boxShadow: "inset 0px -6px 0px 0px rgba(0, 0, 0, 0.1)",
                backgroundColor: lighten(primary.green, 0.3),
              },
            }}
          />
          <Small
            sx={{
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translateX(-50%)",
              fontSize: "10px",
            }}
          >
            {isCompleted ? "100%" : `${currentCount}/${threshold}`}
          </Small>
        </Stack>
      </Stack>

      <Stack
        flex={1}
        spacing={0.5}
        alignItems={isClaimed ? "center" : "flex-end"}
      >
        {isClaimed ? (
          <SmartImage
            src="/images/icons/check.webp"
            width={34}
            height={34}
            alt="Glory"
          />
        ) : (
          <SimpleButton
            color={disabled ? "grey" : "green"}
            disabled={disabled}
            size="S"
            image="/images/icons/gem.webp"
            onClick={handleClaimReward}
          >
            {isClaimed ? "" : rewardCount}
          </SimpleButton>
        )}
      </Stack>
    </Stack>
  );
}
