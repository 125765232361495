import { memo, useEffect, useRef } from "react";
import { Container, useTick } from "@pixi/react";
import { Spine } from "pixi-spine";
import * as PIXI from "pixi.js";
import { getHeroPath } from "@/utils/player";
import { useUserStore } from "../logic/useUserStore";
import { useAssetStore } from "../logic/useAssetStore";

const SpineCharacter = memo(
  ({ path, size, animate, skin = "Side", shadow, spotlight, onSpineLoad }) => {
    const spineRef = useRef(null);
    const shadowRef = useRef(null);
    const spotlightRef = useRef(null);
    const initialShadowScale = useRef(null);

    useEffect(() => {
      if (!spineRef.current) return;

      const loadSpine = async () => {
        try {
          const spineData = await PIXI.Assets.load(path);
          const spine = new Spine(spineData.spineData);
          spine.scale.set(size / 400);

          // Center the spine in the container
          spine.position.set(0, 0);

          spine.skeleton.setSkin(null);
          spine.skeleton.setSkinByName(skin);
          if (animate) {
            spine.state.setAnimation(0, `${skin}_Idle`, true);
          }

          // Create spotlight
          if (spotlight) {
            const spotlight = new PIXI.Graphics();
            spotlight.beginFill(0xffffff, 0.1);
            spotlight.drawEllipse(0, 0, size * 0.8, size * 1);
            spotlight.endFill();
            spotlight.position.set(0, -size / 2);
            spineRef.current?.addChild(spotlight);
            spotlightRef.current = spotlight;
            spotlightRef.current.alpha = 0.4;
          }

          // Create shadow
          if (shadow) {
            const shadowGraphic = new PIXI.Graphics();
            shadowGraphic.beginFill(0x000000, 0.3);
            shadowGraphic.drawEllipse(0, 0, size / 3.3, size / 7);
            shadowGraphic.endFill();

            // Adjust shadow position if needed
            shadowGraphic.position.set(0, 0);

            spineRef.current?.addChild(shadowGraphic);
            shadowRef.current = shadowGraphic;
            initialShadowScale.current = {
              x: shadowGraphic.scale.x,
              y: shadowGraphic.scale.y,
            };
          }
          spineRef.current?.addChild(spine);
          if (onSpineLoad) {
            onSpineLoad(spine);
          }
        } catch (error) {
          console.error("Failed to load spine:", error);
        }
      };

      loadSpine();

      return () => {
        if (spineRef.current) {
          spineRef.current.removeChildren();
        }
      };
    }, [path, size, animate, skin, shadow, onSpineLoad]);

    useTick(() => {
      if (shadowRef.current && animate) {
        const time = Date.now() / 500;
        const scaleOffset = Math.sin(time * 2) * 0.04;
        shadowRef.current.scale.set(
          initialShadowScale.current.x * (1 + scaleOffset),
          initialShadowScale.current.y * (1 + scaleOffset)
        );
      }
    });

    return <Container ref={spineRef} />;
  }
);

SpineCharacter.displayName = "SpineCharacter";

export const PlayerAvatar = memo(
  ({
    size = 26,
    animate,
    skin,
    shadow,
    spotlight,
    onSpineLoad,
    x = 0,
    y = 0,
  }) => {
    const { user } = useUserStore();
    const { HEROES_NEW } = useAssetStore();
    const heroPath = getHeroPath(user, HEROES_NEW);

    if (!heroPath) return null;

    return (
      <Container x={x} y={y} anchor={0.5}>
        <SpineCharacter
          path={heroPath}
          size={size}
          animate={animate}
          skin={skin}
          shadow={shadow}
          spotlight={spotlight}
          onSpineLoad={onSpineLoad}
        />
      </Container>
    );
  }
);

PlayerAvatar.displayName = "PlayerAvatar";
