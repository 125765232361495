import {
  calculateStatUpgradeCost,
  calculateTotalStats,
  calculateEnemyStats,
} from "@/utils/statCalculations";
import { useUserStore } from "../logic/useUserStore";
import { useAssetStore } from "../logic/useAssetStore";

export const gameStatsActions = {
  calculateStatUpgradeCost: (stat) => {
    const user = useUserStore.getState().user;
    const { BASE_UPGRADE_COST } = useAssetStore.getState();
    return calculateStatUpgradeCost(stat, user, BASE_UPGRADE_COST);
  },

  calculateTotalStats: (equippedItems) => {
    const user = useUserStore.getState().user;
    const { STAT_CONFIG, ITEMS } = useAssetStore.getState();
    return calculateTotalStats(equippedItems, user, STAT_CONFIG, ITEMS);
  },

  calculateEnemyStats: (baseEnemy, worldId) => {
    const user = useUserStore.getState().user;
    const findWorld = useAssetStore.getState().findWorld;
    return calculateEnemyStats(baseEnemy, worldId, user, findWorld);
  },
};
