import { Box, Stack } from "@mui/material";
import { H4, H6, Small } from "../../components/Typography";
import { SmartImage } from "@/components/SmartImage";
import { KeyboardDoubleArrowUp } from "@mui/icons-material";
import { useHapticFeedback } from "@telegram-apps/sdk-react";
import { upgradeSound } from "@/utils/sounds";
import { useAlert } from "@/hooks/useAlert";
import { getTileStyles } from "@/theme/colors";
import { useSounds } from "@/hooks/useSound";
import { useIntroState } from "../logic/useIntroState";
import { useUserStore } from "../logic/useUserStore";
import { usePlayerStore } from "../logic/usePlayerStore";

export function StatItem({ name, statKey, number, upgradeCost }) {
  const { playSound } = useSounds();
  const { upgradePlayerStat } = usePlayerStore();
  const { user } = useUserStore();
  const hapticFeedback = useHapticFeedback(true);
  const { setError } = useAlert();
  const { setStep, ongoingTutorial } = useIntroState();

  const currentLevel = user?.statLevels?.[statKey];
  const canAfford = user?.resources?.gold >= upgradeCost;

  async function handleClick() {
    if (!canAfford) {
      setError("Not enough gold");
      return;
    }
    if (ongoingTutorial) {
      setStep("HOME_RESTART");
      setTimeout(() => {
        setStep("USE_POTION");
      }, 7000);
    }

    hapticFeedback?.impactOccurred("heavy");
    playSound(upgradeSound);
    const result = await upgradePlayerStat(statKey);
    if (!result.success) {
      setError(result.error);
    }
  }

  return (
    <Stack
      sx={{
        ...getTileStyles(60, true, "rgba(0,0,0,0.5)"),
        padding: "4px 8px 6px 6px",
        position: "relative",
        margin: "4px 4px",
        alignItems: "center",
        opacity: canAfford ? 1 : 0.5,
        transition: "opacity 0.3s ease",
        cursor: canAfford ? "pointer" : "not-allowed",
        userSelect: "none",
      }}
      onClick={handleClick}
    >
      {currentLevel > 0 && (
        <Box
          sx={{
            color: "#000000",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 3,
            height: 20,
            width: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "3px",
          }}
        >
          <Small lineHeight={1.3}>{currentLevel}</Small>
        </Box>
      )}

      <Small sx={{ lineHeight: 1.3 }}>{name}</Small>
      <H4
        sx={{
          lineHeight: 1.2,
          marginBottom: "2px",
        }}
      >
        {number}
      </H4>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundColor: canAfford ? "#7DD647" : "#A9A9A9",
          borderRadius: "20px",
          padding: "0 4px",
        }}
      >
        <SmartImage
          src="/images/icons/coin.webp"
          height={20}
          width={20}
          alt="Icon"
        />
        <H6
          ml={0.2}
          sx={{
            color: "common.white",
            lineHeight: 1.3,
          }}
        >
          {upgradeCost}
        </H6>
        <KeyboardDoubleArrowUp
          sx={{
            color: canAfford
              ? "rgba(250,250,250,0.4)"
              : "rgba(250,250,250,0.2)",
            fontSize: "20px",
          }}
        />
      </Stack>
      {/* <Small sx={{ lineHeight: 1.3, color: "secondary.main" }}>
        Next: {nextValue} (Lv. {currentLevel + 1})
      </Small> */}
    </Stack>
  );
}
