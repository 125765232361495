import { create } from "zustand";

export const useAttackStore = create((set) => ({
  playerAttackCount: 0,
  enemyAttackCount: 0,

  incrementPlayerAttack: () =>
    set((state) => ({
      playerAttackCount: state.playerAttackCount + 1,
    })),

  incrementEnemyAttack: () =>
    set((state) => ({
      enemyAttackCount: state.enemyAttackCount + 1,
    })),

  resetAttackCounts: () =>
    set({
      playerAttackCount: 0,
      enemyAttackCount: 0,
    }),
}));
