import { useState, useEffect, useRef, memo } from "react";
import { gsap } from "gsap";
import styled from "@emotion/styled";

const ChestContainer = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
`;

const ChestImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const SHAKE_SPEED = 0.1; // Duration of one shake cycle in seconds
const SHAKE_DISTANCE = 2; // Distance to shake in pixels

const ParticleContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  pointer-events: none;
`;

const Particle = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  opacity: 0;
`;

const PARTICLE_COLORS = ["#FFD700", "#FFA500", "#FF4500", "#8A2BE2", "#00BFFF"];
const PARTICLE_COUNT = 250;

export const ChestAnimation = memo(
  ({ isOpen, chestLevel, animate, onOpen }) => {
    const [showOpenChest, setShowOpenChest] = useState(false);
    const chestRef = useRef(null);
    const shakeTimelineRef = useRef(null);
    const particlesRef = useRef([]);

    useEffect(() => {
      if (animate && !isOpen) {
        // Create shaking animation
        shakeTimelineRef.current = gsap
          .timeline({ repeat: -1, yoyo: true })
          .to(chestRef.current, {
            x: SHAKE_DISTANCE,
            duration: SHAKE_SPEED / 2,
            ease: "power1.inOut",
          })
          .to(chestRef.current, {
            x: -SHAKE_DISTANCE,
            duration: SHAKE_SPEED / 2,
            ease: "power1.inOut",
          });
      } else {
        // Stop shaking animation
        if (shakeTimelineRef.current) {
          shakeTimelineRef.current.kill();
        }
        gsap.to(chestRef.current, { x: 0, duration: 0.2 });
      }

      return () => {
        if (shakeTimelineRef.current) {
          shakeTimelineRef.current.kill();
        }
      };
    }, [animate, isOpen]);

    useEffect(() => {
      if (isOpen) {
        // Animate chest opening with explosive feel
        gsap.to(chestRef.current, {
          scale: 1.3,
          duration: 0.2,
          ease: "power2.out",
          onComplete: () => {
            setShowOpenChest(true);
            gsap.to(chestRef.current, {
              scale: 1,
              duration: 0.3,
              ease: "elastic.out(1, 0.5)",
            });

            // Animate particles
            const animateParticle = (particle) => {
              gsap.set(particle, {
                x: 0,
                y: 0,
                scale: gsap.utils.random(0.3, 1),
                opacity: 1,
              });
              gsap.to(particle, {
                x: gsap.utils.random(-200, 200),
                y: gsap.utils.random(-200, 200),
                scale: 0,
                opacity: 0,
                duration: gsap.utils.random(1, 2),
                ease: "power2.out",
                onComplete: () => animateParticle(particle),
              });
            };

            particlesRef.current.forEach((particle) => {
              animateParticle(particle);
            });
          },
        });
      } else {
        setShowOpenChest(false);
        // Reset particles animation
        particlesRef.current.forEach((particle) => {
          gsap.killTweensOf(particle);
          gsap.set(particle, { x: 0, y: 0, scale: 0, opacity: 0 });
        });
      }
    }, [isOpen]);

    const handleClick = () => {
      if (!isOpen && onOpen) {
        onOpen();
      }
    };

    return (
      <ChestContainer ref={chestRef} onClick={handleClick}>
        <ParticleContainer>
          {[...Array(PARTICLE_COUNT)].map((_, index) => (
            <Particle
              key={index}
              ref={(el) => (particlesRef.current[index] = el)}
              color={PARTICLE_COLORS[index % PARTICLE_COLORS.length]}
            />
          ))}
        </ParticleContainer>
        <ChestImage
          src={`/images/chests/${chestLevel}${showOpenChest ? "o" : ""}.webp`}
          alt={`Level ${chestLevel} chest ${showOpenChest ? "open" : "closed"}`}
        />
      </ChestContainer>
    );
  }
);

ChestAnimation.displayName = "ChestAnimation";
