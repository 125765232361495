import { useState, useEffect } from "react";
import { usePreloadCharacters } from "@/hooks/usePreloadCharacters";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { H2, H3, H6 } from "@/components/Typography";
import { useSounds } from "@/hooks/useSound";
import { countdownSound } from "@/utils/sounds";
import { combatActions } from "../actions/combatActions";

const COUNTDOWN_TIME = 3;

export function PreBattle() {
  const [showPreBattle, setShowPreBattle] = useState(true);
  const [countdown, setCountdown] = useState(COUNTDOWN_TIME);
  const { error } = usePreloadCharacters();
  const { t } = useTranslation();
  const { playSound } = useSounds();

  useEffect(() => {
    let isActive = true;

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1 && isActive) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    playSound(countdownSound);

    return () => {
      isActive = false;
      clearInterval(timer);
    };
  }, [playSound]);

  useEffect(() => {
    if (countdown === 0) {
      const result = combatActions.startNewBattle();
      if (result) {
        setShowPreBattle(false);
      }
    }
  }, [countdown]);

  if (!showPreBattle) {
    return null;
  }

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      bgcolor="rgba(0, 0, 0, 0.3)"
      zIndex={1000}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <H3>{t("battle.preparingBattle")}</H3>
        <H2 variant="h2" mt={4}>
          {countdown}
        </H2>
        {error && (
          <H6 variant="h6" color="error" mt={2}>
            {t("battle.error", { error })}
          </H6>
        )}
      </Box>
    </Box>
  );
}
