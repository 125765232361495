import { memo } from "react";
import { Box } from "@mui/material";
import { SmartImage } from "./SmartImage";
import { useUserStore } from "@/game/logic/useUserStore";
import { useProgressStore } from "@/game/logic/useProgressStore";

export const BackgroundImage = memo(() => {
  const { world } = useProgressStore();
  const { user } = useUserStore();
  const image = world?.img;
  const isCurrentWorld = world?.id === user?.progress?.world;

  return (
    <Box
      sx={{
        width: window.innerWidth,
        height: window.innerHeight,
        position: "relative",
        overflow: "hidden",
        zIndex: -1,
      }}
    >
      <SmartImage
        src={image}
        alt="Background"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />

      {!isCurrentWorld && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        />
      )}
    </Box>
  );
});

BackgroundImage.displayName = "BackgroundImage";
