import { useEffect } from "react";
import { useAlert } from "./useAlert";

export function useAsyncEffect(initFunction, dependencies = []) {
  const { setError } = useAlert();

  useEffect(() => {
    async function runAsync() {
      try {
        await initFunction();
      } catch (error) {
        console.error("❌  ERROR useAsyncEffect:", error);
        setError(error);
      }
    }
    runAsync();
  }, dependencies);
}
