import { secondary } from "@/theme/colors";
import { Stack, Box } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import { H5, Small } from "./Typography";
import { calculateDifficulty } from "@/utils/stats";
import { useTranslation } from "react-i18next";
import { useUserStore } from "@/game/logic/useUserStore";
import { useProgressStore } from "@/game/logic/useProgressStore";

export const DungeonTitle = ({ completedWorld }) => {
  const { t } = useTranslation();
  const { world } = useProgressStore();
  const { user } = useUserStore();

  const cycle = user?.progress?.cycle || 1;
  const futureWorld = user?.progress?.world < world?.id;
  const imageSrc = completedWorld
    ? "/images/icons/Icon_ItemIcon_Trophy.webp"
    : "/images/icons/Icon_ItemIcon_Lock.png";

  const difficultyText = calculateDifficulty(world?.baseDifficulty, cycle);

  return (
    <Stack
      direction="row"
      sx={{
        position: "relative",
        height: "80px",
        width: "240px",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        border: `1px solid ${secondary.main}`,
        boxShadow: "0 0 0 2px rgba(0,0,0,1)",
        backgroundImage: `url(${world?.img})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0,0.3)",
          borderRadius: "inherit",
          pointerEvents: "none",
          zIndex: completedWorld || futureWorld ? 2 : 0,
        }}
      />
      <Box
        sx={{
          display: completedWorld || futureWorld ? "block" : "none",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <SmartImage
          src={imageSrc}
          alt="Level"
          width={100}
          height={100}
          style={{
            width: "50px",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: 2,
          }}
        />
      </Box>
      <Stack zIndex={1} position="relative">
        <H5>
          {world?.id}. {world?.title}
        </H5>

        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "center",
            marginBottom: -2,
            marginLeft: -1,
          }}
        >
          <SmartImage
            src={`/images/badges/${cycle}.webp`}
            height={50}
            width={50}
            alt={`${cycle} badge`}
            style={{
              width: `${40}px`,
              height: "auto",
            }}
          />
          <Small fontSize={12} color="secondary.main">
            {t("game.difficulty")}: {difficultyText}
          </Small>
        </Stack>
      </Stack>
    </Stack>
  );
};
