import {
  calculateDifficulty,
  calculateHeroStat,
  calculateHeroEnergy,
  calculateEnergyRegenRate,
  calculateItemValue,
} from "@/utils/stats";

export const calculateEnemyStats = (baseEnemy, worldId, user, findWorld) => {
  const world = findWorld(worldId);
  const cycle = user.progress.cycle || 1;
  const worldDifficulty = world
    ? calculateDifficulty(world.baseDifficulty, cycle)
    : 1;

  return {
    ...baseEnemy,
    hp: Math.round(baseEnemy.hp * worldDifficulty),
    maxHp: Math.round(baseEnemy.hp * worldDifficulty),
    attack: Math.round(baseEnemy.attack * worldDifficulty),
    attackCount: 0,
  };
};

export const calculateStatUpgradeCost = (stat, user, baseUpgradeCost) => {
  const level = user?.statLevels?.[stat];
  return Math.floor(baseUpgradeCost + (level - 1) * 50);
};

export const calculateTotalStats = (equippedItems, user, statConfig, items) => {
  if (!user) {
    return {
      attack: statConfig.attack.base,
      hp: statConfig.maxHp.base,
      maxHp: statConfig.maxHp.base,
      energyRegenRate: statConfig.energyRegenRate.base,
    };
  }

  // Get the active hero
  const activeHero = user.selectedHero;
  const rarityIndex = activeHero.rarityIndex;

  // Calculate hero stats
  const heroAttack = activeHero
    ? calculateHeroStat(activeHero, "attack", rarityIndex)
    : statConfig.attack.base;
  const heroDefense = activeHero
    ? calculateHeroStat(activeHero, "defense", rarityIndex)
    : statConfig.maxHp.base;
  const heroEnergy = activeHero
    ? calculateHeroEnergy(activeHero, rarityIndex)
    : statConfig.energy.base;

  // Use hero stats as base values
  const baseAttack =
    heroAttack + (user.statLevels.attack - 1) * statConfig.attack.increment;
  const baseHp =
    heroDefense + (user.statLevels.maxHp - 1) * statConfig.maxHp.increment;

  const baseEnergyRegenRate = calculateEnergyRegenRate(
    user.statLevels.energyRegenRate,
    statConfig
  );

  let totalAttack = baseAttack;
  let totalHp = baseHp;

  // Ensure equippedItems is an object and each type is an array
  const equippedItemsSafe = equippedItems || {};

  Object.values(equippedItemsSafe).forEach((itemArray) => {
    // Ensure itemArray is an array before calling forEach
    if (Array.isArray(itemArray)) {
      itemArray.forEach((item) => {
        if (item) {
          const fullItem = items.find((i) => i.id === item.typeId);

          if (fullItem) {
            // Calculate attack and defense values using the calculateValue function
            const attackValue = calculateItemValue(
              fullItem,
              item.level,
              "attack"
            );
            const defenseValue = calculateItemValue(
              fullItem,
              item.level,
              "defense"
            );

            // Add calculated values to totals
            if (attackValue != null) totalAttack += attackValue;
            if (defenseValue != null) totalHp += defenseValue;
          }
        }
      });
    }
  });

  return {
    attack: totalAttack,
    hp: totalHp,
    maxHp: totalHp,
    energyRegenRate: baseEnergyRegenRate,
    energy: heroEnergy,
  };
};
