import { useClickSound } from "@/hooks/useClickSound";
import { borderRadiusNew } from "@/theme/colors";
import { closeSound } from "@/utils/sounds";
import { Box } from "@mui/material";
import { SmartImage } from "./SmartImage";

export function CloseIcon({ size = "30px", onClose, color, sx }) {
  const handleClose = useClickSound(onClose, "", closeSound);

  return (
    <Box
      onClick={handleClose}
      sx={{
        visibility: onClose ? "visible" : "hidden",
        position: "absolute",
        top: 0,
        right: 0,
        backgroundColor: color,
        height: size,
        width: size,
        borderRadius: borderRadiusNew,
        boxShadow: `
          inset 0px -3px 0px 0px rgba(0, 0, 0, 0.3)
        `,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <SmartImage
        src="/images/icons/close.webp"
        width={120}
        height={120}
        alt="Close"
        style={{
          visibility: onClose ? "visible" : "hidden",
          height: "22px",
          width: "22px",
        }}
      />
    </Box>
  );
}
