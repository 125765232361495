import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BottomBarItem } from "@/components/BottomBarItem";
import { maxWidth } from "@/theme/colors";
import { useAlert } from "@/hooks/useAlert";
import { useAppStore } from "@/hooks/useAppStore";
import { useIntroState } from "../logic/useIntroState";

export function BottomBar() {
  const { t } = useTranslation();
  const { setError } = useAlert();
  const { tab, setTab } = useAppStore();
  const { isUnlocked } = useIntroState();

  function handleClick(route) {
    // TODO: add GA event
    // sendGAEvent("event", "bottom_bar_clicked", { value: route });
    setTab(route);
  }

  return (
    <Box
      position="fixed"
      sx={{
        width: "100%",
        margin: "auto",
        top: "auto",
        bottom: 0,
        backgroundColor: "transparent",
        boxShadow: "none",
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          margin: "auto",
          maxWidth: maxWidth,
        }}
      >
        <BottomBarItem
          onClick={() => handleClick("/Store")}
          iconSrc="/images/icons/Icon_ItemIcon_Shop.webp"
          label={t("pages.store")}
          isActive={tab === "/Store"}
          locked={!isUnlocked("BOTTOM_BAR")}
        />

        <BottomBarItem
          onClick={() => handleClick("/Gear")}
          iconSrc="/images/items/Shield_B.webp"
          label={t("pages.gear")}
          isActive={tab === "/Gear"}
          locked={!isUnlocked("BOTTOM_BAR")}
        />

        <BottomBarItem
          onClick={() => {
            handleClick("/");
          }}
          iconSrc="/images/icons/swords.webp"
          label={t("pages.home")}
          main
          isActive={tab === "/"}
        />

        <BottomBarItem
          onClick={() => handleClick("/Hero")}
          iconSrc="/images/icons/slime2.webp"
          label={t("pages.hero")}
          isActive={tab === "/Hero"}
          locked={!isUnlocked("BOTTOM_BAR")}
        />

        <BottomBarItem
          onClick={() => handleClick("/Arena")}
          iconSrc="/images/icons/energy.webp"
          label={t("pages.arena")}
          locked
          onLockedClicked={() => setError(t("lockedFeatures.arena"))}
          isActive={tab === "/Arena"}
        />
      </Box>
    </Box>
  );
}
