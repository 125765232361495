import { memo, useEffect, useRef, useState, useMemo } from "react";
import { Stack } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import { Paragraph } from "./Typography";
import { usePlayerStore } from "@/game/logic/usePlayerStore";
import { usePotionStore } from "@/game/logic/usePotionStore";
import {
  primary,
  error,
  borderWidth,
  backgroundColorSecondary,
  backgroundColorHex,
} from "@/theme/colors";

export const IconNumberAttack = memo(() => {
  const { player } = usePlayerStore();
  const { damageBuffActive } = usePotionStore();
  const [isBuffed, setIsBuffed] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const countdownRef = useRef(null);
  const [animationKey, setAnimationKey] = useState(0);

  // Handle buff activation and deactivation
  useEffect(() => {
    if (damageBuffActive) {
      setIsBuffed(true);
      setTimeLeft(5);
      setAnimationKey((prev) => prev + 1);
    } else {
      setIsBuffed(false);
      setTimeLeft(0);
      if (countdownRef.current) {
        clearInterval(countdownRef.current);
      }
    }
  }, [damageBuffActive]);

  // Handle countdown separately
  useEffect(() => {
    if (isBuffed && timeLeft > 0) {
      countdownRef.current = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(countdownRef.current);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (countdownRef.current) {
        clearInterval(countdownRef.current);
      }
    };
  }, [isBuffed]);

  const containerStyle = useMemo(
    () => ({
      background: `linear-gradient(to top, ${backgroundColorHex}, ${backgroundColorSecondary})`,
      borderWidth: borderWidth,
      borderColor: isBuffed ? error.main : backgroundColorHex,
      borderStyle: "solid",
      borderRadius: "24px",
      zIndex: 2,
      padding: "4px 10px",
      position: "relative",
      margin: "4px 4px",
      height: "fit-content",
      animation: isBuffed ? "active-buff 0.8s ease-in-out" : "none",
      transition: "border-color 0.3s ease",
    }),
    [isBuffed]
  );

  return (
    <Stack
      key={animationKey}
      direction="row"
      alignItems="center"
      sx={containerStyle}
    >
      <SmartImage
        src="/images/items/Sword_B.webp"
        height={66}
        width={66}
        alt="Attack"
        style={{
          position: "absolute",
          top: "-16px",
          left: "-8px",
          zIndex: 1,
        }}
      />
      <Stack
        direction="row"
        sx={{
          padding: "1px",
          width: "70px",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        <Paragraph
          ml={6}
          sx={{
            color: isBuffed ? error.main : primary.white,
            fontSize: "1.5em",
            transition: "color 0.3s ease",
          }}
        >
          {isBuffed ? player.attack * 2 : player.attack}
        </Paragraph>
      </Stack>

      {isBuffed && timeLeft > 0 && (
        <Paragraph
          sx={{
            color: error.main,
            fontSize: "1em",
            position: "absolute",
            bottom: -10,
            right: -10,
            opacity: 0.8,
          }}
        >
          {timeLeft}
        </Paragraph>
      )}
    </Stack>
  );
});

IconNumberAttack.displayName = "IconNumberAttack";
