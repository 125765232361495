import { backgroundColorSecondary, topBarHeight } from "@/theme/colors";
import { Box, Modal } from "@mui/material";
import { H3 } from "./Typography";
import { darkenColor } from "@/utils/misc";
import { closeSound } from "@/utils/sounds";
import { useClickSound } from "@/hooks/useClickSound";
import { SmartImage } from "./SmartImage";
import { CloseIcon } from "./CloseIcon";

export function ScreenModal({
  open,
  onClose,
  children,
  title,
  color: mainColor = backgroundColorSecondary,
  titleBgColor,
  titleImage,
  contentSx,
  modalSx,
  closeIconSx,
}) {
  const handleClose = useClickSound(onClose, "", closeSound);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          height: "100vh",
          backgroundColor: mainColor,
          boxShadow: `
                inset 0px 3px 0px 0px rgba(255,255,255,0.1), 
                inset 0px -3px 0px 0px rgba(0, 0, 0, 0.4), 
                7px 7px 10px 0px rgba(0,0,0,.1), 
                0px 4px 2px 0px rgba(0,0,0,.5)
              `,
          ":focus-visible": {
            outline: "none",
          },
          animation: open ? "bounce-in-3 0.25s" : "none",
          ...modalSx,
        }}
      >
        <Box
          display={title ? "block" : "none"}
          textAlign="center"
          sx={{
            position: "relative",
            backgroundColor: titleBgColor || darkenColor(mainColor, 15),
            padding: "10px",
            boxShadow: `
                  inset 0px 3px 0px 0px rgba(255,255,255,0.3), 
                  inset 0px -3px 0px 0px rgba(0, 0, 0, 0.1), 
                  7px 7px 10px 0px rgba(0,0,0,.1)
                `,
          }}
        >
          {titleImage && (
            <SmartImage
              src={titleImage}
              width={120}
              height={120}
              alt={title}
              style={{
                position: "absolute",
                width: "60px",
                height: "auto",
                top: "50%",
                left: "10px",
                transform: "translateY(-50%)",
              }}
            />
          )}
          <H3 color="white">{title}</H3>
        </Box>

        <Box
          sx={{
            padding: "20px",
            ...contentSx,
          }}
        >
          <CloseIcon
            onClose={onClose}
            color={darkenColor(titleBgColor || mainColor, 20)}
            size="36px"
            sx={{
              top: topBarHeight,
              right: "0",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              ...closeIconSx,
            }}
          />
          {children}
        </Box>
      </Box>
    </Modal>
  );
}
