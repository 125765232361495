import { create } from "zustand";
import { prestigeUser } from "@/lib/api";
import { useUserStore } from "./useUserStore";
import { usePlayerStore } from "./usePlayerStore";
import { gameStatsActions } from "../actions/gameStats";

const initialState = {
  prestige: null,
};

export const usePrestigeStore = create((set) => ({
  ...initialState,

  resetPrestige: () => {
    usePlayerStore.getState().recalculateTotalStats();
    set({ prestige: null });
  },

  handlePrestige: async ({ userId, newWorldId, newCycle, jwt }) => {
    try {
      const { data } = await prestigeUser({
        userId,
        newWorldId,
        newCycle,
        jwt,
      });

      // Update UserStore with prestiged user data
      useUserStore.getState().setUserSafe(data.user);

      // Update player stats
      const totalStats = gameStatsActions.calculateTotalStats(
        data.user.equippedItems
      );
      usePlayerStore.getState().setPlayerStats({
        attack: totalStats.attack,
        hp: totalStats.hp,
        maxHp: totalStats.hp,
        energyRegenRate: totalStats.energyRegenRate,
      });

      // Set prestige data
      set({
        prestige: {
          newWorldId,
          gemsAwarded: data.gemsAwarded,
          goldConverted: data.goldConverted,
          newCycle: data.user.progress.cycle,
        },
      });

      return { success: true, data };
    } catch (error) {
      console.error("Error during prestige:", error);
      return { success: false, error: error.message };
    }
  },
}));
