import { Box, lighten } from "@mui/material";
import { H5 } from "@/components/Typography";
import { glowAnimation } from "@/styles/animations";
import { useMemo } from "react";
import { useAssetStore } from "../logic/useAssetStore";

export function RarityChip({ rarity, sx }) {
  const { RARITY_COLORS, RARITY, RARITY_RANK } = useAssetStore();

  const chipStyle = useMemo(() => {
    if (!RARITY_COLORS || !RARITY || !RARITY_RANK) return null;

    const heroColor = RARITY_COLORS?.[rarity];
    const rarityIndex = Object.keys(RARITY)?.indexOf(rarity);
    const glowIntensity = rarityIndex / (Object.keys(RARITY)?.length - 1);

    const animationStyle =
      rarityIndex > 1
        ? {
            animation: `${glowAnimation(heroColor)} ${
              2 + glowIntensity * 4
            }s ease-in-out infinite`,
          }
        : {};

    return {
      position: "absolute",
      top: "6px",
      left: "-2px",
      backgroundColor: heroColor,
      border: `1px solid ${lighten(heroColor, 0.2)}`,
      boxShadow: "inset 0px -7px 0px 0px rgba(0, 0, 0, 0.1)",
      padding: "0 4px",
      borderRadius: "3px",
      height: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,
      ...animationStyle,
      ...sx,
    };
  }, [RARITY_COLORS, RARITY, RARITY_RANK, rarity]);

  if (!chipStyle) return null;

  return (
    <Box sx={chipStyle}>
      <H5
        sx={{
          textShadow: "none",
          WebkitTextStroke: "0.4px black",
        }}
      >
        {RARITY_RANK[rarity]}
      </H5>
    </Box>
  );
}
