import { Box } from "@mui/material";
import { useAppStore } from "@/hooks/useAppStore";
import { Store } from "@/game/Store";
import { Gear } from "@/game/Gear";
import { Hero } from "@/game/Hero";
import { Battle } from "@/game/Battle";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { BottomBar } from "@/game/parts/BottomBar";
import { postEvent } from "@telegram-apps/sdk-react";
import { TopBar } from "@/game/parts/TopBar";
import { Home } from "@/game/Home";
import { PixiWorld } from "@/game/PixiWorld";
import { gaPageview } from "@/utils/analytics";
import { Story } from "@/game/intro/Story";
import { Tutorial } from "@/game/intro/Tutorial";
import { useIntroState } from "@/game/logic/useIntroState";
import { useSessionTracker } from "@/hooks/useSessionTracker";
import { useUserStore } from "@/game/logic/useUserStore";
import { useBattleStore } from "@/game/logic/useBattleStore";

const TabContent = ({ isActive, children }) => (
  <Box style={{ display: isActive ? "block" : "none" }}>{children}</Box>
);

export default function Index() {
  const { tab } = useAppStore();
  const { battleSession } = useBattleStore();
  const { user } = useUserStore();
  const { ongoingTutorial } = useIntroState();

  // track session length
  useSessionTracker(user?.id);

  useEffect(() => {
    postEvent("web_app_expand");
    postEvent("web_app_setup_swipe_behavior", { allow_vertical_swipe: false });
    postEvent("web_app_setup_closing_behavior", { need_confirmation: true });
  }, []);

  useEffect(() => {
    gaPageview(tab || "/");
  }, [tab]);

  useEffect(() => {
    if (battleSession) {
      gaPageview("/Battle");
    }
  }, [battleSession]);

  return (
    <>
      <PixiWorld />
      <AnimatePresence mode="wait">
        {battleSession ? (
          <motion.div
            key="battle"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.5 }}
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              willChange: "auto", // setting this will disable framer motion to set values that disable pointer events
            }}
          >
            <Battle />
          </motion.div>
        ) : (
          <motion.div
            key="home"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.5 }}
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              willChange: "auto", // setting this will disable framer motion to set values that disable pointer events
            }}
          >
            <>
              <TopBar />
              <Box sx={{ position: "relative", height: "100vh" }}>
                <TabContent isActive={tab === "/"}>
                  <Home />
                </TabContent>
                <TabContent isActive={tab === "/Store"}>
                  <Store />
                </TabContent>
                <TabContent isActive={tab === "/Gear"}>
                  <Gear />
                </TabContent>
                <TabContent isActive={tab === "/Hero"}>
                  <Hero />
                </TabContent>
              </Box>
              <BottomBar />
            </>
          </motion.div>
        )}
      </AnimatePresence>
      {ongoingTutorial && (
        <>
          <Story />
          <Tutorial />
        </>
      )}
    </>
  );
}
