import { motion } from "framer-motion";
import { useIntroState } from "@/game/logic/useIntroState";
import { useState } from "react";
import { Box, Stack } from "@mui/material";
import { H6, Small } from "@/components/Typography";
import { useTranslation } from "react-i18next";
import { SmartImage } from "@/components/SmartImage";

const INTRO_TEXTS = {
  INTRO_1: "intro.intro1",
  INTRO_2: "intro.intro2",
  INTRO_3: "intro.intro3",
};

export function Story() {
  const { t } = useTranslation();
  const { step, setStep } = useIntroState();
  const [showing, setShowing] = useState(true);

  // Check if the current step is part of the intro steps
  const isIntroStep =
    step === "INTRO_1" || step === "INTRO_2" || step === "INTRO_3";

  // If not an intro step, do not show the component
  if (!isIntroStep || !showing) return null;

  const handleTap = () => {
    if (step === "INTRO_1") {
      setStep("INTRO_2");
    } else if (step === "INTRO_2") {
      setStep("INTRO_3");
    } else if (step === "INTRO_3") {
      setStep("HOME_START");
      setShowing(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0,0,0,0.8)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
      onClick={handleTap}
    >
      <Box sx={{ animation: "up-down 2s infinite" }}>
        {step === "INTRO_1" && (
          <SmartImage src="/images/story/1.webp" height={300} width={300} />
        )}
        {step === "INTRO_2" && (
          <SmartImage src="/images/story/2.webp" height={300} width={300} />
        )}
        {step === "INTRO_3" && (
          <SmartImage src="/images/story/3.webp" height={300} width={300} />
        )}
      </Box>

      <Box
        sx={{
          marginTop: "10px",
          background: "white",
          padding: 3,
          borderRadius: 2,
          maxWidth: "80%",
          textAlign: "center",
          position: "relative",
          ":after": {
            content: "''",
            position: "absolute",
            bottom: 0,
            left: "50%",
            width: 0,
            height: 0,
            border: "40px solid transparent",
            borderTopColor: "white",
            borderBottom: 0,
            borderLeft: 0,
            marginLeft: "-28px",
            marginBottom: "-40px",
          },
        }}
      >
        <H6
          color="primary.black"
          sx={{
            mb: 2,
            textShadow: "none",
            WebkitTextStroke: "none",
          }}
        >
          {t(INTRO_TEXTS[step])}
        </H6>
        <Small
          color="primary.black"
          sx={{
            mb: 2,
            textShadow: "none",
            WebkitTextStroke: "none",
            opacity: 0.5,
            position: "absolute",
            top: "10px",
            left: "14px",
          }}
        >
          {`${step === "INTRO_1" ? 1 : step === "INTRO_2" ? 2 : 3}/3`}
        </Small>
      </Box>

      <Stack sx={{ position: "absolute", bottom: 0, left: 0 }}>
        <SmartImage
          src="/images/blu.png"
          height={180}
          width={180}
          style={{ margin: "auto" }}
        />
      </Stack>

      <Stack
        zIndex={10}
        sx={{
          position: "absolute",
          top: "6%",
          left: 0,
          right: 0,
          animation: "pulse-animation 1.5s infinite",
        }}
      >
        <H6 textAlign="center" onClick={handleTap}>
          {t("intro.tapToContinue")}
        </H6>
      </Stack>
    </motion.div>
  );
}
