import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { PlayerModal } from "./PlayerModal";
import { SettingsModal } from "./SettingsModal";
import { maxWidth } from "@/theme/colors";
import { useClickSound } from "@/hooks/useClickSound";
import { useAppStore } from "@/hooks/useAppStore";
import { StonesModal } from "../hero/StonesModal";
import { useMemo } from "react";
import { IconTile } from "@/components/IconTile";
import { ResourceDisplay } from "@/components/ResourceDisplay";
import { formatXP } from "@/utils/misc";
import { UserChip } from "@/components/UserChip";
import { useIntroState } from "../logic/useIntroState";
import { useUserStore } from "../logic/useUserStore";
import { PotionsModal } from "../gear/PotionsModal";

export function TopBar() {
  const { user } = useUserStore();
  const { tab, setTab } = useAppStore();
  const [userOpen, setUserOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [stonesOpen, setStonesOpen] = useState(false);
  const [potionsOpen, setPotionsOpen] = useState(false);
  const { isUnlocked } = useIntroState();
  const handleOpen = useClickSound(() => {
    setUserOpen(true);
  });

  const scrollToCategory = (categoryId) => {
    setTab("/Store");
    setTimeout(() => {
      const element = document.getElementById(categoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const totalStones = useMemo(() => {
    if (!user?.resources?.stones) return 0;
    return Object.values(user.resources.stones).reduce(
      (sum, amount) => sum + amount,
      0
    );
  }, [user?.resources?.stones]);

  const totalPotions = useMemo(() => {
    if (!user?.resources?.potions) return 0;
    return Object.values(user.resources.potions).reduce(
      (sum, amount) => sum + amount,
      0
    );
  }, [user?.resources?.potions]);

  return (
    <Box
      py={1}
      px={1}
      sx={{
        width: "100%",
        zIndex: 10,
        position: "fixed",
        top: 0,
        display: isUnlocked("TOP_BAR") ? "block" : "none",
      }}
    >
      <Stack
        sx={{
          margin: "auto",
          maxWidth: maxWidth,
        }}
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        px={1}
      >
        <ResourceDisplay
          iconComponent={<UserChip user={user} />}
          count={formatXP(user?.xp || 0)}
          onClick={handleOpen}
          altText="XP"
          badge
        />

        <ResourceDisplay
          icon="/images/icons/rune.webp"
          count={user?.resources?.runes || 0}
          onClick={() => scrollToCategory("Runes")}
          showCondition={tab === "/Gear"}
          altText="Runes"
        />

        <ResourceDisplay
          icon="/images/icons/potions.webp"
          count={totalPotions}
          onClick={() => setPotionsOpen(true)}
          showCondition={tab === "/Gear"}
          altText="Potions"
        />

        <ResourceDisplay
          icon="/images/icons/gem.webp"
          count={user?.resources?.gems}
          onClick={() => scrollToCategory("Gems")}
          showCondition={["/Hero", "/Store", "/"].includes(tab)}
          altText="Gems"
        />

        <ResourceDisplay
          icon="/images/icons/coin.webp"
          count={user?.resources?.gold}
          onClick={() => scrollToCategory("Gold")}
          showCondition={["/Store", "/"].includes(tab)}
          altText="Gold"
        />

        <ResourceDisplay
          icon="/images/icons/stones.webp"
          count={totalStones}
          onClick={() => setStonesOpen(true)}
          showCondition={tab === "/Hero"}
          altText="Stones"
        />

        <IconTile
          image="/images/icons/settings.webp"
          onClick={() => setSettingsOpen(true)}
        />
      </Stack>
      <PlayerModal open={userOpen} onClose={() => setUserOpen(false)} />

      <SettingsModal
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
      />

      <StonesModal open={stonesOpen} onClose={() => setStonesOpen(false)} />

      <PotionsModal open={potionsOpen} onClose={() => setPotionsOpen(false)} />
    </Box>
  );
}
