import { Box, Stack } from "@mui/material";
import { borderRadiusNew } from "@/theme/colors";
import { H2 } from "@/components/Typography";
import { Item } from "../../gear/Item";

export function AtlasArmory({ tab, sortedRarities, groupedGear, onClaim }) {
  return (
    <Box
      margin="0 auto"
      sx={{
        animation: tab === 2 ? "slide-up 0.15s ease-out" : "none",
      }}
    >
      {sortedRarities.map((rarity) => (
        <Stack
          key={rarity}
          mb={2}
          sx={{
            borderRadius: borderRadiusNew,
            backgroundColor: "rgba(0,0,0,0.1)",
            padding: 1,
          }}
        >
          <H2
            sx={{
              mt: 2,
              mb: 1,
              textAlign: "center",
            }}
          >
            {rarity}
          </H2>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={1}>
              {groupedGear[rarity].map((item, index) => (
                <Item
                  size="M"
                  key={index}
                  xs={3}
                  item={item.isClaimed ? item : null}
                  showLevel={item?.level}
                  isSelected={false}
                  tileIndex={index + 1}
                  count={item.count}
                  onClick={() => onClaim(item)}
                  alternativeIcon={
                    item.isAchieved
                      ? "/images/icons/gift.webp"
                      : "/images/icons/question.webp"
                  }
                  imageSx={{
                    opacity: item.isAchieved ? 1 : 0.7,
                  }}
                  showBurst={item.isAchieved && !item.isClaimed}
                />
              ))}
            </Box>
          </Box>
        </Stack>
      ))}
    </Box>
  );
}
