import { useEffect } from "react";
import { SimpleModal } from "@/components/SimpleModal";
import { Stack, LinearProgress, Box } from "@mui/material";
import { Paragraph, Small } from "@/components/Typography";
import { SimpleButton } from "@/components/SimpleButton";
import {
  backgroundPattern,
  borderRadiusNew,
  primary,
  scrollbarStyles,
} from "@/theme/colors";
import { SmartImage } from "@/components/SmartImage";
import { useAlert } from "@/hooks/useAlert";
import { won2xSound } from "@/utils/sounds";
import { useAppStore } from "@/hooks/useAppStore";
import {
  getImageForRewardType,
  hasClaimableAchievements,
  mergeAchievements,
} from "@/utils/glory";
import { useSounds } from "@/hooks/useSound";
import { useAssetStore } from "../logic/useAssetStore";
import { usePlayerMetricsStore } from "../logic/usePlayerMetricsStore";

export function Glory({ open, onClose }) {
  const { achievements: playerAchievements, claimAchievementRewards } =
    usePlayerMetricsStore();
  const { setError } = useAlert();
  const { setBadge } = useAppStore();
  const { playSound } = useSounds();
  const { ACHIEVEMENTS } = useAssetStore();

  useEffect(() => {
    if (ACHIEVEMENTS && playerAchievements) {
      const mergedAchievements = mergeAchievements(
        ACHIEVEMENTS,
        playerAchievements
      );
      const hasClaimable = hasClaimableAchievements(mergedAchievements);
      setBadge("glory", hasClaimable);
    }
  }, [ACHIEVEMENTS, playerAchievements, setBadge]);

  if (!ACHIEVEMENTS || !playerAchievements) return null;

  const mergedAchievements = mergeAchievements(
    ACHIEVEMENTS,
    playerAchievements
  );

  const handleClaimReward = async (achievementType, level) => {
    const response = await claimAchievementRewards({ achievementType, level });
    if (!response.success) {
      setError(response.error);
    } else {
      playSound(won2xSound);
    }
  };

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title="Glory"
      titleBgColor={primary.main}
      titleImage="/images/icons/trophy.webp"
      contentSx={{
        padding: "10px",
        background: `linear-gradient(to top, #FF9B00, ${primary.main})`,
        ...backgroundPattern,
      }}
    >
      <Stack
        spacing={2}
        sx={{
          maxHeight: "500px",
          overflowY: "auto",
          minWidth: "300px",
          ...scrollbarStyles,
          // backgroundColor: darken(backgroundColorSecondary, 0.2),
          padding: "10px",
          borderRadius: borderRadiusNew,
        }}
      >
        {mergedAchievements.map((achievement) => {
          const {
            currentCount,
            threshold,
            claimed,
            achieved,
            rewardType,
            rewardCount,
            level,
          } = achievement.nextAchievement || achievement.lastAchievement || {};

          const isCompleted = !achievement.nextAchievement;
          const disabled = claimed || !achieved || isCompleted;

          return (
            <Stack
              key={achievement.type}
              direction="row"
              spacing={2}
              px={1}
              py={0.5}
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                borderRadius: borderRadiusNew,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack width="160px" flex={2} spacing={0.5}>
                <Paragraph sx={{ lineHeight: 1 }}>{achievement.name}</Paragraph>
                <Small sx={{ marginBottom: "2px", fontSize: "10px" }}>
                  {isCompleted
                    ? "Completed"
                    : `${threshold} ${achievement.description}`}
                </Small>
                <Box position="relative">
                  <LinearProgress
                    variant="determinate"
                    value={
                      isCompleted
                        ? 100
                        : Math.min(((currentCount || 0) / threshold) * 100, 100)
                    }
                    style={{ flexGrow: 1 }}
                    sx={{
                      height: "14px",
                      width: "100%",
                      borderRadius: "2px",
                      "& .MuiLinearProgress-bar": {
                        boxShadow: "inset 0px -6px 0px 0px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  />
                  <Small
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: "50%",
                      transform: "translateX(-50%)",
                      fontSize: "10px",
                    }}
                  >
                    {isCompleted ? "100%" : `${currentCount}/${threshold}`}
                  </Small>
                </Box>
              </Stack>
              <Stack flex={1} spacing={0.5} alignItems="center">
                <Stack direction="row" justifyContent="space-between">
                  {Array.from({ length: achievement.totalSubAchievements }).map(
                    (_, index) => (
                      <SmartImage
                        key={index}
                        src={
                          index < achievement.achievedSubAchievements
                            ? "/images/icons/star.webp"
                            : "/images/icons/star_grey.webp"
                        }
                        width={16}
                        height={16}
                        alt="Glory"
                        style={{
                          opacity:
                            index < achievement.achievedSubAchievements
                              ? 1
                              : 0.3,
                          transition: "opacity 0.3s ease-in-out",
                        }}
                      />
                    )
                  )}
                </Stack>
                {isCompleted ? (
                  <SmartImage
                    src="/images/icons/check.webp"
                    width={40}
                    height={40}
                    alt="Glory"
                  />
                ) : (
                  <SimpleButton
                    color={disabled ? "grey" : "green"}
                    disabled={disabled}
                    size="M"
                    image={getImageForRewardType(rewardType)}
                    onClick={() => handleClaimReward(achievement.type, level)}
                  >
                    {claimed || rewardCount}
                  </SimpleButton>
                )}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </SimpleModal>
  );
}
