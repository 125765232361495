export const API_URL = import.meta.env.DEV
  ? "http://127.0.0.1:8060"
  : "https://loot-api.vallarok.com";

export const CDN_URL = "https://slimes.fra1.cdn.digitaloceanspaces.com/public";

export const confettiProps = {
  force: 0.6,
  duration: 2000,
  particleCount: 60,
  width: 400,
  colors: ["#40986F", "#216ED8", "#4B48E8", "#FF8000", "#FF0000"],
};

export const STATUS = {
  ERROR: "error",
  SUCCESS: "success",
};

export const DAMAGE_DELAY_ENEMY = 200;
export const GOLD_UPDATE_DELAY = 2000; // 2 seconds delay for gold updates
export const METRICS_UPDATE_DELAY = 5000; // 5 seconds delay for metrics updates

export const BADGES = {
  1: "Bronze 1",
  2: "Bronze 2",
  3: "Bronze 3",
  4: "Silver 1",
  5: "Silver 2",
  6: "Silver 3",
  7: "Gold 1",
  8: "Gold 2",
  9: "Gold 3",
  10: "Diamond 1",
  11: "Diamond 2",
  12: "Diamond 3",
};

export const LEVEL_CAPS = {
  COMMON: 5,
  UNCOMMON: 7,
  RARE: 10,
  EPIC: 15,
  LEGENDARY: 25,
  MYTHIC: 50,
};

export const LOCKED_MAP = {
  ATLAS: 130,
  RANK: 160,
  GLORY: 190,
  QUESTS: 230,
  OFFER_STARTER: 250,
  OFFER_ULTIMATE: 270,
};

export const POTION_BUFF_DURATION = 5000;
