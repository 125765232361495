import { Stack } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import { Paragraph } from "./Typography";
import { useClickSound } from "@/hooks/useClickSound";

export function Display({
  children,
  imgSrc,
  value,
  onClick = () => {},
  icon,
  sx,
}) {
  const handleClick = useClickSound(onClick);
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        // background: `linear-gradient(to top, ${backgroundColorHex}, ${backgroundColorSecondary})`,
        // borderWidth: borderWidth,
        // borderColor: backgroundColorHex,
        // borderStyle: "solid",
        backgroundColor: "rgba(0,0,0,0.6)",
        borderRadius: "20px",
        zIndex: 2,
        // padding: "2px 8px 2px 6px",
        // padding: "2px",
        position: "relative",
        // margin: "4px 4px",
        height: "26px",
        minWidth: "50px",
        ...sx,
      }}
      onClick={handleClick}
    >
      {icon && icon}
      {imgSrc && (
        <SmartImage
          src={imgSrc}
          height={60}
          width={60}
          alt="Icon"
          style={{ position: "absolute", top: "-20px", left: "-20px" }}
        />
      )}
      <Stack direction="row" sx={{ padding: "2px 2px" }}>
        {children ? (
          children
        ) : (
          <Paragraph ml={4}>{value?.toLocaleString()}</Paragraph>
        )}
      </Stack>
    </Stack>
  );
}
