import { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { gsap } from "gsap";

const getRandomPosition = () => ({
  left: `${Math.random() * 80 + 10}%`,
  top: `${Math.random() * 80 + 10}%`,
});

const Star = ({ index }) => {
  const starRef = useRef(null);

  return (
    <Box
      ref={starRef}
      key={index}
      sx={{
        position: "absolute",
        width: "20px",
        height: "20px",
        backgroundImage: "url('/images/effects/Particle_06.webp')",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        zIndex: 1,
        opacity: 0,
      }}
    />
  );
};

export function SlowStarEffect({ count = 8, sx, ...props }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const stars = containerRef.current.children;

    const animateStar = (star) => {
      gsap.set(star, { ...getRandomPosition(), scale: 0, opacity: 0 });

      gsap.to(star, {
        duration: 1,
        scale: 1,
        opacity: 1,
        ease: "power2.out",
      });

      gsap.to(star, {
        duration: 1,
        scale: 1.2,
        opacity: 1,
        ease: "none",
        yoyo: true,
        repeat: 1,
      });

      gsap.to(star, {
        duration: 1,
        scale: 0,
        opacity: 0,
        ease: "power2.in",
        delay: 3,
        onComplete: () => animateStar(star),
      });
    };

    // Start animations with staggered delays
    gsap.delayedCall(0, () => {
      Array.from(stars).forEach((star, index) => {
        gsap.delayedCall(index * 0.5, animateStar, [star]);
      });
    });

    return () => {
      gsap.killTweensOf(stars);
    };
  }, [count]);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: "none",
        ...sx,
      }}
      {...props}
    >
      {Array.from({ length: count }).map((_, index) => (
        <Star key={index} index={index} />
      ))}
    </Box>
  );
}
