import { darkenColor } from "@/utils/misc";
import { alpha, lighten } from "@mui/material/styles";

const primaryMain = "#FFD641";
const secondaryMain = "#1EBBC8";

// export const backgroundColorHex = "#26243E";
// export const backgroundColorSecondary = "#2D3350";

export const backgroundColorHex = "#1D1D30";
export const backgroundColorSecondary = "#2D3350";
export const backgroundColor = alpha(backgroundColorHex, 0.7);

export const borderWidth = "2px";
export const borderRadius = 3;
export const borderRadiusNew = "6px";
export const borderColor = alpha(backgroundColor, 0.4);
export const maxWidth = "440px";
export const topBarHeight = "60px";
export const bottomBarHeight = "80px";

export const purpleTextColor = "#faa4ff";
export const greenTextColor = "#f8ffd7";
export const redTextColor = "#FFD6D3";
export const disabledColor = "#666666";

export const primary = {
  light: "#fff7dc",
  main: primaryMain,
  100: alpha(primaryMain, 0.08),
  200: alpha(primaryMain, 0.2),
  300: alpha(primaryMain, 0.3),
  400: alpha(primaryMain, 0.4),
  500: alpha(primaryMain, 0.5),
  600: alpha(primaryMain, 0.6),
  700: alpha(primaryMain, 0.7),
  800: alpha(primaryMain, 0.8),
  red: "#E5312A",
  purple: "#8221B8",
  green: "#51D513",
  white: "#eee",
  black: "#111",
};

export const secondary = {
  light: "#b1f9ff",
  main: secondaryMain,
  100: alpha(secondaryMain, 0.08),
  200: alpha(secondaryMain, 0.2),
  300: alpha(secondaryMain, 0.3),
  400: alpha(secondaryMain, 0.4),
  500: alpha(secondaryMain, 0.5),
  600: alpha(secondaryMain, 0.6),
  700: alpha(secondaryMain, 0.7),
};

export const displaySX = {
  background: `linear-gradient(to top, ${backgroundColorHex}, ${backgroundColorSecondary})`,
  borderWidth: borderWidth,
  borderColor: backgroundColorHex,
  borderStyle: "solid",
  borderRadius: borderRadius,
  border: `1px solid ${secondary.main}`,
  boxShadow: "0 0px 0px 2px rgba(0,0,0,1)",
};

export const info = {
  light: "#F4F4FF",
  main: "#8C8DFF",
  dark: "#0C53B7",
};

export const success = {
  light: "#EAFBF4",
  main: "#27CE88",
  dark: "#229A16",
};

export const warning = {
  light: "#FFFAF2",
  main: "#FFC675",
  dark: "#B78103",
};

export const error = {
  light: "#ff5a8b",
  main: "#FF316F",
  dark: "#B72136",
}; // For light theme

export const greyLight = {
  50: "#f9f9f9",
  100: "#eff3f5",
  200: "#D3E6F3",
  300: "#B1C9DC",
  400: "#8CA3BA",
  500: "#5F748D",
  600: "#455A79",
  700: "#2F4365",
  800: "#1E2E51",
  900: "#121F43",
}; // For dark theme

export const greyDark = {
  900: "#E9F3F9",
  800: "#D3E6F3",
  700: "#B1C9DC",
  600: "#8CA3BA",
  500: "#5F748D",
  400: "#455A79",
  300: "#2F4365",
  200: "#1E2E51",
  100: "#121F43",
  50: "#111111",
}; // For Light theme

export const textLight = {
  primary: greyLight[900],
  secondary: greyLight[500],
  disabled: greyLight[400],
}; // For Dark theme

export const textDark = {
  primary: "#ffffff",
  secondary: greyDark[600],
  disabled: greyDark[400],
}; // For Light theme

export const actionLight = {
  activatedOpacity: 0.12,
  active: alpha(greyLight[900], 0.54),
  disabled: greyLight[300],
  disabledBackground: alpha(greyLight[900], 0.12),
  disabledOpacity: 0.38,
  focus: alpha(greyLight[900], 0.12),
  focusOpacity: 0.12,
  hover: alpha(greyLight[900], 0.04),
  hoverOpacity: 0.04,
  selected: greyLight[100],
  selectedOpacity: 0.08,
}; // Common colors

const palette = {
  info,
  error,
  primary,
  success,
  warning,
  secondary,
};

export const darkPalette = {
  ...palette,
  mode: "dark",
  grey: greyDark,
  text: textDark,
  background: {
    default: backgroundColor,
    paper: "#222b36",
  },
};

export const gradientBackground = `radial-gradient(circle, ${lighten(
  backgroundColorSecondary,
  0.2
)} 0%, ${backgroundColorSecondary} 100%)`;

export const backgroundPattern = {
  position: "relative",
  "&::before, &::after": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
    userSelect: "none",
    zIndex: 0,
  },
  "&::before": {
    backgroundImage: `
      linear-gradient(to right, #516C81 1px, transparent 1px),
      linear-gradient(to bottom, #516C81 1px, transparent 1px)
    `,
    backgroundSize: "20px 20px",
    opacity: 0.1,
  },
  "&::after": {
    backgroundImage: `
      radial-gradient(circle, #516C81 1px, transparent 1px),
      radial-gradient(circle, #516C81 1px, transparent 1px)
    `,
    backgroundSize: "20px 20px",
    backgroundPosition: "0 0, 10px 10px",
    opacity: 0.1,
  },
};

export const getTileStyles = (height, active = true, color) => ({
  background: color || backgroundColorSecondary,
  borderRadius: borderRadiusNew,
  boxShadow: `
    inset 0px 2px 0px 0px rgba(255,255,255,.4), 
    inset 0px -3px 0px 0px rgba(0, 0, 0, 0.4), 
    inset 0px -${height / 2}px 0px 2px rgba(0, 0, 0, 0.1), 
    7px 7px 10px 0px rgba(0,0,0,.1), 
    0px 4px 2px 0px rgba(0,0,0,.5)
  `,
  "&:active": active
    ? {
        boxShadow: `
          inset 0px 2px 0px 0px rgba(255,255,255,.5),
          inset 0px -3px 0px 0px rgba(0, 0, 0, 0.4),
          inset 0px -${height / 2}px 0px 2px rgba(0, 0, 0, 0.1)
        `,
        transform: "translateY(2px)",
      }
    : {},
});

export function getGradient(color, deg = 180) {
  if (!color) return "";
  return `linear-gradient(${deg}deg, ${color} 0%, ${darkenColor(
    color,
    30
  )} 50%, ${color} 100%)`;
}

export const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
};

export const vipPass = {
  background: `
  radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)
`,
};
