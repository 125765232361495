import { create } from "zustand";

export const useAppStore = create((set) => ({
  version: "v0.1.0",

  tab: "/",
  setTab: (newTab) => set({ tab: newTab }),

  badges: {
    glory: false,
    atlasArmory: false,
    atlasBestiary: false,
    quests: false,
    // ... other badges
  },
  setBadge: (badgeName, value) =>
    set((state) => ({
      badges: {
        ...state.badges,
        [badgeName]: value,
      },
    })),
}));
