import { Box, IconButton, Stack, useTheme } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { maxWidth, primary } from "@/theme/colors";
import { CoinCounter } from "@/components/CoinCounter";
import { useState, useEffect, useRef } from "react";
import { GOLD_UPDATE_DELAY } from "@/constants";
import { HealthBar } from "@/components/HealthBar";
import { LevelProgressBar } from "@/game/battle/LevelProgressBar";
import { useDOMShake } from "@/hooks/useDOMShake";
import { memo } from "react";
import { useIntroState } from "../logic/useIntroState";
import { useEnemyAttackState } from "@/game/logic/useEnemyAttackState";
import { useLootStore } from "../logic/useLootStore";
import { useEnemyStore } from "../logic/useEnemyStore";
import { useProgressStore } from "../logic/useProgressStore";
import { useBattleStore } from "../logic/useBattleStore";
import { combatActions } from "../actions/combatActions";

const BattleTopBarComponent = memo(() => {
  const theme = useTheme();
  const { accumulatedLoot } = useLootStore();
  const { dungeon } = useProgressStore();
  const { enemy, character } = useEnemyStore();
  const [visualGold, setVisualGold] = useState(accumulatedLoot.gold);
  const pendingGoldUpdates = useRef({});
  const { isUnlocked } = useIntroState();

  const handleEndBattle = () => {
    combatActions.endBattle();
  };

  useEffect(() => {
    const goldDifference = accumulatedLoot.gold - visualGold;

    if (goldDifference > 0) {
      if (goldDifference >= 50) {
        // Immediate update for large increases
        setVisualGold(accumulatedLoot.gold);
        // Clear any pending updates
        Object.keys(pendingGoldUpdates.current).forEach((key) => {
          clearTimeout(pendingGoldUpdates.current[key]);
        });
        pendingGoldUpdates.current = {};
      } else {
        // Schedule updates for each coin
        for (let i = 0; i < goldDifference; i++) {
          const newGold = visualGold + i + 1;
          const timeoutId = setTimeout(() => {
            setVisualGold(newGold);
            delete pendingGoldUpdates.current[newGold];
          }, GOLD_UPDATE_DELAY);
          pendingGoldUpdates.current[newGold] = timeoutId;
        }
      }
    }
  }, [accumulatedLoot.gold, visualGold]);

  // Clean up any pending timeouts when component unmounts
  useEffect(() => {
    return () => {
      Object.keys(pendingGoldUpdates.current).forEach((key) => {
        clearTimeout(pendingGoldUpdates.current[key]);
      });
    };
  }, []);

  return (
    <Stack
      sx={{
        marginTop: "20px",
        padding: theme.spacing(1),
        maxWidth: maxWidth,
        marginX: "auto",
      }}
    >
      <Stack>
        <Stack
          sx={{
            position: "absolute",
            top: "26px",
            left: "0px",
            display: isUnlocked("CANCEL_BATTLE_BUTTON") ? "block" : "none",
          }}
        >
          <IconButton onClick={handleEndBattle}>
            <Clear sx={{ color: primary.main }} />
          </IconButton>
        </Stack>

        {enemy && (
          <>
            <HealthBar
              type={enemy.type}
              name={character?.displayName || enemy?.type}
              currentHp={enemy.hp}
              maxHp={enemy.maxHp}
            />
            <LevelProgressBar
              currentCheckpoint={dungeon?.currentCheckpoint || 0}
              checkpoints={dungeon?.checkpoints}
            />
            <CoinCounter
              mt={0}
              ml={-3}
              justifyContent="center"
              count={visualGold}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
});
BattleTopBarComponent.displayName = "BattleTopBarComponent";

export const BattleTopBar = memo(() => {
  const { style: shakeStyle, start: startShake } = useDOMShake();
  const enemyAttacked = useEnemyAttackState((state) => state.enemyAttacked);
  const { battleEnded } = useBattleStore();

  useEffect(() => {
    if (enemyAttacked) {
      startShake();
    }
  }, [enemyAttacked, startShake]);

  if (battleEnded) {
    return null;
  }

  return (
    <Box
      sx={{
        ...shakeStyle,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 10,
      }}
    >
      <BattleTopBarComponent />
    </Box>
  );
});

BattleTopBar.displayName = "BattleTopBar";
