import { H6, Paragraph, Small } from "@/components/Typography";
import { Stack } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import { useTranslation } from "@/hooks/useTranslation";
import { borderRadiusNew } from "@/theme/colors";

export function HeroAbility({ ability, locked }) {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={1} alignItems="center" position="relative">
      {locked && (
        <Stack
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backdropFilter: "blur(4px)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            borderRadius: borderRadiusNew,
          }}
        />
      )}
      <Stack
        sx={{
          position: "relative",
          border: "0px solid white",
          height: 80,
          width: 80,
        }}
      >
        <SmartImage
          src={locked ? "/images/icons/Icon_ItemIcon_Lock.png" : ability.icon}
          alt={ability.name}
          width={50}
          height={50}
          style={{ width: "auto", height: "100%" }}
        />
      </Stack>
      <Stack direction="column" spacing={0.5}>
        <H6 color="secondary.main">{ability.name}</H6>
        <Paragraph sx={{ fontSize: "12px" }}>{ability.description}</Paragraph>
        <Small fontSize="10px">{t("ability.damage")}</Small>
        {/* <Small fontSize="10px">{t("ability.damageFormula")}</Small> */}
      </Stack>
    </Stack>
  );
}
