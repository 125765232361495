import { STATUS } from "../constants";

function parseJSON(errorMessage) {
  try {
    const p = JSON.parse(errorMessage);
    return p?.message;
  } catch (error) {
    return undefined;
  }
}

export function createErrorMessage(error, hideDuration = 5000) {
  const parsedError = parseJSON(error.message);
  const message =
    error?.response?.data?.error ||
    error?.response?.data?.message ||
    error.cause?.reason || // web3
    error.shortMessage || // web3
    error?.response?.data?.errors?.[0]?.msg ||
    error?.data?.message ||
    error?.error?.message ||
    error?.reason ||
    (error?.message && parsedError) ||
    error?.message ||
    error;

  const finalMessage = message?.includes("Request failed")
    ? "Error performing this action"
    : message;

  return {
    type: STATUS.ERROR,
    message: finalMessage,
    hideDuration,
  };
}

export function cutAddress(str, cutStart = 4, cutEnd = 37) {
  if (!str) {
    return "";
  }
  if (cutEnd > 64) {
    return str.substr(0, cutStart);
  }
  return str.substr(0, cutStart) + "..." + str.substr(cutEnd + 1);
}

export function splitQuests(user, quests) {
  const newQuests = [];
  const completedQuests = [];

  quests?.forEach((quest) => {
    if (user?.completedQuests?.map((q) => q.questId)?.includes(quest.id)) {
      completedQuests.push(quest);
    } else {
      newQuests.push(quest);
    }
  });

  return { newQuests, completedQuests };
}

export function getTimeUntilNextHpReset() {
  const now = new Date();
  const nextMidnight = new Date(now);
  nextMidnight.setHours(24, 0, 0, 0); // Set to next midnight
  const nextNoon = new Date(now);
  nextNoon.setHours(12, 0, 0, 0); // Set to next noon

  // If it's past noon, calculate time until next midnight
  if (now.getHours() >= 12) {
    const hoursUntilReset = Math.floor((nextMidnight - now) / (1000 * 60 * 60));
    const minutesUntilReset = Math.floor(
      ((nextMidnight - now) % (1000 * 60 * 60)) / (1000 * 60)
    );
    return `${hoursUntilReset} hours and ${minutesUntilReset} minutes`;
  } else {
    // It's before noon, calculate time until next noon
    if (now.getHours() < 12) {
      const hoursUntilReset = Math.floor((nextNoon - now) / (1000 * 60 * 60));
      const minutesUntilReset = Math.floor(
        ((nextNoon - now) % (1000 * 60 * 60)) / (1000 * 60)
      );
      return `${hoursUntilReset} hours and ${minutesUntilReset} minutes`;
    } else {
      // It's exactly noon, set next reset to midnight
      const hoursUntilReset = Math.floor(
        (nextMidnight - now) / (1000 * 60 * 60)
      );
      const minutesUntilReset = Math.floor(
        ((nextMidnight - now) % (1000 * 60 * 60)) / (1000 * 60)
      );
      return `${hoursUntilReset} hours and ${minutesUntilReset} minutes`;
    }
  }
}

export function trimNumber(num, decimals = 4) {
  return parseFloat(num?.toFixed(decimals));
}

export function getStakedAt(stakedDateStr) {
  if (!stakedDateStr) {
    return "";
  }
  const stakedDate = new Date(stakedDateStr);
  const now = new Date();
  const differenceInMilliseconds = now - stakedDate;

  // Convert milliseconds to days and hours
  const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  return `STAKED ${days}D ${hours}H`;
}

export const generateRandomPosition = () => {
  const x = Math.random() * (window.innerWidth - 120); // 120 is the assumed size of the circle
  const y = Math.random() * (window.innerHeight - 120 - 120);
  return { x, y };
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
};

export const splitIntoStacks = (dungeons, perStack) => {
  if (!dungeons) {
    return [];
  }
  let result = [];
  for (let i = 0; i < dungeons.length; i += perStack) {
    result.push(dungeons.slice(i, i + perStack));
  }
  return result.reverse();
};

export function preloadAudio(url, updateAssetCount) {
  fetch(url)
    .then((response) => {
      if (response.ok) return response.blob();
      throw new Error("Network response was not ok.");
    })
    .then((blob) => {
      const audio = new Audio();
      audio.src = URL.createObjectURL(blob);
      updateAssetCount(); // Mark as loaded
    })
    .catch((error) => {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
      updateAssetCount(); // Mark as loaded even in case of error to prevent hangup
    });
}

export function pickRandom(arr, n) {
  // Create a shallow copy of the array to prevent modifying the original array
  const shuffled = [...arr];

  // Fisher-Yates shuffle algorithm to shuffle the array
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }

  // Slice the first n elements from the shuffled array
  return shuffled.slice(0, n);
}

// Function to darken a color
export const darkenColor = (color, amount) => {
  let col = color.slice(1); // Remove the '#'
  let num = parseInt(col, 16); // Convert to integer
  let r = (num >> 16) - amount; // Get red
  let g = ((num >> 8) & 0x00ff) - amount; // Get green
  let b = (num & 0x0000ff) - amount; // Get blue

  // Ensure values are within 0-255
  r = r < 0 ? 0 : r;
  g = g < 0 ? 0 : g;
  b = b < 0 ? 0 : b;

  return `#${(0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1)}`; // Convert back to hex
};

export const colorizeFullText = (text, RARITY_COLORS) => {
  const firstWord = text.split(" ")[0].toUpperCase();
  const color = RARITY_COLORS[firstWord] || "#fff";
  return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
};

export const colorizeText = (item, RARITY_COLORS, RARITY_RANK) => {
  const text = item.description;
  const words = text.split(/(\s+|,)/);
  return words.map((word, index) => {
    const trimmedWord = word.trim();
    if (!trimmedWord) return word; // Return spaces and empty strings as-is

    const upperWord = trimmedWord.toUpperCase();

    // Find the rarity rank that matches the word
    const rarityEntry = Object.entries(RARITY_RANK).find(
      ([rarity, rank]) => rank === upperWord
    );
    const color = rarityEntry ? RARITY_COLORS[rarityEntry[0]] : "#fff";

    return (
      <span
        key={index}
        style={{
          color,
          fontSize: item.chestLevel === 5 ? "16px" : "14px",
          textShadow: "0px 1px 0 #000000",
          WebkitTextStroke: "0.1px black",
        }}
      >
        {word}
      </span>
    );
  });
};

export function formatTimeLeft(endDate) {
  const end = new Date(endDate);
  const now = new Date();
  const diff = end - now;

  if (diff <= 0) {
    return "Ended";
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 0) {
    return `${days}d ${hours}h`;
  } else if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else {
    return `${minutes}m`;
  }
}

export const formatXP = (xp) => {
  if (xp === 0) return "0";
  const absXP = Math.abs(xp);

  if (absXP < 1000) return xp;

  const units = ["", "k", "M", "B", "T"];
  const unitIndex = Math.floor(Math.log10(absXP) / 3);
  const scaledXP = xp / Math.pow(1000, unitIndex);
  return scaledXP.toFixed(1).replace(/\.0$/, "") + units[unitIndex];
};
