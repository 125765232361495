import { Box } from "@mui/material";
import { H4, Paragraph } from "../../components/Typography";
import { SlowStarEffect } from "../../components/effects/SlowStarEffect";
import { SmartImage } from "@/components/SmartImage";

export function StoreItemContent({ item }) {
  return (
    <Box
      sx={{
        padding: "6px",
        position: "relative",
      }}
    >
      <H4 color="#fff" sx={{ zIndex: 1 }}>
        {item.amount}
      </H4>

      <Paragraph color="#fff" sx={{ zIndex: 1 }}>
        {item.name}
      </Paragraph>

      {item.image && (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              width: 80,
              height: 80,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: "0 auto",
              "&::before": {
                content: "''",
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "140%",
                height: "140%",
                background:
                  "radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%)",
                zIndex: 0,
              },
            }}
          />
          <SlowStarEffect
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: "0 auto",
              width: 50,
              height: 50,
            }}
          />
          <SmartImage
            src={item.image}
            alt={item.name || "Item"}
            width={80}
            height={80}
            style={{
              position: "absolute",
              top: "-10px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 0,
            }}
          />
        </Box>
      )}
    </Box>
  );
}
