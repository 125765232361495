import { useCallback } from "react";
import {
  sound,
  battleMusic,
  battleEliteMusic,
  battleBossMusic,
} from "@/utils/sounds";
import { useUserStore } from "@/game/logic/useUserStore";

export function useMusic() {
  const { user } = useUserStore();
  const playMusic = user?.settings?.playMusic ?? false;

  const playMainMusic = useCallback(() => {
    if (playMusic) {
      battleMusic.stop();
      sound.play();
    }
  }, [playMusic]);

  const stopMainMusic = useCallback(() => {
    sound.stop();
  }, []);

  const playBattleMusic = useCallback(
    (enemyType = "enemy") => {
      if (playMusic) {
        sound.stop();
        battleMusic.stop();
        battleEliteMusic.stop();
        battleBossMusic.stop();

        switch (enemyType) {
          case "elite":
            battleEliteMusic.load();
            battleEliteMusic.play();
            break;
          case "boss":
            battleBossMusic.load();
            battleBossMusic.play();
            break;
          default:
            battleMusic.load();
            battleMusic.play();
        }
      }
    },
    [playMusic]
  );

  const stopAllMusic = useCallback(() => {
    sound.stop();
    battleMusic.stop();
    battleEliteMusic.stop();
    battleBossMusic.stop();
  }, []);

  return { playMainMusic, stopMainMusic, playBattleMusic, stopAllMusic };
}
