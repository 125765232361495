import { SimpleModal } from "@/components/SimpleModal";
import { H5, Paragraph, Small } from "@/components/Typography";
import {
  backgroundColorSecondary,
  borderRadiusNew,
  primary,
  secondary,
} from "@/theme/colors";
import { Box, darken, Stack } from "@mui/material";
import { SimpleButton } from "@/components/SimpleButton";
import { IconNumber } from "@/components/IconNumber";
import { HeroLevel } from "./HeroLevel";
import { HeroAbility } from "./HeroAbility";
import { SmartImage } from "@/components/SmartImage";
import { useState } from "react";
import { purchaseHero, upgradeHero } from "@/lib/api";
import { PurchaseConfirmModal } from "../store/PurchaseConfirmModal";
import { useAlert } from "@/hooks/useAlert";
import {
  calculateHeroEnergy,
  calculateHeroStat,
  calculateHeroUpgradeCost,
} from "@/utils/stats";
import { HeroPurchasedModal } from "./HeroPurchasedModal";
import { RarityChip } from "./RarityChip";
import { LEVEL_CAPS } from "../../constants";
import { useTranslation } from "react-i18next";
import { upgradeSound } from "@/utils/sounds";
import { useSounds } from "@/hooks/useSound";
import { useHapticFeedback } from "@telegram-apps/sdk-react";
import { useUserStore } from "../logic/useUserStore";
import { useAssetStore } from "../logic/useAssetStore";
import { usePlayerStore } from "../logic/usePlayerStore";

export function HeroModal({ hero, open, onClose, isOwned }) {
  const { changeHero, recalculateTotalStats } = usePlayerStore();
  const { ABILITIES } = useAssetStore();
  const { user, setUser } = useUserStore();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmed, setShowConfirmed] = useState(false);
  const [upgradeMode, setUpgradeMode] = useState(false);
  const { setError } = useAlert();
  const { t } = useTranslation();
  const { playSound } = useSounds();
  const hapticFeedback = useHapticFeedback(true);

  const abilities = ABILITIES;
  const abilityList = hero.abilities?.map((id) => abilities?.[id]);

  const maxLevel = LEVEL_CAPS[hero.rarity];
  const isMaxLevel = hero.level >= maxLevel;

  const handleSelect = async () => {
    const response = await changeHero(hero.id);
    if (response.success) {
      onClose();
    } else if (response.error) {
      setError(response.error);
    }
  };

  const handlePurchase = async () => {
    try {
      const response = await purchaseHero({
        userId: user.id,
        heroTemplateId: hero.id,
        jwt: user.jwt,
      });
      setUser(response.data.user);
      setShowConfirmation(false);
      setShowConfirmed(true);
      onClose();
    } catch (error) {
      setError(error);
      console.error("Error purchasing hero:", error);
    }
  };

  const handleUpgrade = async () => {
    try {
      const response = await upgradeHero({
        userId: user.id,
        heroId: hero._id,
        jwt: user.jwt,
      });

      hapticFeedback?.impactOccurred("heavy");
      playSound(upgradeSound);

      recalculateTotalStats();
      setUser(response.data.user);
      setUpgradeMode(false);
      onClose();
    } catch (error) {
      setError(error);
      console.error("Error upgrading hero:", error);
    }
  };

  const canAfford = user.resources.gems >= hero.price;
  const rarityIndex = hero.rarityIndex;

  const attackValue = calculateHeroStat(hero, "attack", rarityIndex);
  const defenseValue = calculateHeroStat(hero, "defense", rarityIndex);
  const energyValue = calculateHeroEnergy(hero, rarityIndex);
  const upgradeCost = calculateHeroUpgradeCost(
    hero,
    hero.level || 1,
    rarityIndex
  );
  const canUpgrade =
    !isMaxLevel &&
    (user.resources.stones?.[hero.element.toLowerCase()] >= upgradeCost ||
      false);

  const heroLevelUpgrade = upgradeMode
    ? Math.min(hero.level + 1, maxLevel)
    : hero.level;
  const attackValueUpgrade = calculateHeroStat(
    { ...hero, level: heroLevelUpgrade },
    "attack",
    rarityIndex
  );
  const defenseValueUpgrade = calculateHeroStat(
    { ...hero, level: heroLevelUpgrade },
    "defense",
    rarityIndex
  );

  return (
    <>
      <SimpleModal
        open={open}
        onClose={() => {
          setUpgradeMode(false);
          onClose();
        }}
        title={hero.name}
        titleBgColor={hero.color}
      >
        <Stack spacing={2}>
          <Stack direction="row" pt={2}>
            <Stack flex={1} alignItems="center">
              <HeroLevel
                level={upgradeMode ? heroLevelUpgrade : hero.level}
                maxxedOut={isMaxLevel}
                size="M"
                sx={{
                  position: "absolute",
                  top: "46px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  animation: upgradeMode && "pulsate 0.8s infinite ease-in-out",
                }}
              />
              <SmartImage
                src={`/heroes/${hero.id}.webp`}
                alt={hero.name}
                width={110}
                height={110}
                style={{ marginTop: "-20px" }}
              />
            </Stack>
            <Stack flex={1} spacing={2} alignItems="center">
              <RarityChip
                rarity={hero?.rarity}
                rarityIndex={hero?.rarityIndex}
                sx={{ right: 0, top: 0, left: 0, position: "relative" }}
              />

              <Stack direction="row" spacing={1}>
                <IconNumber
                  imgSrc="/images/items/Sword_B.webp"
                  value={upgradeMode ? attackValueUpgrade : attackValue}
                  size="S"
                  textColor={upgradeMode ? secondary.main : primary.white}
                  sx={{
                    background: "rgba(0,0,0,0.2)",
                    border: "none",
                    animation:
                      upgradeMode && "pulsate 0.8s infinite ease-in-out",
                  }}
                />
                <IconNumber
                  imgSrc="/images/icons/heart.webp"
                  value={upgradeMode ? defenseValueUpgrade : defenseValue}
                  size="S"
                  textColor={upgradeMode ? secondary.main : primary.white}
                  sx={{
                    background: "rgba(0,0,0,0.2)",
                    border: "none",
                    animation:
                      upgradeMode && "pulsate 0.8s infinite ease-in-out",
                  }}
                />
                <IconNumber
                  imgSrc="/images/icons/energy.webp"
                  value={energyValue}
                  size="S"
                  textColor={primary.white}
                  sx={{
                    background: "rgba(0,0,0,0.2)",
                    border: "none",
                  }}
                />
              </Stack>

              <Stack direction="row" spacing={1} justifyContent="center">
                <Paragraph color="white">{t("hero.level")}:</Paragraph>
                <Paragraph color="white">
                  {hero.level || 0} / {maxLevel}
                </Paragraph>
              </Stack>
            </Stack>
          </Stack>

          {hero.locked && (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <SmartImage
                src="/images/icons/Icon_ItemIcon_Lock.png"
                alt="lock"
                width={30}
                height={30}
              />
              <Small color="white">{t("hero.unlocksWithArena")}</Small>
            </Stack>
          )}

          <Box
            sx={{
              backgroundColor: darken(backgroundColorSecondary, 0.3),
              borderRadius: borderRadiusNew,
              padding: "10px",
            }}
          >
            <Small color="white">{hero.description}</Small>
          </Box>

          <Box
            sx={{
              backgroundColor: darken(backgroundColorSecondary, 0.3),
              borderRadius: borderRadiusNew,
              padding: "10px",
            }}
          >
            {abilityList?.map((ability) => (
              <HeroAbility
                key={ability.id}
                ability={ability}
                locked={hero.locked}
              />
            ))}
          </Box>

          {!isMaxLevel && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              <Paragraph color="white">{t("hero.upgrade")}</Paragraph>
              <Stack direction="row" spacing={1} alignItems="center">
                <SmartImage
                  scope="modal"
                  src={`/images/stones/${hero.element}.webp`}
                  alt={hero.element}
                  width={40}
                  height={40}
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
                <H5 color="white">{upgradeCost}</H5>
              </Stack>
            </Stack>
          )}

          <Stack
            direction="row"
            spacing={2}
            justifyContent={isOwned ? "space-between" : "center"}
          >
            {isOwned && (
              <SimpleButton
                image={
                  !upgradeMode && hero.locked
                    ? "/images/icons/Icon_ItemIcon_Lock.png"
                    : ""
                }
                onClick={
                  upgradeMode ? () => setUpgradeMode(false) : handleSelect
                }
              >
                {upgradeMode ? t("hero.back") : t("hero.select")}
              </SimpleButton>
            )}
            {!isOwned && (
              <SimpleButton
                color={canAfford ? "green" : "grey"}
                onClick={() => setShowConfirmation(true)}
                image="/images/icons/gem.webp"
              >
                {hero.price}
              </SimpleButton>
            )}
            {isOwned && !isMaxLevel && (
              <SimpleButton
                color={canUpgrade ? "green" : "grey"}
                onClick={() =>
                  upgradeMode ? handleUpgrade() : setUpgradeMode(true)
                }
              >
                {upgradeMode ? t("hero.confirm") : t("hero.upgrade")}
              </SimpleButton>
            )}
          </Stack>

          {isMaxLevel && (
            <Paragraph color="secondary" align="center">
              {t("hero.maxLevelReached")}
            </Paragraph>
          )}
        </Stack>
      </SimpleModal>

      <PurchaseConfirmModal
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handlePurchase}
        item={{
          name: hero.name,
          image: `/heroes/${hero.id}.webp`,
          price: hero.price,
          type: "hero",
        }}
      />

      <HeroPurchasedModal
        open={showConfirmed}
        onClose={() => setShowConfirmed(false)}
        hero={hero}
      />
    </>
  );
}
