import { Box, Grid } from "@mui/material";
import { Item } from "../game/gear/Item";
import { motion } from "framer-motion";

export function ItemRewards({ items }) {
  let xs;
  if (items.length === 2) {
    xs = 6;
  } else if (items.length === 3) {
    xs = 4;
  } else if (items.length === 4) {
    xs = 6;
  } else if (items.length >= 5) {
    xs = 4;
  } else {
    xs = 12;
  }

  return (
    <Box
      component={motion.div}
      initial="hidden"
      animate="show"
      variants={{
        hidden: { opacity: 0, scale: 0.7 },
        show: {
          opacity: 1,
          scale: 1,
          transition: {
            duration: 0.3,
            delay: 0.1,
            staggerChildren: 0.2, // Adjust the delay between each item
          },
        },
      }}
    >
      <Grid container sx={{ justifyContent: "center" }}>
        {items?.map((item, index) => {
          const tileIndex = (index % 4) + 1;

          return (
            <Grid
              item
              xs={xs}
              key={index}
              component={motion.div}
              variants={{
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 },
              }}
              transition={{ duration: 0.2 }}
            >
              <Item
                scope="dark-modal"
                item={item}
                size="M"
                isSelected={false}
                tileIndex={tileIndex}
                onClick={() => null}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
