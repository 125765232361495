import { Box } from "@mui/material";
import { H4 } from "./Typography";

export const Ribbon = ({ text, color = "#FF2741", textColor = "#FFFFFF" }) => {
  return (
    <Box
      sx={{
        "--s": "1.6em",
        "--d": "0.7em",
        "--c": "0.7em",
        padding: "0px calc(var(--s) + 1.2em) var(--d)",
        lineHeight: 1.6,
        background: `
          conic-gradient(at left  var(--s) bottom var(--d),
           #0000 25%,#0008 0 37.5%,#0004 0) 0   /50% 100% no-repeat,
          conic-gradient(at right var(--s) bottom var(--d),
           #0004 62.5%,#0008 0 75%,#0000 0) 100%/50% 100% no-repeat
        `,
        clipPath: `polygon(
          0 var(--d), 
          var(--s) var(--d),
          var(--s) 0,
          calc(100% - var(--s)) 0,
          calc(100% - var(--s)) var(--d),
          100% var(--d),
          calc(100% - var(--c)) calc(50% + var(--d)/2),
          100% 100%,
          calc(100% - var(--s) - var(--d)) 100%,
          calc(100% - var(--s) - var(--d)) calc(100% - var(--d)),
          calc(var(--s) + var(--d)) calc(100% - var(--d)),
          calc(var(--s) + var(--d)) 100%,
          0 100%,
          var(--c) calc(50% + var(--d)/2)
        )`,
        backgroundColor: color,
        width: "fit-content",
      }}
    >
      <H4 color={textColor}>{text}</H4>
    </Box>
  );
};

export const Ribbon2 = ({ text, color = "#987F69" }) => {
  return (
    <Box
      className="ribbon"
      sx={{
        fontSize: "12px",
        fontWeight: "bold",
        color: "#fff",
        position: "absolute",
        top: "20px",
        left: "calc(-1*var(--f))",
        paddingInline: ".25em",
        lineHeight: 1.8,
        background: color,
        borderBottom: ".5em solid #0005",
        borderRight: ".8em solid #0000",
        clipPath: `
          polygon(0 0, 0 calc(100% - var(--f)), var(--f) 100%,
          var(--f) calc(100% - var(--f)), 100% calc(100% - var(--f)),
          calc(100% - var(--r)) calc(50% - var(--f)/2), 100% 0)
        `,
      }}
    >
      {text}
    </Box>
  );
};

export const Ribbon3 = ({ text, color }) => {
  return (
    <Box
      className="ribbon"
      sx={{
        fontSize: "8px",
        fontWeight: "bold",
        color: "#fff",
        position: "absolute",
        top: 0,
        left: 0,
        lineHeight: 1.3,
        paddingInline: "1lh",
        paddingBottom: "var(--f)",
        borderImage: "conic-gradient(#0008 0 0) 51%/var(--f)",
        clipPath: `
          polygon(
            100% calc(100% - var(--f)), 100% 100%, 
            calc(100% - var(--f)) calc(100% - var(--f)), 
            var(--f) calc(100% - var(--f)), 0 100%, 
            0 calc(100% - var(--f)), 
            999px calc(100% - var(--f) - 999px), 
            calc(100% - 999px) calc(100% - var(--f) - 999px)
          )
        `,
        transform:
          "translate(calc((cos(45deg) - 1)*100%), -100%) rotate(-45deg)",
        transformOrigin: "100% 100%",
        backgroundColor: color || "#F07818",
        zIndex: 1,
      }}
    >
      {text}
    </Box>
  );
};
