import { HeroItem } from "@/game/hero/HeroItem";
import { HeroModal } from "@/game/hero/HeroModal";
import { SelectedHero } from "@/game/hero/SelectedHero";
import {
  maxWidth,
  topBarHeight,
  bottomBarHeight,
  scrollbarStyles
} from "@/theme/colors";
import { Box, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useHeroState } from "./logic/useHeroState";
import { useUserStore } from "./logic/useUserStore";
import { useAssetStore } from "./logic/useAssetStore";

const sortHeroes = (heroes, rarityOrder) => {
  return heroes.sort((a, b) => {
    // First, sort by isOwned status
    if (a.isOwned !== b.isOwned) {
      return a.isOwned ? -1 : 1;
    }
    // Then, sort by locked status
    if (a.locked !== b.locked) {
      return a.locked ? 1 : -1;
    }
    // Finally, sort by rarity
    return rarityOrder.indexOf(b.rarity) - rarityOrder.indexOf(a.rarity);
  });
};

export function Hero() {
  const [modalOpen, setModalOpen] = useState(false);
  const { selectedHero, setSelectedHero } = useHeroState();
  const { RARITY, HEROES_NEW } = useAssetStore();
  const { user } = useUserStore();

  const rarityOrder = useMemo(() => {
    return Object.keys(RARITY || {}).reverse();
  }, [RARITY]);

  const heroes = useMemo(() => {
    if (!HEROES_NEW || !user?.heroes) return [];

    const mappedHeroes = HEROES_NEW.map((hero) => {
      const ownedHero = user.heroes.find((h) => h.templateId === hero.id);
      return {
        ...hero,
        ...ownedHero,
        id: hero.id,
        isOwned: !!ownedHero,
        locked: hero ? hero.locked : true,
      };
    });

    return sortHeroes(mappedHeroes, rarityOrder);
  }, [HEROES_NEW, user?.heroes, rarityOrder]);

  const handleHeroSelect = (hero) => {
    if (hero.id === selectedHero?.id) {
      setModalOpen(true);
    } else {
      setSelectedHero(hero);
    }
  };

  useEffect(() => {
    if (heroes && !modalOpen) {
      const selectedHero = heroes.find(
        (hero) => hero.id === user?.selectedHero?.templateId
      );
      setSelectedHero(selectedHero);
    }
  }, [heroes]);

  if (!heroes || !user) return null;

  return (
    <Box
      px={2}
      sx={{
        paddingTop: topBarHeight,
        paddingBottom: bottomBarHeight,
        maxWidth: maxWidth,
        margin: "auto",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <SelectedHero hero={selectedHero} onClick={() => setModalOpen(true)} />
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          ...scrollbarStyles,
          padding: "0 10px",
          zIndex: 2,
        }}
      >
        {heroes?.map((hero, index) => (
          <Grid item xs={4} key={`${hero.id}-${index}`}>
            <HeroItem
              hero={hero}
              onSelect={handleHeroSelect}
              isSelected={hero.id === selectedHero?.id}
              isActiveHero={hero.id === user?.selectedHero?.templateId}
              isOwned={hero.isOwned}
            />
          </Grid>
        ))}
      </Grid>

      {selectedHero && (
        <HeroModal
          hero={selectedHero}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          isOwned={selectedHero.isOwned}
          isSelected={selectedHero.id === user?.selectedHero?.templateId}
        />
      )}
    </Box>
  );
}
