import { useEffect } from "react";
import { Container } from "@pixi/react";
import { PixiHitNumber } from "./PixiTypography";
import useHitNumbersStore from "../../hooks/useHitNumbersStore";

export const HitNumbers = () => {
  const { hitNumbers, updateHitNumbers } = useHitNumbersStore();

  useEffect(() => {
    const interval = setInterval(updateHitNumbers, 30); // ~60 FPS
    return () => clearInterval(interval);
  }, [updateHitNumbers]);

  return (
    <Container>
      {hitNumbers.map(({ id, number, x, y, progress }) => {
        return (
          <PixiHitNumber
            key={`${id}-${x}-${y}-${number}`}
            text={`-${number}`}
            x={x}
            y={y - 50 * progress}
            alpha={1 - progress}
          />
        );
      })}
    </Container>
  );
};
