import gsap from "gsap";
import { MotionPathPlugin } from "gsap/dist/MotionPathPlugin";
import { TextPlugin } from "gsap/dist/TextPlugin";

gsap.registerPlugin(MotionPathPlugin, TextPlugin);

export function playItemUpgradeAnimation(
  itemRef,
  onComplete,
  totalDuration = 2
) {
  const tl = gsap.timeline();

  // Glow effect
  tl.to(itemRef.current, {
    duration: 0.3,
    // boxShadow:
    //   "0 0 20px #ffff00, 0 0 30px #ffff00, 0 0 40px #ffff00, 0 0 50px #ffff00",
    ease: "power2.inOut",
  });

  // Rotate and scale effect
  tl.to(itemRef.current, {
    duration: 0.5,
    rotation: 360,
    scale: 1.5,
    ease: "back.out(1.7)",
  });

  // Bounce effect
  tl.to(itemRef.current, {
    duration: 0.5,
    y: -50,
    ease: "power2.out",
  }).to(itemRef.current, {
    duration: 0.3,
    y: 0,
    ease: "bounce.out",
  });

  // Shockwave effect
  const shockwave = document.createElement("div");
  shockwave.style.position = "absolute";
  shockwave.style.border = "2px solid #ffff00";
  shockwave.style.borderRadius = "50%";
  itemRef.current.parentNode.appendChild(shockwave);

  tl.to(
    shockwave,
    {
      duration: 0.5,
      scale: 20,
      opacity: 0,
      ease: "power2.out",
    },
    "-=0.5"
  );

  // Text effect
  const levelUpText = document.createElement("div");
  levelUpText.style.position = "absolute";
  levelUpText.style.fontSize = "24px";
  levelUpText.style.fontWeight = "bold";
  levelUpText.style.color = "#ffff00";
  itemRef.current.parentNode.appendChild(levelUpText);

  tl.to(
    levelUpText,
    {
      duration: 1,
      text: { value: "LEVEL UP!", delimiter: " " },
      ease: "none",
    },
    "-=0.5"
  );

  // Particles effect
  const particles = Array.from({ length: 40 }, () => {
    const particle = document.createElement("div");
    particle.style.position = "absolute";
    particle.style.width = "10px";
    particle.style.height = "10px";
    particle.style.backgroundColor = "#ffff00";
    particle.style.borderRadius = "50%";
    itemRef.current.parentNode.appendChild(particle);
    return particle;
  });

  particles.forEach((particle) => {
    tl.to(
      particle,
      {
        duration: "random(0.5, 1)",
        x: "random(-100, 100)",
        y: "random(-100, 100)",
        opacity: 0,
        ease: "power2.out",
      },
      "-=0.4"
    );
  });

  // Clean up
  tl.to([shockwave, levelUpText, ...particles], {
    duration: 0.1,
    opacity: 0,
    onComplete: () => {
      [shockwave, levelUpText, ...particles].forEach((el) => el.remove());
    },
  });

  // Reset item to normal
  tl.to(itemRef.current, {
    duration: 0.3,
    scale: 1,
    boxShadow: "none",
    ease: "power2.inOut",
  });

  // Adjust the timeline to fit the desired total duration
  tl.timeScale(tl.duration() / totalDuration);

  // Add onComplete callback
  tl.call(onComplete);

  return tl;
}
