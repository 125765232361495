import { create } from "zustand";

const initialState = {
  assets: null,
  STAT_CONFIG: null,
  LEVELS: [],
  WORLDS: [],
  ABILITIES: {},
  ITEMS: [],
  BASE_UPGRADE_COST: 0,
};

export const useAssetStore = create((set, get) => ({
  ...initialState,

  setAssets: (assets) => {
    if (!assets) return;

    set({
      assets,
      STAT_CONFIG: assets.STAT_CONFIG,
      LEVELS: assets.LEVELS || [],
      WORLDS: assets.WORLDS || [],
      ABILITIES: assets.ABILITIES || {},
      ITEMS: assets.ITEMS || [],
      BASE_UPGRADE_COST: assets.BASE_UPGRADE_COST || 0,
      SHOP_ITEMS: assets.SHOP_ITEMS || [],
      CHALLENGES: assets.CHALLENGES || [],
      CYCLES: assets.CYCLES || [],
      RARITY: assets.RARITY || {},
      ITEM_TYPES: assets.ITEM_TYPES || {},
      SLOTS: assets.SLOTS || [],
      HEROES_NEW: assets.HEROES_NEW || [],
      ENEMIES: assets.ENEMIES || [],
      RARITY_COLORS: assets.RARITY_COLORS || {},
      GEAR: assets.GEAR || [],
      ACHIEVEMENTS: assets.ACHIEVEMENTS || {},
      CHESTS: assets.CHESTS || [],
      RARITY_RANK: assets.RARITY_RANK || {},
      BASE_ENEMY_LOOT: assets.BASE_ENEMY_LOOT || {},
      POTIONS: assets.POTIONS || {},
    });
  },

  // Helper methods
  findDungeon: (dungeonId) => {
    return get().LEVELS.find((d) => d.id === dungeonId);
  },

  findWorld: (worldId) => {
    return get().WORLDS.find((w) => w.id === worldId);
  },

  findItem: (itemId) => {
    return get().ITEMS.find((i) => i.id === itemId);
  },

  getAbility: (abilityId) => {
    return get().ABILITIES[abilityId];
  },

  // World navigation helpers
  findNextWorld: (currentWorldId) => {
    const currentWorldIndex = get().WORLDS.findIndex(
      (w) => w.id === currentWorldId
    );
    if (currentWorldIndex === get().WORLDS.length - 1) {
      return get().WORLDS[0]; // Return first world if at end
    }
    return get().WORLDS[currentWorldIndex + 1];
  },

  findNextDungeon: (currentDungeonId) => {
    const currentDungeonIndex = get().LEVELS.findIndex(
      (d) => d.id === currentDungeonId
    );
    return get().LEVELS.find((d, index) => index > currentDungeonIndex);
  },
}));
