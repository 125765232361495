import { useEffect, useRef, useState } from "react";
import { Container, useTick } from "@pixi/react";
import { PlayerAvatar } from "./PlayerAvatar";

export function PlayerAnimated({ size, x, y, stageWidth }) {
  const containerRef = useRef(null);
  const spineRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isWalking, setIsWalking] = useState(false);
  const [direction, setDirection] = useState(1);
  const [targetX, setTargetX] = useState(x);

  const startWalking = () => {
    if (!containerRef.current || !spineRef.current || !spineRef.current.state)
      return;

    const newDirection = Math.random() < 0.5 ? -1 : 1;
    const distance = Math.random() * 100 + 50;
    const newTargetX = containerRef.current.x + newDirection * distance;

    // Ensure the character stays within the screen bounds
    const boundedTargetX = Math.max(
      size / 2,
      Math.min(stageWidth - size / 2, newTargetX)
    );

    setDirection(newDirection);
    setTargetX(boundedTargetX);
    setIsWalking(true);
    spineRef.current.state.setAnimation(0, "Side_Walk", true);
    containerRef.current.scale.x = newDirection;
  };

  useEffect(() => {
    if (isLoaded && containerRef.current) {
      // Reset position when loaded
      if (x && containerRef?.current?.x !== x) {
        containerRef.current.x = x;
        containerRef.current.y = y;
        setTargetX(x);
      }

      // Start walking after a short delay
      const timer = setTimeout(startWalking, 1000);

      // Cleanup function
      return () => {
        clearTimeout(timer);
        // Don't destroy the container here
      };
    }
  }, [isLoaded, x, y, size, stageWidth]);

  // Add a separate effect for cleanup on unmount
  useEffect(() => {
    return () => {
      if (containerRef.current) {
        console.log("Destroying containerRef.current");
        containerRef.current.destroy({ children: true });
      }
    };
  }, []); // Empty dependency array means this only runs on unmount

  useTick((delta) => {
    if (!containerRef.current || !spineRef.current || !spineRef.current.state)
      return;

    if (isWalking) {
      const speed = 2 * delta;
      const currentX = containerRef.current.x;
      const distanceToTarget = Math.abs(targetX - currentX);

      if (distanceToTarget > speed) {
        containerRef.current.x += direction * speed;
        const currentAnim = spineRef.current.state.getCurrent(0);
        if (currentAnim && currentAnim.animation.name !== "Side_Walk") {
          spineRef.current.state.setAnimation(0, "Side_Walk", true);
        }
      } else {
        containerRef.current.x = targetX;
        setIsWalking(false);
        spineRef.current.state.setAnimation(0, "Side_Idle", true);
        setTimeout(startWalking, Math.random() * 5000 + 2000);
      }
    } else {
      const currentAnim = spineRef.current.state.getCurrent(0);
      if (currentAnim && currentAnim.animation.name !== "Side_Idle") {
        spineRef.current.state.setAnimation(0, "Side_Idle", true);
      }
    }
  });

  const handleSpineLoad = (spine) => {
    if (spineRef.current && typeof spineRef.current.destroy === "function") {
      spineRef.current.destroy();
    }
    spineRef.current = spine;
    setIsLoaded(true);
  };

  return (
    <Container ref={containerRef} x={x} y={y}>
      <PlayerAvatar
        size={size}
        animate={true}
        skin="Side"
        shadow={true}
        isPixiContext={true}
        spotlight={false}
        onSpineLoad={handleSpineLoad}
      />
    </Container>
  );
}
