import { useCallback } from "react";
import { ability_1 } from "@/utils/sounds";
import { ABILITY_SOUNDS } from "@/utils/vfxSprites";
import { useUserStore } from "@/game/logic/useUserStore";

export function useSounds() {
  const { user } = useUserStore();
  const playSounds = user?.settings?.playSounds ?? true;

  const playAbilitySound = useCallback((abilityId) => {
    const abilitySound = ABILITY_SOUNDS[abilityId] || ability_1;
    if (abilitySound) {
      playSound(abilitySound);
    }
  }, []);

  const playSound = useCallback(
    (sound) => {
      if (playSounds && sound) {
        sound.play();
      }
    },
    [playSounds]
  );

  const stopSound = useCallback((sound) => {
    if (sound) {
      sound.stop();
    }
  }, []);

  return { playSound, stopSound, playAbilitySound };
}
