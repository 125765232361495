import { metrics } from "@/game/logic/Metrics";
import { useEffect } from "react";

export function useSessionTracker(userId) {
  useEffect(() => {
    const sessionStartTime = Date.now();

    const recordSession = () => {
      const sessionLength = Date.now() - sessionStartTime;
      metrics.add("sessionDuration", sessionLength);
      metrics.flushWithBeacon();
    };

    // For page unload, use beacon
    window.addEventListener("beforeunload", recordSession);

    return () => {
      // For normal unmount, use regular flush
      const sessionLength = Date.now() - sessionStartTime;
      metrics.add("sessionDuration", sessionLength);
      metrics.flushNow();
      window.removeEventListener("beforeunload", recordSession);
    };
  }, [userId]);
}
