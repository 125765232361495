import { Text } from "@pixi/react";
import * as PIXI from "pixi.js";
import { primary, greyLight, secondary } from "@/theme/colors";

const createTextStyle = (options) => {
  return new PIXI.TextStyle({
    fontFamily: "'Squirk', sans-serif",
    fontSize: options.fontSize,
    fontWeight: options.fontWeight,
    fill: options.fill || [primary.main],
    strokeThickness: options.strokeThickness || 1,
    dropShadow: options.dropShadow,
    dropShadowColor: options.dropShadowColor || secondary.main,
    dropShadowBlur: options.dropShadowBlur,
    dropShadowAngle: options.dropShadowAngle,
    dropShadowDistance: options.dropShadowDistance,
    wordWrap: options.wordWrap,
    wordWrapWidth: options.wordWrapWidth,
    lineHeight: options.lineHeight,
  });
};

const createTypographyComponent = (defaultStyle) => {
  const TypographyComponent = ({ children, style, ...props }) => (
    <Text
      text={children}
      style={createTextStyle({ ...defaultStyle, ...style })}
      {...props}
    />
  );
  TypographyComponent.displayName = "PixiTypography";
  return TypographyComponent;
};

export const PixiH1 = createTypographyComponent({
  fontSize: 46,
  fontWeight: 400,
  dropShadow: true,
  dropShadowColor: "#000000",
  dropShadowBlur: 4,
  dropShadowAngle: Math.PI / 6,
  dropShadowDistance: 6,
});

export const PixiH2 = createTypographyComponent({
  fontSize: 38,
  fontWeight: 400,
});

export const PixiH3 = createTypographyComponent({
  fontSize: 30,
  fontWeight: 400,
});

export const PixiH4 = createTypographyComponent({
  fontSize: 28,
  fontWeight: 600,
});

export const PixiH5 = createTypographyComponent({
  fontSize: 22,
  fontWeight: 600,
  lineHeight: 1,
});

export const PixiH6 = createTypographyComponent({
  fontSize: 20,
  fontWeight: 600,
});

export const PixiParagraph = createTypographyComponent({
  fontSize: 18,
  fontWeight: 500,
});

export const PixiParagraphLight = createTypographyComponent({
  fontSize: 18,
  fontWeight: 500,
  fill: [greyLight[500]],
});

export const PixiSmall = createTypographyComponent({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: 1.6,
  fill: [primary.light],
});

export const PixiSpan = createTypographyComponent({
  fontSize: 14,
});

export const PixiTiny = createTypographyComponent({
  fontSize: 10,
  fontWeight: 500,
  lineHeight: 1.65,
  fill: ["#757575"], // Assuming this is your text.secondary color
});

export const PixiTinyDebug = createTypographyComponent({
  fontSize: 14,
  fontWeight: 200,
  lineHeight: 1.65,
  fill: ["red"],
});

export const PixiHitNumber = createTypographyComponent({
  fontSize: 24,
  fontWeight: 700,
  fill: ["#FF0000"],
  stroke: "#FFFFFF",
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: "#000000",
  dropShadowBlur: 0,
  dropShadowAngle: Math.PI / 2,
  dropShadowDistance: 3,
});
