import { Box, darken, Grid, Stack } from "@mui/material";
import {
  backgroundPattern,
  borderRadiusNew,
  primary,
  scrollbarStyles,
} from "../../theme/colors";
import { SimpleModal } from "@/components/SimpleModal";
import { SmartImage } from "@/components/SmartImage";
import { H6, Small } from "@/components/Typography";
import { useTranslation } from "@/hooks/useTranslation";
import { formatTimeLeft } from "@/utils/misc";
import { QuestsProgress } from "./quests/QuestProgress";
import { QuestMission } from "./quests/QuestMission";
import { CloseIcon } from "@/components/CloseIcon";
import { useMemo, useState, useEffect } from "react";
import { QuestDay } from "./quests/QuestDay";
import { useAppStore } from "@/hooks/useAppStore";
import { getCurrentChallenge } from "@/utils/quests";
import { useAssetStore } from "../logic/useAssetStore";
import { useChallengeStore } from "../logic/useChallengeStore";

export function Quests({ open, onClose }) {
  const { challenge } = useChallengeStore();
  const { CHALLENGES } = useAssetStore();
  const { t } = useTranslation();
  const setBadge = useAppStore((state) => state.setBadge);

  const mergedChallenge = getCurrentChallenge(challenge, CHALLENGES);
  const currentDate = new Date();
  const startDate = mergedChallenge && new Date(mergedChallenge.startDate);

  const activeDay = useMemo(() => {
    if (!mergedChallenge || !startDate) return 0;
    const daysSinceStart = Math.floor(
      (currentDate - startDate) / (1000 * 60 * 60 * 24)
    );
    return Math.min(daysSinceStart, mergedChallenge.days - 1);
  }, [currentDate, startDate, mergedChallenge?.days]);

  const [selectedDay, setSelectedDay] = useState(activeDay || 0);

  const days = useMemo(() => {
    if (!mergedChallenge) return [];
    return Array.from({ length: mergedChallenge.days }, (_, i) => i + 1);
  }, [mergedChallenge?.days]);

  const hasMissionsToClaimForDay = useMemo(() => {
    if (!mergedChallenge || !days.length) return {};
    return days.reduce((acc, day) => {
      const dayMissions = mergedChallenge.missions?.[day] || [];
      const hasUnclaimedCompletedMission = dayMissions.some(
        (mission) =>
          mission.currentCount >= mission.threshold && !mission.claimed
      );
      acc[day] = hasUnclaimedCompletedMission;
      return acc;
    }, {});
  }, [mergedChallenge?.missions, days]);

  const hasAnyMissionsToClaim = useMemo(() => {
    return Object.values(hasMissionsToClaimForDay).some((value) => value);
  }, [hasMissionsToClaimForDay]);

  useEffect(() => {
    setBadge("quests", hasAnyMissionsToClaim);
  }, [hasAnyMissionsToClaim, setBadge]);

  if (!mergedChallenge) return null;

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      titleBgColor={primary.green}
      contentSx={{
        padding: "0px",
        background: `linear-gradient(to bottom, ${primary.green}, ${darken(
          primary.green,
          0.3
        )})`,
        borderTopLeftRadius: borderRadiusNew,
        borderTopRightRadius: borderRadiusNew,

        ...backgroundPattern,
      }}
      modalSx={{ minWidth: "340px" }}
    >
      <Box
        sx={{
          overflow: "hidden",
          height: "220px",
          display: "flex",
          alignItems: "center",
          position: "relative",
          justifyContent: "center",
          borderTopLeftRadius: borderRadiusNew,
          borderTopRightRadius: borderRadiusNew,
        }}
      >
        <SmartImage
          src="/images/scenes/house.webp"
          width={500}
          height={500}
          alt="Quests"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        />
        <Stack
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: 1,
          }}
        >
          <H6 color="primary.green" mb={0.5}>
            {t(mergedChallenge?.name)}
          </H6>
          <Box
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              padding: "2px 12px",
              borderRadius: "12px",
              display: "inline-flex",
              alignItems: "center",
              marginBottom: 1,
            }}
          >
            <Small
              color="primary.red"
              sx={{ animation: "pulse-animation 1s infinite" }}
            >
              Ends in {formatTimeLeft(challenge?.endDate)}
            </Small>
          </Box>
          <Small sx={{ textAlign: "center" }}>
            {t(mergedChallenge?.description)}
          </Small>

          <QuestsProgress mergedChallenge={mergedChallenge} />
        </Stack>
      </Box>

      <CloseIcon
        onClose={onClose}
        color={darken(primary.green, 0.2)}
        sx={{
          top: "-4px",
          right: "-4px",
        }}
      />

      <Grid container direction="row" mt={1}>
        <Grid item xs={2.5}>
          {days.map((day, index) => (
            <QuestDay
              key={day}
              day={day}
              index={index}
              activeDay={activeDay}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
              currentDay={days[activeDay]}
              hasBadge={hasMissionsToClaimForDay[day]}
            />
          ))}
        </Grid>

        <Grid item xs={9.5}>
          <Stack px={1} alignItems="center">
            <Small>Day {days[selectedDay]}</Small>
          </Stack>
          <Box sx={{ height: "300px", overflowY: "auto", ...scrollbarStyles }}>
            {mergedChallenge.missions?.[days[selectedDay]]?.map(
              (mission, index) => (
                <QuestMission
                  key={`${mission.id}-${index}`}
                  mission={mission}
                  day={days[selectedDay]}
                  missionIndex={index}
                />
              )
            )}
          </Box>
        </Grid>
      </Grid>
    </SimpleModal>
  );
}
