import { PriorityHigh } from "@mui/icons-material";

export const PriorityBadge = ({ sx, size = 14 }) => {
  return (
    <PriorityHigh
      sx={{
        position: "absolute",
        top: "-2px",
        right: "-8px",
        fontSize: size,
        color: "white",
        backgroundColor: "#FF004D",
        borderRadius: "50%",
        padding: "2px",
        ...sx,
      }}
    />
  );
};
