import { Box, Stack } from "@mui/material";
import { H2 } from "./Typography";

const rarityEffects = {
  COMMON: {
    animationName: "pulse-common",
    animationDuration: "2s",
    fontSize: "2.5rem",
  },
  UNCOMMON: {
    animationName: "pulse-uncommon",
    animationDuration: "1.8s",
    fontSize: "2.7rem",
  },
  RARE: {
    animationName: "pulse-rare",
    animationDuration: "1.6s",
    fontSize: "2.9rem",
  },
  EPIC: {
    animationName: "pulse-epic",
    animationDuration: "1.4s",
    fontSize: "3.1rem",
  },
  LEGENDARY: {
    animationName: "pulse-legendary",
    animationDuration: "1.2s",
    fontSize: "3.3rem",
  },
  MYTHIC: {
    animationName: "pulse-mythic",
    animationDuration: "1s",
    fontSize: "3.5rem",
  },
};

export function ChestRarityDisplay({ chestRarity, rarityColor }) {
  const effect = rarityEffects[chestRarity] || rarityEffects.COMMON;

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      width="100vw"
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        {chestRarity !== "COMMON" && (
          <Box
            sx={{
              position: "absolute",
              width: "200%",
              height: "200%",
              background: `radial-gradient(circle, ${rarityColor}40 0%, transparent 70%)`,
            }}
          />
        )}
        <H2
          sx={{
            color: rarityColor,
            fontSize: effect.fontSize,
            fontWeight: "bold",
            textTransform: "uppercase",
            zIndex: 1,
            WebkitTextStroke: "none",
            animationName: effect.animationName,
            animationDuration: effect.animationDuration,
            animationIterationCount: "infinite",
          }}
        >
          {chestRarity}
        </H2>
      </Box>
    </Stack>
  );
}
