import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { HealthBarPlayer } from "@/components/HealthBar";
import { backgroundColorHex } from "@/theme/colors";
import { useDOMShake } from "@/hooks/useDOMShake";
import { memo } from "react";
import { IconNumberEnergy } from "@/components/IconNumberEnergy";
import { IconNumberAttack } from "@/components/IconNumberAttack";
import { useEnemyAttackState } from "@/game/logic/useEnemyAttackState";
import { Ability } from "./Ability";
import { Potions } from "./Potions";

const BattleBottomBarComponent = memo(() => {
  return (
    <Box
      sx={{
        backgroundColor: backgroundColorHex,
        height: "20vh",
      }}
      px={3}
      py={1}
    >
      <HealthBarPlayer />

      <Stack
        spacing={4}
        alignItems="center"
        direction="row"
        justifyContent="center"
        sx={{ height: "80%" }}
      >
        <Potions />
        <IconNumberEnergy />
        <IconNumberAttack />
        <Ability />
      </Stack>
    </Box>
  );
});

BattleBottomBarComponent.displayName = "BattleTopBarComponent";

export const BattleBottomBar = memo(() => {
  const { style: shakeStyle, start: startShake } = useDOMShake();
  const enemyAttacked = useEnemyAttackState((state) => state.enemyAttacked);

  useEffect(() => {
    if (enemyAttacked) {
      startShake();
    }
  }, [enemyAttacked, startShake]);

  return (
    <Box
      sx={{
        ...shakeStyle,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 10,
      }}
    >
      <BattleBottomBarComponent />
    </Box>
  );
});

BattleBottomBar.displayName = "BattleBottomBar";
