import { Box } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import { BurstImage } from "@/components/effects/BurstImage";
import { SlowStarEffect } from "@/components/effects/SlowStarEffect";
import { borderRadiusNew } from "@/theme/colors";

export function AtlasMonsterItem({ monster, isAchieved, isClaimed, onClick }) {
  const originalSize = 100;
  const size = isClaimed ? originalSize : isAchieved ? 80 : 60;

  const iconImage = isClaimed
    ? `/images/enemies/${monster.id}.webp`
    : isAchieved
    ? "/images/icons/gift.webp"
    : "/images/icons/question.webp";

  return (
    <Box
      position="relative"
      onClick={onClick}
      sx={{
        width: `${originalSize}px`,
        height: `${originalSize}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: "rgba(0,0,0,0.1)",
        borderRadius: borderRadiusNew,
      }}
    >
      <SmartImage
        src={iconImage}
        height={size}
        width={size}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          opacity: isAchieved ? 1 : 0.7,
          zIndex: 1,
        }}
        alt="Monster"
      />
      {isAchieved && !isClaimed && (
        <>
          <BurstImage size={size} />
          <SlowStarEffect />
        </>
      )}
    </Box>
  );
}
