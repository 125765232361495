import { useState, useEffect, useCallback } from "react";
import { Assets } from "pixi.js";
import { getMatchingEnemies, resetSelectedEnemies } from "@/utils/battle";
import { CDN_URL } from "@/constants";
import { useAssetStore } from "@/game/logic/useAssetStore";
import { useProgressStore } from "@/game/logic/useProgressStore";

export const usePreloadCharacters = () => {
  const [state, setState] = useState({
    characters: [],
    isLoading: true,
    error: null,
  });

  const { dungeon, world } = useProgressStore();
  const { ENEMIES } = useAssetStore();

  const preloadCharacters = useCallback(async () => {
    if (!dungeon?.checkpoints || !ENEMIES || !world.id) return;

    try {
      resetSelectedEnemies();

      const selectedCharacters = dungeon.checkpoints
        .filter((c) => c.type !== "chest")
        .map((checkpoint) =>
          getMatchingEnemies(
            ENEMIES,
            checkpoint.level,
            world.id,
            checkpoint.type
          )
        );

      await Promise.all(
        selectedCharacters.flatMap((char) => {
          const basePath = char.path.split(".json")[0];
          return [
            Assets.load(`${CDN_URL}${basePath}.json`),
            Assets.load(`${CDN_URL}${basePath}.png`),
          ];
        })
      );

      console.log("👉  selectedCharacters:", selectedCharacters);
      setState({
        characters: selectedCharacters,
        isLoading: false,
        error: null,
      });
    } catch (err) {
      console.error("Error preloading characters:", err);
      setState((prev) => ({ ...prev, isLoading: false, error: err.message }));
    }
  }, []);

  useEffect(() => {
    if (state.characters.length === 0) {
      preloadCharacters();
    }
  }, [state.characters.length]);

  return state;
};
