import { Container } from "@pixi/react";
import { VFXComponent } from "./VFXComponent";
import { useVFX } from "@/hooks/useVFX";
import { useMemo } from "react";

export function VFXLayer() {
  const { activeEffects, onEffectComplete } = useVFX();

  const vfxComponents = useMemo(
    () =>
      Object.entries(activeEffects).map(([type, effect]) => (
        <VFXComponent
          key={type}
          type={type}
          x={effect.x}
          y={effect.y}
          scale={effect.scale}
          isActive={effect.isActive}
          loop={effect.loop}
          speed={effect.speed}
          onComplete={onEffectComplete}
        />
      )),
    [activeEffects]
  );

  return <Container>{vfxComponents}</Container>;
}
