import { Stack } from "@mui/material";
import { useCallback } from "react";
import { StatItem } from "@/game/battle/StatItem";
import { SimpleButton } from "@/components/SimpleButton";
import { useIntroState } from "../logic/useIntroState";
import { useUserStore } from "../logic/useUserStore";
import { gameStatsActions } from "../actions/gameStats";
import { usePlayerStore } from "../logic/usePlayerStore";
import { useProgressStore } from "../logic/useProgressStore";
import { useBattleStore } from "../logic/useBattleStore";

export function GameBottomBar() {
  const { isUnlocked, setStep, ongoingTutorial } = useIntroState();
  const { startBattleSession } = useBattleStore();
  const { world } = useProgressStore();
  const { player } = usePlayerStore();
  const { user } = useUserStore();
  const isCurrentWorld = world?.id === user?.progress?.world;

  const handleStartBattle = useCallback(() => {
    if (ongoingTutorial) {
      setStep("HOME_START", false);
      setTimeout(() => {
        setStep("BATTLE_TAP");
      }, 3000);
      setTimeout(() => {
        setStep("BATTLE_ABILITY");
      }, 13000);
      if (isUnlocked("STAT_ITEMS")) {
        setStep("HOME_RESTART", false);
      }
    }
    console.log("start battle");
    startBattleSession();
  }, []);

  const getNextValue = (statKey) => {
    switch (statKey) {
      case "attack":
        return player.attack + 5;
      case "maxHp":
        return player.maxHp + 10;
      case "energyRegenRate":
        return `${(1000 / Math.max(player.energyRegenRate - 50, 100)).toFixed(
          2
        )}/s`;
      default:
        return "";
    }
  };

  return (
    <>
      <Stack
        alignItems="center"
        sx={{
          position: "absolute",
          width: "100%",
          zIndex: 2,
          bottom: "110px",
        }}
        spacing={1}
      >
        {isCurrentWorld && (
          <SimpleButton onClick={handleStartBattle}>Start</SimpleButton>
        )}

        <Stack
          direction="row"
          sx={{ visibility: isUnlocked("STAT_ITEMS") ? "visible" : "hidden" }}
        >
          <StatItem
            name="ATK"
            statKey="attack"
            number={player?.attack}
            nextValue={getNextValue("attack")}
            upgradeCost={gameStatsActions.calculateStatUpgradeCost("attack")}
          />
          <StatItem
            name="HP"
            statKey="maxHp"
            number={player?.maxHp}
            nextValue={getNextValue("maxHp")}
            upgradeCost={gameStatsActions.calculateStatUpgradeCost("maxHp")}
          />
          <StatItem
            name="Energy"
            statKey="energyRegenRate"
            number={`${(1000 / player?.energyRegenRate).toFixed(2)}/s`}
            nextValue={getNextValue("energyRegenRate")}
            upgradeCost={gameStatsActions.calculateStatUpgradeCost(
              "energyRegenRate"
            )}
          />
        </Stack>
      </Stack>
    </>
  );
}
