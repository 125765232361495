import { SimpleModal } from "@/components/SimpleModal";
import { Grid, Stack } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import { H6, Paragraph } from "@/components/Typography";
import { useTranslation } from "react-i18next";
import { useUserStore } from "../logic/useUserStore";

export function StonesModal({ open, onClose }) {
  const { t } = useTranslation();
  const { user } = useUserStore();
  const stones = user?.resources?.stones;

  return (
    <SimpleModal open={open} onClose={onClose} title={t("hero.stones")}>
      {stones && Object.keys(stones).length > 0 ? (
        <Grid container spacing={3}>
          {Object.entries(stones).map(([element, amount]) => (
            <Grid item xs={4} key={element}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <SmartImage
                  src={`/images/stones/${element}.webp`}
                  width={80}
                  height={80}
                  alt={element}
                  style={{ width: "40px", height: "40px" }}
                />
                <H6>{amount}</H6>
              </Stack>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Paragraph align="center">{t("hero.noStones")}</Paragraph>
      )}
    </SimpleModal>
  );
}
