import { Menu, MenuItem, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Item } from "./Item";
import { useState, useRef } from "react";
import { H5, H6, Paragraph, Small } from "../../components/Typography";
import { secondary } from "@/theme/colors";
import { SimpleButton } from "../../components/SimpleButton";
import { StarBurst } from "../../components/effects/StarBurst";
import { playItemUpgradeAnimation } from "@/utils/effects/playItemUpgradeAnimation";
import {
  calculateScrolls,
  calculateItemUpgradeCost,
  calculateItemValue
} from "@/utils/stats";
import { SimpleModal } from "@/components/SimpleModal";
import { SmartImage } from "@/components/SmartImage";
import { RarityChip } from "../hero/RarityChip";
import { LEVEL_CAPS } from "../../constants";
import { equipItem, upgradeItem } from "@/lib/api";
import {
  equipAttackSound,
  equipDefenseSound,
  equipSpecialSound,
  upgradeSound
} from "@/utils/sounds";
import { useAlert } from "@/hooks/useAlert";
import { useSounds } from "@/hooks/useSound";
import { useRefreshUser } from "@/hooks/useRefreshUser";
import { useHapticFeedback } from "@telegram-apps/sdk-react";
import { useUserStore } from "../logic/useUserStore";
import { useAssetStore } from "../logic/useAssetStore";
import { usePlayerStore } from "../logic/usePlayerStore";

export function ItemModal({ open, onClose, item }) {
  const { t } = useTranslation();
  const [upgradeMode, setUpgradeMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { ITEM_TYPES, RARITY_COLORS } = useAssetStore();
  const [playStarAnimation, setPlayStarAnimation] = useState(false);
  const itemRef = useRef(null);
  const { recalculateTotalStats } = usePlayerStore();
  const { user } = useUserStore();
  const { setError } = useAlert();
  const { playSound } = useSounds();
  const refreshUser = useRefreshUser();
  const hapticFeedback = useHapticFeedback(true);

  const colors = RARITY_COLORS;
  const itemColor = colors?.[item?.rarity];

  const itemLevel = item?.level;
  const maxLevel = LEVEL_CAPS[item?.rarity];
  const isMaxLevel = itemLevel >= maxLevel;
  const itemLevelUpgrade = upgradeMode
    ? Math.min(itemLevel + 1, maxLevel)
    : itemLevel;
  const hasAttackUpgrade = item?.type?.toLowerCase() === "attack";
  const hasDefenseUpgrade = item?.type?.toLowerCase() === "defense";

  // Calculate attack and defense values using the new function
  const attackValue = calculateItemValue(item, itemLevelUpgrade, "attack");
  const defenseValue = calculateItemValue(item, itemLevelUpgrade, "defense");
  const upgradeCost = calculateItemUpgradeCost(item, itemLevel);
  const scrolls = calculateScrolls(item, itemLevel);
  const isUpgradable = !!(item?.level && upgradeCost && !isMaxLevel);
  const isScroll = item?.type === "Scroll";

  const handleEquipItem = async (slot) => {
    try {
      const type = item.type;
      await equipItem({
        userId: user.id,
        slot,
        itemId: item.id,
        jwt: user.jwt,
      });
      hapticFeedback?.impactOccurred("light");
      type === ITEM_TYPES.ATTACK && playSound(equipAttackSound);
      type === ITEM_TYPES.DEFENSE && playSound(equipDefenseSound);
      type === ITEM_TYPES.SPECIAL && playSound(equipSpecialSound);
      await refreshUser();
      recalculateTotalStats();
      onClose();
    } catch (error) {
      setError(error);
    }
  };

  async function handleUpgrade() {
    setUpgradeMode(false);

    try {
      await upgradeItem({
        userId: user.id,
        itemId: item.id,
        jwt: user.jwt,
      });

      hapticFeedback?.impactOccurred("heavy");
      playSound(upgradeSound);

      await refreshUser();
      recalculateTotalStats();

      setPlayStarAnimation(true);
      playItemUpgradeAnimation(itemRef, () => setPlayStarAnimation(false), 3);
    } catch (error) {
      setError(error);
    }
  }

  const handleEquip = (event) => {
    if (item?.type === ITEM_TYPES.ATTACK || item?.type === ITEM_TYPES.DEFENSE) {
      setAnchorEl(event.currentTarget);
    } else {
      handleEquipItem();
    }
  };

  const handleSlotSelect = (slot) => {
    setAnchorEl(null);
    handleEquipItem(slot);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    setUpgradeMode(false);
    onClose(e);
  };

  return (
    <SimpleModal
      open={open}
      onClose={handleClose}
      title={item?.name}
      titleBgColor={itemColor}
    >
      <Stack
        direction="row"
        mb={0.5}
        justifyContent="flex-start"
        alignItems="center"
        position="relative"
      >
        <div ref={itemRef}>
          <Item
            scope="modal"
            xs={3}
            size="L"
            item={item}
            showLevel
            isSelected={false}
            tileIndex={1}
            onClick={() => {}}
          />
        </div>
        <StarBurst play={playStarAnimation} />
        <Stack pl={2} spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            display={item?.rarity ? "flex" : "none"}
            alignItems="center"
          >
            <H6>{t("item.rarity")}:</H6>
            <RarityChip
              rarity={item?.rarity}
              sx={{ position: "relative", top: 0, left: 0, right: 0 }}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <H6>{t("item.type")}:</H6>
            <H6>{item?.type}</H6>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            display={item?.class ? "flex" : "none"}
          >
            <H6>{t("item.set")}:</H6>
            <H6>{item?.class}</H6>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={1} display={item?.type === "Scroll" ? "none" : "flex"}>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Paragraph>{t("item.level")}:</Paragraph>
          <H5>
            {itemLevel} / {maxLevel}
          </H5>
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Paragraph>{t("item.attack")}:</Paragraph>
          <H5
            sx={{
              transform: hasAttackUpgrade && upgradeMode && "scale(1.4)",
              animation:
                hasAttackUpgrade &&
                upgradeMode &&
                "pulsate 0.8s infinite ease-in-out",
              color: hasAttackUpgrade && upgradeMode && secondary.main,
            }}
          >
            {attackValue}
          </H5>
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Paragraph>{t("item.defense")}:</Paragraph>
          <H5
            sx={{
              transform: hasDefenseUpgrade && upgradeMode && "scale(1.4)",
              animation:
                hasDefenseUpgrade &&
                upgradeMode &&
                "pulsate 0.8s infinite ease-in-out",
              color: hasDefenseUpgrade && upgradeMode && secondary.main,
            }}
          >
            {defenseValue}
          </H5>
        </Stack>
      </Stack>

      <Stack spacing={1} display={item?.description ? "flex" : "none"}>
        <Small>{item?.description}</Small>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        MenuListProps={{ sx: { padding: "4px 4px 0px 4px" } }}
      >
        {[
          item?.type === ITEM_TYPES.ATTACK ? 1 : 3,
          item?.type === ITEM_TYPES.ATTACK ? 2 : 4,
        ].map((slot) => (
          <MenuItem
            key={`slot${slot}`}
            sx={{ display: "inline-flex", padding: 0 }}
          >
            <Item
              scope="modal"
              xs={3}
              item={null}
              showLevel={false}
              isSelected={false}
              tileIndex={1}
              label={slot}
              onClick={() => handleSlotSelect(slot)}
            />
          </MenuItem>
        ))}
      </Menu>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pt={1}
        spacing={2}
        display={item?.type === "Scroll" ? "none" : "flex"}
      >
        <Paragraph>{t("game.upgrade")}:</Paragraph>
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack direction="row" alignItems="center">
            <SmartImage
              src={`/images/items/scroll_${item?.type?.toLowerCase()}.webp`}
              height={30}
              width={30}
              alt="Icon"
            />
            <H5 ml={0.5}>{scrolls}</H5>
          </Stack>

          <Stack direction="row" alignItems="center">
            <SmartImage
              src="/images/icons/rune.webp"
              height={30}
              width={30}
              alt="Icon"
              style={{ marginLeft: "-10px" }}
            />
            <H5 ml={0.5}>{upgradeCost}</H5>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        pt={1}
        alignItems="center"
        direction="row"
        justifyContent={isUpgradable ? "space-between" : "center"}
      >
        {!isScroll && (
          <SimpleButton
            onClick={(e) =>
              upgradeMode ? setUpgradeMode(false) : handleEquip(e)
            }
            playSound={upgradeMode ? true : false}
          >
            {upgradeMode ? t("game.back") : t("game.equip")}
          </SimpleButton>
        )}
        {isUpgradable && (
          <SimpleButton
            color="green"
            onClick={() =>
              upgradeMode ? handleUpgrade() : setUpgradeMode(true)
            }
          >
            {upgradeMode ? t("game.confirm") : t("game.upgrade")}
          </SimpleButton>
        )}
      </Stack>

      {isMaxLevel && (
        <Stack pt={1} alignItems="center">
          <Paragraph color="secondary">{t("item.maxLevelReached")}</Paragraph>
        </Stack>
      )}
    </SimpleModal>
  );
}
