import { API_URL } from "@/constants";
import axios from "axios";

export function getAuthHeaders(jwt) {
  if (!jwt) {
    return console.warn("No JWT");
  }
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwt}`,
  };
}

export async function getAssets() {
  return axios.get(`${API_URL}/assets`);
}

export async function authTelegramUser(initDataRaw, trackingParams) {
  return axios.post(
    `${API_URL}/user/telegram`,
    { tracking: trackingParams },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
}

export async function updateUser({ userId, update, jwt }) {
  return axios.put(`${API_URL}/user/${userId}`, update, {
    headers: getAuthHeaders(jwt),
  });
}

export async function generateInvoice({ productId, jwt }) {
  return axios.post(
    `${API_URL}/invoice/generate`,
    { productId },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function getInvoice({ invoiceId, jwt }) {
  return axios.get(`${API_URL}/invoice/${invoiceId}`, {
    headers: getAuthHeaders(jwt),
  });
}

export async function upgradeStats({ userId, stat, jwt }) {
  return axios.put(
    `${API_URL}/user/${userId}/upgrade-stats`,
    { stat },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function openChest({ userId, chestHash, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/open-chest`,
    { chestHash },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function claimLoot({ userId, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/claim-loot`,
    {},
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function resolveGamble({ userId, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/resolve-gamble`,
    {},
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function equipItem({ userId, slot, itemId, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/equip`,
    { itemId, slot },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function upgradeItem({ userId, itemId, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/upgrade/${itemId}`,
    {},
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function getUser({ userId, jwt }) {
  return axios.get(`${API_URL}/user/${userId}`, {
    headers: getAuthHeaders(jwt),
  });
}

export async function prestigeUser({ userId, newWorldId, newCycle, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/prestige`,
    { newWorldId, newCycle },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

// Add this new function
export async function changeSelectedHero({ userId, heroId, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/change-hero`,
    { heroId },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function purchaseHero({ userId, heroTemplateId, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/purchase-hero`,
    { heroTemplateId },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function upgradeHero({ userId, heroId, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/upgrade-hero`,
    { heroId },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function resetUser({ userId, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/reset`,
    {},
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

// Add this new function
export async function purchaseChest({ userId, chestLevel, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/purchase-chest`,
    { chestLevel },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function purchaseGold({ userId, amount, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/purchase-gold`,
    { amount },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function addDebugGems({ userId, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/add-debug-gems`,
    {},
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function authUser(jwt, trackingParams) {
  return axios.post(
    `${API_URL}/user/auth`,
    { tracking: trackingParams },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export function hasJWT() {
  return !!localStorage.getItem("userJWT");
}

export function getStoredJWT() {
  return localStorage.getItem("userJWT");
}

export function setStoredJWT(jwt) {
  localStorage.setItem("userJWT", jwt);
}

export async function updatePlayerMetrics({ updates, jwt }) {
  try {
    const response = await axios.post(
      `${API_URL}/metrics/update`,
      { updates },
      {
        headers: getAuthHeaders(jwt),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating player metrics:", error);
  }
}

// Add new function for beacon
export function sendMetricsBeacon(sessionLength, jwt) {
  const blob = new Blob(
    [
      JSON.stringify({
        sessionDuration: sessionLength,
        jwt,
      }),
    ],
    {
      type: "text/plain", // Use text/plain to avoid preflight
    }
  );

  return navigator.sendBeacon(`${API_URL}/beacon`, blob);
}

export async function getPlayerMetrics({ jwt }) {
  return axios.get(`${API_URL}/metrics`, {
    headers: getAuthHeaders(jwt),
  });
}

export async function getLeaderboards({ jwt }) {
  return axios.get(`${API_URL}/leaderboard/all`, {
    headers: getAuthHeaders(jwt),
  });
}

export async function claimAchievementReward({
  userId,
  achievementType,
  level,
  id,
  jwt,
}) {
  return axios.post(
    `${API_URL}/user/${userId}/claim-achievement`,
    { achievementType, level, id },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function updateUserSettings({
  userId,
  playSounds,
  playMusic,
  jwt,
}) {
  return axios.post(
    `${API_URL}/user/${userId}/update-settings`,
    { playSounds, playMusic },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function claimChallengeReward({
  userId,
  type,
  day,
  missionIndex,
  milestone,
  jwt,
}) {
  return axios.post(
    `${API_URL}/user/${userId}/claim-challenge-reward`,
    { type, day, missionIndex, milestone },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function processDungeonCompleted({ userId, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/dungeon-completed`,
    {},
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function getPendingChests({ userId, jwt }) {
  return axios.get(`${API_URL}/user/${userId}/pending-chests`, {
    headers: getAuthHeaders(jwt),
  });
}

export async function updateTutorialProgress({ userId, jwt, step }) {
  return axios.post(
    `${API_URL}/user/${userId}/tutorial-progress`,
    { step },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}

export async function purchasePotions({ userId, potionType, amount, jwt }) {
  return axios.post(
    `${API_URL}/user/${userId}/purchase-potions`,
    { potionType, amount },
    {
      headers: getAuthHeaders(jwt),
    }
  );
}
