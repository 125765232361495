import { Container, Stack } from "@mui/material";
import { H1, Paragraph } from "./Typography";
import { SmartImage } from "./SmartImage";

export const NetworkError = () => {
  return (
    <Container maxWidth="sm" sx={{ height: "100%" }}>
      <Stack
        height="100%"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <SmartImage
          src="/images/icons/maintenance.png"
          alt="Under Maintenance"
          height={100}
          width={100}
        />
        <H1>Under Maintenance</H1>
        <Paragraph>
          We're currently performing some updates to improve your experience.
          Please check back again soon.
        </Paragraph>
        <SmartImage
          src="/images/blu.png"
          alt="Under Maintenance"
          height={100}
          width={100}
        />
      </Stack>
    </Container>
  );
};
