import { PriorityBadge } from "@/components/PriorityBadge";
import { SmartImage } from "@/components/SmartImage";
import { H6 } from "@/components/Typography";
import { borderRadiusNew, primary } from "@/theme/colors";
import { lighten, Stack } from "@mui/material";
import { useTranslation } from "@/hooks/useTranslation";
import { useSounds } from "@/hooks/useSound";
import { clickSound, notPossibleSound } from "@/utils/sounds";

export function QuestDay({
  day,
  index,
  activeDay,
  selectedDay,
  setSelectedDay,
  currentDay,
  hasBadge,
}) {
  const { playSound } = useSounds();
  const { t } = useTranslation();

  const disabled = index > activeDay;
  const isActive = index === selectedDay;
  const isCurrentDay = day === currentDay;

  const backgroundColor =
    (isCurrentDay && primary.white) ||
    (isActive && primary.green) ||
    lighten(primary.green, 0.2);

  function handleClick() {
    if (disabled) {
      playSound(notPossibleSound);
    } else {
      playSound(clickSound);
      setSelectedDay(index);
    }
  }

  return (
    <Stack
      key={day}
      direction="row"
      sx={{
        margin: "10px 4px",
        marginLeft: "-4px",
        borderRadius: borderRadiusNew,
        backgroundColor,
        border: `2px solid ${isActive ? primary.white : "transparent"}`,
        boxShadow: isActive
          ? `0px 0px 6px 0px ${primary.white}, inset 0px -8px 0px 2px rgba(0, 0, 0, 0.1)`
          : "inset 0px -8px 0px 2px rgba(0, 0, 0, 0.1)",
        padding: "0 4px",
        position: "relative",
      }}
      onClick={handleClick}
    >
      <H6 color="white">
        {t("challenge.day")} {day}
      </H6>
      {hasBadge && <PriorityBadge />}
      {disabled && (
        <SmartImage
          src="/images/icons/Icon_ItemIcon_Lock.png"
          width={26}
          height={26}
          alt="Arrow"
          style={{
            position: "absolute",
            right: "-2px",
          }}
        />
      )}
    </Stack>
  );
}
