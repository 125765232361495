import { create } from "zustand";
import { claimChallengeReward } from "@/lib/api";
import { useChestStore } from "./useChestStore";
import { useUserStore } from "./useUserStore";

export const useChallengeStore = create((set) => ({
  challenge: null,

  setChallenge: (challenge) => set({ challenge }),

  claimChallengeRewards: async ({ type, day, missionIndex, milestone }) => {
    const user = useUserStore.getState().user;
    try {
      const { data } = await claimChallengeReward({
        userId: user.id,
        jwt: user.jwt,
        type,
        day,
        missionIndex,
        milestone,
      });

      if (data.challenge) {
        // Update challenge state
        set({ challenge: data.challenge });

        // Update chest hash
        useChestStore.getState().setChestHash(data.chestHash);

        // Update user data
        useUserStore.getState().setUser({
          ...user,
          resources: data.user.resources,
          inventory: data.newItem
            ? [...user.inventory, data.newItem]
            : user.inventory,
        });
        return { success: true };
      }
      return { success: false, error: "No challenge rewards to claim" };
    } catch (error) {
      console.error("Error claiming challenge reward:", error);
      return { success: false, error: error.message };
    }
  },
}));
