import { useEffect, useRef } from "react";
import { gsap } from "gsap";

const colors = [
  "#ff0000",
  "#ff7f00",
  "#80ff00",
  "#00ff80",
  "#00ffff",
  "#0080ff",
  "#0000ff",
  "#8000ff",
  "#ff00ff",
  "#fe007f",
];

export function StarBurst({ play }) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (play) {
      fireStars();
    }
  }, [play]);

  function buildStars() {
    const stars = 15;
    const starSize = 30;
    const container = containerRef.current;

    for (let i = 0; i < stars; i++) {
      const star = document.createElement("div");
      star.className = "gStar";
      star.innerHTML = "★";
      const sz = Math.floor(Math.random() * starSize + starSize / 3);
      Object.assign(star.style, {
        position: "absolute",
        zIndex: 510,
        fontSize: `${sz}px`,
        opacity: 0,
      });
      container.appendChild(star);
    }
  }

  function fireStars() {
    const stars = containerRef.current.querySelectorAll(".gStar");
    const starDistance = 200;
    const starSpeed = 1.25;

    const theme_color = colors[Math.floor(Math.random() * colors.length)];
    let theme_color_secondary;
    do {
      theme_color_secondary = colors[Math.floor(Math.random() * colors.length)];
    } while (theme_color_secondary === theme_color);

    stars.forEach((star, i) => {
      const sz = parseInt(star.style.fontSize);
      const angle = Math.floor(
        Math.random() * (i + 1 * (360 / stars.length)) +
          i * (360 / stars.length)
      );

      gsap.set(star, {
        x: 0,
        y: 0,
        rotation: 0,
        scale: 0.35,
        left: "50%",
        top: "50%",
        xPercent: -50,
        yPercent: -50,
        color: i % 2 === 0 ? theme_color : theme_color_secondary,
      });

      gsap
        .timeline()
        .to(star, 0.35, { autoAlpha: 0.7 })
        .to(
          star,
          Math.floor(
            Math.random() * (starSpeed * 100) + (starSpeed * 100) / 3
          ) / 100,
          {
            x:
              Math.cos((angle * Math.PI) / -180) *
              Math.floor(Math.random() * starDistance + starDistance / 4),
            y:
              Math.sin((angle * Math.PI) / -180) *
              Math.floor(Math.random() * starDistance + starDistance / 4),
            rotation: 280,
            scale: 1,
            ease: "power0.none",
          },
          "<"
        )
        .to(
          star,
          0.35,
          {
            autoAlpha: 0,
          },
          ">-.25"
        );
    });
  }

  useEffect(() => {
    buildStars();
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        pointerEvents: "none",
      }}
    />
  );
}
