import { create } from "zustand";

export const useImageLoadStore = create((set, get) => ({
  totalImages: 0,
  loadedImages: 0,
  registerImage: () => set((state) => ({ totalImages: state.totalImages + 1 })),
  imageLoaded: () => set((state) => ({ loadedImages: state.loadedImages + 1 })),
  reset: () => set({ totalImages: 0, loadedImages: 0 }),
  getProgress: () => {
    const { totalImages, loadedImages } = get();
    return totalImages > 0 ? (loadedImages / totalImages) * 100 : 0;
  },
  isAllLoaded: () => {
    const { totalImages, loadedImages } = get();
    return totalImages > 0 && loadedImages === totalImages;
  },
}));
