import { useProgressStore } from "../logic/useProgressStore";
import { useUserStore } from "../logic/useUserStore";
import { useLootStore } from "../logic/useLootStore";
import { useAssetStore } from "../logic/useAssetStore";
import { usePrestigeStore } from "../logic/usePrestigeStore";
import { usePlayerMetricsStore } from "../logic/usePlayerMetricsStore";
import { useBattleStore } from "../logic/useBattleStore";
import { updateUser } from "@/lib/api";
import * as Sentry from "@sentry/react";
import { metrics } from "../logic/Metrics";
const resetDungeon = (dungeon) => ({
  ...dungeon,
  currentCheckpoint: 0,
  checkpoints: dungeon.checkpoints.map((cp) => ({
    ...cp,
    hp: cp.hp,
    maxHp: cp.hp,
    attackCount: 0,
  })),
});

const prepareNextDungeonState = (nextDungeon) => ({
  dungeon: resetDungeon(nextDungeon),
  progress: { dungeon: nextDungeon.id },
});

const prepareNextWorldState = (nextWorld, currentCycle) => {
  const { LEVELS, WORLDS } = useAssetStore.getState();
  const firstDungeonOfNextWorld = LEVELS[0];
  const isLastWorld = nextWorld.id === WORLDS[0].id;
  const newCycle = isLastWorld ? currentCycle + 1 : currentCycle;

  return {
    world: nextWorld,
    dungeon: resetDungeon(firstDungeonOfNextWorld),
    progress: {
      world: nextWorld.id,
      dungeon: firstDungeonOfNextWorld.id,
      cycle: newCycle,
    },
  };
};

const handleProgressUpdate = (dungeonCompleted) => {
  const progress = useProgressStore.getState();
  const assetStore = useAssetStore.getState();
  const user = useUserStore.getState().user;

  if (!dungeonCompleted) {
    useProgressStore.getState().setDungeon(resetDungeon(progress.dungeon));
    return { shouldPrestige: false };
  }

  const nextDungeon = assetStore.findNextDungeon(progress.dungeon.id);
  if (nextDungeon) {
    const nextDungeonState = prepareNextDungeonState(nextDungeon);
    useProgressStore.getState().setDungeon(nextDungeonState.dungeon);
    return {
      shouldPrestige: false,
      progress: nextDungeonState.progress,
    };
  }

  // Handle world transition
  const nextWorld = assetStore.findNextWorld(progress.world.id);
  const currentCycle = user.progress.cycle || 1;
  const nextWorldState = prepareNextWorldState(nextWorld, currentCycle);

  useProgressStore.getState().setWorld(nextWorldState.world);
  useProgressStore.getState().setDungeon(nextWorldState.dungeon);

  return {
    shouldPrestige: true,
    newWorldId: nextWorldState.progress.world,
    progress: nextWorldState.progress,
  };
};

export const rewardsActions = {
  claimRewards: async () => {
    const battleStore = useBattleStore.getState();
    const progress = useProgressStore.getState();
    const user = useUserStore.getState().user;
    const loot = useLootStore.getState().getLoot();

    if (
      (!battleStore.dungeonCompleted && !battleStore.playerLost) ||
      !progress.dungeon
    ) {
      return { success: false, error: "No rewards to claim" };
    }

    try {
      let update = {
        xp: loot.xp || 0,
        resources: { gold: loot.gold },
        progress: {
          world: progress.world.id,
          dungeon: progress.dungeon.id,
          cycle: user.progress.cycle,
        },
      };

      // Handle progression and prestige
      const {
        shouldPrestige,
        newWorldId,
        progress: progressUpdate,
      } = handleProgressUpdate(battleStore.dungeonCompleted);

      if (progressUpdate) {
        update.progress = { ...update.progress, ...progressUpdate };
      }

      // Handle prestige or regular update
      if (shouldPrestige) {
        const result = await usePrestigeStore.getState().handlePrestige({
          userId: user.id,
          newWorldId,
          newCycle: update.progress.cycle,
          jwt: user.jwt,
        });

        if (!result.success) {
          console.error("Error during prestige:", result.error);
          Sentry.captureException(result.error, {
            level: "error",
            tags: {
              userId: user.id,
            },
          });
        }
      } else {
        const { data } = await updateUser({
          userId: user.id,
          update,
          jwt: user.jwt,
        });
        useUserStore.getState().setUserSafe(data.user);
      }

      // Record metrics
      metrics.add("goldCollected", loot.gold);
      metrics.add("xpCollected", loot.xp || 0);
      metrics.add("levelCompleted", battleStore.dungeonCompleted ? 1 : 0);
      await metrics.flushNow();

      // Cleanup
      useLootStore.getState().resetLoot();
      setTimeout(() => {
        usePlayerMetricsStore.getState().fetchAndUpdateMetrics();
      }, 500);

      return { success: true };
    } catch (error) {
      console.error("Error claiming rewards:", error);
      Sentry.captureException(error, {
        level: "error",
        tags: { userId: user.id },
      });
      return { success: false, error: error.message };
    }
  },
};
