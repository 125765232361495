import { useEnemyAttackState } from "@/game/logic/useEnemyAttackState";
import styled from "@emotion/styled";
import { useHapticFeedback } from "@telegram-apps/sdk-react";
import { memo, useEffect, useState } from "react";

const FLASH_DURATION = 300; // Duration in milliseconds

const FlashOverlay = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  animation: flash-animation ${FLASH_DURATION}ms ease-out;
  background: radial-gradient(
    circle,
    transparent 50%,
    rgba(255, 0, 0, 0.6) 100%
  );
`;

const RedFlashComponent = memo(({ show, onComplete }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(onComplete, FLASH_DURATION);
      return () => clearTimeout(timer);
    }
  }, [show, onComplete]);

  return show ? <FlashOverlay /> : null;
});

RedFlashComponent.displayName = "RedFlashComponent";

export const RedFlash = memo(() => {
  const [showRedFlash, setShowRedFlash] = useState(false);
  const enemyAttacked = useEnemyAttackState((state) => state.enemyAttacked);
  const hapticFeedback = useHapticFeedback(true);

  useEffect(() => {
    if (enemyAttacked) {
      setTimeout(() => {
        hapticFeedback?.impactOccurred("medium");
        setShowRedFlash(true);
      }, 650);
    }
  }, [enemyAttacked]);

  return (
    <RedFlashComponent
      show={showRedFlash}
      onComplete={() => setShowRedFlash(false)}
    />
  );
});

RedFlash.displayName = "RedFlash";
