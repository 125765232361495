import { H5, Paragraph } from "@/components/Typography";
import { alpha, Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SimpleModal } from "@/components/SimpleModal";
import { SmartImage } from "@/components/SmartImage";
import {
  backgroundColorSecondary,
  borderRadiusNew,
  vipPass,
} from "@/theme/colors";
import { BADGES } from "@/constants";
import { InfoIcon } from "@/components/InfoIcon";
import { useState } from "react";
import { useUserStore } from "../logic/useUserStore";
import { usePlayerMetricsStore } from "../logic/usePlayerMetricsStore";

export function PlayerModal({ open, onClose }) {
  const { achievements: playerAchievements } = usePlayerMetricsStore();
  const { user } = useUserStore();
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const cycle = user?.progress?.cycle;
  const { t } = useTranslation();

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={t("user.title")}
      modalSx={{
        ...(user?.passes?.vip && vipPass),
      }}
      titleSx={{
        ...(user?.passes?.vip && vipPass),
      }}
    >
      <Stack spacing={1} px={3}>
        {/* <Stack alignItems="center">
          <SmartImage
            src={`/heroes/${selectedHero?.templateId}.webp`}
            width={160}
            height={160}
            style={{
              width: "100px",
              height: "auto",
            }}
            alt="chest"
          />
        </Stack> */}

        <Stack alignItems="center" direction="row" pb={1} position="relative">
          <InfoIcon onClick={() => setInfoModalOpen(true)} />
          <SmartImage
            src={`/images/badges/${cycle}.webp?v=2`}
            height={140}
            width={140}
            alt={`${cycle} badge`}
            style={{
              width: `${100}px`,
              height: "auto",
              zIndex: 1,
            }}
          />
          <Stack>
            <H5>{`@${user?.username}`}</H5>
            <Paragraph>
              {t("playerModal.firstNameLastName", {
                firstName: user?.firstName,
                lastName: user?.lastName,
              })}
            </Paragraph>
            <Paragraph>
              {t("playerModal.xp", { xp: (user?.xp || 0).toLocaleString() })}
            </Paragraph>
            <Paragraph>
              {t("playerModal.league", { league: BADGES[cycle] })}
            </Paragraph>
            <Paragraph>{t("playerModal.cycle", { cycle })}</Paragraph>
          </Stack>
        </Stack>

        <Stack
          sx={{
            backgroundColor: alpha(backgroundColorSecondary, 0.3),
            borderRadius: borderRadiusNew,
            padding: "10px",
            maxHeight: "160px",
            overflowY: "auto",
          }}
          spacing={1}
        >
          <Stack direction="row" justifyContent="space-between">
            <Paragraph>Monsters defeated</Paragraph>
            <Paragraph color="secondary.main">
              {playerAchievements?.monstersDefeated?.[0]?.currentCount || 0}
            </Paragraph>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Paragraph>Gold collected</Paragraph>
            <Paragraph color="secondary.main">
              {playerAchievements?.goldCollected?.[0]?.currentCount || 0}
            </Paragraph>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Paragraph>Damage dealt</Paragraph>
            <Paragraph color="secondary.main">
              {playerAchievements?.damageDealt?.[0]?.currentCount || 0}{" "}
            </Paragraph>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Paragraph>Level completed</Paragraph>
            <Paragraph color="secondary.main">
              {playerAchievements?.levelCompleted?.[0]?.currentCount || 0}
            </Paragraph>
          </Stack>
          {/* <Stack direction="row" justifyContent="space-between">
            <Paragraph>Elites defeated</Paragraph>
            <Paragraph color="secondary.main">
              {playerAchievements?.elitesDefeated?.[0]?.currentCount || 0}
            </Paragraph>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Paragraph>Bosses defeated</Paragraph>
            <Paragraph color="secondary.main">
              {playerAchievements?.bossesDefeated?.[0]?.currentCount || 0}
            </Paragraph>
          </Stack> */}
        </Stack>
      </Stack>

      <SimpleModal
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        title={t("badges.title")}
      >
        <Stack mb={2} textAlign="center" spacing={1}>
          <Paragraph>{t("badges.description")}</Paragraph>
        </Stack>
        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={1}>
          {Object.entries(BADGES).map(([key, badge]) => (
            <Stack key={badge} alignItems="center">
              <SmartImage
                src={`/images/badges/${key}.webp?v=2`}
                height={100}
                width={100}
                alt={`${badge} badge`}
                style={{
                  width: "60px",
                  height: "auto",
                }}
              />
              <Paragraph>{badge}</Paragraph>
            </Stack>
          ))}
        </Box>
      </SimpleModal>
    </SimpleModal>
  );
}
