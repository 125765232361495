import { SmartImage } from "@/components/SmartImage";
import { H3, H4, H6 } from "@/components/Typography";
import { Box, darken, lighten, Modal, Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import { StarEffect } from "@/components/effects/StarEffect";
import { useSounds } from "@/hooks/useSound";
import { fanfareHeroSound } from "@/utils/sounds";
import { BurstImage } from "@/components/effects/BurstImage";
import { useMusic } from "@/hooks/useMusic";

export const HeroPurchasedModal = ({ open, onClose, hero }) => {
  const { t } = useTranslation();
  const [showText, setShowText] = useState(false);
  const particlesRef = useRef(null);
  const { playSound, stopSound } = useSounds();
  const { playMainMusic, stopMainMusic } = useMusic();

  const handleClick = () => {
    onClose();
    stopSound(fanfareHeroSound);
    playMainMusic();
  };

  useEffect(() => {
    let timer;

    setShowText(false);
    timer = setTimeout(() => {
      setShowText(true);
    }, 500);

    if (open) {
      stopMainMusic();
      playSound(fanfareHeroSound);
    }

    if (open && particlesRef.current) {
      // Create explosion animation
      const particles = particlesRef.current.children;
      gsap.set(particles, { scale: 0, opacity: 0 });
      gsap.to(particles, {
        scale: () => gsap.utils.random(0.5, 1.5),
        opacity: 1,
        duration: 0.8,
        stagger: 0.02,
        ease: "power2.out",
        onComplete: () => {
          gsap.to(particles, {
            scale: 0,
            opacity: 0,
            duration: 0.6,
            stagger: 0.01,
            ease: "power2.in",
          });
        },
      });
    } else {
      setShowText(false);
    }
    return () => clearTimeout(timer);
  }, [open]);

  return (
    <Modal open={open}>
      <Stack
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          ":focus-visible": {
            outline: "none",
          },
          background: `radial-gradient(circle, ${darken(
            hero.color,
            0.3
          )} 0%, ${lighten(hero.color, 0.1)} 100%)`,
          overflow: "hidden",
          "&::before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
            animation: "move-pattern 10s linear infinite",
          },
        }}
        onClick={showText && handleClick}
      >
        <H3
          textAlign="center"
          sx={{ position: "absolute", top: "10%", zIndex: 10 }}
        >
          {t("hero.puchased_title")}
        </H3>

        <H4
          textAlign="center"
          sx={{ position: "absolute", top: "20%", zIndex: 10 }}
        >
          {t("hero.purchased", { hero: hero.name })}
        </H4>

        <StarEffect />

        <Box
          ref={particlesRef}
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            pointerEvents: "none",
          }}
        >
          {[...Array(50)].map((_, i) => (
            <Box
              key={i}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor:
                  i % 2 === 0 ? hero.color : hero.complementColor,
              }}
            />
          ))}
        </Box>
        <Box position="relative" sx={{ width: "200px", height: "200px" }}>
          <SmartImage
            src={`/heroes/${hero.id}.webp`}
            alt={hero.name}
            width={300}
            height={300}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              margin: "0 auto",
              width: "200px",
              height: "auto",
              zIndex: 1,
            }}
          />
          <BurstImage
            size={300}
            sx={{
              zIndex: 0,
              width: "160%",
              left: "-30%",
              top: "-30%",
            }}
          />
        </Box>

        <Stack zIndex={10}>
          <AnimatePresence mode="wait">
            {showText && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                style={{
                  position: "absolute",
                  bottom: "10%",
                  left: 0,
                  right: 0,
                  animation: "pulsate 1s ease-in-out infinite",
                }}
              >
                <H6 textAlign="center" onClick={handleClick}>
                  {t("hero.continue")}
                </H6>
              </motion.div>
            )}
          </AnimatePresence>
        </Stack>
      </Stack>
    </Modal>
  );
};
