import { memo, useMemo, useState, useEffect, useRef } from "react";
import { Stack, LinearProgress } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import { Paragraph } from "./Typography";
import { useTranslation } from "react-i18next";
import { useSounds } from "@/hooks/useSound";
import {
  primary,
  error,
  borderWidth,
  backgroundColorSecondary,
  backgroundColorHex,
} from "@/theme/colors";
import { useAlert } from "@/hooks/useAlert";
import { notPossibleSound } from "@/utils/sounds";
import { usePlayerStore } from "@/game/logic/usePlayerStore";
import { useEnergyStore } from "@/game/logic/useEnergyStore";
import { useBattleStore } from "@/game/logic/useBattleStore";
import { usePotionStore } from "@/game/logic/usePotionStore";

const useEnergyRegeneration = (regenRate, battleStarted) => {
  const [progress, setProgress] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {
    if (battleStarted) {
      timerRef.current = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 100) return 0;
          const newProgress = oldProgress + 100 / (regenRate / 50);
          return Math.min(newProgress, 100);
        });
      }, 50);
    } else {
      setProgress(0);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [regenRate, battleStarted]);

  return progress;
};

export const IconNumberEnergy = memo(() => {
  const { t } = useTranslation();
  const { playSound } = useSounds();
  const { setError } = useAlert();
  const { player } = usePlayerStore();
  const { battleStarted } = useBattleStore();
  const { energy } = useEnergyStore();
  const { energyBuffActive } = usePotionStore();

  const regenRate = useMemo(
    () => player.energyRegenRate,
    [player.energyRegenRate]
  );
  const progress = useEnergyRegeneration(regenRate, battleStarted);

  useEffect(() => {
    if (energy <= 0 && battleStarted) {
      playSound(notPossibleSound);
      setError(t("battle.noEnergy"));
    }
  }, [energy, battleStarted]);

  const containerStyle = useMemo(
    () => ({
      background: `linear-gradient(to top, ${backgroundColorHex}, ${backgroundColorSecondary})`,
      borderWidth: borderWidth,
      borderColor: backgroundColorHex,
      borderStyle: "solid",
      borderRadius: "24px",
      zIndex: 2,
      padding: "4px 10px",
      position: "relative",
      margin: "4px 4px",
      height: "fit-content",
      animation: energyBuffActive ? "active-buff 0.8s ease-in-out" : "none",
    }),
    [energyBuffActive]
  );

  const progressStyle = useMemo(
    () => ({
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "100%",
      width: "100%",
      zIndex: -1,
      borderRadius: "24px",
      backgroundColor: "transparent",
      "& .MuiLinearProgress-bar": {
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        transition: "transform 0.05s linear",
      },
    }),
    []
  );

  const energyColor = useMemo(
    () => (energy < 1 ? error.main : primary.white),
    [energy]
  );

  return (
    <Stack direction="row" alignItems="center" sx={containerStyle}>
      {battleStarted && (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={progressStyle}
        />
      )}
      <SmartImage
        src="/images/icons/energy.webp"
        height={66}
        width={66}
        alt="Energy"
        style={{
          position: "absolute",
          top: "-16px",
          left: "-8px",
          zIndex: 1,
        }}
      />
      <Stack
        direction="row"
        sx={{
          padding: "1px",
          width: "70px",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        <Paragraph
          ml={6}
          sx={{
            color: energyColor,
            fontSize: "1.5em",
          }}
        >
          {energy}
        </Paragraph>
      </Stack>
    </Stack>
  );
});

IconNumberEnergy.displayName = "IconNumberEnergy";
