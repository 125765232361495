import { useState, useEffect, useCallback } from "react";
import { Container } from "@pixi/react";
import { CoinDrop } from "./CoinDrop";
import { useLootStore } from "../logic/useLootStore";

export const CoinDropManager = () => {
  const [coins, setCoins] = useState([]);
  const { accumulatedLoot } = useLootStore();

  const createCoin = useCallback(() => {
    const startX = window.innerWidth / 2 + (Math.random() - 0.5) * 100;
    const startY = window.innerHeight / 2;
    const margin = 20;
    const endX = margin + Math.random() * (window.innerWidth - 2 * margin);
    const endY = window.innerHeight * 0.77;

    return {
      id: Date.now(),
      startX,
      startY,
      endX,
      endY,
    };
  }, []);

  const removeCoin = useCallback((id) => {
    setCoins((prev) => prev.filter((coin) => coin.id !== id));
  }, []);

  useEffect(() => {
    if (accumulatedLoot.gold > 0) {
      setCoins((prev) => [...prev, createCoin()]);
    }
  }, [accumulatedLoot.gold, createCoin]);

  return (
    <Container>
      {coins.map((coin) => (
        <CoinDrop
          key={coin.id}
          startX={coin.startX}
          startY={coin.startY}
          endX={coin.endX}
          endY={coin.endY}
          onComplete={() => removeCoin(coin.id)}
        />
      ))}
    </Container>
  );
};
