import { create } from "zustand";

const useHitNumbersStore = create((set) => ({
  hitNumbers: [],
  addHitNumber: (number, x, y) =>
    set((state) => ({
      hitNumbers: [...state.hitNumbers, { id: Date.now(), number, x, y }],
    })),
  updateHitNumbers: () =>
    set((state) => {
      const now = Date.now();
      const updatedHitNumbers = state.hitNumbers
        .filter((hitNumber) => now - hitNumber.id < 1000)
        .map((hitNumber) => ({
          ...hitNumber,
          progress: Math.min((now - hitNumber.id) / 1000, 1),
        }));

      if (
        updatedHitNumbers.length !== state.hitNumbers.length ||
        updatedHitNumbers.some(
          (hn, i) => hn.progress !== state.hitNumbers[i].progress
        )
      ) {
        return { hitNumbers: updatedHitNumbers };
      }
      return state;
    }),
}));

export default useHitNumbersStore;
