import { Box } from "@mui/material";
import { SmartImage } from "./SmartImage";
import { memo } from "react";
import { Small } from "./Typography";

export const UserChip = memo(({ user }) => {
  const isVIP = user?.passes?.vip;

  return (
    <Box
      sx={{
        position: "absolute",
        top: "-14px",
        left: isVIP ? "-20px" : "-16px",
      }}
    >
      <SmartImage
        src={
          isVIP ? "/images/icons/token_gold.webp" : "/images/icons/token.webp"
        }
        height={isVIP ? 54 : 50}
        width={isVIP ? 54 : 50}
        alt="Token"
      />
      <SmartImage
        src={`/heroes/${user?.selectedHero?.templateId}.webp`}
        height={40}
        width={40}
        alt="Hero"
        style={{
          position: "absolute",
          top: "4px",
          left: isVIP ? "6px" : "4px",
        }}
      />
      {isVIP && (
        <Small sx={{ position: "absolute", bottom: "6px", left: "20px" }}>
          VIP
        </Small>
      )}
    </Box>
  );
});

UserChip.displayName = "UserChip";
