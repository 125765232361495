import { motion } from "framer-motion";
import { useIntroState } from "@/game/logic/useIntroState";
import { useEffect } from "react";
import { useActiveAbilityStore } from "../logic/useActiveAbilityStore";
import { useAttackStore } from "../logic/useAttackStore";

export function Tutorial() {
  const { getHandPosition, setStep } = useIntroState();
  const { activeAbility } = useActiveAbilityStore();
  const { playerAttackCount } = useAttackStore();
  const handPosition = getHandPosition();

  useEffect(() => {
    if (playerAttackCount === 6) {
      setStep("BATTLE_TAP", false);
    }
    if (activeAbility) {
      setStep("BATTLE_ABILITY", false);
    }
  }, [playerAttackCount, activeAbility]);

  if (!handPosition) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: "none",
        zIndex: 10000,
      }}
    >
      <motion.div
        animate={{
          x: [0, 10, 0],
          y: [0, -10, 0],
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
        }}
        style={{
          position: "absolute",
          ...handPosition,
          width: 80,
          height: 80,
          backgroundImage: "url(/images/icons/hand.webp)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      />
    </motion.div>
  );
}
