import { H4, Small } from "@/components/Typography";
import {
  backgroundColor,
  borderColor,
  borderWidth,
  primary
} from "@/theme/colors";
import {
  MusicNote,
  MusicOff,
  VolumeOffTwoTone,
  VolumeUpTwoTone
} from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SimpleModal } from "@/components/SimpleModal";
import { useClickSound } from "@/hooks/useClickSound";
import { useMusic } from "@/hooks/useMusic";
import { useState } from "react";
import { DebugModal } from "./DebugModal";
import { useUserStore } from "../logic/useUserStore";
import { useAppStore } from "@/hooks/useAppStore";

export function SettingsModal({ open, onClose }) {
  const [debugOpen, setDebugOpen] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const { user, updateSettings } = useUserStore();
  const { playMainMusic, stopAllMusic } = useMusic();
  const { version } = useAppStore();
  const { t } = useTranslation();

  const playMusic = user?.settings?.playMusic ?? true;
  const playSounds = user?.settings?.playSounds ?? true;

  const handleMusicToggle = useClickSound(() => {
    const newPlayMusic = !playMusic;
    updateSettings({ playMusic: newPlayMusic });
    if (newPlayMusic) {
      playMainMusic();
    } else {
      stopAllMusic();
    }
  });

  const handleSoundsToggle = useClickSound(() => {
    updateSettings({ playSounds: !playSounds });
  });

  return (
    <>
      <SimpleModal open={open} onClose={onClose} title={t("settings.title")}>
        <Stack spacing={2} px={3}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="space-between"
          >
            <H4>{t("settings.music")}</H4>
            <IconButton
              onClick={handleMusicToggle}
              sx={{
                zIndex: 3,
                height: "40px",
                width: "40px",
                backgroundColor: backgroundColor,
                borderWidth: borderWidth,
                borderColor: borderColor,
                borderStyle: "solid",
              }}
            >
              {playMusic ? (
                <MusicNote sx={{ color: primary.white }} />
              ) : (
                <MusicOff sx={{ color: primary.white }} />
              )}
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="space-between"
          >
            <H4>{t("settings.sounds")}</H4>
            <IconButton
              onClick={handleSoundsToggle}
              sx={{
                zIndex: 3,
                height: "40px",
                width: "40px",
                backgroundColor: backgroundColor,
                borderWidth: borderWidth,
                borderColor: borderColor,
                borderStyle: "solid",
              }}
            >
              {playSounds ? (
                <VolumeUpTwoTone sx={{ color: primary.white }} />
              ) : (
                <VolumeOffTwoTone sx={{ color: primary.white }} />
              )}
            </IconButton>
          </Stack>
          {/* <Stack spacing={1} textAlign="center" alignItems="center">
            <Paragraph>Terms of Service</Paragraph>
            <Paragraph>Privacy Policy</Paragraph>
          </Stack> */}
          <Stack textAlign="center">
            <Small>{version}</Small>
          </Stack>
          <Box
            sx={{
              position: "absolute",
              bottom: "-18px",
              right: 0,
              width: "40px",
              height: "40px",
              backgroundColor: "transparent",
            }}
            onClick={() => {
              const handleClick = () => {
                setClickCount(clickCount + 1);
                if (clickCount === 4) {
                  setDebugOpen(true);
                  setClickCount(0);
                }
              };
              handleClick();
            }}
          />
        </Stack>
      </SimpleModal>

      <DebugModal open={debugOpen} onClose={() => setDebugOpen(false)} />
    </>
  );
}
