import { useEffect } from "react";
import { Character } from "../components/Character";
import { enemyDefeatedSound } from "@/utils/sounds";
import { usePreloadCharacters } from "@/hooks/usePreloadCharacters";
import { CDN_URL } from "@/constants";
import { useAttackStore } from "../logic/useAttackStore";
import { useEnemyStore } from "../logic/useEnemyStore";
import { useProgressStore } from "../logic/useProgressStore";
import { useBattleStore } from "../logic/useBattleStore";
import { useMusic } from "@/hooks/useMusic";

const SIZES = {
  enemy: 0.4,
  elite: 0.5,
  boss: 0.6,
};

export const Enemy = () => {
  const { characters } = usePreloadCharacters();
  const { battleEnded, battleSession } = useBattleStore();
  const { dungeon } = useProgressStore();
  const { enemy, setCharacter } = useEnemyStore();
  const { enemyAttackCount } = useAttackStore();
  const { playBattleMusic } = useMusic();

  const currentCheckpoint = dungeon.currentCheckpoint;
  const selectedCharacter = characters[currentCheckpoint];
  const enemyScale = SIZES[enemy?.type] || SIZES.enemy;

  useEffect(() => {
    setCharacter(selectedCharacter);
  }, [selectedCharacter, setCharacter]);

  useEffect(() => {
    if (enemy?.type && battleSession) {
      playBattleMusic(enemy.type);
    }
  }, [enemy?.type, battleSession, playBattleMusic]);

  if (!selectedCharacter || battleEnded || !battleSession) {
    return null;
  }

  return (
    <Character
      key={`character-${currentCheckpoint}-${selectedCharacter.path}`}
      path={`${CDN_URL}${selectedCharacter.path}`}
      x={window.innerWidth / 1.5}
      y={window.innerHeight / 1.8}
      hp={enemy?.hp}
      scale={enemyScale}
      skin="Front"
      attackCount={enemyAttackCount}
      defeatedSounds={[enemyDefeatedSound]}
      shadow
    />
  );
};
