import { H3 } from "@/components/Typography";
import { Stack } from "@mui/material";
import { motion } from "framer-motion";
import ConfettiExplosion from "react-confetti-explosion";
import { confettiProps } from "@/constants";
import { SmartImage } from "@/components/SmartImage";

// New ResourceStack component
const ResourceStack = ({
  src,
  alt,
  value,
  width = 80,
  height = 80,
  delay = 0.6,
  scope,
  imageSx,
}) => (
  <Stack
    direction="row"
    alignItems="center"
    component={motion.div}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3, delay }}
  >
    <SmartImage
      scope={scope}
      src={src}
      alt={alt}
      width={width}
      height={height}
      style={imageSx}
    />
    <H3>{value.toLocaleString()}</H3>
  </Stack>
);

export default function ResourceRewards({ rewards, fireConfetti }) {
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center" mb={2}>
        {fireConfetti && (
          <ConfettiExplosion
            {...confettiProps}
            zIndex={100000}
            style={{
              position: "absolute",
            }}
          />
        )}
        <ResourceStack
          src="/images/icons/gem.webp"
          alt="Gem"
          value={rewards.gems}
        />
        <ResourceStack
          src="/images/icons/rune.webp"
          alt="Rune"
          value={rewards.runes}
          scope="dark-modal"
        />
        <ResourceStack
          src="/images/icons/coin.webp"
          alt="Coin"
          value={rewards.gold}
          delay={0.7}
          scope="dark-modal"
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="center">
        {Object.entries(rewards.stones).map(
          ([element, amount]) =>
            amount > 0 && (
              <Stack
                key={element}
                direction="row"
                alignItems="center"
                component={motion.div}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.8 }}
                mr={2}
                mb={2}
              >
                <ResourceStack
                  src={`/images/stones/${element}.webp`}
                  alt={element}
                  value={amount}
                  width={50}
                  height={50}
                  imageSx={{ marginRight: "10px" }}
                />
              </Stack>
            )
        )}
      </Stack>
    </>
  );
}
