import { Box, Divider, Stack } from "@mui/material";
import { Paragraph } from "@/components/Typography";
import { SmartImage } from "@/components/SmartImage";
import { WhiteRadial } from "@/components/effects/WhiteRadial";
import { PriorityBadge } from "@/components/PriorityBadge";
import { useAppStore } from "@/hooks/useAppStore";

export function AtlasBottom({ tab, onTabClick }) {
  const { badges } = useAppStore();
  const { atlasBestiary, atlasArmory } = badges;
  return (
    <Stack
      flexShrink={0}
      direction="row"
      mt="auto"
      bgcolor="rgba(0,0,0,0.5)"
      height={60}
      zIndex={3}
    >
      <Stack
        flex={1}
        alignItems="center"
        position="relative"
        onClick={() => onTabClick(1)}
        bgcolor={tab === 1 ? "rgba(255,255,255,0.05)" : "transparent"}
        justifyContent="flex-end"
      >
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "70px",
            width: "70px",
            zIndex: 1,
            animation: tab === 1 ? "click-bounce 0.25s" : "none",
          }}
        >
          {atlasBestiary && <PriorityBadge size={20} sx={{ top: "5px" }} />}
          <SmartImage
            src="/images/icons/book.webp"
            width={100}
            height={100}
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        {tab === 1 && (
          <WhiteRadial
            size={40}
            sx={{ top: "50%", transform: "translateY(-50%)" }}
          />
        )}
        <Paragraph>Bestiary</Paragraph>
      </Stack>

      <Divider orientation="vertical" flexItem />

      <Stack
        flex={1}
        alignItems="center"
        position="relative"
        onClick={() => onTabClick(2)}
        bgcolor={tab === 2 ? "rgba(255,255,255,0.05)" : "transparent"}
        justifyContent="flex-end"
      >
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "70px",
            width: "70px",
            zIndex: 1,
            animation: tab === 2 ? "click-bounce 0.25s" : "none",
          }}
        >
          {atlasArmory && <PriorityBadge size={20} sx={{ top: "5px" }} />}
          <SmartImage
            src="/images/icons/swords.webp"
            width={100}
            height={100}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Box>
        {tab === 2 && (
          <WhiteRadial
            size={40}
            sx={{ top: "30%", transform: "translateY(-50%)" }}
          />
        )}
        <Paragraph>Armory</Paragraph>
      </Stack>
    </Stack>
  );
}
