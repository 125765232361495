import { useEffect, useRef, useState, useCallback } from "react";
import { Container, useTick } from "@pixi/react";
import { Spine } from "pixi-spine";
import * as PIXI from "pixi.js";
import useHitNumbersStore from "../../hooks/useHitNumbersStore";
import { getRandom, getRandomPunch } from "@/utils/sounds";
import { useSounds } from "@/hooks/useSound";
import { useVFX } from "@/hooks/useVFX";

export function Character({
  x,
  y,
  scale = 0.4,
  path,
  hp,
  flipX = false,
  skin = "Front", // Back | Front | Side | z | z2 | z3
  attackCount = 0,
  hurtSounds = [],
  defeatedSounds,
  shadow = false, // New prop for shadow
  isHero = false,
}) {
  const { playSound } = useSounds();
  const containerRef = useRef(null);
  const spineRef = useRef(null);
  const [availableAnimations, setAvailableAnimations] = useState([]);
  const isDead = useRef(false);
  const prevHp = useRef(hp);
  const { addHitNumber } = useHitNumbersStore();
  const [opacity, setOpacity] = useState(1);
  const fadeStartTime = useRef(null);
  const impactStartTime = useRef(null);
  const { triggerVFX } = useVFX();
  const prevAttackCount = useRef(attackCount);
  const shadowRef = useRef(null);
  const initialShadowScale = useRef(null);

  const idleAnim = isHero ? "Idle" : `${skin}_Idle`;
  const hurtAnim = isHero ? "Hurt" : `${skin}_Hurt`;
  const deadAnim = isHero ? "Death" : `${skin}_Death`;
  const attackAnim = isHero ? "Attack" : `${skin}_Attack`;
  // const walkAnim = `${skin}_Walk`;

  const loadSpine = useCallback(async () => {
    try {
      const spineData = await PIXI.Assets.load(path);
      const spine = new Spine(spineData.spineData);

      spine.x = 0;
      spine.y = 0;
      spine.scale.set(scale);
      spine.scale.x *= flipX ? -1 : 1;
      // Set the character's skin to the specified skin
      spine.skeleton.setSkin(null);
      spine.skeleton.setSkinByName(skin);

      if (containerRef.current) {
        containerRef.current.removeChildren();

        // Add shadow if enabled
        if (shadow) {
          const shadowGraphic = new PIXI.Graphics();
          shadowGraphic.beginFill(0x000000, 0.3);
          shadowGraphic.drawEllipse(0, 0, 100 * scale, 40 * scale);
          shadowGraphic.endFill();
          shadowGraphic.position.set(4, 10);
          containerRef.current.addChild(shadowGraphic);
          shadowRef.current = shadowGraphic;
          initialShadowScale.current = {
            x: shadowGraphic.scale.x,
            y: shadowGraphic.scale.y,
          };
        }

        containerRef.current.addChild(spine);
      }
      spineRef.current = spine;

      const animations = spine.spineData.animations.map((anim) => anim.name);
      setAvailableAnimations(animations);

      playIdleAnimation(spine, animations);

      spine.state.addListener({
        complete: (entry) => {
          if (entry.animation.name.includes(deadAnim)) {
            isDead.current = true;
          } else if (entry.animation.name !== idleAnim) {
            playIdleAnimation(spine, animations);
          }
        },
      });
    } catch (error) {
      console.error("Error loading Spine character:", error);
    }
  }, [path, scale, flipX, shadow]); // Add shadow to dependencies

  const playIdleAnimation = useCallback((spine, animations) => {
    if (!spine) return;
    const idleAnimation =
      animations.find((anim) => anim.includes(idleAnim)) || animations[0];
    if (idleAnimation) {
      spine.state.setAnimation(0, idleAnimation, true);
    } else {
      console.warn("No idle animation found");
    }
  }, []);

  const playAnimation = useCallback(
    (animName, loop = false) => {
      if (!spineRef.current || isDead.current) return;

      if (spineRef.current.state.hasAnimation(animName)) {
        spineRef.current.state.setAnimation(0, animName, loop);
        if (animName.includes(deadAnim)) {
          playSound(getRandomPunch());
          playSound(getRandom(defeatedSounds));
          fadeStartTime.current = Date.now();
        }
        if (animName.includes(hurtAnim)) {
          playSound(getRandomPunch());
          playSound(getRandom(hurtSounds));
          impactStartTime.current = Date.now();
        }
      } else {
        console.warn(
          `Animation "${animName}" not found. Playing default animation.`
        );
        playIdleAnimation(spineRef.current, availableAnimations);
      }
    },
    [availableAnimations, playIdleAnimation]
  );

  useTick(() => {
    if (fadeStartTime.current && spineRef.current) {
      const elapsedTime = (Date.now() - fadeStartTime.current) / 1000; // Convert to seconds
      const fadeDuration = 0.9; // Adjust this value to change fade duration (in seconds)
      const newOpacity = Math.max(0, 1 - elapsedTime / fadeDuration);

      setOpacity(newOpacity);
      spineRef.current.alpha = newOpacity;

      if (newOpacity <= 0) {
        fadeStartTime.current = null;
      }
    }

    if (impactStartTime.current && spineRef.current) {
      const elapsedTime = (Date.now() - impactStartTime.current) / 1000;
      const impactDuration = 0.2; // Duration of the impact effect in seconds
      const flickerFrequency = 30; // Higher values make it flicker faster

      if (elapsedTime < impactDuration) {
        const flickerValue =
          Math.sin(elapsedTime * flickerFrequency * Math.PI) * 0.5 + 0.5;

        // Apply tint to the Spine object
        const tintColor = PIXI.utils.rgb2hex([
          1,
          1 - flickerValue * 0.5,
          1 - flickerValue * 0.5,
        ]);
        spineRef.current.tint = tintColor;
      } else {
        spineRef.current.tint = 0xffffff; // Reset tint to normal
        impactStartTime.current = null;
      }
    }

    // Animate shadow
    if (shadowRef.current && !isDead.current && shadow) {
      const time = Date.now() / 1000; // Current time in seconds
      const scaleOffset = Math.sin(time * 2) * 0.05; // Adjust frequency and amplitude as needed
      shadowRef.current.scale.set(
        initialShadowScale.current.x * (1 + scaleOffset),
        initialShadowScale.current.y * (1 + scaleOffset)
      );
    }
  });

  useEffect(() => {
    loadSpine();
  }, [loadSpine]);

  useEffect(() => {
    if (spineRef.current) {
      spineRef.current.skeleton.setSkinByName(skin);
    }
  }, [skin]);

  useEffect(() => {
    if (hp <= 0 && !isDead.current) {
      const deathAnim =
        availableAnimations.find((anim) => anim.includes(deadAnim)) || deadAnim;
      playAnimation(deathAnim, false);
      addHitNumber(prevHp.current - hp, x, y - 120);
      triggerVFX("lightning", x, y - 100);
      triggerVFX("smoke", x, y - 50);
      isDead.current = true;
    } else if (hp < prevHp.current && !isDead.current) {
      const hitAnim =
        availableAnimations.find((anim) => anim.includes(hurtAnim)) || hurtAnim;
      playAnimation(hitAnim, false);
      addHitNumber(prevHp.current - hp, x, y - 120);
      triggerVFX("lightning", x, y - 100);
    }
    prevHp.current = hp;
  }, [hp, availableAnimations]);

  useEffect(() => {
    if (attackCount > prevAttackCount.current) {
      const attackAnimation =
        availableAnimations.find((anim) => anim.includes(attackAnim)) ||
        attackAnim;
      playAnimation(attackAnimation, false);
      prevAttackCount.current = attackCount;
    }
  }, [attackCount, availableAnimations]);

  return <Container ref={containerRef} x={x} y={y} alpha={opacity}></Container>;
}
