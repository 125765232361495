import { Box, Stack } from "@mui/material";
import { borderRadiusNew } from "@/theme/colors";
import { H2 } from "@/components/Typography";
import { AtlasMonsterItem } from "./AtlasMonsterItem";
import { useState } from "react";
import { AtlasMonsterModal } from "./AtlasMonsterModal";

export function AtlasBestiary({ tab, enemies, playerAchievements, onClaim }) {
  const [selectedMonster, setSelectedMonster] = useState(null);
  const groupedEnemies = enemies.reduce((acc, enemy) => {
    const world =
      enemy.worlds && enemy.worlds.length > 0 ? enemy.worlds[0] : "undefined";
    if (!acc[world]) {
      acc[world] = [];
    }
    const achievementData =
      playerAchievements.enemies && playerAchievements.enemies[enemy.id];
    const isAchieved = achievementData?.achieved;
    const isClaimed = achievementData?.claimed;
    acc[world].push({ ...enemy, isAchieved, isClaimed });
    return acc;
  }, {});

  const worlds = Object.keys(groupedEnemies).sort((a, b) => {
    if (a === "undefined") return 1;
    if (b === "undefined") return -1;
    return parseInt(a) - parseInt(b);
  });

  return (
    <>
      <Box
        sx={{
          animation: tab === 1 ? "slide-up 0.15s ease-out" : "none",
        }}
      >
        {worlds.map((world) => (
          <Stack
            key={world}
            mb={2}
            sx={{
              borderRadius: borderRadiusNew,
              backgroundColor: "rgba(0,0,0,0.1)",
              padding: 1,
            }}
          >
            <H2
              sx={{
                mt: 2,
                mb: 1,
                textAlign: "center",
              }}
            >
              {world === "undefined" ? "World Undefined" : `World ${world}`}
            </H2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: 2,
                }}
              >
                {groupedEnemies[world].map((enemy) => (
                  <AtlasMonsterItem
                    key={enemy.id}
                    monster={enemy}
                    isAchieved={enemy.isAchieved}
                    isClaimed={enemy.isClaimed}
                    onClick={() => {
                      if (enemy.isClaimed) {
                        setSelectedMonster(enemy);
                      } else {
                        onClaim(enemy);
                      }
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Stack>
        ))}
      </Box>

      <AtlasMonsterModal
        selectedMonster={selectedMonster}
        setSelectedMonster={setSelectedMonster}
      />
    </>
  );
}
