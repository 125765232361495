import { useCallback } from "react";
import { useUserStore } from "@/game/logic/useUserStore";
import { getUser } from "@/lib/api";

export const useRefreshUser = () => {
  const { user, setUser } = useUserStore();

  return useCallback(async () => {
    try {
      const response = await getUser({ userId: user.id, jwt: user.jwt });
      if (response.data) {
        setUser({ ...user, ...response.data });
        return true; // Indicate success
      }
      return false; // Indicate failure if no data received
    } catch (error) {
      console.error("Error fetching user data:", error);
      return false; // Indicate failure
    }
  }, [user, setUser]);
};
