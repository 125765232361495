import { useClickSound } from "@/hooks/useClickSound";
import { backgroundColorHex } from "@/theme/colors";
import { PriorityHigh } from "@mui/icons-material";
import { alpha, Box } from "@mui/material";

export function InfoIcon({ onClick, sx }) {
  const handleClick = useClickSound(onClick);
  return (
    <Box
      sx={{
        width: 22,
        height: 22,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: alpha(backgroundColorHex, 0.7),
        position: "absolute",
        border: "1px solid black",
        boxShadow: "0px 1px 0px #000000",
        top: 0,
        left: 0,
        cursor: "pointer",
        zIndex: 10,
        ...sx,
      }}
      onClick={handleClick}
    >
      <PriorityHigh
        sx={{
          transform: "rotate(180deg)",
          fontSize: 22,
          color: "white",
        }}
      />
    </Box>
  );
}
