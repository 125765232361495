import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CustomTheme } from "./theme";
import { SDKProvider } from "@telegram-apps/sdk-react";
import { AlertProvider } from "./providers/AlertProvider";
import { Root } from "./components/Root";
import { useEffect } from "react";
import * as PIXI from "pixi.js";
import "@/styles/fonts.css";
import "@/theme/style.css";
import "@/styles/animations.css";
import Index from "./pages";

function App() {
  console.log("👉  import.meta.env.MODE:", import.meta.env.MODE);
  if (import.meta.env.PROD) {
    console.log = function () {};
  }
  if (import.meta.env.VITE_ENABLE_ERUDA === "true") {
    console.log("👉  init eruda");
    import("eruda").then((eruda) => eruda.default.init());
  }

  useEffect(() => {
    // Global PIXI.js configurations
    PIXI.BaseTexture.defaultOptions.scaleMode = PIXI.SCALE_MODES.NEAREST;
    PIXI.settings.ROUND_PIXELS = true;

    // Set default eventMode for all DisplayObjects
    PIXI.DisplayObject.defaultEventMode = "auto";

    // If you need to add any custom PIXI loaders or plugins
    // PIXI.Loader.shared.add(...);
    // PIXI.utils.skipHello(); // Suppress PIXI console banner

    // Custom error handler
    const originalWarn = PIXI.utils.deprecation.warn;
    PIXI.utils.deprecation.warn = (msg) => {
      console.log("👉  msg:", msg);
      if (!msg.includes("rgb2hex")) {
        originalWarn(msg);
      }
    };
  }, []);

  return (
    <CustomTheme>
      <SDKProvider>
        <AlertProvider>
          <Router>
            <Root>
              <Routes>
                <Route path="/" element={<Index />} />
                {/* Add other routes as needed */}
              </Routes>
            </Root>
          </Router>
        </AlertProvider>
      </SDKProvider>
    </CustomTheme>
  );
}

export default App;
