import { Box, LinearProgress } from "@mui/material";
import { Paragraph } from "../components/Typography";
import { SmartImage } from "@/components/SmartImage";
import { memo } from "react";
import { usePlayerStore } from "@/game/logic/usePlayerStore";
import { usePotionStore } from "@/game/logic/usePotionStore";

const getHealthBarStyle = (type) => {
  switch (type) {
    case "player":
      return {
        height: 22,
        borderRadius: 1,
        transform: "skew(-20deg)",
        backgroundColor: "#1a3a1a", // Dark green background
        "& .MuiLinearProgress-bar": {
          backgroundColor: "#4caf50", // Brighter green for health
          transform: "skew(20deg)",
          backgroundImage:
            "linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)",
          backgroundSize: "40px 40px",
        },
      };
    case "elite":
      return {
        height: 24,
        borderRadius: 1,
        transform: "skew(-22deg)",
        backgroundColor: "#8b0000", // red background
        boxShadow: "0 0 16px #ff0000", // red glow
        "& .MuiLinearProgress-bar": {
          backgroundColor: "#A41D1D", // red
          transform: "skew(22deg)",
          backgroundImage:
            "linear-gradient(45deg, rgba(255,255,255,0.2) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%, transparent 75%, transparent)",
          backgroundSize: "30px 30px",
        },
      };
    case "boss":
      return {
        height: 32,
        borderRadius: 2,
        transform: "skew(-25deg)",
        backgroundColor: "#3d0000", // Very dark red background
        boxShadow: "0 0 20px red", // Added gold glow
        border: "1px solid red", // Changed to gold border and increased width
        "& .MuiLinearProgress-bar": {
          backgroundColor: "#8b0000", // Dark red for boss
          transform: "skew(25deg)",
          backgroundImage:
            "repeating-linear-gradient(45deg, rgba(255,255,255,0.1) 0px, rgba(255,255,255,0.1) 20px, transparent 20px, transparent 40px)",
        },
      };
    default: // regular enemy
      return {
        height: 18,
        borderRadius: 1,
        transform: "skew(-15deg)",
        backgroundColor: "#2e2e2e", // Dark gray background
        "& .MuiLinearProgress-bar": {
          backgroundColor: "#8a2be2", // Blue-violet for regular enemies
          transform: "skew(15deg)",
          backgroundImage:
            "linear-gradient(45deg, rgba(255,255,255,0.2) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%, transparent 75%, transparent)",
          backgroundSize: "30px 30px",
        },
      };
  }
};

export const HealthBar = memo(({ type, name, currentHp, maxHp, isBuffed }) => {
  const progress = (currentHp / maxHp) * 100;

  const progressBarWidth =
    (type === "player" && "100%") ||
    (type === "boss" && "100%") ||
    (type === "elite" && "85%") ||
    "80%";

  return (
    <Box
      sx={{
        position: "relative",
        width: progressBarWidth,
        margin: "0 auto",
        mt: 1,
        animation: isBuffed ? "health-buff 0.5s ease-in-out" : "none",
      }}
    >
      {(type === "elite" || type === "boss") && (
        <SmartImage
          src={`/images/icons/${type}.webp`}
          height={type === "elite" ? 50 : 60}
          width={type === "elite" ? 50 : 60}
          alt={type}
          style={{
            position: "absolute",
            top: "-30px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
          }}
        />
      )}
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={getHealthBarStyle(type)}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <Paragraph
          sx={{
            color: "common.white",
            textShadow: "1px 2px 0 #000000",
            fontSize: 14,
          }}
        >
          {name}
        </Paragraph>
        <Paragraph
          sx={{
            color: "common.white",
            textShadow: "1px 2px 0 #000000",
          }}
        >
          {currentHp.toFixed(0)} HP
        </Paragraph>
      </Box>
    </Box>
  );
});

HealthBar.displayName = "HealthBar";

export const HealthBarPlayer = memo(() => {
  const { player } = usePlayerStore();
  const { healthBuffActive } = usePotionStore();

  return (
    <HealthBar
      type="player"
      currentHp={player.hp}
      maxHp={player.maxHp}
      isBuffed={healthBuffActive}
    />
  );
});

HealthBarPlayer.displayName = "HealthBarPlayer";
