import { Paragraph } from "@/components/Typography";
import { Box } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";

export function HeroLevel({ level, maxxedOut, sx, size = "S" }) {
  const sizeMap = {
    S: { size: 30, fontSize: "14px" },
    M: { size: 40, fontSize: "18px" },
    L: { size: 50, fontSize: "22px" },
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: sizeMap[size].size,
        height: sizeMap[size].size,
        marginLeft: "-2px",
        zIndex: 1,
        ...sx,
      }}
    >
      <SmartImage
        src={
          maxxedOut
            ? "/images/badges/hero_max.webp"
            : "/images/badges/hero.webp"
        }
        alt="hero"
        width={sizeMap[size].size}
        height={sizeMap[size].size}
      />
      <Paragraph
        sx={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          top: "20%",
          transform: "translateX(-50%)",
          color: "white",
          fontWeight: "bold",
          fontSize: sizeMap[size].fontSize,
        }}
      >
        {level || 1}
      </Paragraph>
    </Box>
  );
}
