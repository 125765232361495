import { Howl } from "howler";
import { CDN_URL } from "../constants";

export const sound = new Howl({
  src: [`${CDN_URL}/audio/theme.mp3`],
  loop: true,
  autoplay: false,
  volume: 0.2,
});

export const battleMusic = new Howl({
  src: [`${CDN_URL}/audio/battle.mp3`],
  loop: true,
  autoplay: false,
  volume: 0.2,
  preload: false,
});

export const battleEliteMusic = new Howl({
  src: [`${CDN_URL}/audio/battle_elite.mp3`],
  loop: true,
  autoplay: false,
  volume: 0.4,
  preload: false,
});

export const battleBossMusic = new Howl({
  src: [`${CDN_URL}/audio/battle_boss.mp3`],
  loop: true,
  autoplay: false,
  volume: 0.3,
  preload: false,
});

export const fairySound = new Howl({
  src: [`${CDN_URL}/audio/fairy_sound.mp3`],
  loop: false,
  volume: 0.2,
});

export const purchaseSound = new Howl({
  src: [`${CDN_URL}/audio/purchase.mp3`],
  volume: 0.3,
});

export const newWorldSound = new Howl({
  src: [`${CDN_URL}/audio/new-world.mp3`],
  loop: false,
  volume: 0.7,
});

export const magicChorusSound = new Howl({
  src: [`${CDN_URL}/audio/magic_chorus4.mp3`],
  loop: true,
  volume: 0.2,
});

export const chestOpenSound = new Howl({
  src: [`${CDN_URL}/audio/chest-open-1.mp3`],
  loop: false,
  volume: 0.2,
});

export const clickSound = new Howl({
  src: [`${CDN_URL}/audio/click-1.wav`],
});

export const clickReleaseSound = new Howl({
  src: [`${CDN_URL}/audio/click-2.wav`],
});

export const coinSound = new Howl({
  src: [`${CDN_URL}/audio/coin-2.wav`],
  volume: 0.3,
});

export const countdownSound = new Howl({
  src: [`${CDN_URL}/audio/countdown-3s.mp3`],
  volume: 0.7,
});

export const coinClaimSound = new Howl({
  src: [`${CDN_URL}/audio/coin-claim.wav`],
  volume: 0.5,
});

export const closeSound = new Howl({
  src: [`${CDN_URL}/audio/close.wav`],
  volume: 0.5,
});

export const enemySound = new Howl({
  src: [`${CDN_URL}/audio/enemy.mp3`],
});

export const levelUpSound = new Howl({
  src: [`${CDN_URL}/audio/bonus.mp3`],
  volume: 0.7,
});

export const coinBagSound = new Howl({
  src: [`${CDN_URL}/audio/coin-bag.mp3`],
  volume: 0.7,
});

export const notPossibleSound = new Howl({
  src: [`${CDN_URL}/audio/tip.mp3`],
  volume: 0.7,
});

export const equipAttackSound = new Howl({
  src: [`${CDN_URL}/audio/equip-1.mp3`],
  volume: 0.3,
});

export const equipDefenseSound = new Howl({
  src: [`${CDN_URL}/audio/equip-2.mp3`],
  volume: 0.3,
});

export const equipSpecialSound = new Howl({
  src: [`${CDN_URL}/audio/equip-3.mp3`],
  volume: 0.3,
});

export const punch1Sound = new Howl({
  src: [`${CDN_URL}/audio/Punch_1.wav`],
  volume: 0.3,
});
export const punch2Sound = new Howl({
  src: [`${CDN_URL}/audio/Punch_2.wav`],
  volume: 0.3,
});
export const punch3Sound = new Howl({
  src: [`${CDN_URL}/audio/Punch_3.wav`],
  volume: 0.3,
});
export const punch1SwooshSound = new Howl({
  src: [`${CDN_URL}/audio/Swoosh_Punch_1.wav`],
  volume: 0.3,
});
export const punch2SwooshSound = new Howl({
  src: [`${CDN_URL}/audio/Swoosh_Punch_2.wav`],
  volume: 0.3,
});
export const punch3SwooshSound = new Howl({
  src: [`${CDN_URL}/audio/Swoosh_Punch_3.wav`],
  volume: 0.3,
});

export const slimeHurt1 = new Howl({
  src: [`${CDN_URL}/audio/slime-hurt-1.wav`],
  volume: 0.3,
});
export const slimeHurt2 = new Howl({
  src: [`${CDN_URL}/audio/slime-hurt-2.wav`],
  volume: 0.3,
});
export const slimeHurt3 = new Howl({
  src: [`${CDN_URL}/audio/slime-hurt-3.wav`],
  volume: 0.3,
});
export const slimeDefeated = new Howl({
  src: [`${CDN_URL}/audio/slime-death.wav`],
  volume: 0.3,
});

export const failSound = new Howl({
  src: [`${CDN_URL}/audio/fail.mp3`],
  volume: 0.4,
});

export const ability_1 = new Howl({
  src: [`${CDN_URL}/audio/ability_1.wav`],
  volume: 0.4,
});

export const ability_V1 = new Howl({
  src: [`${CDN_URL}/audio/ability_V1.wav`],
  volume: 0.4,
});

export const ability_V3 = new Howl({
  src: [`${CDN_URL}/audio/ability_V3.mp3`],
  volume: 0.4,
});

export const ability_V4 = new Howl({
  src: [`${CDN_URL}/audio/ability_V4.mp3`],
  volume: 0.4,
});

export const ability_V5 = new Howl({
  src: [`${CDN_URL}/audio/ability_V5.mp3`],
  volume: 0.4,
});

export const playerWinsSound = new Howl({
  src: [`${CDN_URL}/audio/player-wins.mp3`],
  volume: 0.7,
});

export const reviveSound = new Howl({
  src: [`${CDN_URL}/audio/revive.wav`],
  volume: 0.7,
});

export const claimSound = new Howl({
  src: [`${CDN_URL}/audio/claim.wav`],
  volume: 0.2,
});

export const betSound = new Howl({
  src: [`${CDN_URL}/audio/bet.wav`],
  volume: 0.2,
});

export const wonSound = new Howl({
  src: [`${CDN_URL}/audio/50.wav`],
  volume: 0.35,
});

export const won2xSound = new Howl({
  src: [`${CDN_URL}/audio/200.wav`],
  volume: 0.25,
});

export const collectSound = new Howl({
  src: [`${CDN_URL}/audio/collect.wav`],
  volume: 0.35,
});

export const fanfareHeroSound = new Howl({
  src: [`${CDN_URL}/audio/fanfare_hero.mp3`],
  volume: 0.5,
});

export const fanfareItemSound = new Howl({
  src: [`${CDN_URL}/audio/fanfare_item.mp3`],
  volume: 0.5,
});

export const lostSound = new Howl({
  src: [`${CDN_URL}/audio/100-lost.wav`],
  volume: 0.35,
});

export const upgradeSound = new Howl({
  src: [`${CDN_URL}/audio/upgrade.mp3`],
  volume: 0.15,
});

export const enemyDefeatedSound = new Howl({
  src: [`${CDN_URL}/audio/enemy-defeated.mp3`],
  volume: 0.2,
});

export const slimeHurtSounds = [slimeHurt1, slimeHurt2, slimeHurt3];

export const punchSounds = [
  punch1Sound,
  punch2Sound,
  punch3Sound,
  punch1SwooshSound,
  punch2SwooshSound,
  punch3SwooshSound,
];

export function getRandomPunch() {
  return punchSounds[Math.floor(Math.random() * punchSounds.length)];
}

export function getRandom(items) {
  return items[Math.floor(Math.random() * items.length)];
}

export const potionHealthSound = new Howl({
  src: [`${CDN_URL}/audio/potion_health.mp3`],
  volume: 0.5,
});

export const potionEnergySound = new Howl({
  src: [`${CDN_URL}/audio/potion_energy.mp3`],
  volume: 0.5,
});

export const potionDamageSound = new Howl({
  src: [`${CDN_URL}/audio/potion_damage.mp3`],
  volume: 0.5,
});
