import { memo } from "react";
import {
  primary,
  error,
  borderWidth,
  backgroundColorSecondary,
  backgroundColorHex,
} from "@/theme/colors";
import { Stack } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import { Paragraph } from "./Typography";

const sizeConfig = {
  S: {
    padding: "0px 4px 0px 10px",
    imageSize: 46,
    imageTop: "-12px",
    imageLeft: "-12px",
    fontSize: "0.9em",
    marginLeft: 4,
    contentWidth: "42px",
  },
  M: {
    padding: "2px 8px",
    imageSize: 56,
    imageTop: "-14px",
    imageLeft: "-6px",
    fontSize: "1.2em",
    marginLeft: 5,
    contentWidth: "50px",
  },
  L: {
    padding: "4px 10px",
    imageSize: 66,
    imageTop: "-16px",
    imageLeft: "-8px",
    fontSize: "1.5em",
    marginLeft: 6,
    contentWidth: "70px",
  },
};

export const IconNumber = memo(
  ({
    imgSrc,
    value,
    base,
    onClick,
    icon,
    sx,
    rounded = true,
    size = "S",
    textColor = primary.white,
  }) => {
    const {
      padding,
      imageSize,
      imageTop,
      imageLeft,
      fontSize,
      marginLeft,
      contentWidth,
    } = sizeConfig[size];

    const borderRadius = "24px";

    return (
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          background: `linear-gradient(to top, ${backgroundColorHex}, ${backgroundColorSecondary})`,
          borderWidth: borderWidth,
          borderColor: backgroundColorHex,
          borderStyle: "solid",
          borderRadius: rounded ? borderRadius : 0,
          zIndex: 2,
          padding: padding,
          position: "relative",
          margin: "4px 4px",
          height: "fit-content",
          ...sx,
          pointerEvents: onClick ? "auto" : "none",
        }}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.(e);
        }}
      >
        {icon && icon}
        {imgSrc && (
          <SmartImage
            src={imgSrc}
            height={imageSize}
            width={imageSize}
            alt="Icon"
            style={{
              position: "absolute",
              top: imageTop,
              left: imageLeft,
              zIndex: 1,
            }}
          />
        )}

        <Stack
          direction="row"
          sx={{
            padding: "1px",
            width: contentWidth,
            justifyContent: "flex-end",
            position: "relative",
          }}
        >
          <Paragraph
            ml={!icon && !imgSrc ? 0 : marginLeft}
            sx={{
              color: value < 1 ? error.main : textColor || primary.white,
              fontSize: fontSize,
            }}
          >
            {value}
          </Paragraph>
          {base && (
            <Paragraph
              sx={{
                fontSize: fontSize,
              }}
            >
              /{base}
            </Paragraph>
          )}
        </Stack>
      </Stack>
    );
  }
);

IconNumber.displayName = "IconNumber";
