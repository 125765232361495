import { create } from "zustand";
import { useAssetStore } from "./useAssetStore";

const initialState = {
  world: null,
  dungeon: null,
};

export const useProgressStore = create((set, get) => ({
  ...initialState,

  setWorld: (world) => set({ world }),
  setDungeon: (dungeon) => set({ dungeon }),

  initializeProgress: (userData) => {
    const assetStore = useAssetStore.getState();
    const newState = {};

    // Set current dungeon
    if (
      userData.progress?.dungeon &&
      assetStore.findDungeon(userData.progress?.dungeon)
    ) {
      newState.dungeon = assetStore.findDungeon(userData.progress.dungeon);
    } else if (assetStore.LEVELS.length > 0) {
      newState.dungeon = assetStore.LEVELS[0];
    }

    // Set current world
    if (
      userData.progress?.world &&
      assetStore.findWorld(userData.progress?.world)
    ) {
      newState.world = assetStore.findWorld(userData.progress.world);
    } else if (assetStore.WORLDS.length > 0) {
      newState.world = assetStore.WORLDS[0];
    }

    set(newState);
  },

  changeWorld: (direction) => {
    const { WORLDS } = useAssetStore.getState();
    const currentWorldIndex = WORLDS.findIndex((w) => w.id === get().world.id);

    let newIndex;
    if (direction === "left") {
      newIndex = (currentWorldIndex - 1 + WORLDS.length) % WORLDS.length;
    } else {
      newIndex = (currentWorldIndex + 1) % WORLDS.length;
    }

    set({ world: WORLDS[newIndex] });
  },

  updateDungeonCheckpoint: (nextCheckpointIndex) => {
    set((state) => ({
      dungeon: { ...state.dungeon, currentCheckpoint: nextCheckpointIndex },
    }));
  },

  resetDungeon: () => {
    set((state) => ({
      dungeon: { ...state.dungeon, currentCheckpoint: 0 },
    }));
  },

  reset: () => set(initialState),
}));
