import { SimpleModal } from "../../components/SimpleModal";
import { Box, Stack } from "@mui/material";
import { H5, Paragraph } from "../../components/Typography";
import { SimpleButton } from "../../components/SimpleButton";
import { SmartImage } from "@/components/SmartImage";
import { SlowStarEffect } from "@/components/effects/SlowStarEffect";
import { useTranslation } from "react-i18next";

export function PurchaseConfirmModal({ open, onClose, onConfirm, item }) {
  const { t } = useTranslation();

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={t("store.confirm")}
      modalSx={{ background: item.background }}
      titleSx={{ background: item.background }}
    >
      <Stack
        spacing={0}
        textAlign="center"
        alignItems="center"
        position="relative"
      >
        <Box sx={{ position: "absolute", height: 120, width: 120 }}>
          <SmartImage
            scope="modal"
            src="/images/effects/burst_1.webp"
            alt="Background"
            height={100}
            width={100}
            style={{
              position: "absolute",
              top: 0,
              left: "-20%",
              transform: "translateY(-50%)",
              width: "140%",
              height: "auto",
              animation: "rotateImage 20s linear infinite",
              zIndex: -1,
            }}
          />
        </Box>
        <SmartImage
          src={item.image}
          alt={item.name}
          width={200}
          height={200}
          style={{ margin: "0 auto", width: 160, height: 160 }}
        />
        <SlowStarEffect
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "0 auto",
            width: 160,
            height: 160,
          }}
        />

        {["gems", "runes", "pack"].includes(item?.type) && (
          <>
            <Stack mb={2} spacing={1}>
              <Paragraph>{t("store.buy", { title: item.title })}</Paragraph>
              <H5>= ${item.price}</H5>
            </Stack>
            <SimpleButton
              color="green"
              onClick={onConfirm}
              image="/images/icons/tg_star.webp"
            >
              {item.stars}
            </SimpleButton>
          </>
        )}

        {["chest", "hero", "gold", "potion"].includes(item.type) && (
          <>
            <Stack mb={2} spacing={1}>
              <Paragraph>
                {t("store.buy", { title: item.title || item.name })}
              </Paragraph>
            </Stack>
            <SimpleButton
              color="green"
              onClick={onConfirm}
              image={item.price > 0 && "/images/icons/gem.webp"}
            >
              {item.price === 0 ? "FREE" : item.price}
            </SimpleButton>
          </>
        )}
      </Stack>
    </SimpleModal>
  );
}
