import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useAlert } from "@/hooks/useAlert";
import { StarEffect } from "@/components/effects/StarEffect";
import {
  coinBagSound,
  lostSound,
  playerWinsSound,
  purchaseSound,
} from "@/utils/sounds";
import { useBattleStore } from "../logic/useBattleStore";
import { OpenChestModal } from "../parts/OpenChestModal";
import { useRefreshUser } from "@/hooks/useRefreshUser";
import { SimpleButton } from "@/components/SimpleButton";
import { SimpleModal } from "@/components/SimpleModal";
import { primary } from "@/theme/colors";
import { SmartImage } from "@/components/SmartImage";
import { useSounds } from "@/hooks/useSound";
import { BurstImage } from "@/components/effects/BurstImage";
import { H4, Paragraph, Small } from "@/components/Typography";
import { LTOModal } from "../store/LTOModal";
import { getVIPOffer } from "@/utils/store";
import { getChestLevel } from "@/utils/chests";
import { useIntroState } from "../logic/useIntroState";
import { useUserStore } from "../logic/useUserStore";
import { useAssetStore } from "../logic/useAssetStore";
import { useChestStore } from "../logic/useChestStore";
import { usePlayerStore } from "../logic/usePlayerStore";
import { useLootStore } from "../logic/useLootStore";
import { useProgressStore } from "../logic/useProgressStore";
import { rewardsActions } from "../actions/rewardsActions";
import { combatActions } from "../actions/combatActions";

export function BattleResultModal() {
  const { t } = useTranslation();
  const { setError } = useAlert();
  const { dungeonCompleted, playerLost } = useBattleStore();
  const { dungeon } = useProgressStore();
  const { accumulatedLoot, give2xGold } = useLootStore();
  const { player } = usePlayerStore();
  const { chestHash } = useChestStore();
  const { SHOP_ITEMS, CYCLES } = useAssetStore();
  const { user } = useUserStore();
  const [openChestModal, setOpenChestModal] = useState(false);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { playSound } = useSounds();
  const refreshUser = useRefreshUser();
  const { setStep, ongoingTutorial } = useIntroState();

  const invokeModal = dungeonCompleted || playerLost;
  const isVIP = user?.passes?.vip;
  const vipOffer = getVIPOffer(SHOP_ITEMS);
  const show2x = !ongoingTutorial && !isVIP;

  const outcome = dungeonCompleted ? "won" : player.hp <= 0 ? "lost" : null;
  // const chestLevel = dungeonCompleted ? dungeon.chestLevel : null;
  const chestLevel = dungeonCompleted
    ? getChestLevel(CYCLES, dungeon.id, user?.progress?.cycle)
    : null;

  function handle2xClick() {
    setOpenOfferModal(true);
  }

  function handleVIPPurchase() {
    playSound(purchaseSound);
    give2xGold();
  }

  function handleClick(e) {
    if (chestLevel) {
      setOpenChestModal(true);
    } else {
      handleClaim(e);
    }
  }

  async function handleClaim(e) {
    try {
      e.stopPropagation();
      if (ongoingTutorial) {
        setStep("BATTLE_CLAIM", false);
        setTimeout(() => {
          setStep("HOME_UPGRADE");
        }, 1000);
      }
      setShowModal(false);
      setOpenChestModal(false);
      await rewardsActions.claimRewards();
      await refreshUser();
      combatActions.endBattle();
    } catch (error) {
      console.error("Error claiming rewards:", error);
      setError(t("errors.claimingRewardsFailed"));
    }
  }

  useEffect(() => {
    if (outcome === "won") {
      playSound(playerWinsSound);
      playSound(coinBagSound);
    } else if (outcome === "lost") {
      setTimeout(() => {
        playSound(lostSound);
      }, 2000);
    }
  }, [outcome]);

  useEffect(() => {
    if (invokeModal) {
      setShowModal(true);
      if (ongoingTutorial) {
        setStep("BATTLE_CLAIM");
      }
    } else {
      setTimeout(() => {
        setShowModal(false);
      }, 500);
    }
  }, [invokeModal]);

  if (!showModal) return null;

  return (
    <>
      <SimpleModal
        open={showModal}
        title={
          outcome === "won" ? t("reward.victoryTitle") : t("reward.defeatTitle")
        }
        titleBgColor={outcome === "won" ? primary.green : primary.red}
        titleSx={{ zIndex: 2 }}
        mainModalSx={{
          backgroundColor: "rgba(0,0,0,0.9)",
        }}
      >
        <Stack
          sx={{
            alignItems: "center",
            zIndex: 1,
          }}
        >
          {/* Loot display */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            style={{
              zIndex: 1,
              textAlign: "center",
            }}
          >
            <Paragraph mb={1}>{t("reward.loot")}</Paragraph>

            {isVIP && (
              <Paragraph color="primary.main">
                {t("reward.doubleGold")}
              </Paragraph>
            )}
          </motion.div>

          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.35 }}
            zIndex={1}
            mt={1}
          >
            <Stack direction="row" alignItems="center">
              <SmartImage
                src="/images/icons/coin.webp"
                width={60}
                height={60}
              />
              <H4>{accumulatedLoot.gold?.toLocaleString()}</H4>
            </Stack>

            <Stack direction="row" alignItems="center">
              <SmartImage src="/images/icons/xp.webp" width={60} height={60} />
              <H4>{(accumulatedLoot.xp || 0)?.toLocaleString()}</H4>
            </Stack>
          </Stack>

          {chestLevel && (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
              margin="0 auto"
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.45 }}
              position="relative"
              sx={{ width: "100px", height: "100px" }}
            >
              <SmartImage
                scope="modal"
                src={`/images/chests/${chestLevel}.webp`}
                width={150}
                height={150}
                alt="chest"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  width: "120px",
                  height: "auto",
                  margin: "0 auto",
                  top: "40%",
                  left: "55%",
                  transform: "translate(-50%, -50%)",
                }}
              />
              <BurstImage />
            </Stack>
          )}
        </Stack>

        {/* Claim button */}
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="center"
          spacing={3}
          component={motion.div}
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3, delay: 0.5 }}
          mt={2}
          sx={{ position: "relative", zIndex: 1 }}
        >
          {show2x && (
            <Stack
              direction="column"
              spacing={1}
              alignItems="center"
              zIndex={3}
            >
              <Small>{t("reward.claim2x")}</Small>
              <SimpleButton
                onClick={handle2xClick}
                size="M"
                color="green"
                // icon={AutoAwesome}
                image="/images/icons/coin.webp"
              >
                {(accumulatedLoot?.gold * 2).toLocaleString()}
              </SimpleButton>
            </Stack>
          )}
          <SimpleButton
            component={motion.div}
            initial={{ opacity: 0, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 0.5 }}
            onClick={handleClick}
            size="M"
            color="yellow"
          >
            {outcome === "won"
              ? t("reward.claimVictory")
              : t("reward.claimDefeat")}
          </SimpleButton>
        </Stack>

        {outcome === "won" && <StarEffect />}
      </SimpleModal>

      {openChestModal && (
        <OpenChestModal
          open={openChestModal}
          chestLevel={chestLevel}
          onClaim={handleClaim}
          chestHash={chestHash}
        />
      )}

      {openOfferModal && (
        <LTOModal
          open={openOfferModal}
          onClose={() => setOpenOfferModal(false)}
          onConfirm={() => {}}
          item={vipOffer}
          onPurchased={handleVIPPurchase}
        />
      )}
    </>
  );
}
