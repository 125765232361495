import { Stack, useTheme } from "@mui/material";
import { borderRadiusNew, maxWidth, secondary } from "@/theme/colors";
import { WorldProgressBar } from "@/game/parts/WorldProgressBar";
import { DungeonTitle } from "@/components/DungeonTitle";
import { MainMenuItem } from "./MainMenuItem";
import { IconTile } from "@/components/IconTile";
import { useState, useEffect, useCallback } from "react";
import { useAppStore } from "@/hooks/useAppStore";
import { Glory } from "../home/Glory";
import { Atlas } from "../home/Atlas";
import { Quests } from "../home/Quests";
import { Rank } from "../home/Rank";
import { OpenChestModal } from "./OpenChestModal";
import { getPendingChests } from "@/lib/api";
import { LTOModal } from "../store/LTOModal";
import { getValidOffer } from "@/utils/store";
import { useIntroState } from "@/game/logic/useIntroState";
import { hasActiveChallenge } from "@/utils/quests";
import { useUserStore } from "../logic/useUserStore";
import { useAssetStore } from "../logic/useAssetStore";
import { useChestStore } from "../logic/useChestStore";
import { useChallengeStore } from "../logic/useChallengeStore";
import { useProgressStore } from "../logic/useProgressStore";

export const GameTopBar = () => {
  const theme = useTheme();
  const [openGlory, setOpenGlory] = useState(false);
  const [openAtlas, setOpenAtlas] = useState(false);
  const [openQuests, setOpenQuests] = useState(false);
  const [openTop, setOpenTop] = useState(false);
  const [chestModalOpen, setChestModalOpen] = useState(false);
  const [selectedChest, setSelectedChest] = useState(null);
  const [openOffers, setOpenOffers] = useState(null);
  const { dungeon, world } = useProgressStore();
  const { changeWorld } = useProgressStore();
  const { WORLDS, SHOP_ITEMS, LEVELS, CHALLENGES } = useAssetStore();
  const { pendingChests, setPendingChests } = useChestStore();
  const { challenge } = useChallengeStore();
  const { user } = useUserStore();
  const { badges } = useAppStore();
  const { isUnlocked, isXPLocked } = useIntroState();

  const currentWorldIndex = WORLDS?.findIndex((w) => w.id === world?.id) || 0;
  const canGoLeft = currentWorldIndex > 0;
  const canGoRight = currentWorldIndex < WORLDS.length - 1;
  const showWorldProgress = user?.progress?.world === world?.id;

  const completedWorld = user?.progress?.world > world?.id;

  const offer1 = getValidOffer(user?.limitedTimeOffers, 0, SHOP_ITEMS);
  const offer2 = getValidOffer(user?.limitedTimeOffers, 1, SHOP_ITEMS);

  const handleLeftClick = () => canGoLeft && changeWorld("left");
  const handleRightClick = () => canGoRight && changeWorld("right");

  const commonStyle = {
    borderRadius: borderRadiusNew,
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "40px",
    padding: "2px 6px 6px 6px",
    height: "100%",
  };

  const handleChestClick = useCallback((chest) => {
    setSelectedChest(chest);
    setChestModalOpen(true);
  }, []);

  const handleChestClaim = useCallback(() => {
    if (pendingChests?.length > 0) {
      setPendingChests(pendingChests.slice(1));
    }
    setChestModalOpen(false);
    setSelectedChest(null);
  }, [pendingChests]);

  const handleChestClose = useCallback(() => {
    setChestModalOpen(false);
    setSelectedChest(null);
  }, []);

  // Fetch pending chests once when component mounts
  useEffect(() => {
    const fetchPendingChests = async () => {
      if (user?.id && user?.jwt) {
        try {
          const response = await getPendingChests({
            userId: user.id,
            jwt: user.jwt,
          });
          setPendingChests(response.data.chests);
        } catch (error) {
          console.error("Error fetching pending chests:", error);
        }
      }
    };

    fetchPendingChests();
  }, [user?.jwt]);

  const showQuestsIcon = hasActiveChallenge(challenge, CHALLENGES);

  return (
    <Stack
      mx={10}
      sx={{
        position: "absolute",
        top: "60px",
        left: 0,
        right: 0,
        padding: theme.spacing(1),
        zIndex: 10,
        maxWidth: maxWidth,
        marginX: "auto",
        alignItems: "center",
        textAlign: "center",
        visibility: isUnlocked("WORLD_PROGRESS_BAR") ? "visible" : "hidden",
      }}
    >
      <DungeonTitle completedWorld={completedWorld} />

      <Stack
        margin="auto"
        mt={1}
        visibility={
          showWorldProgress && isUnlocked("WORLD_PROGRESS_BAR")
            ? "visible"
            : "hidden"
        }
      >
        <WorldProgressBar
          currentLevel={dungeon?.id - 1 || 0}
          maxLevel={LEVELS?.length || 1}
          sx={{ width: "100%", mt: 1 }}
        />
      </Stack>

      <Stack
        direction="row"
        sx={{
          width: "100%",
          top: "30%",
          left: 0,
          position: "absolute",
          justifyContent: "space-between",
          paddingX: "20px",
        }}
      >
        <IconTile
          size={26}
          color={secondary.main}
          image="/images/icons/arrow_left.webp"
          onClick={handleLeftClick}
          sx={{
            opacity: canGoLeft ? 1 : 0.5,
            cursor: canGoLeft ? "pointer" : "default",
          }}
        />

        <IconTile
          size={26}
          color={secondary.main}
          image="/images/icons/arrow_right.webp"
          onClick={handleRightClick}
          sx={{
            opacity: canGoRight ? 1 : 0.5,
            cursor: canGoRight ? "pointer" : "default",
          }}
        />
      </Stack>

      <Stack
        direction="row"
        sx={{
          width: "100%",
          top: "100%",
          height: "auto",
          position: "absolute",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <Stack
          sx={{
            ...commonStyle,
            left: 0,
          }}
          spacing={1}
        >
          <MainMenuItem
            icon="/images/icons/trophy.webp"
            title="Glory"
            onClick={() => setOpenGlory(true)}
            hasBadge={badges.glory}
            sx={{
              display: isXPLocked("GLORY") ? "none" : "flex",
            }}
          />
          {showQuestsIcon && (
            <MainMenuItem
              icon="/images/icons/scroll.webp"
              title="Quests"
              onClick={() => setOpenQuests(true)}
              hasBadge={badges.quests}
              sx={{
                display: isXPLocked("QUESTS") ? "none" : "flex",
              }}
            />
          )}
          {pendingChests?.length > 0 && (
            <MainMenuItem
              icon={`/images/chests/${pendingChests[0].level}.webp`}
              title="Chest"
              onClick={() => handleChestClick(pendingChests[0])}
              hasBadge={pendingChests.length}
            />
          )}
          {offer1 && (
            <MainMenuItem
              icon={offer1.image}
              countdownEnd={offer1.expiresAt}
              onClick={() => setOpenOffers(offer1)}
              hasBadge
              sx={{
                display: isXPLocked("OFFER_STARTER") ? "none" : "flex",
              }}
            />
          )}
        </Stack>

        <Stack
          sx={{
            ...commonStyle,
            right: 0,
          }}
          spacing={1}
        >
          <MainMenuItem
            icon="/images/icons/book.webp"
            title="Atlas"
            onClick={() => setOpenAtlas(true)}
            hasBadge={badges.atlasArmory || badges.atlasBestiary}
            sx={{
              display: isXPLocked("ATLAS") ? "none" : "flex",
            }}
          />
          <MainMenuItem
            icon="/images/icons/medal_gold.webp"
            title="Rank"
            onClick={() => setOpenTop(true)}
            sx={{
              display: isXPLocked("RANK") ? "none" : "flex",
            }}
          />
          {offer2 && (
            <MainMenuItem
              icon={offer2.image}
              countdownEnd={offer2.expiresAt}
              onClick={() => setOpenOffers(offer2)}
              hasBadge
              sx={{
                display: isXPLocked("OFFER_ULTIMATE") ? "none" : "flex",
              }}
            />
          )}
        </Stack>
      </Stack>

      <Glory open={openGlory} onClose={() => setOpenGlory(false)} />
      <Atlas open={openAtlas} onClose={() => setOpenAtlas(false)} />
      <Quests open={openQuests} onClose={() => setOpenQuests(false)} />
      <Rank open={openTop} onClose={() => setOpenTop(false)} />

      {chestModalOpen && (
        <OpenChestModal
          open={chestModalOpen}
          chestLevel={selectedChest?.level}
          chestHash={selectedChest?.hash}
          onClose={handleChestClose}
          onClaim={handleChestClaim}
        />
      )}

      {openOffers && (
        <LTOModal
          open={!!openOffers}
          onClose={() => setOpenOffers(null)}
          onConfirm={() => {}}
          item={openOffers}
        />
      )}
    </Stack>
  );
};
