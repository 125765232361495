// Initialize Google Analytics
export const GA_TRACKING_ID = "G-WMW4Z8P1K2";

// Wait for the window.gtag to be available
export const gaPageview = (url) => {
  if (typeof window.gtag !== "undefined") {
    // Send config
    window.gtag("config", GA_TRACKING_ID, {
      page_path: url,
    });
    // Explicitly send page_view event
    window.gtag("event", "page_view", {
      page_title: url,
      page_location: window.location.href,
      page_path: url,
    });
  }
};

// Custom events
export const gaEvent = ({ action, category, label, value }) => {
  if (typeof window.gtag !== "undefined") {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};
