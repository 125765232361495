import { darken, Stack } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import { H6 } from "./Typography";
import { useClickSound } from "@/hooks/useClickSound";
import { useState } from "react";
import { notPossibleSound } from "@/utils/sounds";
import {
  backgroundColorSecondary,
  getTileStyles,
  primary,
} from "@/theme/colors";

export function BottomBarItem({
  onClick,
  iconSrc,
  label,
  locked = false,
  onLockedClicked = null,
  isActive = false,
}) {
  const iconSize = 64;
  const handleClick = useClickSound(onClick);
  const [isShaking, setIsShaking] = useState(false);

  const handleLockedClick = useClickSound(
    () => {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 600); // Duration of shake animation
      onLockedClicked();
    },
    `bottom_bar_clicked_locked--${label}`,
    notPossibleSound
  );

  const backgroundColor = isActive ? primary.main : backgroundColorSecondary;

  return (
    <Stack
      sx={{
        alignItems: "center",
        position: "relative",
        zIndex: 2,
        width: isActive ? "100px" : "80px",
        height: "76px",
        animation:
          (isShaking &&
            "shake-bottom-bar-item 0.6s cubic-bezier(.36,.07,.19,.97) both") ||
          (isActive && "enlarge-width 0.25s") ||
          "none",
        ...getTileStyles(70),
        background: `linear-gradient(to bottom, ${backgroundColor}, ${darken(
          backgroundColor,
          0.2
        )})`,
        borderRadius: 0,
        border: "0.5px solid #000",
      }}
      onClick={locked ? handleLockedClick : handleClick}
    >
      <Stack
        sx={{
          position: "relative",
          animation: isActive && "click-bounce-3 0.25s",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!locked && (
          <SmartImage
            priority
            src={iconSrc}
            height={iconSize * 2}
            width={iconSize * 2}
            alt="Icon"
            style={{
              position: "absolute",
              top: isActive ? "-30px" : "-22px",
              margin: "0 auto",
              opacity: locked ? 0.7 : 1,
              width: isActive ? iconSize * 1.2 : iconSize,
              height: "auto",
            }}
          />
        )}
      </Stack>
      {locked && (
        <SmartImage
          src="/images/icons/Icon_ItemIcon_Lock.png"
          height={isActive ? iconSize * 1.2 : iconSize}
          width={isActive ? iconSize * 1.2 : iconSize}
          alt="Lock"
          style={{
            position: "absolute",
            top: "-16px",
            margin: "0 auto",
            zIndex: 3,
          }}
        />
      )}
      <H6
        sx={{
          color: primary.white,
          zIndex: 2,
          position: "absolute",
          bottom: 14,
          opacity: locked ? 0.7 : 1,
        }}
      >
        {label}
      </H6>
    </Stack>
  );
}
