import { create } from "zustand";
import { useUserStore } from "./useUserStore";
import { getLeaderboards } from "@/lib/api";

export const useLeaderboardStore = create((set) => ({
  leaderboards: null,

  fetchLeaderboards: async () => {
    const user = useUserStore.getState().user;
    try {
      const { data } = await getLeaderboards({ jwt: user.jwt });
      set({ leaderboards: data });
      return { success: true, leaderboard: data };
    } catch (error) {
      console.error("Error fetching leaderboard:", error);
      return { success: false, error: error.message };
    }
  },
}));
