import { greyLight, primary } from "@/theme/colors";
import { Box, styled, useMediaQuery } from "@mui/material";
import clsx from "clsx";

const StyledBox = styled(Box)(({ ellipsis }) => ({
  ...(ellipsis && {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }),
}));

export const H1 = (props) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { ellipsis, children, className, sx, ...others } = props;
  return (
    <StyledBox
      fontFamily={"'Squirk', sans-serif"}
      fontSize={isSmallScreen ? 40 : 46}
      component="h1"
      fontWeight={400}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      sx={{
        textShadow: "0px 3px 0 #000000",
        WebkitTextStroke: "0.2px black",
        ...sx,
      }}
      color={primary.white}
      {...others}
    >
      {children}
    </StyledBox>
  );
};

export const H2 = (props) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { ellipsis, children, className, sx, ...others } = props;
  return (
    <StyledBox
      fontFamily={"'Squirk', sans-serif"}
      fontSize={isSmallScreen ? 30 : 38}
      component="h2"
      fontWeight={400}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      sx={{
        textShadow: "0px 2.5px 0 #000000",
        WebkitTextStroke: "0.2px black",
        ...sx,
      }}
      color={primary.white}
      {...others}
    >
      {children}
    </StyledBox>
  );
};

export const H3 = (props) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { ellipsis, children, className, sx, ...others } = props;
  return (
    <StyledBox
      fontFamily={"'Squirk', sans-serif"}
      fontSize={isSmallScreen ? 24 : 30}
      component="h3"
      fontWeight={400}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      color={primary.white}
      sx={{
        textShadow: "0px 2.5px 0 #000000",
        WebkitTextStroke: "0.2px black",
        ...sx,
      }}
      {...others}
    >
      {children}
    </StyledBox>
  );
};

export const H4 = (props) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { ellipsis, children, className, sx, ...others } = props;
  return (
    <StyledBox
      fontSize={isSmallScreen ? 20 : 28}
      component="h4"
      fontWeight={600}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      color={primary.white}
      sx={{
        textShadow: "0px 2px 0 #000000",
        WebkitTextStroke: "0.2px black",
        ...sx,
      }}
      {...others}
    >
      {children}
    </StyledBox>
  );
};

export const H5 = (props) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { ellipsis, children, className, sx, ...others } = props;
  return (
    <StyledBox
      fontSize={isSmallScreen ? 18 : 22}
      component="h5"
      lineHeight={1}
      fontWeight={600}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      sx={{
        textShadow: "0px 2px 0 #000000",
        WebkitTextStroke: "0.2px black",
        ...sx,
      }}
      color={primary.white}
      {...others}
    >
      {children}
    </StyledBox>
  );
};

export const H6 = (props) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { ellipsis, children, className, sx, ...others } = props;
  return (
    <StyledBox
      fontSize={isSmallScreen ? 16 : 20}
      component="h6"
      fontWeight={600}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      color={primary.white}
      sx={{
        textShadow: "0px 1.5px 0 #000000",
        WebkitTextStroke: "0.2px black",
        ...sx,
      }}
      {...others}
    >
      {children}
    </StyledBox>
  );
};

export const Paragraph = (props) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { ellipsis, children, className, sx, ...others } = props;
  return (
    <StyledBox
      fontSize={isSmallScreen ? 14 : 18}
      component="p"
      fontWeight={500}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      sx={{
        textShadow: "0px 1px 0 #000000",
        WebkitTextStroke: "0.1px black",
        ...sx,
      }}
      color={primary.white}
      {...others}
    >
      {children}
    </StyledBox>
  );
};

export const ParagraphLight = styled(Paragraph)(() => ({
  color: greyLight[500],
}));

export const Small = (props) => {
  const { ellipsis, children, className, sx, ...others } = props;
  return (
    <StyledBox
      fontSize={12}
      component="small"
      fontWeight={500}
      lineHeight={1.6}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      color={primary.light}
      sx={{
        textShadow: "0px 1px 0 #000000",
        WebkitTextStroke: "0.1px black",
        ...sx,
      }}
      {...others}
    >
      {children}
    </StyledBox>
  );
};

export const Span = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={14}
      component="span"
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};

export const Tiny = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      component="p"
      fontSize={10}
      fontWeight={500}
      lineHeight={1.65}
      color="text.secondary"
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
