import { Box, Stack } from "@mui/material";
import { H1, H3, H6 } from "@/components/Typography";
import { DungeonTitle } from "@/components/DungeonTitle";
import { InvisibleClickBox } from "@/components/InvisibleClickBox";
import { useClickSound } from "@/hooks/useClickSound";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { DoubleArrow } from "@mui/icons-material";
import ConfettiExplosion from "react-confetti-explosion";
import { confettiProps } from "@/constants";
import { useTranslation } from "react-i18next";
import { newWorldSound } from "@/utils/sounds";
import { DarkModal } from "@/components/DarkModal";
import { SmartImage } from "@/components/SmartImage";
import { useSounds } from "@/hooks/useSound";
import { useMusic } from "@/hooks/useMusic";
import { usePrestigeStore } from "../logic/usePrestigeStore";

export function PrestigeModal() {
  const { prestige, resetPrestige } = usePrestigeStore();
  const { playSound, stopSound } = useSounds();
  const { stopAllMusic, playMainMusic } = useMusic();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [fireConfetti, setFireConfetti] = useState(false);

  const handleClick = useClickSound(() => {
    setIsOpen(false);
    resetPrestige();
  }, "click");

  useEffect(() => {
    if (prestige?.newWorldId) {
      setIsOpen(true);
    }
  }, [prestige]);

  useEffect(() => {
    if (isOpen) {
      stopAllMusic();
      playSound(newWorldSound);
    }
    return () => {
      stopSound(newWorldSound);
      playMainMusic();
    };
  }, [isOpen]);

  // Add a cleanup function to reset isOpen when the component unmounts
  useEffect(() => {
    setTimeout(() => {
      setFireConfetti(true);
    }, 800);
    return () => {
      setIsOpen(false);
    };
  }, []);

  if (!prestige) return null;

  return (
    <DarkModal open={isOpen}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.5 }}
      >
        <SmartImage
          src="/images/effects/burst_1.webp"
          alt="Background"
          height={500}
          width={500}
          style={{
            position: "absolute",
            top: 0,
            left: "-20%",
            transform: "translateY(-50%)",
            width: "140%",
            height: "auto",
            animation: "rotateImage 10s linear infinite",
            zIndex: -1,
          }}
        />
      </motion.div>

      <Stack
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        spacing={4}
        textAlign="center"
        height="100%"
        position="relative"
      >
        <InvisibleClickBox onClick={handleClick} />

        {fireConfetti && (
          <ConfettiExplosion
            {...confettiProps}
            zIndex={100000}
            style={{
              position: "absolute",
            }}
          />
        )}

        <H1
          component={motion.h1}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0 }}
        >
          {t("prestige.newWorld")}
        </H1>
        <H6
          component={motion.h6}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          {t("prestige.nextWorld")}
        </H6>

        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
        >
          <DungeonTitle completedWorld={false} />
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={3}
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
        >
          <Stack direction="row" alignItems="center">
            <SmartImage
              src="/images/icons/coin.webp"
              alt="Coin"
              width={64}
              height={64}
            />
            <H3>{prestige.goldConverted.toLocaleString()}</H3>
          </Stack>
          <Stack alignItems="center" justifyContent="center">
            <DoubleArrow color="secondary" fontSize="large" />
          </Stack>
          <Stack direction="row" alignItems="center">
            <SmartImage
              src="/images/icons/gem.webp"
              alt="Gem"
              width={60}
              height={60}
            />
            <H3>{prestige.gemsAwarded.toLocaleString()}</H3>
          </Stack>
        </Stack>

        {prestige.gemsAwarded >= 25 && (
          <Stack
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.35 }}
          >
            <H6 color="warning.main">{t("prestige.maxGemsReached")}</H6>
          </Stack>
        )}

        <Stack
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
        >
          <H6>{t("prestige.tapToContinue")}</H6>
        </Stack>
      </Stack>
    </DarkModal>
  );
}
