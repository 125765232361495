import { Container } from "@pixi/react";
import { HitNumbers } from "../components/HitNumbers";
import { CoinDropManager } from "./CoinDropManager";
import { useEffect, useMemo, memo } from "react";
import { Enemy } from "./Enemy";
import { Player } from "./Player";
import { useSounds } from "@/hooks/useSound";
import { useMusic } from "@/hooks/useMusic";
import { useShake } from "@/hooks/useShake";
import { useVFX } from "@/hooks/useVFX";
import { useEnemyAttackState } from "@/game/logic/useEnemyAttackState";
import { useActiveAbilityStore } from "../logic/useActiveAbilityStore";
import { useBattleStore } from "../logic/useBattleStore";

const BattleSceneContent = memo(({ characterShake }) => (
  <Container x={characterShake.x} y={characterShake.y}>
    <Enemy />
    <Player />
    <HitNumbers />
    <CoinDropManager />
  </Container>
));

BattleSceneContent.displayName = "BattleSceneContent";

export function BattleScene() {
  const { battleStarted } = useBattleStore();
  const { activeAbility, setActiveAbility } = useActiveAbilityStore();
  const enemyAttacked = useEnemyAttackState((state) => state.enemyAttacked);

  const { playBattleMusic, stopMainMusic, stopAllMusic } = useMusic();
  const { playAbilitySound } = useSounds();
  const { triggerVFX } = useVFX();
  const characterShake = useShake();

  const x = useMemo(() => window.innerWidth / 1.5, []);
  const y = useMemo(() => window.innerHeight / 1.8, []);

  useEffect(() => {
    if (enemyAttacked) {
      characterShake.start();
    }
  }, [enemyAttacked]);

  useEffect(() => {
    if (activeAbility) {
      const abilityId = activeAbility?.id;
      triggerVFX(abilityId, x, y - 100);
      playAbilitySound(abilityId);
      setActiveAbility(null);
    }
  }, [activeAbility]);

  useEffect(() => {
    if (battleStarted) {
      stopMainMusic();
      playBattleMusic();
    } else {
      stopAllMusic();
    }
  }, [battleStarted]);

  // useEffect(() => {
  //   if (enemyType === "boss") {
  //     console.log("TRIGGER BOSS VFX");
  //     triggerVFX("aura_53c", x, window.innerHeight / 2, 0.5, "background");
  //     triggerVFX("aura_13", x, window.innerHeight / 1.5, 0.4, "background");
  //   } else if (enemyType === "elite") {
  //     triggerVFX("aura_13", x, window.innerHeight / 1.5, 0.3, "background");
  //   }
  //   return () => {
  //     console.log("removeAllEffects");
  //     removeAllEffects();
  //   };
  // }, [enemyType]);

  return <BattleSceneContent characterShake={characterShake} />;
}
