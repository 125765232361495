import { Box, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Item } from "@/game/gear/Item";
import { IconNumber } from "@/components/IconNumber";
import { useState, useCallback, useMemo, useEffect } from "react";
import { H4 } from "@/components/Typography";
import {
  backgroundColorHex,
  backgroundColorSecondary,
  backgroundPattern,
  maxWidth,
  scrollbarStyles
} from "@/theme/colors";
import { ItemModal } from "@/game/gear/ItemModal";
import { SimpleButton } from "@/components/SimpleButton";
import { SmartImage } from "@/components/SmartImage";
import { useUserStore } from "./logic/useUserStore";
import { useAssetStore } from "./logic/useAssetStore";
import { usePlayerStore } from "./logic/usePlayerStore";

export function Gear() {
  const { t } = useTranslation();
  const { player } = usePlayerStore();
  const { RARITY, ITEM_TYPES, SLOTS } = useAssetStore();
  const { user } = useUserStore();
  const [selectedItem, setSelectedItem] = useState();
  const [inventoryFilter, setInventoryFilter] = useState("all");
  const [sortInventory, setSortInventory] = useState("amount");

  const cycleInventoryFilter = useCallback(() => {
    setInventoryFilter((currentFilter) => {
      switch (currentFilter) {
        case "all":
          return "gear";
        case "gear":
          return "collectible";
        case "collectible":
          return "all";
        default:
          return "all";
      }
    });
  }, []);

  const cycleSortInventory = useCallback(() => {
    setSortInventory((currentSort) => {
      switch (currentSort) {
        case "amount":
          return "level";
        case "level":
          return "rarity";
        case "rarity":
          return "amount";
        default:
          return "amount";
      }
    });
  }, []);

  const rarityOrder = useMemo(
    () => ({
      [RARITY.COMMON]: 0,
      [RARITY.UNCOMMON]: 1,
      [RARITY.RARE]: 2,
      [RARITY.EPIC]: 3,
      [RARITY.LEGENDARY]: 4,
      [RARITY.MYTHIC]: 5,
    }),
    []
  );

  const filteredAndSortedInventory = useMemo(() => {
    let filtered = user?.inventory?.filter((item) => {
      if (inventoryFilter === "all") return true;
      if (inventoryFilter === "gear") return item.type !== "Scroll";
      if (inventoryFilter === "collectible") return item.type === "Scroll";
      return true;
    });

    let grouped = filtered?.reduce((acc, item) => {
      const existingItem = acc.find((i) => i.name === item?.name);
      if (existingItem) {
        existingItem.count = (existingItem.count || 1) + 1;
      } else {
        acc.push({ ...item, count: 1 });
      }
      return acc;
    }, []);

    return grouped?.sort((a, b) => {
      if (sortInventory === "amount") {
        return b.count - a.count;
      } else if (sortInventory === "level") {
        return (b.level || 0) - (a.level || 0) || b.count - a.count;
      } else if (sortInventory === "rarity") {
        return (
          rarityOrder[b.rarity] - rarityOrder[a.rarity] || b.count - a.count
        );
      }
      return 0;
    });
  }, [user?.inventory, inventoryFilter, sortInventory, rarityOrder]);

  useEffect(() => {
    if (selectedItem) {
      // item was upgraded
      const upgradedItem = user?.inventory?.find(
        (i) => i.id === selectedItem.id
      );
      setSelectedItem(upgradedItem);
    }
  }, [user?.inventory]);

  return (
    <>
      <Box>
        <Stack>
          <Box
            position="relative"
            p={3}
            sx={{
              paddingTop: "60px",
              height: "40vh",
              overflow: "hidden",
            }}
          >
            <SmartImage
              priority
              src="/images/backgrounds/b1.webp"
              alt="background"
              width={1000}
              height={1000}
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
              spacing={0}
              sx={{
                position: "relative",
                maxWidth: maxWidth,
                margin: "auto",
                height: "100%",
              }}
            >
              {SLOTS?.map((type, index) => {
                const items = user?.equippedItems?.[type];
                let itemIndex = 0;

                if (type === ITEM_TYPES.ATTACK) {
                  itemIndex = index % 2; // 0 for first attack slot, 1 for second
                } else if (type === ITEM_TYPES.DEFENSE) {
                  itemIndex = index % 2; // 0 for first defense slot, 1 for second
                }

                const item = items?.[itemIndex];
                const tileIndex = (index % 4) + 1;

                return (
                  <Stack
                    key={index}
                    width="50%"
                    alignItems={index % 2 === 0 ? "flex-start" : "flex-end"}
                    mt={1}
                  >
                    <Item
                      item={item}
                      size="M"
                      showLevel
                      isSelected={false}
                      tileIndex={tileIndex}
                      xs={6}
                      onClick={() => setSelectedItem(item)}
                    />
                  </Stack>
                );
              })}
            </Stack>
            <Box
              sx={{
                position: "absolute",
                top: "54%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                filter: "drop-shadow(0 0 10px rgba(255, 0, 0, 0.5))",
              }}
            >
              {/* Placeholder for the hero image */}
            </Box>
            <Stack
              direction="row"
              margin="auto"
              width="100%"
              mt={2}
              sx={{
                position: "absolute",
                bottom: "2%",
                width: "100%",
                justifyContent: "center",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <IconNumber
                imgSrc="/images/items/Sword_B.webp"
                value={player.attack}
                size="S"
              />
              <IconNumber
                imgSrc="/images/icons/heart.webp"
                value={player.maxHp}
                size="S"
              />
            </Stack>
          </Box>

          <Stack
            direction="row"
            py={0.5}
            px={3}
            sx={{
              backgroundColor: backgroundColorSecondary,
              alignItems: "center",
              justifyContent: "space-between",
              borderTop: "1px solid #516C81",
              borderBottom: "1px solid #516C81",
              position: "relative",
              overflow: "hidden",
              ...backgroundPattern,
            }}
          >
            <SimpleButton
              size="S"
              onClick={() => {
                cycleSortInventory();
              }}
              color="yellow"
              sx={{ zIndex: 2 }}
            >
              {t(`hero.${sortInventory}`)}
            </SimpleButton>
            <H4
              color="white"
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              {t("hero.inventory")}
            </H4>
            <SimpleButton
              size="S"
              onClick={() => {
                console.log("Filter button clicked");
                cycleInventoryFilter();
              }}
              color="yellow"
              sx={{ zIndex: 2 }}
            >
              {t(`hero.${inventoryFilter}`)}
            </SimpleButton>
          </Stack>

          {/* EQUIPPED ITEMS */}
          <Stack sx={{ backgroundColor: backgroundColorHex, height: "40vh" }}>
            <Stack
              sx={{
                maxWidth: maxWidth,
                margin: "auto",
                height: "100%",
                overflow: "scroll",
                ...scrollbarStyles,
              }}
              py={1}
            >
              <Grid
                container
                sx={{
                  justifyContent: "center",
                }}
              >
                {filteredAndSortedInventory?.map((item, index) => {
                  const tileIndex = (index % 4) + 1;

                  return (
                    <Item
                      key={index}
                      xs={3}
                      item={item}
                      showLevel={item?.level}
                      isSelected={false}
                      tileIndex={tileIndex}
                      count={item.count}
                      onClick={() => setSelectedItem(item)}
                    />
                  );
                })}
              </Grid>
            </Stack>
          </Stack>
          {/* <Stack
            direction="row"
            px={2}
            py={1}
            sx={{
              backgroundColor: backgroundColorSecondary,
              height: "50vh",
              justifyContent: "space-between",
              borderTop: "1px solid #516C81",
              borderBottom: "1px solid #516C81",
              position: "relative",
              overflow: "hidden",
              ...backgroundPattern,
            }}
          >
            <SimpleButton size="M" onClick={() => {}} color="purple">
              {t("hero.equip")}
            </SimpleButton>
            <SimpleButton size="M" onClick={() => {}} color="purple">
              {t("hero.sell")}
            </SimpleButton>
          </Stack> */}
        </Stack>
      </Box>

      <ItemModal
        open={!!selectedItem}
        item={selectedItem}
        onClose={() => setSelectedItem()}
      />
    </>
  );
}
