import { memo } from "react";
import { Container } from "@pixi/react";
import { BattleScene } from "./BattleScene";
import { useVFX } from "@/hooks/useVFX";
import { useCallback, useEffect } from "react";
import { useEnergyStore } from "../logic/useEnergyStore";
import { combatActions } from "../actions/combatActions";

export const GameplayScene = memo(() => {
  const { preloadAllVFX, triggerVFX } = useVFX();
  const { energy } = useEnergyStore();

  const handleClick = useCallback(
    (event) => {
      const x = event.data.global.x;
      const y = event.data.global.y;

      const type = energy > 0 ? "hit_1" : "smoke_3";
      triggerVFX(type, x, y, 0.3);
      combatActions.performAttack();
    },
    [energy]
  );

  useEffect(() => {
    preloadAllVFX();
  }, [preloadAllVFX]);

  return (
    <Container
      interactive={true}
      pointerdown={handleClick}
      hitArea={{
        contains: () => true,
      }}
    >
      <BattleScene />
    </Container>
  );
});

GameplayScene.displayName = "GameplayScene";
