import { create } from "zustand";
import { calculateXpReward } from "@/utils/stats";
import { generateEnemyLoot } from "@/utils/battle";
import { getAbility } from "@/utils/merge";
import { useAssetStore } from "./useAssetStore";
import { useUserStore } from "./useUserStore";

export const useLootStore = create((set, get) => ({
  accumulatedLoot: {
    gold: 0,
    xp: 0,
  },

  resetLoot: () => set({ accumulatedLoot: { gold: 0, xp: 0 } }),

  incrementGold: () => {
    set((state) => ({
      accumulatedLoot: {
        ...state.accumulatedLoot,
        gold: state.accumulatedLoot.gold + 1,
      },
    }));
  },

  addXp: (amount) =>
    set((state) => ({
      accumulatedLoot: {
        ...state.accumulatedLoot,
        xp: (state.accumulatedLoot.xp || 0) + amount,
      },
    })),

  give2xGold: () =>
    set((state) => ({
      accumulatedLoot: {
        ...state.accumulatedLoot,
        gold: state.accumulatedLoot.gold * 2,
      },
    })),

  addEnemyLoot: ({ enemy, world, isAbility = false }) => {
    const user = useUserStore.getState().user;
    const { ABILITIES, BASE_ENEMY_LOOT } = useAssetStore.getState();
    const worldDifficulty = world?.baseDifficulty || 1;

    // Add base gold from attack
    const goldGained = isAbility
      ? 1 + (getAbility(user.selectedHero, ABILITIES).goldBonus || 0)
      : 1;

    // Add enemy loot if defeated
    const goldLoot = generateEnemyLoot(
      enemy.level,
      BASE_ENEMY_LOOT,
      user.passes?.vip
    );

    // Calculate XP reward
    const xpReward = calculateXpReward(
      enemy.level,
      enemy.type,
      worldDifficulty
    );

    set((state) => ({
      accumulatedLoot: {
        gold: state.accumulatedLoot.gold + goldGained + goldLoot,
        xp: (state.accumulatedLoot.xp || 0) + xpReward,
      },
    }));
  },

  getLoot: () => get().accumulatedLoot,
}));
