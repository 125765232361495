import { IconButton, lighten } from "@mui/material";
import { SmartImage } from "./SmartImage";
import { backgroundColorSecondary, getTileStyles } from "@/theme/colors";
import { useClickSound } from "@/hooks/useClickSound";

/**
 * IconTile component
 * @param {Object} props
 * @param {string} props.image - The source of the image
 * @param {string} props.icon - The source of the icon image
 * @param {Function} [props.onClick] - Click handler for the icon button
 * @param {number} [props.size=32] - The size of the icon in pixels (default: 32)
 */
export function IconTile({
  image,
  icon,
  color = backgroundColorSecondary,
  onClick,
  size = 30,
  sx,
  imageSx,
}) {
  const handleClick = useClickSound(onClick);
  const IconComponent = icon;

  return (
    <IconButton
      onClick={handleClick}
      sx={{
        ...getTileStyles(size, true, color),
        ...sx,
        padding: "2px",
        "&:hover": {
          backgroundColor: lighten(color, 0.2),
        },
      }}
    >
      {image && (
        <SmartImage
          src={image}
          height={size}
          width={size}
          style={{
            ...imageSx,
          }}
        />
      )}

      {icon && (
        <IconComponent
          sx={{
            height: size,
            width: size,
          }}
        />
      )}
    </IconButton>
  );
}
