import { LEVEL_CAPS } from "@/constants";

// New constants
const STAT_MATRIX = {
  HERO_ATTACK: [10, 25, 10, 10],
  HERO_DEFENSE: [100, 50, 20, 20],
  ITEM_ATTACK: [10, 25, 10, 5],
  ITEM_DEFENSE: [10, 25, 10, 5],
};

const RARITY_RANK = {
  COMMON: 0,
  UNCOMMON: 1,
  RARE: 2,
  EPIC: 3,
  LEGENDARY: 4,
  MYTHIC: 5,
};

// Governing formula
function calculateStat(statType, rarity, level) {
  const [a, b, c, d] = STAT_MATRIX[statType];
  const R = RARITY_RANK[rarity];
  const L = Math.min(level, LEVEL_CAPS[rarity] || 1);

  return a + b * R + (c * R * (R - 1)) / 2 + d * (L - 1);
}

/**
 * ITEMS
 */

export function calculateItemValue(item, level, type) {
  if (!item || !type) return 0;
  const itemType = item?.type?.toLowerCase();
  if (itemType !== type.toLowerCase()) return 0;

  const statType =
    type.toUpperCase() === "ATTACK" ? "ITEM_ATTACK" : "ITEM_DEFENSE";
  return calculateStat(statType, item.rarity, level);
}

export function calculateItemUpgradeCost(item, currentLevel) {
  if (!item) {
    return 0;
  }
  const RUNES = 2;
  const { rarityValue } = item;
  return RUNES + 3 * rarityValue + (currentLevel - 1);
}

export function calculateScrolls(item, currentLevel) {
  if (!item) {
    return 0;
  }
  const SCROLLS = 2;
  const { rarityValue } = item;
  return SCROLLS + 2 * rarityValue + (currentLevel - 1);
}

/**
 * HEROES
 */

export function calculateHeroStat(hero, statType) {
  if (!hero) return 0;
  const type =
    statType.toUpperCase() === "ATTACK" ? "HERO_ATTACK" : "HERO_DEFENSE";
  return calculateStat(type, hero.rarity, hero.level || 1);
}

export function calculateHeroEnergy(hero, rarityIndex) {
  if (!hero) return 0;

  const baseValue = 30; // BV
  const constant = 2; // Cu

  return baseValue + constant * rarityIndex;
}

export function calculateEnergyRegenRate(level, STAT_CONFIG) {
  const { base, incrementPerLevel, minimum } = STAT_CONFIG.energyRegenRate;

  const ratePerSecond = base + (level - 1) * incrementPerLevel;

  // Convert rate per second to milliseconds between regenerations
  const msPerRegen = Math.round(1000 / ratePerSecond);

  // Ensure the rate doesn't go below the minimum
  return Math.max(msPerRegen, minimum);
}

export function calculateHeroUpgradeCost(hero, currentLevel, rarityIndex) {
  if (!hero) return 0;

  const baseValue = 2; // BV
  const constant = 2; // Cu

  return baseValue + constant * rarityIndex + (currentLevel - 1);
}

export function calculateAbilityDamage(totalAttack, rarityIndex) {
  if (!totalAttack || rarityIndex === undefined) return 0;

  const C = 0.75; // Constant
  const R = rarityIndex; // Rarity index from selectedHero

  return Math.round((3 + C * R) * totalAttack);
}

/**
 * COMMON
 */

export function calculateDifficulty(baseDifficulty, cycle) {
  const difficulty = baseDifficulty + cycle - 1;
  return difficulty.toFixed(1);
}

export function calculateXpReward(enemyLevel, enemyType, worldDifficulty) {
  const baseXpConstant = 10;
  const levelMultiplier = enemyLevel;
  const typeMultiplierMap = {
    monster: 1,
    elite: 5,
    boss: 50,
  };
  const typeMultiplier = typeMultiplierMap[enemyType] || 1;

  return Math.round(
    (baseXpConstant + levelMultiplier) * typeMultiplier * worldDifficulty
  );
}
