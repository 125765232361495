import { Box } from "@mui/material";

export function WhiteRadial({ size = 80, top = "40%", sx }) {
  return (
    <Box
      sx={{
        position: "absolute",
        width: size,
        height: size,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: "0 auto",
        zIndex: 0,
        "&::before": {
          content: "''",
          position: "absolute",
          top: top,
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "180%",
          height: "180%",
          background:
            "radial-gradient(circle, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 70%)",
        },
        ...sx,
      }}
    />
  );
}
