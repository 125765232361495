import { useEffect } from "react";
import { GameTopBar } from "./parts/GameTopBar";
import { GameBottomBar } from "./parts/GameBottomBar";
import { PrestigeModal } from "./parts/PrestigeModal";
import { useMusic } from "@/hooks/useMusic";
import { BackgroundImage } from "@/components/BackgroundImage";

export function Home() {
  const { playMainMusic } = useMusic();

  useEffect(() => {
    console.log("Initial music play");
    playMainMusic();
  }, []);

  return (
    <>
      <BackgroundImage />
      <GameTopBar />
      <GameBottomBar />
      <PrestigeModal />
    </>
  );
}
