/**
 * Filters and selects enemies based on the given level and world.
 * @param {number} level - The current level.
 * @param {number} world - The current world.
 * @param {string} type - The type of enemy (e.g., "enemy", "elite", "boss").
 * @returns {Array} - A list of enemies that match the criteria.
 */
// Keep track of selected enemies
let selectedEnemyIds = new Set();

export const getMatchingEnemies = (ENEMIES, level, world, type) => {
  // Filter enemies by type, level, and world
  const filteredEnemies = ENEMIES.filter(
    (enemy) =>
      enemy.type === type &&
      enemy.level.includes(level) &&
      enemy.worlds.includes(world) &&
      !selectedEnemyIds.has(enemy.id) // Exclude already selected enemies
  );

  // Randomly select one enemy from the filtered list
  if (filteredEnemies.length > 0) {
    const randomIndex = Math.floor(Math.random() * filteredEnemies.length);
    const selectedEnemy = filteredEnemies[randomIndex];
    selectedEnemyIds.add(selectedEnemy.id); // Add the selected enemy's id to the set
    return selectedEnemy;
  }

  // If no new enemies are available, clear the set and try again
  if (selectedEnemyIds.size > 0) {
    selectedEnemyIds.clear();
    return getMatchingEnemies(ENEMIES, level, world, type);
  }

  return null;
};

// Add a function to reset the selected enemies (call this when starting a new dungeon)
export const resetSelectedEnemies = () => {
  selectedEnemyIds.clear();
};

export const generateEnemyLoot = (enemyLevel, baseEnemyLoot, isVIP = false) => {
  const baseLoot = Math.floor(baseEnemyLoot * Math.pow(1.2, enemyLevel - 1));
  return isVIP ? baseLoot * 2 : baseLoot;
};
