import { create } from "zustand";
import { useUserStore } from "./useUserStore";
import { useAssetStore } from "./useAssetStore";
import { usePlayerMetricsStore } from "./usePlayerMetricsStore";
import { usePlayerStore } from "./usePlayerStore";
import { useEnergyStore } from "./useEnergyStore";
import { useProgressStore } from "./useProgressStore";

export const useGameState = create(() => ({
  initializeGame: (userData, assets) => {
    useUserStore.getState().setUser(userData);
    useAssetStore.getState().setAssets(assets);
    usePlayerStore.getState().initializePlayerStats(userData);
    useEnergyStore.getState().initializeEnergy(assets);
    useProgressStore.getState().initializeProgress(userData);
    usePlayerMetricsStore.getState().fetchAndUpdateMetrics();
  },
}));
