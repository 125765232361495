import { BattleTopBar } from "@/game/parts/BattleTopBar";
import { BattleBottomBar } from "@/game/parts/BattleBottomBar";
import { BattleResultModal } from "@/game/battle/BattleResultModal";
import { WhiteFlash } from "@/components/WhiteFlash";
import { RedFlash } from "@/components/RedFlash";
import { PreBattle } from "./battle/PreBattle";
import { BackgroundImage } from "@/components/BackgroundImage";

export function Battle() {
  return (
    <>
      <PreBattle />
      <BackgroundImage />
      <BattleTopBar />
      <BattleBottomBar />
      <BattleResultModal />
      <WhiteFlash />
      <RedFlash />
    </>
  );
}
