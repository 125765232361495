import { Box, Stack } from "@mui/material";
import { Paragraph, Small } from "../../components/Typography";
import { SmartImage } from "@/components/SmartImage";
import { Item } from "../gear/Item";

export function StoreItemMythic({ item }) {
  return (
    <Box
      sx={{
        padding: "6px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          zIndex: item.id === "vip-pass" ? 1 : 2,
          backgroundColor: "rgba(0,0,0,0.1)",
          borderRadius: "4px",
          position: "relative",
        }}
      >
        <Paragraph>{item.name}</Paragraph>
      </Box>

      <Stack
        sx={{
          position: "relative",
          height: 100,
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          {item.content?.map((gear, index) => (
            <Stack key={index}>
              <Item
                item={{
                  id: gear.id,
                  rarity: "MYTHIC",
                  icon: gear.icon,
                }}
                showBurst
                showRarity
              />
              <Stack
                direction="row"
                spacing={0.5}
                justifyContent="center"
                zIndex={1}
              >
                <SmartImage
                  src={`/images/icons/${gear.stat}.webp`}
                  width={20}
                  height={20}
                />
                <Small>{gear.statValue}</Small>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>

      {item.description2 && <Small mb={1}>{item.description2}</Small>}
      <Stack
        sx={{
          backgroundColor: "rgba(0,0,0,0.2)",
          borderRadius: "4px",
          padding: "4px 6px",
          mt: 0.5,
        }}
      >
        {item.description && (
          <Paragraph sx={{ fontSize: "14px" }}>{item.description}</Paragraph>
        )}
      </Stack>
    </Box>
  );
}
