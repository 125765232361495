import * as Sentry from "@sentry/react";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://177bc5d365f83ccb5e26e3c3010b0ad2@o4508214292709376.ingest.de.sentry.io/4508214394880080",
    environment: "production",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: [/^https:\/\/loot-api\.vallarok\.com/],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });

  console.log("✅ Sentry initialized in production mode");
} else {
  console.log("ℹ️ Sentry disabled in development mode");
}
