import { SmartImage } from "@/components/SmartImage";
import { Small } from "@/components/Typography";
import { Item } from "@/game/gear/Item";
import { Box, LinearProgress, Stack } from "@mui/material";
import { useAlert } from "@/hooks/useAlert";
import { BurstImage } from "@/components/effects/BurstImage";
import { OpenChestModal } from "@/game/parts/OpenChestModal";
import { useState } from "react";
import { QuestItemReceived } from "./QuestItemReceived";
import { useChestStore } from "@/game/logic/useChestStore";
import { useChallengeStore } from "@/game/logic/useChallengeStore";

const RewardRow = ({
  rewards,
  claimedMilestones,
  isTop,
  onClaimReward,
  currentXP,
}) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      position: "absolute",
      left: "50%",
      top: isTop ? 0 : "50px",
      transform: "translateX(-57%)",
    }}
  >
    {rewards.map((reward, index) => {
      const milestone = Object.keys(claimedMilestones)[reward.index];
      const isClaimed = claimedMilestones[milestone];
      const isAchieved = milestone <= currentXP;
      const isAchievedAndNotClaimed = isAchieved && !isClaimed;

      return (
        <Box sx={{ position: "relative" }} key={index}>
          <Stack
            sx={{
              position: "relative",
              opacity: isAchieved ? 1 : 0.7,
              alignItems: "center",
              animation: isAchievedAndNotClaimed
                ? "pulse-animation 1s infinite"
                : "none",
            }}
          >
            <SmartImage
              src={`/images/chests/${reward.reward.chest}.webp`}
              width={50}
              height={50}
              alt={`Chest ${reward.reward.chest}`}
              onClick={() =>
                isAchievedAndNotClaimed && onClaimReward(milestone)
              }
            />
            {isAchievedAndNotClaimed && (
              <BurstImage sx={{ zIndex: -1, left: "2px" }} />
            )}
            {isClaimed && (
              <SmartImage
                src="/images/icons/check.webp"
                width={20}
                height={20}
                alt="Claimed"
                style={{ position: "absolute", top: "10px", right: 0 }}
              />
            )}
            <Small sx={{ marginTop: "-12px" }}>{milestone}</Small>
          </Stack>

          {isAchievedAndNotClaimed && (
            <Stack
              sx={{
                position: "absolute",
                bottom: "44px",
                left: "10px",
                zIndex: 2,
                animation: "pulse-simple 1s infinite",
                pointerEvents: "none",
              }}
            >
              <SmartImage
                src="/images/icons/hand.webp"
                width={34}
                height={34}
                alt="hand"
                style={{
                  transform: "rotate(180deg)",
                  zIndex: 2,
                }}
              />
            </Stack>
          )}
        </Box>
      );
    })}
  </Stack>
);

const QuestRow = ({ mergedChallenge, itemReward, onClaimReward }) => {
  const isClaimed = mergedChallenge.claimedMilestones[itemReward.milestone];
  const isAchieved = itemReward.milestone <= mergedChallenge.currentXP;
  const isAchievedAndNotClaimed = isAchieved && !isClaimed;
  const lastMilestone =
    mergedChallenge.xpMilestones[mergedChallenge.xpMilestones.length - 1];
  const progress = Math.min(
    (mergedChallenge.currentXP / lastMilestone) * 100,
    100
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ width: "100%" }}
    >
      <Stack justifyContent="center" alignItems="center">
        <SmartImage
          src="/images/icons/xp.webp"
          width={54}
          height={54}
          alt="XP"
        />
        <Small sx={{ fontSize: "14px", marginTop: "-6px" }}>
          {mergedChallenge.currentXP}
        </Small>
      </Stack>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          width: "180px",
          height: 16,
          borderRadius: "2px",
          backgroundColor: "rgba(0,0,0,0.7)",
          "& .MuiLinearProgress-bar": {
            borderRadius: 0,
            boxShadow: "inset 0px -8px 0px 0px rgba(0, 0, 0, 0.1)",
          },
        }}
      />
      <Stack alignItems="center" position="relative">
        <Item
          item={{
            id: itemReward.item,
            icon: itemReward.image,
            level: itemReward.rarityValue,
            rarity: itemReward.rarity,
          }}
          size="S"
          isSelected={false}
          tileIndex={1}
          showBurst
          sx={{
            animation: isAchievedAndNotClaimed
              ? "vallarok-pulse-animation 1s infinite"
              : "none",
          }}
          onClick={() =>
            isAchievedAndNotClaimed && onClaimReward(itemReward.milestone)
          }
        />

        {isClaimed && (
          <SmartImage
            src="/images/icons/check.webp"
            width={30}
            height={30}
            alt="Claimed"
            style={{ position: "absolute", top: 0, right: 0, zIndex: 3 }}
          />
        )}
        <Small>1500</Small>
      </Stack>
    </Stack>
  );
};

export function QuestsProgress({ mergedChallenge }) {
  const { claimChallengeRewards } = useChallengeStore();
  const { chestHash } = useChestStore();
  const { setError } = useAlert();
  const [openChestModal, setOpenChestModal] = useState(false);
  const [openItemModal, setOpenItemModal] = useState(false);

  const [chestLevel, setChestLevel] = useState(null);
  const milestoneRewards = mergedChallenge?.milestoneRewards;

  if (!milestoneRewards) return null;

  const topRewards = [1, 3, 5].map((index) => ({
    index,
    reward: milestoneRewards[index],
  }));
  const bottomRewards = [0, 2, 4, 6].map((index) => ({
    index,
    reward: milestoneRewards[index],
  }));

  const itemReward = milestoneRewards.find((reward) => reward.type === "item");

  const handleClaimReward = async (milestone) => {
    const milestoneInt = parseInt(milestone);
    const result = await claimChallengeRewards({
      type: "milestone",
      milestone: milestoneInt,
    });
    if (result.success) {
      const milestoneIndex = mergedChallenge.xpMilestones.indexOf(milestoneInt);
      const milestoneReward = milestoneRewards[milestoneIndex];
      if (milestoneReward.type === "item") {
        setOpenItemModal(true);
      } else if (milestoneReward.type === "chest") {
        setChestLevel(milestoneReward.chest);
        setOpenChestModal(true);
      }
    }
    if (!result.success) {
      setError(result.error);
      console.error("Failed to claim reward:", result.error);
    }
  };

  const handleClaimChest = () => {
    setOpenChestModal(false);
  };

  return (
    <Stack
      spacing={2}
      position="relative"
      sx={{
        height: "120px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <RewardRow
        rewards={topRewards}
        claimedMilestones={mergedChallenge.claimedMilestones}
        isTop={true}
        onClaimReward={handleClaimReward}
        currentXP={mergedChallenge.currentXP}
      />

      <QuestRow
        mergedChallenge={mergedChallenge}
        itemReward={itemReward}
        onClaimReward={handleClaimReward}
      />

      <RewardRow
        rewards={bottomRewards}
        claimedMilestones={mergedChallenge.claimedMilestones}
        onClaimReward={handleClaimReward}
        currentXP={mergedChallenge.currentXP}
      />

      {openChestModal && (
        <OpenChestModal
          open={openChestModal}
          chestLevel={chestLevel}
          onClaim={handleClaimChest}
          chestHash={chestHash}
        />
      )}

      {openItemModal && (
        <QuestItemReceived
          open={openItemModal}
          onClose={() => setOpenItemModal(false)}
          item={itemReward}
        />
      )}
    </Stack>
  );
}
