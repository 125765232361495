import { useEnergyStore } from "../logic/useEnergyStore";
import { useEnemyStore } from "../logic/useEnemyStore";
import { useProgressStore } from "../logic/useProgressStore";
import { useBattleStore } from "../logic/useBattleStore";
import { usePlayerStore } from "../logic/usePlayerStore";
import { useAttackStore } from "../logic/useAttackStore";
import { useUserStore } from "../logic/useUserStore";
import { useLootStore } from "../logic/useLootStore";
import { useEnemyAttackState } from "../logic/useEnemyAttackState";
import { useAssetStore } from "../logic/useAssetStore";
import { calculateAbilityDamage } from "@/utils/stats";
import { getAbility } from "@/utils/merge";
import { useActiveAbilityStore } from "../logic/useActiveAbilityStore";
import { metrics } from "../logic/Metrics";
import { processDungeonCompleted } from "@/lib/api";
import { useChestStore } from "../logic/useChestStore";
import { usePotionStore } from "../logic/usePotionStore";

const DELAY_AFTER_ENEMY_ATTACK = 500;

export const combatActions = {
  startNewBattle: () => {
    const user = useUserStore.getState().user;
    const player = usePlayerStore.getState().player;
    const progress = useProgressStore.getState();

    if (!progress.dungeon) {
      return { success: false, error: "No dungeon selected" };
    }

    if (user.progress.world !== progress.world.id) {
      return {
        success: false,
        error: `You can only fight in world ${user.progress.world}`,
      };
    }

    // Reset attack counts when starting new battle
    useAttackStore.getState().resetAttackCounts();
    useEnemyStore.getState().setFirstEnemy(progress.dungeon, progress.world.id);
    useEnergyStore.getState().calculateHeroEnergy(user.selectedHero);
    useEnergyStore.getState().startEnergyRegen(player);
    usePlayerStore.getState().updatePlayerHp(player.maxHp);
    useProgressStore.getState().resetDungeon();
    useEnemyStore.getState().startEnemyAttackInterval();
    useBattleStore.getState().startBattle();

    return { success: true };
  },

  performAttack: (isAbility = false) => {
    const enemy = useEnemyStore.getState().enemy;
    const character = useEnemyStore.getState().character;
    const world = useProgressStore.getState().world;
    const battleStore = useBattleStore.getState();

    if (
      !enemy ||
      (!isAbility && !useEnergyStore.getState().hasEnoughEnergy()) ||
      battleStore.battleLock
    ) {
      return;
    }

    // Increment player attack count
    useAttackStore.getState().incrementPlayerAttack();

    const user = useUserStore.getState().user;
    const { ABILITIES } = useAssetStore.getState();
    const player = usePlayerStore.getState().player;

    let damage = isAbility
      ? calculateAbilityDamage(player.attack, user.selectedHero.rarityIndex)
      : player.attack;

    // Apply damage buff if active
    if (usePotionStore.getState().isDamageBuffActive()) {
      damage *= 2;
    }

    if (isAbility) {
      const ability = getAbility(user.selectedHero, ABILITIES);
      useActiveAbilityStore.getState().setActiveAbility(ability);
      metrics.add("heroAbilityUsed", 1);
    } else {
      useEnergyStore.getState().useEnergy();
    }

    const newHp = Math.max(enemy.hp - damage, 0);
    useEnemyStore.getState().setEnemy({ ...enemy, hp: newHp });

    metrics.add("damageDealt", damage);

    // Add loot
    if (newHp <= 0) {
      useLootStore.getState().addEnemyLoot({
        enemy,
        world,
        isAbility,
      });

      metrics.add("monstersDefeated", 1);
      if (enemy.type === "elite") metrics.add("elitesDefeated", 1);
      else if (enemy.type === "boss") metrics.add("bossesDefeated", 1);

      if (character?.id) {
        metrics.add("enemies", {
          enemyId: character.id,
          difficulty: world?.baseDifficulty || 1,
        });
      }

      useBattleStore.getState().setBattleLock(true);
      setTimeout(() => combatActions.progressBattle(), 1000);
    } else {
      useLootStore.getState().incrementGold();
    }
  },

  enemyAttack: () => {
    const battleStore = useBattleStore.getState();
    const player = usePlayerStore.getState().player;
    const enemy = useEnemyStore.getState().enemy;

    if (!battleStore.battleStarted || battleStore.battleLock) return;

    // Increment enemy attack count
    useAttackStore.getState().incrementEnemyAttack();

    if (!enemy || !battleStore.battleStarted || enemy.hp <= 0) return;

    // Calculate damage and new player hp
    const damage = enemy.attack || 0;
    const newPlayerHp = Math.max(player.hp - damage, 0);
    const enemyAttackState = useEnemyAttackState.getState();

    if (newPlayerHp === 0) {
      // Player has been defeated
      useEnergyStore.getState().clearEnergyRegen();
      useEnemyStore.getState().clearEnemyAttackInterval();

      // player loses hp
      usePlayerStore.getState().updatePlayerHp(newPlayerHp);
      enemyAttackState.setEnemyAttacked(true);

      // delay for death animation and sound
      setTimeout(() => {
        useBattleStore.getState().endBattle({ playerLost: true });
        enemyAttackState.setEnemyAttacked(false);
      }, 2000);
    } else {
      // Lock battle to prevent player from attacking again
      useBattleStore.getState().setBattleLock(true);
      enemyAttackState.setEnemyAttacked(true);

      // Delay to allow player to see damage
      setTimeout(() => {
        usePlayerStore.getState().updatePlayerHp(newPlayerHp);
        useBattleStore.getState().setBattleLock(false);
        enemyAttackState.setEnemyAttacked(false);
      }, DELAY_AFTER_ENEMY_ATTACK);
    }
  },

  progressBattle: async () => {
    const progress = useProgressStore.getState();
    const user = useUserStore.getState().user;
    const nextCheckpointIndex = progress.dungeon.currentCheckpoint + 1;
    const hasMoreCheckpoints =
      nextCheckpointIndex < progress.dungeon.checkpoints.length;

    if (hasMoreCheckpoints) {
      useEnemyStore
        .getState()
        .setNextEnemy(progress.dungeon, progress.world.id, nextCheckpointIndex);

      useProgressStore.getState().updateDungeonCheckpoint(nextCheckpointIndex);
      useBattleStore.getState().setBattleLock(false);
    } else {
      // Clear intervals
      useEnergyStore.getState().clearEnergyRegen();
      useEnemyStore.getState().clearEnemyAttackInterval();

      // Process dungeon completion and get chest ticket
      try {
        const response = await processDungeonCompleted({
          userId: user.id,
          jwt: user.jwt,
        });

        // Update chest hash in ChestStore if one was received
        if (response.data?.hash) {
          useChestStore.getState().setChestHash(response.data.hash);
        }

        useEnemyStore.getState().reset();
        useBattleStore.getState().endBattle({ completed: true });
      } catch (error) {
        console.error("Failed to process dungeon completion:", error);
        useEnemyStore.getState().reset();
        useBattleStore.getState().endBattle({ completed: true });
      }
    }
  },

  endBattle: () => {
    useEnergyStore.getState().clearEnergyRegen();
    useLootStore.getState().resetLoot();
    useEnemyStore.getState().reset();
    useBattleStore.getState().reset();
  },

  usePotion: (type) => {
    const battleStore = useBattleStore.getState();

    if (!battleStore.battleStarted || battleStore.battleLock) {
      return false;
    }

    return usePotionStore.getState().usePotion(type);
  },
};
