import { create } from "zustand";
import { useUserStore } from "./useUserStore";
import { upgradeStats, changeSelectedHero } from "@/lib/api";
import { gameStatsActions } from "../actions/gameStats";
import { metrics } from "./Metrics";

export const usePlayerStore = create((set) => ({
  player: {
    attack: 0,
    hp: 0,
    maxHp: 0,
    energyRegenRate: 0,
  },

  setPlayerStats: (stats) =>
    set((state) => ({
      player: {
        ...state.player,
        ...stats,
      },
    })),

  initializePlayerStats: (userData) => {
    const totalStats = gameStatsActions.calculateTotalStats(
      userData.equippedItems
    );
    set({
      player: {
        attack: totalStats.attack,
        hp: totalStats.hp,
        maxHp: totalStats.hp,
        energyRegenRate: totalStats.energyRegenRate,
      },
    });
  },

  updatePlayerHp: (newHp) =>
    set((state) => ({
      player: {
        ...state.player,
        hp: newHp,
      },
    })),

  recalculateTotalStats: () => {
    const user = useUserStore.getState().user;
    const newTotalStats = gameStatsActions.calculateTotalStats(
      user.equippedItems
    );

    set((state) => ({
      player: {
        ...state.player,
        attack: newTotalStats.attack,
        hp: newTotalStats.hp,
        maxHp: newTotalStats.hp,
        energyRegenRate: newTotalStats.energyRegenRate,
      },
    }));
  },

  upgradePlayerStat: async (stat) => {
    const user = useUserStore.getState().user;
    const upgradeCost = gameStatsActions.calculateStatUpgradeCost(stat);

    if (user.resources.gold < upgradeCost) {
      return { success: false, error: "Not enough gold" };
    }

    try {
      const { data } = await upgradeStats({
        userId: user.id,
        stat,
        jwt: user.jwt,
      });

      useUserStore.getState().setUser({
        ...user,
        resources: data.user.resources,
        statLevels: data.user.statLevels,
      });

      const newTotalStats = gameStatsActions.calculateTotalStats(
        user.equippedItems
      );
      set((state) => ({
        player: {
          ...state.player,
          attack: newTotalStats.attack,
          hp: newTotalStats.hp,
          maxHp: newTotalStats.hp,
          energyRegenRate: newTotalStats.energyRegenRate,
        },
      }));

      metrics.add("statsUpgraded", 1);
      return { success: true };
    } catch (error) {
      console.error("Failed to upgrade player stat:", error);
      return {
        success: false,
        error: error.response?.data?.error || error.message,
      };
    }
  },

  changeHero: async (heroId) => {
    try {
      const user = useUserStore.getState().user;

      const { data } = await changeSelectedHero({
        userId: user.id,
        heroId: heroId,
        jwt: user.jwt,
      });
      const { user: newUser } = data;

      useUserStore.getState().setUser(newUser);

      const newTotalStats = gameStatsActions.calculateTotalStats(
        newUser.equippedItems
      );
      set((state) => ({
        player: {
          ...state.player,
          attack: newTotalStats.attack,
          hp: newTotalStats.hp,
          maxHp: newTotalStats.hp,
        },
      }));

      return { success: true };
    } catch (error) {
      console.error("Failed to change hero:", error);
      return { success: false, error: error };
    }
  },
}));
