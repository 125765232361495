import { useEffect, useState, useRef, memo, useMemo, useCallback } from "react";
import { Stack, Box } from "@mui/material";
import { SmartImage } from "@/components/SmartImage";
import { H1 } from "./Typography";
import { coinClaimSound, coinSound } from "@/utils/sounds";
import { primary } from "@/theme/colors";
import { useSounds } from "@/hooks/useSound";

const duration = 700;
const stepTime = 50;
const steps = duration / stepTime;

const sizeConfig = {
  S: { fontSize: 16, iconSize: 30, minWidth: "40px" },
  M: { fontSize: 30, iconSize: 60, minWidth: "50px" },
  L: { fontSize: 40, iconSize: 80, minWidth: "80px" },
};

export const CoinCounter = memo(
  ({
    count = 0,
    withIcon = true,
    iconPosition = "left",
    size = "M",
    ...other
  }) => {
    const [displayCount, setDisplayCount] = useState(count);
    const [isAnimating, setIsAnimating] = useState(false);
    const [opacityLevel, setOpacityLevel] = useState(0);
    const intervalRef = useRef(null);
    const flickerIntervalRef = useRef(null);
    const { playSound } = useSounds();

    const triggerFlickerAnimation = useCallback(() => {
      clearInterval(flickerIntervalRef.current);
      let flickerCount = 0;
      const maxFlickers = 5;

      flickerIntervalRef.current = setInterval(() => {
        setIsAnimating((prev) => !prev);
        setOpacityLevel((prev) => (flickerCount % 2 === 0 ? 0.3 : 0));
        flickerCount++;
        if (flickerCount >= maxFlickers * 2) {
          clearInterval(flickerIntervalRef.current);
          setIsAnimating(false);
          setOpacityLevel(0);
        }
      }, 50);
    }, []);

    const animateCount = useCallback((start, end) => {
      clearInterval(intervalRef.current);
      const increment = (end - start) / steps;

      let currentCount = start;
      triggerFlickerAnimation();

      intervalRef.current = setInterval(() => {
        currentCount += increment;
        if (
          (increment > 0 && currentCount >= end) ||
          (increment < 0 && currentCount <= end)
        ) {
          clearInterval(intervalRef.current);
          setDisplayCount(end);
        } else {
          setDisplayCount(Math.floor(currentCount));
        }
      }, stepTime);
    }, []);

    useEffect(() => {
      const difference = Math.abs(count - displayCount);

      if (difference > 1) {
        animateCount(displayCount, count);
      } else {
        setDisplayCount(count);
        if (count !== displayCount) {
          triggerFlickerAnimation();
        }
      }

      if (difference > 0 && difference < 50) {
        playSound(coinSound);
      } else if (difference > 50) {
        playSound(coinClaimSound);
      }
    }, [count]);

    const { fontSize, iconSize, minWidth } = useMemo(
      () => sizeConfig[size],
      [size]
    );

    const animationStyle = useMemo(
      () => ({
        transform: isAnimating ? "scale(1.3)" : "scale(1)",
        transition: "transform 0.05s ease-out",
        filter: `brightness(${1 + opacityLevel * 1})`,
      }),
      [isAnimating, opacityLevel]
    );

    const IconComponent = useMemo(
      () => (
        <Box
          sx={{
            ...animationStyle,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SmartImage
            src="/images/icons/coin.webp"
            height={iconSize}
            width={iconSize}
            alt="Icon"
          />
        </Box>
      ),
      [animationStyle, iconSize]
    );

    return (
      <Stack
        direction="row"
        alignItems="center"
        sx={{ zIndex: 1, minWidth: "50px", pointerEvents: "none" }}
        {...other}
      >
        {withIcon && iconPosition === "left" && IconComponent}
        <Box
          sx={{
            ...animationStyle,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: minWidth,
          }}
        >
          <H1
            sx={{
              color: primary.white,
              textShadow: "0px 0px 20px rgba(0,0,0,1)",
              fontSize: fontSize,
              textAlign: "center",
            }}
          >
            {typeof displayCount === "number"
              ? displayCount.toLocaleString()
              : displayCount}
          </H1>
        </Box>
        {withIcon && iconPosition === "right" && IconComponent}
      </Stack>
    );
  }
);

CoinCounter.displayName = "CoinCounter";
