import { create } from "zustand";
import { updateTutorialProgress } from "@/lib/api";
import { LOCKED_MAP } from "@/constants";
import { useUserStore } from "./useUserStore";

export const TUTORIAL_STEPS = {
  INTRO_1: 0,
  INTRO_2: 1,
  INTRO_3: 2,
  HOME_START: 3,
  BATTLE_TAP: 4,
  BATTLE_ABILITY: 5,
  BATTLE_CLAIM: 6,
  HOME_UPGRADE: 7,
  HOME_RESTART: 8,
  USE_POTION: 9,
  COMPLETED: 10,
};

export const HAND_POSITIONS = {
  HOME_START: { bottom: "15%", left: "42%" },
  BATTLE_TAP: { top: "50%", left: "50%" },
  BATTLE_ABILITY: { bottom: "15%", right: "10%" },
  BATTLE_CLAIM: { bottom: "30%", left: "40%" },
  HOME_UPGRADE: { bottom: "5%", left: "15%" },
  HOME_RESTART: { bottom: "15%", left: "42%" },
  USE_POTION: { bottom: "15%", left: "3%" },
};

export const UI_UNLOCKS = {
  WORLD_PROGRESS_BAR: "COMPLETED",
  TOP_BAR: "BATTLE_CLAIM",
  SETTINGS_BUTTON: "COMPLETED",
  STAT_ITEMS: "HOME_UPGRADE",
  BOTTOM_BAR: "COMPLETED",
  CANCEL_BATTLE_BUTTON: "COMPLETED",
  POTIONS: "USE_POTION",
};

export const useIntroState = create((set, get) => ({
  ongoingTutorial: false,
  showHand: true,
  step: "INTRO_1",

  initializeTutorial: (dbStep) => {
    // If dbStep is undefined, assume the tutorial is completed
    const step = dbStep || "COMPLETED";
    console.log("👉  step:", step);

    set({
      step,
      ongoingTutorial: step !== "COMPLETED",
      showHand: step !== "COMPLETED", // Only show hand if tutorial is ongoing
    });
  },

  setStep: async (step, showHand = true) => {
    console.log("👉  setStep called:", step);
    const currentStep = get().step;
    const ongoingTutorial = get().ongoingTutorial;

    if (TUTORIAL_STEPS[step] < TUTORIAL_STEPS[currentStep]) {
      console.warn("Cannot go backwards in tutorial");
      return;
    }

    if (ongoingTutorial) {
      try {
        const user = useUserStore.getState().user;
        await updateTutorialProgress({
          userId: user.id,
          jwt: user.jwt,
          step,
        });
      } catch (error) {
        console.error("Failed to update tutorial progress:", error);
        return;
      }
    }

    if (step === "COMPLETED" && ongoingTutorial) {
      set({ step, ongoingTutorial: false, showHand: false });
    } else {
      set({
        step,
        showHand: showHand,
        ongoingTutorial: step !== "COMPLETED",
      });
    }
  },

  isUnlocked: (elementKey) => {
    const currentStep = get().step;
    return (
      TUTORIAL_STEPS[currentStep] >= TUTORIAL_STEPS[UI_UNLOCKS[elementKey]]
    );
  },

  getHandPosition: () => {
    const { step, showHand } = get();
    return showHand ? HAND_POSITIONS[step] || null : null;
  },

  isXPLocked: (item) => {
    const user = useUserStore.getState().user;
    return user.xp < LOCKED_MAP[item];
  },
}));
