import { useImageLoadStore } from "@/hooks/useImageLoadStore";
import { useEffect, useRef } from "react";
import { Image } from "./Image";

export function SmartImage(props) {
  const { registerImage, imageLoaded } = useImageLoadStore();
  const registered = useRef(false);

  useEffect(() => {
    if (!registered.current) {
      registerImage();
      registered.current = true;
    }
  }, [registerImage]);

  return (
    <Image
      loading="eager"
      alt={props.alt || "image"}
      {...props}
      onLoad={() => {
        imageLoaded();
        props.onLoad && props.onLoad();
      }}
    />
  );
}
