import { create } from "zustand";
import { useUserStore } from "./useUserStore";
import { useEnergyStore } from "./useEnergyStore";
import { usePlayerStore } from "./usePlayerStore";
import { updateUser } from "@/lib/api";
import { POTION_BUFF_DURATION } from "@/constants";

export const usePotionStore = create((set, get) => ({
  healthBuffActive: false,
  energyBuffActive: false,
  damageBuffActive: false,

  usePotion: async (type) => {
    const user = useUserStore.getState().user;
    if (!user?.resources?.potions?.[type]) {
      console.warn(`No ${type} potion`);
      return false;
    }

    // Optimistically update frontend state immediately
    useUserStore.getState().updateUserPotions({
      ...user.resources.potions,
      [type]: user.resources.potions[type] - 1,
    });

    // Apply potion effect immediately
    switch (type) {
      case "health": {
        const player = usePlayerStore.getState().player;
        usePlayerStore.getState().updatePlayerHp(player.maxHp);
        set({ healthBuffActive: true });
        setTimeout(() => {
          set({ healthBuffActive: false });
        }, POTION_BUFF_DURATION);
        break;
      }
      case "energy": {
        useEnergyStore.getState().addEnergy(15);
        set({ energyBuffActive: true });
        setTimeout(() => {
          set({ energyBuffActive: false });
        }, POTION_BUFF_DURATION);
        break;
      }
      case "damage": {
        set({ damageBuffActive: true });
        setTimeout(() => {
          set({ damageBuffActive: false });
        }, POTION_BUFF_DURATION);
        break;
      }
    }

    // Update backend asynchronously
    try {
      await updateUser({
        userId: user.id,
        update: {
          resources: {
            potions: {
              [type]: -1,
            },
          },
        },
        jwt: user.jwt,
      });
    } catch (error) {
      console.error("Failed to sync potion usage:", error);
      // Rollback frontend state if backend update fails
      useUserStore.getState().updateUserPotions({
        ...user.resources.potions,
      });
      return false;
    }

    return true;
  },

  isDamageBuffActive: () => get().damageBuffActive,
}));
