import { Box } from "@mui/material";

export const SunBurst = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        background:
          "radial-gradient(transparent 50%, white), radial-gradient(yellow, transparent 70%)",
        maskImage: "radial-gradient(rgba(0, 0, 0, 1.0) 40%, transparent 65%)",
        WebkitMaskImage:
          "radial-gradient(rgba(0, 0, 0, 1.0) 40%, transparent 65%)",
        borderRadius: "50%",
        "&::before, &::after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          transformOrigin: "center",
          borderRadius: "50%",
          maskImage: "radial-gradient(rgba(0, 0, 0, 1.0) 40%, transparent 65%)",
          WebkitMaskImage:
            "radial-gradient(rgba(0, 0, 0, 1.0) 40%, transparent 65%)",
        },
        "&::before": {
          background:
            "repeating-conic-gradient(from 0deg, yellow 0deg 20deg, transparent 20deg 40deg)",
          animation: "rotate 720s linear, scale 3s linear infinite",
        },
        "&::after": {
          background: `
            radial-gradient(yellow, orange 10%, transparent calc(27% + 3px) 100%),
            radial-gradient(gold, transparent 70%),
            repeating-conic-gradient(from 0deg, gold 0deg 5deg, transparent 5deg 10deg)
          `,
          transform: "rotate(15deg)",
          animation: "rotate 360s linear",
        },
      }}
    />
  );
};
