import {
  backgroundColorSecondary,
  borderRadiusNew,
  maxWidth,
} from "@/theme/colors";
import { Box, Modal } from "@mui/material";
import { H3 } from "./Typography";
import { darkenColor } from "@/utils/misc";
import { closeSound } from "@/utils/sounds";
import { useClickSound } from "@/hooks/useClickSound";
import { SmartImage } from "./SmartImage";
import { CloseIcon } from "./CloseIcon";

export function SimpleModal({
  open,
  onClose,
  children,
  title,
  color: mainColor = backgroundColorSecondary,
  titleBgColor,
  titleImage,
  contentSx,
  modalSx,
  titleSx,
  mainModalSx,
}) {
  const handleClose = useClickSound(onClose, "", closeSound);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        backgroundColor: "rgba(0,0,0,0.5)",
        ...mainModalSx,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "300px",
          maxWidth: maxWidth,
          backgroundColor: mainColor,
          border: "1px solid #000",
          borderRadius: borderRadiusNew,
          boxShadow: `
                inset 0px 3px 0px 0px rgba(255,255,255,0.1), 
                inset 0px -3px 0px 0px rgba(0, 0, 0, 0.4), 
                7px 7px 10px 0px rgba(0,0,0,.1), 
                0px 4px 2px 0px rgba(0,0,0,.5)
              `,
          ":focus-visible": {
            outline: "none",
          },
          animation: open ? "bounce-in-2 0.25s" : "none",
          ...modalSx,
        }}
      >
        <Box
          display={title ? "block" : "none"}
          textAlign="center"
          sx={{
            position: "relative",
            borderTopRightRadius: borderRadiusNew,
            borderTopLeftRadius: borderRadiusNew,
            backgroundColor: titleBgColor || darkenColor(mainColor, 15),
            padding: "10px",
            boxShadow: `
                  inset 0px 3px 0px 0px rgba(255,255,255,0.3), 
                  inset 0px -3px 0px 0px rgba(0, 0, 0, 0.1), 
                  7px 7px 10px 0px rgba(0,0,0,.1)
                `,
            ...titleSx,
          }}
        >
          {titleImage && (
            <SmartImage
              src={titleImage}
              width={120}
              height={120}
              alt={title}
              style={{
                position: "absolute",
                width: "60px",
                height: "auto",
                top: "50%",
                left: "10px",
                transform: "translateY(-50%)",
              }}
            />
          )}
          <H3 color="white">{title}</H3>

          <CloseIcon
            onClose={onClose}
            color={darkenColor(titleBgColor || mainColor, 20)}
            sx={{
              top: "-4px",
              right: "-4px",
            }}
          />
        </Box>

        <Box
          sx={{
            padding: "20px",
            borderBottomRightRadius: borderRadiusNew,
            borderBottomLeftRadius: borderRadiusNew,
            ...contentSx,
          }}
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
}
