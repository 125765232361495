import { Box, lighten, Stack } from "@mui/material";
import { borderRadiusNew, primary } from "@/theme/colors";
import { Paragraph, Small } from "@/components/Typography";
import { SimpleModal } from "@/components/SimpleModal";
import { SmartImage } from "@/components/SmartImage";

const MONSTER_COLORS = {
  enemy: "#FF4500",
  elite: "#FF0000",
  boss: "#8A2BE2",
};

export function AtlasMonsterModal({ selectedMonster, setSelectedMonster }) {
  if (!selectedMonster) return null;

  return (
    <SimpleModal
      open={!!selectedMonster}
      onClose={() => setSelectedMonster(null)}
      title={selectedMonster?.displayName}
      titleBgColor={primary.purple}
      contentSx={{
        padding: "0px",
        background: "linear-gradient(to bottom, #B874FC, #7029D4)",
        height: "100%",
      }}
    >
      <Stack position="relative">
        <Box
          sx={{
            position: "absolute",
            top: "6px",
            left: "-2px",
            backgroundColor: MONSTER_COLORS?.[selectedMonster?.type],
            border: `1px solid ${lighten(
              MONSTER_COLORS?.[selectedMonster?.type],
              0.2
            )}`,
            boxShadow: "inset 0px -11px 0px 0px rgba(0, 0, 0, 0.1)",
            padding: "0 4px",
            borderRadius: borderRadiusNew,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: "22px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paragraph>{selectedMonster?.type}</Paragraph>
        </Box>

        <Box sx={{ position: "absolute", top: "6px", right: "10px" }}>
          <Paragraph>
            {selectedMonster?.worlds.length > 1
              ? `Worlds ${selectedMonster?.worlds
                  .map((world) => ` ${world}`)
                  .join(", ")}`
              : `World ${selectedMonster?.worlds[0]}`}
          </Paragraph>
        </Box>
        <SmartImage
          src={`/images/enemies/${selectedMonster?.id}.webp`}
          width={500}
          height={500}
          style={{
            width: "300px",
            height: "auto",
            margin: "0 auto",
            borderRadius: borderRadiusNew,
          }}
        />

        <Box
          sx={{
            padding: 1,
            backgroundColor: "rgba(0,0,0,0.1)",
            margin: "0 10px 10px 10px",
            borderRadius: borderRadiusNew,
          }}
        >
          <Small sx={{ fontSize: "12px" }}>
            {selectedMonster?.description}
          </Small>
        </Box>
      </Stack>
    </SimpleModal>
  );
}
