import { SmartImage } from "@/components/SmartImage";
import { Small } from "@/components/Typography";
import { primary } from "@/theme/colors";
import { Stack } from "@mui/material";
import { useUserStore } from "../logic/useUserStore";
import { combatActions } from "../actions/combatActions";
import { useIntroState } from "../logic/useIntroState";
import {
  potionDamageSound,
  potionEnergySound,
  potionHealthSound,
} from "@/utils/sounds";
import { useState, useEffect, useRef } from "react";
import { usePotionStore } from "../logic/usePotionStore";
import { Box } from "@mui/material";
import { POTION_BUFF_DURATION } from "@/constants";

function Potion({ type, count = 3 }) {
  const canBeUsed = count > 0;
  const { setStep, ongoingTutorial } = useIntroState();
  const { healthBuffActive, energyBuffActive, damageBuffActive } =
    usePotionStore();
  const [timeRemaining, setTimeRemaining] = useState(0);
  const timerRef = useRef(null);

  const isBuffActive = {
    health: healthBuffActive,
    energy: energyBuffActive,
    damage: damageBuffActive,
  }[type];

  const color = {
    health: "red",
    energy: "green",
    damage: "blue",
  }[type];

  const canbeUsedStyle = canBeUsed
    ? `
      7px 7px 10px 0px rgba(0,0,0,.1), 
      0px 0px 10px 2px white,
      0px 0px 30px 0px gold
    `
    : "7px 7px 10px 0px rgba(0,0,0,.1)";

  useEffect(() => {
    if (isBuffActive) {
      setTimeRemaining(POTION_BUFF_DURATION / 1000);
      if (timerRef.current) clearInterval(timerRef.current);

      timerRef.current = setInterval(() => {
        setTimeRemaining((prev) => {
          if (prev <= 0) {
            clearInterval(timerRef.current);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      setTimeRemaining(0);
      if (timerRef.current) clearInterval(timerRef.current);
    }

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [isBuffActive]);

  const handleClick = () => {
    if (canBeUsed && !isBuffActive) {
      combatActions.usePotion(type);

      if (type === "health") {
        potionHealthSound.play();
      } else if (type === "energy") {
        potionEnergySound.play();
      } else {
        potionDamageSound.play();
      }

      if (ongoingTutorial) {
        setStep("USE_POTION", false);
        setTimeout(() => {
          setStep("COMPLETED");
        }, 2000);
      }
    }
  };

  return (
    <Stack
      onClick={handleClick}
      sx={{
        cursor: canBeUsed ? "pointer" : "default",
        position: "relative",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        height: "40px",
        width: "40px",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid ${canBeUsed ? "white" : "gray"}`,
        boxShadow: isBuffActive
          ? `
            7px 7px 10px 0px rgba(0,0,0,.1), 
            0px 0px 10px 2px white,
            0px 0px 30px 0px gold,
            0px 0px 15px 2px ${color}
          `
          : canbeUsedStyle,
      }}
    >
      <SmartImage
        src={`/images/potions/${type}.webp`}
        height={32}
        width={32}
        alt={type}
      />
      <Small
        sx={{
          position: "absolute",
          bottom: "-4px",
          right: "-4px",
          backgroundColor: primary.black,
          height: "14px",
          width: "14px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        {count}
      </Small>
      {isBuffActive && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: `${(timeRemaining / (POTION_BUFF_DURATION / 1000)) * 100}%`,
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            transition: "height 1s linear",
          }}
        />
      )}
    </Stack>
  );
}

export const Potions = () => {
  const { user } = useUserStore();
  const { isUnlocked } = useIntroState();
  const { potions } = user.resources;

  if (!isUnlocked("POTIONS")) {
    return null;
  }

  return (
    <Stack
      spacing={1}
      sx={{ position: "absolute", top: "-150px", left: "20px" }}
    >
      <Potion type="health" count={potions.health} />
      <Potion type="energy" count={potions.energy} />
      <Potion type="damage" count={potions.damage} />
    </Stack>
  );
};
