import { Modal, Box } from "@mui/material";

export function DarkModal({ open, children, modalSx, boxSx }) {
  return (
    <Modal
      open={open}
      sx={{
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.7)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...modalSx,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "440px",
          height: "auto",
          position: "relative",
          ":focus-visible": {
            outline: "none",
          },
          animation: open ? "bounce-in 0.25s" : "none",
          ...boxSx,
        }}
      >
        {children}
      </Box>
    </Modal>
  );
}
