import { H1, H6 } from "@/components/Typography";
import { IconButton, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import { chestOpenSound, fairySound, magicChorusSound } from "@/utils/sounds";
import { motion, AnimatePresence } from "framer-motion";
import { useAsyncEffect } from "@/hooks/useAsyncEffect";
import { useAlert } from "@/hooks/useAlert";
import { ItemRewards } from "@/components/ItemRewards";
import { useTranslation } from "react-i18next";
import { ChestRarityDisplay } from "@/components/ChestRarityDisplay";
import { DarkModal } from "@/components/DarkModal";
import { SmartImage } from "@/components/SmartImage";
import ResourceRewards from "@/components/ResourceRewards";
import { useMusic } from "@/hooks/useMusic";
import { useSounds } from "@/hooks/useSound";
import { ChestAnimation } from "@/components/ChestAnimationGsap";
import { CloseOutlined } from "@mui/icons-material";
import { StarEffect } from "@/components/effects/StarEffect";
import { InvisibleClickBox } from "@/components/InvisibleClickBox";
import { useHapticFeedback } from "@telegram-apps/sdk-react";
import { openChest } from "@/lib/api";
import { claimLoot } from "@/lib/api";
import { useUserStore } from "../logic/useUserStore";
import { useAssetStore } from "../logic/useAssetStore";
import { usePlayerMetricsStore } from "../logic/usePlayerMetricsStore";

const FIRST_DELAY = 600;
const SECOND_DELAY = 2000;

export function OpenChestModal({ open, onClaim, chestLevel, chestHash }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [animate, setAnimate] = useState(false);
  const { stopMainMusic } = useMusic();
  const { playSound, stopSound } = useSounds();
  const { fetchAndUpdateMetrics } = usePlayerMetricsStore();
  const { user } = useUserStore();
  const { setError } = useAlert();
  const [fireConfetti, setFireConfetti] = useState(false);
  const [rewards, setRewards] = useState({
    gold: 0,
    gems: 0,
    runes: 0,
    items: [],
    stones: {},
  });
  const hapticFeedback = useHapticFeedback(true);
  const [claiming, setClaiming] = useState(false);
  const { CHESTS, RARITY_COLORS, ITEMS } = useAssetStore();

  const chestRarity = CHESTS?.[chestLevel - 1]?.rarity;
  const rarityColor = RARITY_COLORS[chestRarity];

  const handleClick = (e) => {
    isOpen ? handleClaimChest(e) : handleOpenChest(e);
  };

  const handleOpenChest = () => {
    setAnimate(false);
    setIsOpen(true);
    hapticFeedback?.impactOccurred("medium");
    setTimeout(() => {
      setFireConfetti(true);
      hapticFeedback?.impactOccurred("heavy");
    }, 1000);
    setShowText(false); // Hide text immediately when opening the chest
  };

  const handleClaimChest = async (e) => {
    if (claiming) return; // Prevent multiple claims

    try {
      setClaiming(true);
      const response = await claimLoot({
        userId: user.id,
        jwt: user.jwt,
      });

      setIsOpen(false);
      fetchAndUpdateMetrics();
      onClaim(e, response.data.rewards);
    } catch (error) {
      console.error("Failed to claim chest:", error);
      setError(error.response?.data?.error || "Failed to claim chest");
      setIsOpen(false);
      onClaim(e);
    } finally {
      setClaiming(false);
    }
  };

  useEffect(() => {
    if (open) {
      setAnimate(true);
    }
  }, [open]);

  useEffect(() => {
    stopMainMusic();
    if (isOpen) {
      playSound(fairySound);
      playSound(chestOpenSound);
      stopSound(magicChorusSound);
    } else {
      playSound(magicChorusSound);
      stopSound(fairySound);
    }
    return () => {
      stopSound(fairySound);
      stopSound(chestOpenSound);
      stopSound(magicChorusSound);
    };
  }, [isOpen]);

  useAsyncEffect(async () => {
    if (!chestHash) {
      return setError("Chest is not defined");
    }
    try {
      const response = await openChest({
        userId: user.id,
        chestHash,
        jwt: user.jwt,
      });

      const fullItems = response.data?.items?.map((itemId) => {
        return ITEMS?.find((i) => i.id === parseInt(itemId, 10));
      });

      setRewards({
        gold: response.data?.gold || 0,
        gems: response.data?.gems || 0,
        runes: response.data?.runes || 0,
        stones: response.data?.stones || {},
        items: fullItems || [],
      });
    } catch (error) {
      console.error("Failed to open chest:", error);
      setError(error.message);
    }
  }, [chestHash]);

  const [showText, setShowText] = useState(false);
  const [displayedText, setDisplayedText] = useState("rewards.openChest");

  useEffect(() => {
    let timer;
    if (open) {
      setShowText(false);
      timer = setTimeout(
        () => {
          setShowText(true);
          setDisplayedText(isOpen ? "rewards.continue" : "rewards.openChest");
        },
        isOpen ? SECOND_DELAY : FIRST_DELAY
      );
    } else {
      setShowText(false);
      setDisplayedText("rewards.openChest");
    }
    return () => clearTimeout(timer);
  }, [open, isOpen]);

  return (
    <DarkModal
      open={open}
      modalSx={{ alignItems: "flex-start", padding: "20px 0" }}
      boxSx={{ height: "100%" }}
    >
      <Stack
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        height="100%"
      >
        {isOpen && <StarEffect />}

        {isOpen ? (
          <InvisibleClickBox
            key="chest-open"
            onClick={handleClick}
            delay={SECOND_DELAY}
          />
        ) : (
          <InvisibleClickBox
            key="chest-close"
            onClick={handleClick}
            delay={FIRST_DELAY}
          />
        )}

        <H1>{t("rewards.title")}</H1>

        <Stack mt={2} position="relative">
          {isOpen ? (
            <>
              <ItemRewards items={rewards.items} />
              <ResourceRewards rewards={rewards} fireConfetti={fireConfetti} />
            </>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              height={200}
            >
              <ChestRarityDisplay
                chestRarity={chestRarity}
                rarityColor={rarityColor}
              />
            </Stack>
          )}
        </Stack>

        <Stack
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ChestAnimation
            isOpen={isOpen}
            chestLevel={chestLevel}
            animate={animate}
            onOpen={handleOpenChest}
          />

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            style={{ zIndex: -1 }}
          >
            <SmartImage
              src="/images/effects/burst_1.webp"
              alt="Background"
              height={260}
              width={260}
              style={{
                position: "absolute",
                // zIndex: 0,
                top: "-30%",
                left: 0,
                width: "100%",
                height: "auto",
                animation: "rotateImage 5s linear infinite",
                zIndex: -1,
              }}
            />
          </motion.div>

          <Stack zIndex={10}>
            <AnimatePresence mode="wait">
              {showText && (
                <motion.div
                  key={displayedText}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <H6 textAlign="center" onClick={handleClick}>
                    {t(displayedText)}
                  </H6>
                </motion.div>
              )}
            </AnimatePresence>
          </Stack>
          {import.meta.env.DEV && (
            <IconButton
              onClick={() => setIsOpen(false)}
              sx={{ position: "absolute", bottom: 10, right: 10, zIndex: 1000 }}
            >
              <CloseOutlined color="white" />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </DarkModal>
  );
}
