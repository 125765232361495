import { alpha, lighten, Stack } from "@mui/material";
import { H5 } from "@/components/Typography";
import { darkenColor } from "@/utils/misc";
import { Ribbon3 } from "../../components/Ribbon";
import { borderRadiusNew } from "@/theme/colors";
import { StoreItemContent } from "./StoreItemContent";
import { StoreItemChest } from "./StoreItemChest";
import { useState, useEffect } from "react";
import { PurchaseConfirmModal } from "./PurchaseConfirmModal";
import { useRefreshUser } from "@/hooks/useRefreshUser";
import { useAlert } from "@/hooks/useAlert";
import { OpenChestModal } from "@/game/parts/OpenChestModal";
import { SmartImage } from "@/components/SmartImage";
import { glowAnimation } from "@/styles/animations";
import { useJWT } from "@/hooks/useJWT";
import {
  generateInvoice,
  getInvoice,
  getPendingChests,
  purchaseGold,
  purchasePotions,
} from "@/lib/api";
import { openInvoice } from "@/utils/telegram";
import { PurchaseSuccessModal } from "./PurchaseSuccessModal";
import { StoreItemPack } from "./StoreItemPack";
import { StoreItemMythic } from "./StoreItemMythic";
import { useClickSound } from "@/hooks/useClickSound";
import { useUserStore } from "../logic/useUserStore";
import { useChestStore } from "../logic/useChestStore";

export function StoreItem({ item, category }) {
  const [buyChestLevel, setBuyChestLevel] = useState();
  const { purchaseChest, chestHash, setPendingChests } = useChestStore();
  const { user } = useUserStore();
  const refreshUser = useRefreshUser();
  const { setError } = useAlert();
  const jwt = useJWT();
  const [invoiceId, setInvoiceId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const color = item.color || category.color;
  const background =
    item.background ||
    `linear-gradient(45deg, ${lighten(color, 0.3)} 0%, ${color} 100%)`;
  const height = item.height || 150;
  const darkColor = darkenColor(color, 30);
  const isDisabled =
    item?.id === "gold-200" &&
    user?.cooldowns?.freeGoldClaim &&
    new Date().getTime() - new Date(user.cooldowns.freeGoldClaim).getTime() <
      24 * 60 * 60 * 1000;

  const filter =
    (isDisabled && "grayscale(100%)") ||
    (item.background && "saturate(150%)") ||
    "none";

  const itemClickHandler = () => {
    if (isDisabled) return;
    setShowConfirmModal(true);
  };

  const handleItemClick = useClickSound(itemClickHandler);

  const handleChestPurchaseComplete = () => {
    setBuyChestLevel();
    refreshUser();
  };

  const handleGoldPurchase = async () => {
    try {
      const { data } = await purchaseGold({
        userId: user.id,
        amount: item.amount,
        jwt,
      });
      if (data.success) {
        setShowConfirmModal(false);
        setShowSuccessModal(true);
        refreshUser();
      } else {
        setError(data.error);
      }
    } catch (error) {
      setError(error.response?.data?.error || error.message);
    }
  };

  const handleChestPurchase = async () => {
    const result = await purchaseChest(item.chestLevel);
    if (result.success) {
      setBuyChestLevel(item.chestLevel);
      setShowConfirmModal(false);
    } else {
      setError(result.error);
    }
  };

  const handlePremiumPurchase = async () => {
    try {
      const { data } = await generateInvoice({ productId: item.id, jwt });
      console.log("👉  invoice link:", data.invoiceLink);
      setInvoiceId(data?.invoice?.id);
      await openInvoice(data?.invoiceLink);

      // Check for new chests after a delay
      setTimeout(async () => {
        try {
          const response = await getPendingChests({
            userId: user.id,
            jwt: user.jwt,
          });
          setPendingChests(response.data.chests);
        } catch (error) {
          console.error("Error fetching pending chests:", error);
        }
      }, 5000); // 5 second delay
    } catch (error) {
      setError(error.response?.data?.error || error.message);
    }
  };

  const handlePotionPurchase = async () => {
    try {
      // Extract potion type from item.id (e.g., "potion-health" -> "health")
      const potionType = item.id.replace("potion-", "");

      const { data } = await purchasePotions({
        userId: user.id,
        potionType,
        amount: item.amount,
        jwt,
      });

      if (data.success) {
        setShowConfirmModal(false);
        setShowSuccessModal(true);
        refreshUser();
      } else {
        setError(data.error);
      }
    } catch (error) {
      setError(error.response?.data?.error || error.message);
    }
  };

  const handlePurchase = async () => {
    switch (item.type) {
      case "potion":
        await handlePotionPurchase();
        break;
      case "gold":
        await handleGoldPurchase();
        break;
      case "chest":
        await handleChestPurchase();
        break;
      case "gems":
      case "runes":
      case "pack":
        await handlePremiumPurchase();
        break;
    }
  };

  useEffect(() => {
    let intervalId;
    if (invoiceId) {
      intervalId = setInterval(async () => {
        try {
          const { data } = await getInvoice({ invoiceId, jwt });
          if (data.status === "PAID") {
            clearInterval(intervalId);
            setShowConfirmModal(false);
            setShowSuccessModal(true);
            refreshUser();
          }
        } catch (error) {
          console.error("Error checking invoice status:", error);
        }
      }, 2000); // Check every 2 seconds
    }
    return () => clearInterval(intervalId);
  }, [invoiceId]);

  return (
    <>
      <Stack
        onClick={handleItemClick}
        sx={{
          position: "relative",
          overflow: "hidden",
          borderRadius: borderRadiusNew,
          background: background,
          height,
          display: "flex",
          wordBreak: "break-word",
          textAlign: "center",
          justifyContent: "space-between",
          animation: `${glowAnimation(color)} 2s ease-in-out infinite`,
          boxShadow: `
            inset 2px 2px 1px 0px rgba(255,255,255,.5), 
            7px 7px 20px 0px rgba(0,0,0,.1), 
            0px 4px 4px 0px rgba(0,0,0,.5)
          `,
          "&:active": {
            transform: "translateY(2px)",
            boxShadow: "inset 2px 2px 2px 0px rgba(255,255,255,.5)",
          },
          filter: filter,
        }}
      >
        {item?.label && (
          <Ribbon3 text={item.label} color={darkenColor(color, 50)} />
        )}

        {item.type === "chest" ? (
          <StoreItemChest item={item} />
        ) : item.id === "mythic-gear" ? (
          <StoreItemMythic item={item} />
        ) : item.type === "pack" ? (
          <StoreItemPack item={item} />
        ) : (
          <StoreItemContent item={item} />
        )}

        <Stack
          direction="row"
          sx={{
            backgroundColor: alpha(darkColor, 0.5),
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            boxShadow: `
              inset 0px -4px 1px 0px rgba(0, 0, 0, 0.4), 
              inset 2px 0px 1px 0px rgba(255,255,255,.5), 
              inset 0px -18px 0px 2px rgba(0, 0, 0, 0.05)
          `,
            borderBottomLeftRadius: borderRadiusNew,
            borderBottomRightRadius: borderRadiusNew,
          }}
          py={0.2} // Increased padding slightly
        >
          {item.currency === "gems" && (
            <SmartImage
              src="/images/icons/gem.webp"
              alt="gem"
              width={30}
              height={30}
              style={{
                marginLeft: "-5px",
                marginRight: "5px",
              }}
            />
          )}
          {item.prices ? (
            <H5 py={1} zIndex={1}>
              {item.prices.USD.sign}
              {item.prices.USD.amount}
            </H5>
          ) : (
            <H5 py={1} zIndex={1}>
              {item.price === 0 ? "FREE" : item.price}
            </H5>
          )}
        </Stack>
      </Stack>

      <PurchaseConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handlePurchase}
        item={item}
      />

      <PurchaseSuccessModal
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        item={item}
      />

      {buyChestLevel && (
        <OpenChestModal
          open={!!buyChestLevel}
          chestLevel={buyChestLevel}
          chestHash={chestHash}
          onClaim={handleChestPurchaseComplete}
        />
      )}
    </>
  );
}
