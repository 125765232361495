import { useState, useEffect, useCallback } from "react";

export function useDOMShake(duration = 500, intensity = 5, delay = 650) {
  const [shake, setShake] = useState({ x: 0, y: 0 });
  const [shouldShake, setShouldShake] = useState(false);

  const start = useCallback(() => {
    setTimeout(() => {
      setShouldShake(true);
    }, delay);
  }, [delay]);

  useEffect(() => {
    if (!shouldShake) return;

    let startTime;
    let animationFrameId;

    const animate = (time) => {
      if (!startTime) startTime = time;
      const progress = time - startTime;

      if (progress < duration) {
        const x = Math.random() * 2 - 1;
        const y = Math.random() * 2 - 1;
        setShake({
          x: x * intensity * (1 - progress / duration),
          y: y * intensity * (1 - progress / duration),
        });
        animationFrameId = requestAnimationFrame(animate);
      } else {
        setShake({ x: 0, y: 0 });
        setShouldShake(false);
      }
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [duration, intensity, shouldShake]);

  const style = {
    transform: `translate(${shake.x}px, ${shake.y}px)`,
  };

  return { style, start };
}
