import { useEffect } from "react";
import { Stack } from "@mui/material";
import { primary, scrollbarStyles, topBarHeight } from "@/theme/colors";
import { ScreenModal } from "@/components/ScreenModal";
import { ResourceDisplay } from "@/components/ResourceDisplay";
import { useState } from "react";
import { useSounds } from "@/hooks/useSound";
import { clickSound, won2xSound } from "@/utils/sounds";
import { useAlert } from "@/hooks/useAlert";
import { AtlasBottom } from "./atlas/AtlasBottom";
import { AtlasArmory } from "./atlas/AtlasArmory";
import { useAppStore } from "@/hooks/useAppStore";
import { AtlasBestiary } from "./atlas/AtlasBestiary";
import { useHapticFeedback } from "@telegram-apps/sdk-react";
import { useUserStore } from "../logic/useUserStore";
import { useAssetStore } from "../logic/useAssetStore";
import { usePlayerMetricsStore } from "../logic/usePlayerMetricsStore";

export function Atlas({ open, onClose }) {
  const { setBadge } = useAppStore();
  const { playSound } = useSounds();
  const [tab, setTab] = useState(1);
  const { achievements: playerAchievements, claimAchievementRewards } =
    usePlayerMetricsStore();
  const { user } = useUserStore();
  const { GEAR, RARITY, ENEMIES } = useAssetStore();
  const hapticFeedback = useHapticFeedback(true);
  const { setError } = useAlert();

  useEffect(() => {
    if (playerAchievements) {
      // Check for unclaimed gear achievements
      if (playerAchievements.gear) {
        const hasUnclaimedGear = Object.values(playerAchievements.gear).some(
          (achievement) => achievement.achieved && !achievement.claimed
        );
        setBadge("atlasArmory", hasUnclaimedGear);
      }

      // Check for unclaimed enemy achievements
      if (playerAchievements.enemies) {
        const hasUnclaimedEnemies = Object.values(
          playerAchievements.enemies
        ).some((achievement) => achievement.achieved && !achievement.claimed);
        setBadge("atlasBestiary", hasUnclaimedEnemies);
      }
    }
  }, [playerAchievements, setBadge]);

  const handleTabClick = (tab) => {
    playSound(clickSound);
    setTab(tab);
  };

  const handleClaimReward = async (item) => {
    if (item.isClaimed || !item.isAchieved) return;
    const response = await claimAchievementRewards({
      achievementType: ["enemy", "elite", "boss"].includes(item.type)
        ? "enemies"
        : "gear",
      id: item.id,
    });
    if (!response.success) {
      setError(response.error);
    } else {
      playSound(won2xSound);
      hapticFeedback?.impactOccurred("light");
    }
  };

  if (!playerAchievements) return null;

  const groupedGear = GEAR.reduce((acc, item) => {
    if (!acc[item.rarity]) {
      acc[item.rarity] = [];
    }
    const achievementData =
      playerAchievements.gear && playerAchievements.gear[item.id];
    const isAchieved = achievementData?.achieved;
    const isClaimed = achievementData?.claimed;
    acc[item.rarity].push({ ...item, isAchieved, isClaimed });
    return acc;
  }, {});

  const sortedRarities = Object.keys(RARITY).filter(
    (rarity) => groupedGear[rarity]
  );

  return (
    <ScreenModal
      open={open}
      onClose={onClose}
      titleBgColor={primary.purple}
      title="Atlas"
      titleImage="/images/icons/book.webp"
      contentSx={{
        padding: "0px",
        background: "linear-gradient(to bottom, #B874FC, #7029D4)",
        height: "100%",
      }}
      closeIconSx={{
        top: "10px",
      }}
    >
      <Stack
        sx={{
          paddingTop: "10px",
          paddingBottom: topBarHeight,
          height: "100%",
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          width="200px"
          margin="0 auto"
          mt={0.7}
        >
          <ResourceDisplay
            icon="/images/icons/gem.webp"
            count={user?.resources?.gems}
            onClick={() => {}}
          />
          <ResourceDisplay
            icon="/images/icons/coin.webp"
            count={user?.resources?.gold}
            onClick={() => {}}
          />
        </Stack>

        <Stack
          p={2}
          flexGrow={1}
          overflow="auto"
          sx={{ ...scrollbarStyles }}
          mt={1}
        >
          {tab === 1 && (
            <AtlasBestiary
              tab={tab}
              enemies={ENEMIES}
              playerAchievements={playerAchievements}
              onClaim={handleClaimReward}
            />
          )}

          {tab === 2 && (
            <AtlasArmory
              tab={tab}
              sortedRarities={sortedRarities}
              groupedGear={groupedGear}
              onClaim={handleClaimReward}
            />
          )}
        </Stack>

        <AtlasBottom tab={tab} onTabClick={handleTabClick} />
      </Stack>
    </ScreenModal>
  );
}
