import { useEffect, useRef } from "react";
import { Container } from "@pixi/react";
import { Spine } from "pixi-spine";
import * as PIXI from "pixi.js";
import { CDN_URL } from "@/constants";

export function SimpleSpine({
  path = `${CDN_URL}/heroes/monster_03.json`,
  shadow = true,
  size,
  skin = "Idle",
  x = 0,
  y = 0,
}) {
  const containerRef = useRef(null);
  const shadowRef = useRef(null);
  const spineRef = useRef(null);

  useEffect(() => {
    const loadSpine = async () => {
      try {
        // Clean up existing content
        if (containerRef.current) {
          containerRef.current.removeChildren();
        }

        const spineData = await PIXI.Assets.load(path);
        const spine = new Spine(spineData.spineData);

        spine.skeleton.setSkin(null);
        spine.skeleton.setSkinByName(skin);

        spine.state.setAnimation(0, "Idle", true);

        spine.scale.set(size / 400);
        spine.position.set(size / 2, size / 1.3);

        // Create shadow
        if (shadow) {
          const shadowGraphic = new PIXI.Graphics();
          shadowGraphic.beginFill(0x000000, 0.3);
          shadowGraphic.drawEllipse(0, 0, size / 3.2, size / 7);
          shadowGraphic.endFill();
          shadowGraphic.position.set(size / 2, size - size / 4);

          containerRef.current?.addChild(shadowGraphic);
          shadowRef.current = shadowGraphic;
        }

        if (containerRef.current) {
          containerRef.current.addChild(spine);
          spineRef.current = spine;
        }
      } catch (error) {
        console.error("Error loading Spine character:", error);
      }
    };

    loadSpine();

    // Cleanup function
    return () => {
      if (containerRef.current) {
        containerRef.current.removeChildren();
      }
      // if (spineRef.current) {
      //   spineRef.current.destroy();
      // }
      // if (shadowRef.current) {
      //   shadowRef.current.destroy();
      // }
    };
  }, [path, skin, shadow, size]);

  return <Container ref={containerRef} x={x} y={y} />;
}
