import { Character } from "../components/Character";
import { slimeDefeated, slimeHurtSounds } from "@/utils/sounds";
import { getHeroPath } from "@/utils/player";
import { useUserStore } from "../logic/useUserStore";
import { useAssetStore } from "../logic/useAssetStore";
import { useAttackStore } from "../logic/useAttackStore";
import { usePlayerStore } from "../logic/usePlayerStore";

export function Player({ x, y, skin, scale }) {
  const { player } = usePlayerStore();
  const { playerAttackCount } = useAttackStore();
  const { user } = useUserStore();
  const { HEROES_NEW } = useAssetStore();
  const heroPath = getHeroPath(user, HEROES_NEW);

  return (
    <Character
      attackCount={playerAttackCount}
      x={x || window.innerWidth / 2.5}
      y={y || window.innerHeight / 1.3}
      hp={player.hp}
      scale={scale || 0.4}
      path={heroPath}
      skin={skin || "Back"}
      hurtSounds={slimeHurtSounds}
      defeatedSounds={[slimeDefeated]}
      shadow
    />
  );
}
