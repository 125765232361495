import { useEffect, useState } from "react";

const starImages = [
  "/images/effects/Particle_03.webp",
  "/images/effects/Particle_04.webp",
  "/images/effects/Particle_05.webp",
];

export function StarEffect({ totalStars = 40 }) {
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const newStars = [];
    for (let i = 0; i < totalStars; i++) {
      const angle = Math.random() * 360;
      const distance = Math.random() * (40 - 10) + 20;
      const image = starImages[Math.floor(Math.random() * starImages.length)];
      newStars.push({
        left: `${50 + distance * Math.cos(angle)}%`,
        top: `${50 + distance * Math.sin(angle)}%`,
        backgroundImage: `url(${image})`,
        animation: "popStar 0.75s ease-out infinite",
      });
    }
    setStars(newStars);
  }, []);

  return (
    <>
      {stars.map((style, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            ...style,
            width: "20px",
            height: "20px",
            backgroundSize: "cover",
            zIndex: 0,
            overflow: "hidden",
          }}
        />
      ))}
    </>
  );
}
