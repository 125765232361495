import { useEnergyStore } from "@/game/logic/useEnergyStore";
import styled from "@emotion/styled";
import { useState, useEffect, useRef, useCallback, memo } from "react";

const TIME_BETWEEN_FLASHES = 500;
const TIME_FLASH_DURATION = 300;

const FlashOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  pointer-events: none;
  animation: white-flash ${TIME_FLASH_DURATION}ms ease-out;
`;

const WhiteFlashComponent = memo(({ show, onComplete }) => {
  const [isVisible, setIsVisible] = useState(false);
  const lastFlashTime = useRef(0);
  const timeoutRef = useRef(null);

  const triggerFlash = useCallback(() => {
    const now = Date.now();
    if (now - lastFlashTime.current >= TIME_BETWEEN_FLASHES) {
      setIsVisible(true);
      lastFlashTime.current = now;

      timeoutRef.current = setTimeout(() => {
        setIsVisible(false);
        onComplete();
      }, TIME_FLASH_DURATION);
    }
  }, [onComplete]);

  useEffect(() => {
    if (show) {
      triggerFlash();
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setIsVisible(false);
    }
  }, [show, triggerFlash]);

  return isVisible ? <FlashOverlay /> : null;
});

WhiteFlashComponent.displayName = "WhiteFlashComponent";

export const WhiteFlash = memo(() => {
  const [showFlash, setShowFlash] = useState(false);
  const { energy } = useEnergyStore();

  useEffect(() => {
    if (energy <= 0) {
      setShowFlash(true);
    }
  }, [energy]);

  return (
    <WhiteFlashComponent
      show={showFlash}
      onComplete={() => setShowFlash(false)}
    />
  );
});

WhiteFlash.displayName = "WhiteFlash";
