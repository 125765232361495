import { useState, useEffect } from "react";
import { useImageLoadStore } from "@/hooks/useImageLoadStore";
import { CDN_URL } from "@/constants";

export const Image = ({
  src,
  alt,
  width,
  height,
  loading = "lazy",
  onLoad,
  priority = false,
  ...props
}) => {
  const cdnSrc = `${CDN_URL}${src}`;
  const [imageSrc, setImageSrc] = useState(cdnSrc);
  const { registerImage, imageLoaded } = useImageLoadStore();

  useEffect(() => {
    registerImage();
    return () => {
      // Cleanup if needed
    };
  }, [registerImage]);

  useEffect(() => {
    const img = new window.Image();
    img.src = cdnSrc;
    img.onload = () => {
      setImageSrc(cdnSrc);
      imageLoaded();
      onLoad && onLoad();
    };
  }, [cdnSrc]);

  return (
    <img
      src={imageSrc}
      alt={alt}
      width={width}
      height={height}
      loading={loading}
      {...props}
    />
  );
};
