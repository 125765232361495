import { Box, Grid, Stack } from "@mui/material";
import { useMemo } from "react";
import {
  backgroundColorSecondary,
  maxWidth,
  topBarHeight,
  bottomBarHeight,
  scrollbarStyles,
} from "@/theme/colors";
import { H3 } from "@/components/Typography";
import { StoreItem } from "@/game/store/StoreItem";
import { createClaimedOffersMap, filterCategoryItems } from "@/utils/store";
import { useUserStore } from "./logic/useUserStore";
import { useAssetStore } from "./logic/useAssetStore";

export function Store() {
  const { user } = useUserStore();
  const { SHOP_ITEMS } = useAssetStore();

  const claimedOffersMap = useMemo(
    () => createClaimedOffersMap(user?.limitedTimeOffers),
    [user?.limitedTimeOffers]
  );

  const categoriesWithFilteredItems = useMemo(
    () => filterCategoryItems(SHOP_ITEMS, claimedOffersMap, user?.passes),
    [SHOP_ITEMS, claimedOffersMap, user?.passes]
  );

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          overflowY: "scroll",
          paddingTop: topBarHeight,
          paddingBottom: bottomBarHeight,
          maxWidth: maxWidth,
          margin: "0 auto",
          position: "relative",
          ...scrollbarStyles,
        }}
      >
        <Stack spacing={4} px={2} mb={4}>
          {categoriesWithFilteredItems?.map((category) => (
            <Stack
              key={category.name}
              id={category.name}
              alignItems="center"
              sx={{
                backgroundColor: backgroundColorSecondary,
                padding: "10px",
                borderRadius: "6px",
              }}
            >
              <H3 mt={1} color={"white"}>
                {category.name}
              </H3>

              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                mt={0}
              >
                {category.items.map((item, index) => (
                  <Grid item key={index} xs={item.size}>
                    <StoreItem item={item} category={category} />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          ))}
        </Stack>
      </Box>
    </>
  );
}
