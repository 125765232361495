import { Box, Grid } from "@mui/material";
import { Paragraph } from "../../components/Typography";
import { colorizeText } from "@/utils/misc";
import { SunBurst } from "../../components/effects/SunBurst";
import { SlowStarEffect } from "../../components/effects/SlowStarEffect";
import { WhiteRadial } from "../../components/effects/WhiteRadial";
import { SmartImage } from "@/components/SmartImage";
import { useAssetStore } from "../logic/useAssetStore";

export function StoreItemChest({ item }) {
  const { RARITY_COLORS, RARITY_RANK } = useAssetStore();

  return (
    <Box
      sx={{
        padding: "6px",
        position: "relative",
      }}
    >
      <Paragraph
        color="white"
        sx={{
          zIndex: 1,
          position: "absolute",
          top: "4px",
          left: 0,
          right: 0,
          margin: "0 auto",
        }}
      >
        {item.name}
      </Paragraph>

      <Grid container mt={3}>
        <Grid item xs={7}>
          <Box sx={{ position: "relative" }}>
            <WhiteRadial />
            <Box
              sx={{
                position: "absolute",
                top: "-30px",
                left: item.size === 12 ? "5px" : "-18px",
                right: 0,
                bottom: 0,
                margin: "0 auto",
                height: "140px",
                width: "140px",
                zIndex: 0,
              }}
            >
              <SunBurst />
            </Box>

            <SlowStarEffect
              count={20}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: "0 auto",
                width: 80,
                height: 80,
              }}
            />

            <SmartImage
              src={item.image}
              alt={item.name || "Item"}
              width={item.size === 12 ? 160 : 140}
              height={item.size === 12 ? 160 : 140}
              style={{
                position: "absolute",
                top: item.size === 12 ? "-50px" : "-40px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 0,
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={5} zIndex={0} sx={{ lineHeight: 1 }}>
          {colorizeText(item, RARITY_COLORS, RARITY_RANK)}
        </Grid>
      </Grid>
    </Box>
  );
}
