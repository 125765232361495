import { create } from "zustand";
import { getPlayerMetrics, claimAchievementReward } from "@/lib/api";
import { useChallengeStore } from "./useChallengeStore";
import { useUserStore } from "./useUserStore";

export const usePlayerMetricsStore = create((set) => ({
  achievements: null,

  fetchAndUpdateMetrics: async () => {
    const user = useUserStore.getState().user;
    try {
      console.log("👉  fetching metrics & achievements");
      const { data } = await getPlayerMetrics({ jwt: user.jwt });

      // Update achievements in this store
      set({ achievements: data.achievements });

      // Update challenges in ChallengeStore
      useChallengeStore.getState().setChallenge(data.challenge);

      return { success: true };
    } catch (error) {
      console.error("Error fetching metrics:", error);
      return { success: false, error: error.message };
    }
  },

  claimAchievementRewards: async ({ achievementType, level, id }) => {
    const user = useUserStore.getState().user;
    try {
      const { data } = await claimAchievementReward({
        userId: user.id,
        jwt: user.jwt,
        achievementType,
        level,
        id,
      });

      if (data.achievements) {
        // Update achievements in this store
        set({ achievements: data.achievements });

        // Update user resources
        useUserStore.getState().setUser({
          ...user,
          resources: data.user.resources,
        });
        return { success: true };
      }
      return { success: false, error: "No achievements to claim" };
    } catch (error) {
      console.error("Error claiming achievement:", error);
      return { success: false, error: error.message };
    }
  },
}));
