import { useCallback } from "react";
import { useHapticFeedback } from "@telegram-apps/sdk-react";
import { clickSound } from "@/utils/sounds";
import { useUserStore } from "@/game/logic/useUserStore";

export function useClickSound(onClick, gaEvent, sound = clickSound) {
  const { user } = useUserStore();
  const playSounds = user?.settings?.playSounds ?? true;

  const hapticFeedback = useHapticFeedback(true);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (gaEvent) {
        // TODO: add GA event
        // sendGAEvent("event", gaEvent);
      }

      hapticFeedback?.impactOccurred("light");
      if (playSounds) {
        sound.play();
      }

      if (onClick) {
        onClick(e);
      }
    },
    [onClick, sound, hapticFeedback]
  );

  return handleClick;
}
