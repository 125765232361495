import { Stack } from "@mui/material";
import { H4 } from "./Typography";
import { useClickSound } from "@/hooks/useClickSound";
import {
  borderRadiusNew,
  greenTextColor,
  greyLight,
  primary,
  purpleTextColor,
  redTextColor,
  secondary,
} from "@/theme/colors";
import { SmartImage } from "@/components/SmartImage";

export const SimpleButton = ({
  children,
  image,
  icon,
  onClick,
  color = "yellow",
  size = "M",
  disabled = false,
  sx,
}) => {
  const handleClick = useClickSound(onClick);
  const IconComponent = icon;

  const colors = {
    yellow: {
      background: primary.main,
      text: primary.light,
    },
    green: {
      background: primary.green,
      text: greenTextColor,
    },
    blue: {
      background: secondary.main,
      text: secondary.light,
    },
    red: {
      background: primary.red,
      text: redTextColor,
    },
    purple: {
      background: primary.purple,
      text: purpleTextColor,
    },
    grey: {
      background: greyLight[300],
      text: "white",
    },
  };

  const sizes = {
    S: {
      minWidth: "60px",
      fontSize: 16,
      py: 0.4,
      px: 1.5,
      shadow: "-12px",
      imageSize: 22,
    },
    M: {
      minWidth: "80px",
      fontSize: 20,
      py: 0.7,
      px: 2,
      shadow: "-18px",
      imageSize: 32,
    },
    L: {
      minWidth: "100px",
      fontSize: 32,
      py: 1,
      px: 3,
      shadow: "-30px",
      imageSize: 38,
    },
  };

  return (
    <Stack
      onClick={(e) => !disabled && handleClick(e)}
      direction="row"
      sx={{
        opacity: disabled ? 0.5 : 1,
        backgroundColor: colors[color].background,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: borderRadiusNew,
        position: "relative",
        boxShadow: `
          inset 0px 2px 0px 0px rgba(255,255,255,.5), 
          inset 0px -3px 0px 0px rgba(0, 0, 0, 0.4), 
          inset 0px ${sizes[size].shadow} 0px 2px rgba(0, 0, 0, 0.1), 
          7px 7px 10px 0px rgba(0,0,0,.1), 
          0px 4px 2px 0px rgba(0,0,0,.5)
        `,
        "&:active": {
          boxShadow: `
            inset 0px 2px 0px 0px rgba(255,255,255,.5), 
            inset 0px -3px 0px 0px rgba(0, 0, 0, 0.4),
            inset 0px ${sizes[size].shadow} 0px 2px rgba(0, 0, 0, 0.1)
          `,
          transform: "translateY(2px)",
        },
        minWidth: sizes[size].minWidth,
        pointerEvents: disabled ? "none" : "auto",
        ...sx,
      }}
      py={sizes[size].py}
      px={sizes[size].px}
    >
      {IconComponent && (
        <IconComponent
          sx={{
            fontSize: sizes[size].fontSize,
            marginRight: children ? 1 : 0,
            color: colors[color].text,
          }}
        />
      )}
      {image && (
        <SmartImage
          src={image}
          alt="gem"
          width={sizes[size].imageSize * 2}
          height={sizes[size].imageSize * 2}
          style={{
            marginRight: "0px",
            marginLeft: "-5px",
            width: `${sizes[size].imageSize}px`,
            height: "auto",
          }}
        />
      )}
      <H4
        color={colors[color].text}
        sx={{
          // "-webkit-text-stroke": "0.5px black",
          fontSize: sizes[size].fontSize,
        }}
      >
        {children}
      </H4>
    </Stack>
  );
};
